import React from "react";
import { Badge, Tooltip } from "antd";

const ShootTypeBadge = ({ shootType, bgColor }) => {
  return (
    <Tooltip title={shootType}>
      <Badge count={shootType.charAt(0)} style={{ backgroundColor: bgColor }} />
    </Tooltip>
  );
};

export default ShootTypeBadge;
