import React from "react";
import ReactPlayer from "react-player";
import { Row, Col, Typography } from "antd";

import { READY } from "../../../redux/action/shoot/shootConstants";
import processIcon from "../../../content/process.png";
import PlayIcon from "../../../content/icons/play.svg";
import "./video.scss";
const Video = ({ video }) => {
  const type = window.location.href.includes("/b/") ? "Branded" : "Unbranded";

  const getVideoURL = (media) => {
    let dropboxLink;

    if (media?.dropboxBranded) {
      dropboxLink =
        type === "Branded"
          ? `${media?.dropboxBranded}&raw=1`
          : `${media?.dropboxUnbranded}&raw=1`;
    } else {
      dropboxLink = null;
    }

    const youTubeLinks = media.youtubeLinks.filter((obj) => obj.type === type);
    const s3Links = media.s3Links.filter((obj) => obj.type === type);
    if (dropboxLink) {
      return dropboxLink;
    } else if (youTubeLinks.length && youTubeLinks[0]["url"]) {
      return youTubeLinks[0]["url"];
    } else {
      console.log("s3Links", s3Links);
      return s3Links[0]["url"];
    }
  };
  return (
    <Row
      style={{
        width: "100%",
        height: "76vh",
        background: "rgba(56, 67, 79, 0.8)",
        filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2))",
      }}
    >
      <Col span={4}></Col>
      <Col span={16} className="player-wrapper">
        {video.status === READY && getVideoURL(video) !== "" ? (
          <ReactPlayer
            className="react-player"
            // url={getVideoURL(video)}
            url={getVideoURL(video)}
            width="100%"
            height="100%"
            controls={true}
            light={true}
            playing={true}
            playIcon={<img src={PlayIcon} alt="play" />}
          />
        ) : (
          <Row
            justify="space-around"
            align="middle"
            style={{ position: "relative", top: "25%" }}
          >
            <Col span={24}>
              <img src={processIcon} style={{ height: "35vh" }} alt="" />
            </Col>
            <Col span={24} align="middle">
              <Typography.Text type="secondary" style={{ color: "white" }}>
                We are processing your work.
              </Typography.Text>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default Video;
