import {
    FETCH_AMENITIES_BY_SUBDIVISION_ID,
    FETCH_AMENITIES_BY_ID,
    CREATE_AMENITY,
    REMOVE_AMENITY,
    UPDATE_AMENITY,
    UPDATE_AMENITIES,
    RESET_SELECTED_AMENITY
} from "../action/types";

const initialState = { amenities: [], isLoading: true };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_AMENITY:
            return {
                ...state,
                isLoading: false,
                selectedAmenity: payload,
            };
        case UPDATE_AMENITY:
            return {
                ...state,
                selectedAmenity: payload,
            };
        case UPDATE_AMENITIES:
            const filteredAmenities = state.amenities.map(amenity => amenity._id === payload._id ? payload : amenity);
            return {
                ...state,
                amenities: filteredAmenities,
            };
        case FETCH_AMENITIES_BY_SUBDIVISION_ID:
            return {
                ...state,
                isLoading: false,
                amenities: payload,
            };
        case REMOVE_AMENITY:
            return {
                ...state,
                amenities: state.amenities.filter((amenity) => amenity._id !== payload),
            };
        case FETCH_AMENITIES_BY_ID:
            return {
                ...state,
                isLoading: false,
                selectedAmenity: payload,
                amenities: [payload, ...state.amenities],
            };
        case RESET_SELECTED_AMENITY:
            return {
                ...state,
                selectedAmenity: undefined,
            };
        default:
            return state;
    }
}
