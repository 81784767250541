import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Card, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Loading from "../../../shared/loading/Loading.js";
import { fetchBrokerage } from "../../../redux/action/brokerageAction";
import { fetchEntityNotes } from "../../../redux/action/note";
import BrokerageClients from "./BrokerageClients.js";
import BrokerageNotes from "./BrokerageNotes.js";

const BrokerageDetails = ({
  match,
  fetchBrokerage,
  brokerage: { selectedBrokerage, isLoading },
  fetchEntityNotes,
  note: { notes },
  auth: { user },
}) => {
  useEffect(() => {
    fetchBrokerage(match.params.id);
    fetchEntityNotes(match.params.id);
  }, [fetchEntityNotes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && selectedBrokerage && (
          <Row style={{ margin: "10px" }}>
            <Col span={8}>
              <Card>
                <Row>
                  <Col span={6}>
                    {selectedBrokerage.logo ? (
                      <Avatar
                        shape="square"
                        size={100}
                        src={selectedBrokerage.logo}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        size={100}
                        icon={<UserOutlined />}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    <h3>
                      {selectedBrokerage.name}{" "}
                      <span
                        style={{
                          fontSize: "0.8rem",
                          color: "#777",
                          display: "block",
                        }}
                      >
                        HSB# {selectedBrokerage.hsb}
                      </span>
                    </h3>

                    {selectedBrokerage.street +
                      ", " +
                      (selectedBrokerage.room
                        ? "#" + selectedBrokerage.room + " "
                        : "")}
                    <br />
                    {(selectedBrokerage.city || "") +
                      ", " +
                      (selectedBrokerage.state || "") +
                      " " +
                      selectedBrokerage.zip}
                    <br />
                    <br />

                    <a
                      href={`http://${selectedBrokerage.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedBrokerage.website}
                    </a>
                    <br />
                  </Col>
                </Row>
              </Card>
              {user && (
                <>
                  <BrokerageNotes
                    brokerage={selectedBrokerage._id}
                    userId={user._id}
                    notes={notes}
                  />
                </>
              )}
            </Col>
            <Col span={16}>
              <BrokerageClients />
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  brokerage: state.brokerages,
  note: state.note,
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchBrokerage, fetchEntityNotes })(
  BrokerageDetails
);
