import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Card, Table, Typography, Button, Modal } from "antd";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import Loading from "../../../shared/loading/Loading.js";
import Can from "../../../../webapp/user/Component/Auth/Can";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  removeCredit,
  updateCredit,
  createCredit,
} from "../../../../webapp/redux/action/creditAction";
import CreditForm from "../../Components/Credit/CreditForm";
import queryString from "query-string";

const { Text } = Typography;

const ClientCredits = ({
  clientCredits,
  isLoading,
  removeCredit,
  updateCredit,
  client: { clients },
  createCredit,
  selectedClient,
}) => {
  const [tableOpt, setOperation] = useState({
    pageSize: 10,
    skip: 1,
    pageSizeOptions: [10, 20, 50, 100],
  });
  const history = useHistory();
  const [state, setState] = useState({
    title: "Edit Credit",
    mode: "Edit",
    modalVisiblity: false,
    initVal: null,
  });
  const { mode, modalVisiblity, initVal, title } = state;
  const { confirm } = Modal;
  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Credit",
      modalVisiblity: true,
    });
  };
  const formInitValue = {
    client: {
      _id: selectedClient._id,
      first: selectedClient.first,
      last: selectedClient.last,
      hsf: selectedClient.hsf,
      full: selectedClient.full,
    },
    credit_type: "",
    code: "",
    amount: 0,
    redeemed: false,
    _redeemed_at: null,
  };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };
  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createCredit(body);
      return res;
    } else {
      const res = await updateCredit(body._id, body);
      return res;
    }
  };
  const onConfirmRemove = (credit) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete this ${credit.credit_type} credit.`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeCredit(credit._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "credit_type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => <Text ellipsis={true}>{text}</Text>,
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Amount",
      key: "amount",
      render: (amount, record) => (
        <span>
          <NumberFormat
            value={record.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      ),
    },
    {
      title: "Issued",
      key: "_created_at",
      render: (_created_at, record) =>
        moment(record._created_at).format("MM/D/YY"),
    },
    {
      title: "Redeemed",
      key: "redeemed",
      render: (redeemed, record) => (
        <span>{record.redeemed ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          <Can do="edit" on="Credits">
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Credits">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
              disabled={record.redeemed}
            />
          </Can>
        </span>
      ),
    },
  ];

  const setPageBasedOnParam = () => {
    let skip = 1;
    const value = queryString.parse(history.location.search);
    if (value.page) {
      skip = parseInt(value.page);
      setOperation({ ...tableOpt, skip });
    } else {
      setOperation({ ...tableOpt, skip });
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", setPageBasedOnParam);
    return () => {
      window.removeEventListener("popstate", setPageBasedOnParam);
    };
  }, []);
  useEffect(() => {
    setPageBasedOnParam();
  }, []);

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("tab", "Credits");
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div>
      <Card>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clientCredits && (
          <>
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() =>
                clickHandler("Add", formInitValue, state.clientCreditDetails)
              }
            >
              <PlusOutlined /> Add a Credit
            </Button>
            <Table
              dataSource={clientCredits}
              columns={columns}
              onChange={handleTableChange}
              pagination={{
                ...tableOpt,
                total: clientCredits?.length,
                current: tableOpt.skip,
                defaultCurrent: tableOpt.skip,
              }}
              rowKey={(record) => record._id}
              scroll={{ x: 500 }}
            />
            <Modal
              title={<span style={{ color: "white" }}>{title}</span>}
              onCancel={handleCancel}
              visible={modalVisiblity}
              footer={false}
              mask={true}
              destroyOnClose={true}
            >
              <CreditForm
                initVal={initVal}
                close={handleCancel}
                clients={clients}
                submitHandler={(values) => submitHandler(mode, values)}
              />
            </Modal>
          </>
        )}
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  // credit: state.credit.credits,
  // totalCount: state.credit.total,
  client: state.clients,
});
export default connect(mapStateToProps, {
  updateCredit,
  removeCredit,
  createCredit,
})(ClientCredits);
