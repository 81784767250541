import { AbilityBuilder, Ability } from "@casl/ability";
import store from "../../../redux/store";
// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth.user;

  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
});

function defineRulesFor(user) {
  const { can, rules } = new AbilityBuilder();

  if (user && user.access?.includes("CLIENT")) {
    // can("manage", "Shoots");
    can("edit", "Shoots");
    can("create", "Ratings");
    can("create", "Notes");
    can("read", "Notes");
    // can("manage", "Invoices", { userId: user._id });
    can("read", "Invoices");
    can("download", "Invoices");
    can("read", "Activity");
    can("accept", "Request");
    can("reject", "Request");
    can("edit", "Team");
    can("delete", "Team");
    can("delete", "Photos");
    can("read", "Social");
  }
  if (user && user.access?.includes("UBERADMIN")) {
    can("manage", "Shoots");
    can("create", "Shoots");
    can("read", "Shoots");
    can("edit", "Shoots");
    can("cancel", "Shoots");
    can("delete", "Shoots");
    can("complete", "Shoots");

    can("manage", "Invoices");
    can("create", "Invoices");
    can("edit", "Invoices");
    can("delete", "Invoices");
    can("send", "Invoices");
    can("read", "Invoices");
    can("comp", "Invoices");

    can("read", "Ratings");

    can("check", "Checklist");

    can("manage", "Brokerages");
    can("create", "Brokerages");
    can("read", "Brokerages");
    can("edit", "Brokerages");
    can("delete", "Brokerages");

    can("create", "Clients");
    can("read", "Clients");
    can("edit", "Clients");
    can("delete", "Clients");

    can("create", "Credits");
    can("read", "Credits");
    can("edit", "Credits");
    can("delete", "Credits");

    can("manage", "Photographers");
    can("create", "Photographers");
    can("read", "Photographers");
    can("edit", "Photographers");
    can("delete", "Photographers");

    can("manage", "Offerings");
    can("create", "Offerings");
    can("read", "Offerings");
    can("edit", "Offerings");
    can("delete", "Offerings");

    can("manage", "Amenity");
    can("create", "Amenity");
    can("read", "Amenity");
    can("edit", "Amenity");
    can("delete", "Amenity");

    can("create", "Notes");
    can("read", "Notes");
    can("delete", "Notes");

    can("manage", "People");
    can("manage", "Photos");
    can("edit", "Dollhouse");
    can("edit", "Video");
    can("edit", "Flyer");
    can("read", "Activity");

    can("approve", "Request");
    can("reschedule", "Request");
    can("deny", "Request");
    can("edit", "Team");
    can("delete", "Team");
    can("read", "Directions");
    can("confirm", "Address");
    can("request", "Confirmation");

    can("edit", "Device");
    can("delete", "Device");

    can("edit", "Social");
    can("manage", "Social");
    can("manage", "Templates");
    can("send", "SMS");
    can("manage", "Posts");
  }
  if (user && user.access?.includes("ADMIN")) {
    can("manage", "Shoots");
    can("create", "Shoots");
    can("read", "Shoots");
    can("edit", "Shoots");
    can("cancel", "Shoots");
    can("delete", "Shoots");
    can("complete", "Shoots");

    can("manage", "Invoices");
    can("create", "Invoices");
    can("edit", "Invoices");
    can("delete", "Invoices");
    can("send", "Invoices");
    can("read", "Invoices");
    can("comp", "Invoices");

    can("read", "Ratings");

    can("check", "Checklist");

    can("manage", "Brokerages");
    can("create", "Brokerages");
    can("read", "Brokerages");
    can("edit", "Brokerages");
    can("delete", "Brokerages");

    can("create", "Clients");
    can("read", "Clients");
    can("edit", "Clients");
    can("delete", "Clients");

    can("create", "Credits");
    can("read", "Credits");
    can("edit", "Credits");
    can("delete", "Credits");

    can("manage", "Photographers");
    can("create", "Photographers");
    can("read", "Photographers");
    can("edit", "Photographers");
    can("delete", "Photographers");

    can("manage", "Offerings");
    can("create", "Offerings");
    can("read", "Offerings");
    can("edit", "Offerings");
    can("delete", "Offerings");

    can("manage", "Amenity");
    can("create", "Amenity");
    can("read", "Amenity");
    can("edit", "Amenity");
    can("delete", "Amenity");

    can("create", "Notes");
    can("read", "Notes");
    can("delete", "Notes");

    can("manage", "People");
    can("manage", "Photos");
    can("edit", "Dollhouse");
    can("edit", "Video");
    can("edit", "Flyer");
    can("read", "Activity");

    can("approve", "Request");
    can("reschedule", "Request");
    can("deny", "Request");
    can("edit", "Team");
    can("delete", "Team");
    can("read", "Directions");
    can("confirm", "Address");
    can("request", "Confirmation");

    can("edit", "Device");
    can("delete", "Device");

    can("edit", "Social");
    can("manage", "Social");
    can("manage", "Templates");
    can("send", "SMS");
  }
  if (user && user.access?.includes("COMMS")) {
    can("manage", "Shoots");
    can("create", "Shoots");
    can("read", "Shoots");
    can("edit", "Shoots");
    can("cancel", "Shoots");
    can("complete", "Shoots");

    can("create", "Invoices");
    can("edit", "Invoices");
    can("delete", "Invoices");
    can("send", "Invoices");
    can("read", "Invoices");

    can("manage", "Brokerages");
    can("create", "Brokerages");
    can("read", "Brokerages");
    can("edit", "Brokerages");
    can("delete", "Brokerages");

    can("create", "Clients");
    can("read", "Clients");
    can("edit", "Clients");
    can("delete", "Clients");

    can("read", "Photographers");

    can("manage", "Photos");

    can("edit", "Dollhouse");
    can("edit", "Video");
    can("edit", "Flyer");

    can("read", "Activity");
    can("check", "Checklist");

    can("approve", "Request");
    can("reschedule", "Request");
    can("deny", "Request");
    can("read", "Notes");
    can("read", "Directions");
    can("confirm", "Address");
    can("request", "Confirmation");

    can("manage", "Social");

    can("send", "SMS");
  }
  if (user && user.access?.includes("EDITOR")) {
    can("manage", "Photos");
    can("edit", "Video");
    can("edit", "Slideshow");
    can("edit", "Dollhouse");
    can("complete", "Shoots");
    can("read", "Notes");
    can("manage", "Amenity");
    can("create", "Amenity");
    can("read", "Amenity");
    can("edit", "Amenity");
    can("delete", "Amenity");
    can("edit", "Social");
  }
  if (user && user.access?.includes("PHOTOGRAPHER")) {
    can("manage", "Photos");
    can("edit", "Video");
    can("edit", "Slideshow");
    can("edit", "Dollhouse");
    can("complete", "Shoots");
    can("read", "Notes");
  }
  if (user && user.access?.includes("MARKETING")) {
    can("manage", "Posts");
  }
  return rules;
}

export default ability;
