import React, { useState } from "react";
import { Row, Col, Button, Typography } from "antd";
import { Input, Form } from "formik-antd";
import { FieldArray } from "formik";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const Property = ({ flyer, setFieldValue, formVal }) => {
  const [state, setState] = useState({
    featureInput: "",
    charLeft: 25,
  });
  const { featureInput } = state;
  const charCounter = (event) => {
    const charLen = event.target.value.length;
    const charLeft = 25 - charLen;
    setState({ featureInput: event.target.value, charLeft });
  };
  return (
    <Form initialValues={flyer.property} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="property.street"
            label="Street"
            className="form-group"
          >
            <Input name="property.street" />
          </Form.Item>
          <Form.Item name="property.city" label="City" className="form-group">
            <Input name="property.city" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="property.state" label="State" className="form-group">
            <Input name="property.state" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="property.zip" label="Zip" className="form-group">
            <Input name="property.zip" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="property.description"
            label="Description"
            className="form-group"
          >
            <Input.TextArea name="property.description" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="property.features"
            label="Features"
            className="form-group"
          >
            <FieldArray
              name="features"
              label="Features"
              render={(arrayHelpers) => (
                <>
                  <Row gutter={[8, 8]}>
                    <Col span={10}>
                      <Input
                        value={featureInput}
                        maxlength="25"
                        onChange={charCounter}
                      />
                    </Col>
                    <Col span={3}>
                      <Button
                        justify="center"
                        icon={<PlusOutlined style={{ color: "lightgreen" }} />}
                        onClick={() => {
                          const arr = formVal.property.features.concat();
                          arr.push(featureInput);
                          setFieldValue("property.features", arr);
                          setState({ ...state, featureInput: "" });
                        }}
                      ></Button>
                    </Col>
                    <Col align="middle" justify="center">
                      <Typography.Text
                        type="secondary"
                        style={{ position: "relative", top: "8px" }}
                      >{`${state.charLeft} left of 25`}</Typography.Text>
                    </Col>
                  </Row>
                  {formVal && formVal.property
                    ? formVal.property.features.map((feature, index) => (
                        <Row key={index} gutter={[8, 8]}>
                          <Col span={10}>
                            <Input name={`property.features.${index}`} />
                          </Col>
                          <Col span={3}>
                            <Button
                              justify="center"
                              icon={<MinusOutlined style={{ color: "red" }} />}
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const arr = formVal.property.features.concat();
                                arr.splice(index, 1);
                                setFieldValue("property.features", arr);
                              }}
                            ></Button>
                          </Col>
                        </Row>
                      ))
                    : null}
                </>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="property.price"
            label="Listing Price"
            className="form-group"
          >
            <Input name="property.price" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="property.sqft" label="Sqft." className="form-group">
            <Input name="property.sqft" type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="property.beds" label="Beds" className="form-group">
            <Input name="property.beds" type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="property.baths" label="Baths" className="form-group">
            <Input name="property.baths" type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="property.year" label="Year" className="form-group">
            <Input name="property.year" type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Property;
