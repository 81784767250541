import React, { Fragment } from "react";
import moment from "moment";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    fontStyle: "bold",
    fontFamily: "Helvetica-Oblique",
  },
});

const InvoiceNo = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Invoice #: </Text>
      <Text style={styles.invoiceDate}>{invoice?.hsn}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Date: </Text>
      <Text>{moment(invoice?._created_at).format("MMMM D, YYYY")}</Text>
    </View>
  </Fragment>
);

export default InvoiceNo;
