import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Card, Button, Modal, Input, Select, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { createNote, removeNote } from "../../../redux/action/note";
import Loading from "../../../shared/loading/Loading.js";
import Can from "../../../user/Component/Auth/Can";

import "./ClientDetails.scss";

const { Paragraph } = Typography;

const ClientNotes = ({
  notes,
  isLoading,
  userId,
  clientId,
  createNote,
  removeNote,
  userAccess,
}) => {
  const { TextArea } = Input;
  const { Option } = Select;

  const [visible, setVisible] = useState(false);

  const [formData, setFormData] = useState({
    text: "",
    access: [],
  });

  // let user = userId;
  const entity_id = clientId;
  const entity_type = "Client";
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    createNote({ ...formData, user: userId, entity_id, entity_type });
    setFormData({});
    // handleOk();
    setVisible(false);
  };

  const handleCancel = () => {
    setFormData({});
    setVisible(false);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, text: e.target.value });
  };

  const handleSelectChange = (value) => {
    setFormData({ ...formData, access: value });
  };

  const handleRemoveNote = (id) => {
    removeNote(id);
  };

  const renderNotes = () => {
    return (
      <div className="client-notes">
        {notes.map((note) => (
          <p key={note._id}>
            {note.user === userId ||
            note.user._id === userId ||
            userAccess.includes("ADMIN") ? (
              <Button
                style={{ float: "right" }}
                onClick={() => handleRemoveNote(note._id)}
                danger
                type="link"
                size="small"
                icon={<DeleteOutlined />}
              />
            ) : null}

            <span className="timestamp">
              {note.user === userId || note.user._id === userId
                ? "Me"
                : note.user.name}{" "}
              - {moment(note._created_at).format("MM/D/YYYY")}
            </span>
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,
              }}
            >
              {note?.text}
            </Paragraph>
          </p>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && notes && (
          <Card style={{ marginTop: "10px" }}>
            <Can do="create" on="Notes"></Can>
            <Button
              onClick={showModal}
              type="link"
              size="small"
              style={{ float: "right" }}
            >
              Add Note
            </Button>
            <h2>Notes</h2>
            {notes.length > 0 ? renderNotes() : <p>No notes to display</p>}
          </Card>
        )}
        <Modal
          title="Add a New Note"
          visible={visible}
          okText={"Save"}
          onOk={handleOk}
          onCancel={handleCancel}
          keyboard={false}
        >
          <h4>Who should see this note?</h4>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select all that apply"
            onChange={handleSelectChange}
            optionLabelProp="label"
          >
            <Option value="ADMIN" label="Admins">
              Admins
            </Option>
            <Option value="COMMS" label="Comms">
              Comms
            </Option>
            <Option value="CLIENT" label="Client">
              Client
            </Option>
            <Option value="EDITOR" label="Editors">
              Editors
            </Option>
            <Option value="PHOTOGRAPHER" label="Photographers">
              Photographers
            </Option>
          </Select>
          <TextArea
            style={{ marginTop: "20px" }}
            name="text"
            rows={4}
            onChange={(e) => onInputChange(e)}
            required
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { createNote, removeNote })(ClientNotes);
