import React, { useEffect, useState } from "react";
import { Row, Col, message, Tooltip, Switch } from "antd";
import FlyerInfo from "./FlyerInfo";
import FlyerPreview from "./FlyerPreview";
import {
  fetchFlyerByShootId,
  updateFlyer,
} from "../../../../redux/action/flyerAction";
//import { fetchTourByShootId } from "../../../../redux/action/tourAction";
import { fetchClient } from "../../../../redux/action/clientAction";
import { connect } from "react-redux";
import { createActivity } from "../../../../redux/action/activityAction";
import { updateShoot } from "../../../../redux/action/shootAction";

const Flyer = ({
  shoot: { selectedShoot },
  fetchFlyerByShootId,
  // fetchTourByShootId,
  updateFlyer,
  updateShoot,
  fetchClient,
  flyer,
  media,
  id,
  client,
  createActivity,
}) => {
  const [state, setState] = useState({
    flyerReady: selectedShoot.flyer.ready ? true : false,
    flyerdisable: true,
  });
  const { flyerReady, flyerdisable } = state;
  useEffect(() => {
    const fetchFlyer = async () => {
      const res = await fetchFlyerByShootId(id);
      if (res.error) {
        message.error("Oops! something went wrong.");
      } else {
        fetchClientData(res.data.client);
      }
    };

    const fetchClientData = async (id) => {
      const res = await fetchClient(id);
      if (res.error) {
        message.error("Oops! something went wrong.");
      }
    };

    fetchFlyer();
    //fetchTour();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeHandler = (flyerReady) => {
    if (flyerReady) {
      const updatedShoot = { ...selectedShoot };
      updatedShoot.flyer.ready = true;
      updateShoot(updatedShoot._id, updatedShoot);
      createActivity(selectedShoot, `Flyer ready.`);
    } else {
      const updatedShoot = { ...selectedShoot };
      updatedShoot.flyer.ready = false;
      updateShoot(updatedShoot._id, updatedShoot);
      createActivity(selectedShoot, `Flyer status changed to not ready.`);
    }
    setState({ ...state, flyerReady });
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Tooltip title="The client will receive an email">
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginLeft: "10px",
                lineHeight: "36px",
              }}
            >
              Is this Flyer ready?
            </span>
          </Tooltip>
          <Switch
            disabled={flyerdisable}
            checked={flyerReady}
            onChange={changeHandler}
          />
          <FlyerInfo
            flyer={flyer}
            update={updateFlyer}
            media={media}
            client={client}
          />
        </Col>
        <Col span={12}>
          <FlyerPreview
            flyer={flyer}
            updateDisableStatus={(flyerdisable) => {
              setState({ ...state, flyerdisable });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flyer: state.flyers.currentFlyer ? state.flyers.currentFlyer : {},
  media: state.media.media ? state.media.media : {},
  client: state.clients.selectedClient ? state.clients.selectedClient : {},
  shoot: state.shoots,
});

export default connect(mapStateToProps, {
  fetchFlyerByShootId,
  updateFlyer,
  updateShoot,
  //  fetchTourByShootId,
  fetchClient,
  createActivity,
})(Flyer);
