import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  LOGOUT_USER,
  USER_LOADED,
  AUTH_ERROR,
  CHANGE_PASSWORD,
  LEGACY_CLIENT,
  LEGACY_FAIL,
} from "./types";
import setAuthToken from "../../utils/setAuthToken";

// Load current user
export const loadCurrentUser = () => async (dispatch) => {
  let config;
  if (localStorage.hstoken) {
    setAuthToken(localStorage.hstoken);
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.hstoken,
      },
    };
  }

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/users/me`,
      config
    );
    // debugger;

    if (res.data.user.status === "Deactivated") {
      //log the user out
      dispatch({
        type: AUTH_ERROR,
      });
      dispatch({
        type: LOGOUT_USER,
      });
    } else {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const registerUser =
  ({
    first,
    last,
    email,
    phone,
    role,
    password,
    brokerage,
    legacyClient,
    authType,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const name = `${first} ${last}`;
    const defaultAccess = ["CLIENT"]; // TODO - Change this when we allow admins to invite users
    const body = JSON.stringify({
      first,
      last,
      name,
      email,
      phone,
      role,
      password,
      access: defaultAccess,
      brokerage,
      legacyClient,
      authType,
    });

    const res = await axios
      .post(`${process.env.REACT_APP_ROOT_URL}/users`, body, config)
      .then((res) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
        setAuthToken(res.data.token);
        return res.data;
      })
      .catch((err) => {
        dispatch({
          type: REGISTER_FAIL,
          payload: {
            error: err.response.data,
          }, // TODO: Handle error
        });
      });
    return res;
  };

export const checkUser = (email) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/auth/email/${email}`
    );
    if (res.data.islegacyClient) {
      dispatch({
        type: LEGACY_CLIENT,
        payload: { ...res.data.user, authType: "LOCAL" },
      });
    }
    return { data: res.data, error: null };
  } catch (err) {
    return (
      { data: null },
      dispatch({
        type: LEGACY_FAIL,
        payload: {
          error:
            err.response.status === 400
              ? err.response.data
              : "Oops! The email address you entered was not found. Please check the spelling or",
          redirectLink:
            err.response.status === 400 ? null : " sign up for an account.",
        },
      })
    );
  }
};

export const loginUser =
  ({ email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      password,
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth`,
        body,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: { error: err.response.data },
      });
      return err.response;
    }
  };

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth/forgot-password`,
        body,
        config
      );

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res.data,
      });

      return {
        data: { status: true },
        error: null,
      };
    } catch (err) {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload: { error: err.response },
      });

      return {
        data: null,
        error: err.response,
      };
    }
  };

export const resetPassword =
  ({ password, confirmPassword, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ password, token });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth/reset-password`,
        body,
        config
      );

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });

      return {
        data: { status: true },
        error: null,
      };
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: { error: err.response },
      });

      return {
        data: null,
        error: err.response,
      };
    }
  };

export const socialLogin =
  ({ user, token, authType }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ user, token, authType });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/auth/social`,
        body,
        config
      );
      if (res.data.islegacyClient) {
        const user = { ...res.data.user, authType };
        dispatch({
          type: LEGACY_CLIENT,
          payload: user,
        });
      } else {
        dispatch({
          type: SOCIAL_LOGIN_SUCCESS,
          payload: res.data,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: SOCIAL_LOGIN_FAIL,
        payload: { error: err.response.data },
      });
    }
  };

export const changePassword = (id, body) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/users/resetpassword/${id}`,
      body
    );
    dispatch({
      type: CHANGE_PASSWORD,
      payload: res.data,
    });
    return {
      data: res.data,
      status: res.status,
      err: null,
    };
  } catch (err) {
    return {
      err: err.message,
      status: err.response.status,
      data: null,
    };
  }
};
export const changeClientPassword = (id, body) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/users/resetpassword/client/${id}`,
      body
    );
    return {
      data: res.data,
      status: res.status,
      err: null,
    };
  } catch (err) {
    return {
      err: err.message,
      status: err.response.status,
      data: null,
    };
  }
};
export const logoutUser = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
  });
};

export const deleteClientUser = (id) => async (dispatch) => {
  let config;
  if (localStorage.hstoken) {
    setAuthToken(localStorage.hstoken);
    config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.hstoken,
      },
    };
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/users/client/delete/${id}`,
      config
    );
    return {
      data: res.data,
      status: res.status,
      err: null,
    };
  } catch (err) {
    return {
      err: err.message,
      status: err.response.status,
      data: null,
    };
  }
};
