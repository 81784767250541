import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  LOGOUT_USER,
  USER_LOADED,
  AUTH_ERROR,
  LEGACY_CLIENT,
  LEGACY_FAIL,
  CLEAR_ERROR,
} from "../action/types";

const initialState = {
  token: localStorage.getItem("hstoken"),
  isAuthenticated: JSON.parse(localStorage.getItem("authUser")) ? true : null,
  user: JSON.parse(localStorage.getItem("authUser")),
  error: null,
  redirectLink: null,
  isLoading: true,
  logout: false
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload.user,
      };
    case LEGACY_CLIENT:
      return {
        ...state,
        isLoading: false,
        user: payload,
        error: null,
        redirectLink: null,
      };
    case LEGACY_FAIL:
      return {
        ...state,
        ...payload,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("hstoken", payload.token);
      localStorage.setItem("authUser", JSON.stringify(payload.user));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        error: null,
        isLoading: false,
        logout: false
      };
    case LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        ...payload,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case SOCIAL_LOGIN_SUCCESS:
      localStorage.setItem("hstoken", payload.token);
      localStorage.setItem("authUser", JSON.stringify(payload.user));
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        error: null,
        isLoading: false,
      };
    case SOCIAL_LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGOUT_USER:
      localStorage.removeItem("hstoken");
      localStorage.removeItem("authUser");
      localStorage.removeItem("clientInfo");
      localStorage.removeItem("logged-in");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        logout: true
      };
    case CLEAR_ERROR:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
