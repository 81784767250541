import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

export const PreviewMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      MapTypeIds={["roadmap", "satellite", "hybrid", "terrain"]}
      defaultCenter={{ lat: props.centerLat, lng: props.centerLng }}
      yesIWantToUseGoogleMapApiInternals={true}
      st
    >
      <Marker position={{ lat: props.centerLat, lng: props.centerLng }} />
    </GoogleMap>
  ))
);
