import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { showModal, hideModal } from "../../../redux/action/modal";
import moment from "moment";
import {
  Col,
  Row,
  Alert,
  Button,
  Modal,
  Popover,
  message,
  Select,
  Checkbox,
  Typography,
  Statistic,
  Popconfirm,
  Space,
  Spin,
} from "antd";
import { FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input } from "formik-antd";
import { Formik } from "formik";
import {
  fetchPaymentsByInvoiceId,
  createPayment,
} from "../../../redux/action/paymentAction";
import { createActivity } from "../../../redux/action/activityAction";
import { createNote } from "../../../redux/action/note";
import logo from "../../../content/hs_logo.png";
import "./Invoice.scss";
import stripeIcon from "../../../content/stripe-icon.svg";
import StripePayment from "./StripePayment";
import PayPalPayment from "./PayPalPayment";
import CompItemButton from "./CompItemButton";
import ability from "../../../user/Component/Auth/ability";
import ApplyCoupon from "../../../shared/Components/ApplyCoupon";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfInvoice from "./PdfInvoice";

// const { Option } = Select;
const { Text } = Typography;

const InvoiceDetails = ({
  data,
  showModal,
  hideModal,
  modal: { isOpen },
  payments: { invoicePayments },
  creditRedeem,
  fetchPaymentsByInvoiceId,
  updateInvoice,
  createActivity,
  createPayment,
  manualFetch,
  calculateTax,
  auth: { user },
}) => {
  const [visible, setVisible] = useState(false);
  const [checkInfoVisible, setCheckInfoVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Stripe");
  const [refundMethod, setRefundMethod] = useState("Stripe");
  const [emailComp, setEmailComp] = useState(false);
  const [emailPayConfirmation, setEmailPayConfirmation] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  var discountAmount = 0;
  const [total, setTotal] = useState(0);
  const [creditSelect, setCreditSelect] = useState([]);
  const [availableCredit, setAvailableCredit] = useState(0);
  const [successState, setSuccessState] = useState(false);
  const showCreditModal = () => {
    // setCreditSelect([]);
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const { Option } = Select;
  const handleSelect = (value) => {
    setCreditSelect(value);
    const select = value.map((val) =>
      data.client.credit.filter((credit) => val.toString() === credit._id)
    );
    let totalCredit = 0;
    select.map((select) => (totalCredit = totalCredit + select[0].amount));
    setTotal(totalCredit);
  };
  const handleModalOk = async () => {
    const select = creditSelect.map(
      (val) =>
        data.client.credit.filter((credit) => val.toString() === credit._id)[0]
    );
    select.map((sel) => (discountAmount = discountAmount + sel.amount));
    await creditRedeem(data, discountAmount, select);
    setIsModalVisible(false);
    setCreditSelect([]);
    setTotal(0);
  };

  useEffect(() => {
    let bal;
    let availableTotal = 0;
    bal = data?.client?.credit
      ?.filter((credit) => credit.redeemed === false)
      .map((cre) => {
        availableTotal = availableTotal + cre?.amount;
        setAvailableCredit(availableTotal?.toFixed(2));
        return true;
      });
  }, [setIsModalVisible, creditSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    fetchPaymentsByInvoiceId(data._id);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const displayModal = () => {
    showModal();
  };

  const onClose = () => {
    hideModal();
  };

  const handleOk = (e) => {
    console.log(e);
    setVisible(false);
  };

  const handleCancel = (e) => {
    hideModal();
  };

  const emailPaymentSuccess = async (isComp) => {
    const emailData = {
      invoiceId: data._id,
      clientEmail: data.client.email,
      clientFirst: data.client.first,
      clientLast: data.client.last,
      secondaryContact: data?.shoot?.booking_form?.secondary_contact,
      hsn: data.hsn,
      address: data?.shoot?.address?.street
        ? data?.shoot?.address?.street
        : data.address,
      amount: data.total,
      isComp,
    };
    await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/payment-success`,
      emailData
    );
  };

  const renderPaymentPending = () => {
    return <span>Payment Pending</span>;
  };

  const renderPaid = (payment) => {
    const { amount, date_paid, gateway } = payment;
    return (
      <Row
        key={payment._id}
        justify="space-between"
        style={{
          padding: "5px",
          marginTop: "10px",
          backgroundColor: "#F6FFEC",
        }}
      >
        <Col span={8}>
          {`Paid via ${gateway} on ${moment(date_paid).format("MM/DD/YY")}`}{" "}
        </Col>
        <Col span={2}></Col>
        <Col span={2}></Col>
        <Col span={4}>
          <span style={{ float: "right" }}>{amount.toFixed(2)}</span>
        </Col>
      </Row>
    );
  };

  const handleSelectChange = (value) => {
    setPaymentMethod(value);
    if (value === "Check") {
      setCheckInfoVisible(true);
    } else {
      setCheckInfoVisible(false);
    }
  };

  const handlePaidByCheck = (checkInfo) => {
    const checkPaymentData = {
      client: data.client._id,
      invoice: data._id,
      hsn: data.hsn,
      amount: data.total,
      gateway: "check",
      payment_method: "Check",
      check_info: {
        number: checkInfo.checkNumber,
        name: checkInfo.fullName,
        address: checkInfo.address,
      },
      paid: true,
      date_paid: new Date(),
    };
    createPayment(checkPaymentData);
  };

  const onCheckboxChange = (e) => {
    setEmailPayConfirmation(e.target.checked);
  };

  const handleUnCancel = async () => {
    const res = await updateInvoice(data._id, {
      ...data,
      balance: data?.paid || !data?.refunded ? data?.balance : data?.total,
      canceled: false,
    });
    if (!res.error) {
      createActivity(data.shoot, "Cancelled invoice marked as restored");
      message.success("Invoice restored.");
    }
  };

  const handleUnReschedule = async () => {
    const res = await updateInvoice(data._id, {
      ...data,
      reschedule: false,
    });
    if (!res.error) {
      createActivity(data.shoot, "Rescheduled invoice marked as restored");
      message.success("Invoice restored.");
    }
  };

  const renderCompForm = (action) => {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={async (values, { resetForm }) => {
            const appendToAcctivity =
              emailComp || emailPayConfirmation
                ? "Confirmation email sent to client."
                : "";
            let res;

            if (action === "refund") {
              res = await updateInvoice(data._id, {
                ...data,
                refunded: true,
                refund_method: refundMethod,
                refund_amount: values.refund_amount,
                refund_date: new Date(),
              });
            } else {
              res = await updateInvoice(data._id, {
                ...data,
                balance: 0,
                paid: true,
              });
            }

            if (!res.error) {
              if (action === "paid") {
                if (paymentMethod === "Check") {
                  handlePaidByCheck(values);
                  createActivity(
                    data?.shoot,
                    `Invoice marked as paid via check #${values.checkNumber} from ${values.fullName}. ${appendToAcctivity}`
                  );
                } else {
                  // Create Payment
                  const paymentData = {
                    client: data?.client?._id,
                    invoice: data?._id,
                    hsn: data?.hsn,
                    amount: data?.balance,
                    fee: "",
                    gateway: paymentMethod.toLowerCase(),
                    payment_method: paymentMethod,
                    payment_id: null,
                    gateway_payer_id: null,
                    gateway_email: data?.client?.email,
                    gateway_token: null,
                    paid: true,
                    date_paid: new Date(),
                    note: `Manually marked as paid by ${user?.name}`,
                  };

                  createPayment(paymentData);

                  createActivity(
                    data.shoot,
                    `Invoice marked as paid via ${paymentMethod}. ${appendToAcctivity}`
                  );
                }
                if (emailPayConfirmation) {
                  emailPaymentSuccess(false);
                }
              }

              if (action === "comp") {
                if (emailComp && emailPayConfirmation) {
                  emailPaymentSuccess(true);
                }
                // Create Activity
                createActivity(
                  data.shoot,
                  `Invoice was comped. Reason: ${values.reason}. ${appendToAcctivity}`
                );
              }
              if (action === "refund") {
                // Create Activity
                createActivity(
                  data.shoot,
                  `Invoice was refunded $${values.refund_amount} via ${refundMethod}. Reason: ${values.reason}.`
                );
              }
              message.success("Invoice updated successfully.");
              manualFetch();
              resetForm({ values: "" });
            } else {
              message.error("Oops! Something went wrong.");
            }
          }}
          render={({ handleSubmit }) => (
            <div style={{ width: 300 }}>
              {action === "paid" && (
                <>
                  <div className="form-group">
                    <label>Payment Method:</label>
                    <Select
                      defaultValue={paymentMethod}
                      style={{ width: 300 }}
                      onChange={handleSelectChange}
                    >
                      <Option value="Stripe">Stripe</Option>
                      <Option value="PayPal">PayPal</Option>
                      <Option value="Check">Check</Option>
                      <Option value="Credit">Credit</Option>
                    </Select>
                    {checkInfoVisible && (
                      <div>
                        <div className="form-group">
                          <label>Check #:</label>
                          <Input placeholder="e.g. 12345" name="checkNumber" />
                        </div>
                        <div className="form-group">
                          <label>Name on the check:</label>
                          <Input placeholder="Full Name" name="fullName" />
                        </div>
                        <div className="form-group">
                          <label>Address on the check:</label>
                          <Input placeholder="Full Address" name="address" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <Checkbox onChange={onCheckboxChange}>
                      Send payment confirmation email
                    </Checkbox>
                  </div>
                </>
              )}
              {action === "comp" && (
                <>
                  <div className="form-group">
                    <label>Reason:</label>
                    <Input
                      placeholder="e.g. Paid on HS# 60012345"
                      name="reason"
                    />
                  </div>
                  <div className="form-group mt-3 mb-3">
                    <Checkbox onChange={(e) => setEmailComp(e.target.checked)}>
                      Notify the Client
                    </Checkbox>
                  </div>
                </>
              )}
              {action === "refund" && (
                <>
                  <div className="form-group">
                    <label>Reason:</label>
                    <Input
                      placeholder="e.g. Shoot cancelled due to weather"
                      name="reason"
                    />
                  </div>
                  <div className="form-group">
                    <label>Refund Amount:</label>
                    <Input name="refund_amount" prefix="$" />
                  </div>
                  <div className="form-group">
                    <label>Refund Method:</label>
                    <Select
                      name="refund_method"
                      defaultValue={"Stripe"}
                      style={{ width: 300 }}
                      onChange={(value) => setRefundMethod(value)}
                    >
                      <Option value="Stripe">Stripe</Option>
                      <Option value="PayPal">PayPal</Option>
                      <Option value="Check">Check</Option>
                      <Option value="Credit">Credit</Option>
                    </Select>
                  </div>
                </>
              )}
              <div className="form-group mt-2 mb-2">
                <Button type="primary" block onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </div>
          )}
        />
      </>
    );
  };
  const updatedBalance = data.balance - total;

  return (
    <React.Fragment>
      {!user && (
        <Alert
          message="You have limited access. Please sign in to have full access."
          banner
          action={
            <Space>
              <a href="/admin/login">
                <Button size="small" type="primary">
                  Sign In
                </Button>
              </a>
            </Space>
          }
        />
      )}
      <div className="invoice-container">
        <Spin spinning={successState}>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <div className="invoice-logo">
                <img width="280px" src={logo} alt="HomeSnappers" />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              style={{ textAlign: "right" }}
            >
              <strong>
                Invoice #{" "}
                {data.shoot?.hsn ? (
                  <Link
                    to={`/admin/shoots/${data.shoot?._id}`}
                    style={{ color: "#1890ff" }}
                  >
                    {data.shoot?.hsn}
                  </Link>
                ) : (
                  data?.hsn
                )}
              </strong>
              <br />
              {moment(data.shoot?._created_at).format("MMMM D, YYYY")}
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <h4 style={{ marginTop: "10px", fontSize: 16 }}>
                {data?.shoot?.address?.full}
              </h4>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <PDFDownloadLink
                fileName={`invoice-${data.shoot?.hsn}.pdf`}
                document={<PdfInvoice invoice={data} />}
              >
                <Button className="ml-1 mt-2 float-right" size="small" danger>
                  <FilePdfOutlined /> Download PDF
                </Button>
              </PDFDownloadLink>
            </Col>
          </Row>
        </Spin>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Row>
          <Col span={24}>
            <Alert
              style={{
                marginBottom: "5px",
              }}
              message="Please note that we will no longer be accepting payments by check. Checks received as payment for appointments from now moving forward will not be cashed."
              type="info"
              showIcon
              banner
            />
            {(!data.paid || data.balance !== 0) && !data.canceled && (
              <Alert
                style={{
                  marginBottom: "10px",
                }}
                message={renderPaymentPending()}
                banner
                action={
                  ability.can("comp", "Invoices") && (
                    <>
                      <Popover
                        content={renderCompForm("paid")}
                        trigger="click"
                        placement="bottomRight"
                        width={400}
                      >
                        <Button className="mr-2" size="small" type="primary">
                          Mark as Paid
                        </Button>
                      </Popover>
                      <Popover
                        content={renderCompForm("comp")}
                        trigger="click"
                        placement="bottomRight"
                        width={400}
                      >
                        <Button className="mr-2" size="small">
                          Comp Invoice
                        </Button>
                      </Popover>
                    </>
                  )
                }
              />
            )}
            {data.canceled && ability.can("manage", "Invoices") && (
              <Alert
                style={{
                  marginBottom: "10px",
                }}
                message="Cancelled"
                banner
                type="error"
                action={
                  ability.can("cancel", "Shoots") && (
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to restore this invoice?"
                      onConfirm={handleUnCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="mr-2" size="small" type="primary">
                        Restore
                      </Button>
                    </Popconfirm>
                  )
                }
              />
            )}
            {data.reschedule && ability.can("manage", "Invoices") && (
              <Alert
                style={{
                  marginBottom: "10px",
                }}
                message="Rescheduled"
                banner
                type="warning"
                action={
                  ability.can("cancel", "Shoots") && (
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to restore this invoice?"
                      onConfirm={handleUnReschedule}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="mr-2" size="small" type="primary">
                        Restore
                      </Button>
                    </Popconfirm>
                  )
                }
              />
            )}
          </Col>
          {data?.paid &&
            !data?.refunded &&
            ability.can("manage", "Invoices") && (
              <Col span={24}>
                <div className="float-right mb-2">
                  <Popover
                    content={renderCompForm("refund")}
                    trigger="click"
                    placement="leftTop"
                    width={400}
                  >
                    <Button
                      size="small"
                      type="primary"
                      disabled={data?.refunded}
                    >
                      Mark as Refunded
                    </Button>
                  </Popover>
                </div>
              </Col>
            )}
        </Row>
        {data.products
          .filter(
            (product) => product?.inv_visible && product?.inv_visible === true
          )
          .map((item) => (
            <div key={item._id}>
              <Row className="invoice-line-item">
                <Col span="16">
                  {item.description}{" "}
                  {item.price > 0 &&
                    item.price < data.balance &&
                    (!data.balance <= 0 || !data.paid) &&
                    ability.can("comp", "Invoices") && (
                      <CompItemButton
                        item={item}
                        data={data}
                        createActivity={createActivity}
                        updateInvoice={updateInvoice}
                      />
                    )}
                </Col>

                <Col span="8">
                  <span
                    style={{
                      float: "right",
                    }}
                  >
                    {item?.orig_price && (
                      <Text delete style={{ marginRight: 15 }}>
                        {item?.orig_price?.toFixed(2)}
                      </Text>
                    )}
                    {item?.price?.toFixed(2)}
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        <Row
          justify="space-between"
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundColor: "#eee",
            // borderTop: "solid 3px #cfcfcf",
          }}
        >
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={4}>Subtotal</Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              {data.subtotal ? data.subtotal.toFixed(2) : 0.0}
            </span>
          </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <Col span={1}></Col>
          <Col span={15}>
            {data.client?.credit?.some((crd) => crd.redeemed === false) &&
              data?.balance > 0 &&
              !data?.canceled && (
                <Button type="primary" onClick={showCreditModal}>
                  ${(availableCredit - total).toFixed(2)} Available Credits{" "}
                </Button>
              )}
          </Col>
          <Modal
            title="Available Credits"
            visible={isModalVisible}
            onOk={() => handleModalOk()}
            onCancel={handleModalCancel}
          >
            <Row>
              <Col span={24} className="mb-4">
                <Statistic
                  title="Total Credit Balance"
                  value={availableCredit - total}
                  precision={2}
                  prefix={"$"}
                />
              </Col>
              <p>
                Select the credits below you want to apply towards this invoice.
              </p>
              {data?.client?.credit?.length > 0 &&
              data?.balance > 0 &&
              data?.client?.credit?.some((crd) => crd.redeemed === false) ? (
                <>
                  <Col
                    span={24}
                    style={{ paddingLeft: "20px" }}
                    className="mb-4"
                  >
                    <Checkbox.Group
                      style={{ width: "60%" }}
                      onChange={(value) => handleSelect(value)}
                    >
                      {" "}
                      {data.client?.credit?.map(
                        (credit) =>
                          credit.redeemed === false && (
                            <div key={credit._id}>
                              <Checkbox
                                style={{ marginLeft: "0px" }}
                                key={credit._id}
                                value={credit._id}
                                disabled={
                                  total > data?.balance &&
                                  !creditSelect.includes(credit._id)
                                }
                              >
                                {credit.code} {"$"}
                                {credit.amount.toFixed(2)}
                              </Checkbox>
                              <br />
                            </div>
                          )
                      )}
                    </Checkbox.Group>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col span={12}></Col>
              <Col span={24}>
                {total > data.balance && (
                  <>
                    <Typography>
                      {" "}
                      <InfoCircleOutlined
                        style={{ color: "red", marginRight: 5 }}
                      />
                      Your credit is more than balance owed. The remainder will
                      be available to apply on your next invoice.
                    </Typography>
                  </>
                )}
              </Col>
              <Col span={12}></Col>
              <Col span={12}>
                {total > 0 && (
                  <>
                    <Typography style={{ textAlign: "right" }}>
                      {" "}
                      Invoice Balance: ${data.balance.toFixed(2)}
                    </Typography>
                    <Typography style={{ textAlign: "right" }}>
                      {" "}
                      Credit Used: {`- $${total.toFixed(2)}`}
                    </Typography>
                    <Typography style={{ textAlign: "right" }}>
                      {" "}
                      New Balance:{" "}
                      {updatedBalance < 0 ? 0 : updatedBalance.toFixed(2)}
                    </Typography>
                  </>
                )}
              </Col>
            </Row>
          </Modal>

          {/* <Col span={4}>
            // <Button>{`Apply $${discount} Credit`}</Button>
            //{" "}
          </Col> */}
          {data.discount > 0 ? (
            <>
              <Col span={4}>Discount</Col>
              <Col span={4} className="text-success">
                <span style={{ float: "right" }}>
                  - {data.discount ? data.discount.toFixed(2) : 0.0}
                </span>
              </Col>
            </>
          ) : null}
        </Row>
        <Row
          justify="space-between"
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundColor: "#eee",
          }}
        >
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={4}>Tax</Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              {data.tax ? data.tax.toFixed(2) : 0.0}
            </span>
          </Col>
        </Row>
        {data.cancel_fee && data.cancel_fee > 0 ? (
          <>
            <Row
              justify="space-between"
              style={{
                padding: "5px",
                marginTop: "10px",
                backgroundColor: "#eee",
              }}
            >
              <Col span={4}></Col>
              <Col span={4}></Col>
              <Col span={4}>Cancel Fee</Col>
              <Col span={4}>
                <span style={{ float: "right" }}>
                  {data.cancel_fee.toFixed(2)}
                </span>
              </Col>
            </Row>
          </>
        ) : null}
        <Row
          justify="space-between"
          style={{
            marginTop: "10px",
          }}
        >
          {/* <Col span={4}></Col> */}
          <Col span={10} style={{ marginLeft: 15 }}>
            {!data?.paid &&
              (data?.discount === undefined || data?.discount === 0) && (
                <ApplyCoupon invoice={data} updateInvoice={updateInvoice} />
              )}
          </Col>
          <Col span={4}>
            <strong>Total</strong>
          </Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              <strong>{data.total.toFixed(2)}</strong>
            </span>
          </Col>
        </Row>
        {data.credit_used > 0 ? (
          <Row
            justify="space-between"
            style={{
              padding: "5px",
              marginTop: "10px",
              backgroundColor: "#eee",
            }}
          >
            <Col span={4}></Col>
            <Col span={4}></Col>
            <Col span={4}>Credit Used</Col>
            <Col span={4}>
              <span style={{ float: "right" }} className="text-success">
                {data.credit_used.toFixed(2)}
              </span>
            </Col>
          </Row>
        ) : null}
        {invoicePayments?.length > 0 &&
          invoicePayments?.map((payment) => renderPaid(payment))}
        {data?.refunded && (
          <Row>
            <Col
              span={24}
              style={{
                padding: "5px",
                marginTop: "10px",
                backgroundColor: "#f9f0ff",
              }}
            >
              {`Invoice marked as Refunded via ${
                data.refund_method
              } in the amount of $${data.refund_amount} on ${moment(
                data?.refund_date
              ).format("MM/DD/YY")}`}
            </Col>
          </Row>
        )}

        <Row
          justify="space-between"
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundColor: "#eee",
          }}
        >
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={4}>
            <strong>BALANCE DUE</strong>
          </Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              <strong>{data.balance.toFixed(2)}</strong>
            </span>
          </Col>
        </Row>
        {data.balance > 0 && !data.canceled && (
          <Row gutter={[8, 8]} className="mt-4">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <Button
                size="large"
                block
                type="primary"
                onClick={displayModal}
                disabled={data.balance === 0}
              >
                <img
                  src={stripeIcon}
                  className="pay-icon"
                  alt="Pay with Stripe"
                />{" "}
                Pay with Card
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div style={{ float: "right", width: "100%" }}>
                <PayPalPayment
                  emailPaymentSuccess={emailPaymentSuccess}
                  data={data}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Modal
        title="Pay with Card"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={true}
        maskClosable={false}
        footer={false}
        style={{ top: 20 }}
      >
        <StripePayment
          emailPaymentSuccess={emailPaymentSuccess}
          onClose={onClose}
          data={data}
          createPayment={createPayment}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  modal: state.modal,
  payments: state.payments,
  clients: state.clients.clientProfile,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  fetchPaymentsByInvoiceId,
  createActivity,
  createPayment,
})(InvoiceDetails);
