import React, { useState } from "react";
import axios from "axios";
import { Button, Spin, message } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import "./style.scss";

const DownloadButton = ({
  media,
  selectedShoot,
  type,
  label,
  isDisabled,
  mediaType,
  buttonType,
}) => {
  const [downloadLoader, setDownloadLoader] = useState("");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#FFFFFF" }} spin />
  );

  const handleDownload = async () => {
    setDownloadLoader(type);
    let download;
    if (mediaType === "photos") {
      download = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/media/${
          media._id
        }/download?type=${type}&mediatype=${mediaType}&promoType=${
          label === "Branded" ? "Branded" : "Unbranded"
        }`,
        {
          responseType: "arraybuffer",
        }
      );
    } else {
      download = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/media/${
          media._id
        }/download?&mediatype=${mediaType}&promoType=${
          label === "Branded"
            ? "Branded"
            : label === "Unbranded"
            ? "Unbranded"
            : "Download"
        }`,
        {
          responseType: "arraybuffer",
        }
      );
    }
    if (download.status === 200) {
      const zipName =
        mediaType === "photos"
          ? `${selectedShoot.hsn}_${mediaType}_${type}.zip`
          : `${selectedShoot.hsn}_${mediaType}.zip`;
      fileDownload(download.data, zipName);
      if (mediaType === "photos") {
        message.success("Your images downloaded successfully!");
      } else if (mediaType === "video") {
        message.success("Your Videos downloaded successfully!");
      } else if (mediaType === "socialMedia") {
        message.success("Your Videos downloaded successfully!");
      } else {
        message.success("Your SlideShow downloaded successfully!");
      }
    } else {
      console.error(download);
      message.error("Oops! Error occurred while downloading");
    }
    setDownloadLoader("");
  };

  return (
    <span className="button-container">
      <Button
        type={buttonType ? buttonType : "primary"}
        style={{ marginTop: "10px" }}
        icon={downloadLoader === type ? antIcon : <DownloadOutlined />}
        onClick={() => handleDownload()}
        disabled={isDisabled}
        size="small"
      >
        {downloadLoader === type ? "Downloading..." : label}
      </Button>
    </span>
  );
};

export default DownloadButton;
