import React from "react";
import { Button, Result } from "antd";

const StatusNotFound = ({ status: { is404 } }) => {
  let redirectPath = null;
  if (window.location.pathname.includes('admin')) {
    redirectPath = "/admin/dashboard";
  }
  return (
    <Result
      status={is404 ? "404" : "403"}
      title={is404 ? "404" : "403"}
      subTitle={
        is404
          ? "Sorry, the page you visited does not exist."
          : "Sorry, you are not authorized to access this page."
      }
      extra={redirectPath ? (
        <Button type="primary" href={redirectPath}>
          Back Home
        </Button>) : null
      }
    />
  );
};

export default StatusNotFound;
