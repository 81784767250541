import React from "react";
import { Button, Tag, Popconfirm, message, Radio } from "antd";
import {
  CreditCardOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import mastercardIcon from "../../../../content/mastercard-icon.svg";
import visaIcon from "../../../../content/visa-icon.svg";
import amexIcon from "../../../../content/amex-icon.svg";
import discoverIcon from "../../../../content/discover-icon.svg";

import "./style.scss";

const CardListItem = ({
  allowDelete,
  allowPay,
  allowSelect,
  card,
  handleChargeCard,
  paymentSuccess,
  removePaymentMethod,
  selectedClient,
  setSelectedCardId,
  selectedCardId,
}) => {
  const { id, brand, last4, exp_month, exp_year, name } = card;

  const checkExpiration = (exp_month, exp_year) => {
    let today, someday;
    today = new Date();
    someday = new Date();
    someday.setFullYear(exp_year, exp_month, 1);

    if (someday < today) {
      return "expired";
    } else {
      return "active";
    }
  };

  const statusColor =
    checkExpiration(exp_month, exp_year) === "active" ? "green" : "red";

  const handleRemove = async (cardId) => {
    const customerId = selectedClient?.stripe_id;
    if (customerId) {
      const res = await removePaymentMethod({ customerId, cardId });
      if (res.data.deleted) {
        message.success("Payment method removed successfully!");
      } else {
        message.error("Oops. Failed to remove card.");
      }
    } else {
      message.error("No customer ID found.");
    }
  };

  const renderCardIcon = (brand) => {
    switch (brand) {
      case "MasterCard":
        return <img alt="Mastercard icon" src={mastercardIcon} />;
      case "American Express":
        return <img alt="American Express icon" src={amexIcon} />;
      case "Visa":
        return <img alt="Visa icon" src={visaIcon} />;
      case "Discover":
        return <img alt="Discover icon" src={discoverIcon} />;
      default:
        return <CreditCardOutlined className="mr-1" />;
    }
  };

  return (
    <div className="card-item" key={id}>
      {allowSelect && (
        <div>
          <Radio
            checked={card.id === selectedCardId}
            onChange={(e) => setSelectedCardId(e.target.value)}
            value={card.id}
          />
        </div>
      )}
      <div className="card-icon">{renderCardIcon(brand)}</div>
      {name && (
        <div>
          <span>{name}</span>
        </div>
      )}
      <div>
        <EllipsisOutlined className="card-placeholder" />
        <span>{last4}</span>
      </div>
      <div className="card-exp">{`Exp: ${exp_month}/${exp_year}`}</div>
      <div>
        <Tag color={statusColor}>{checkExpiration(exp_month, exp_year)}</Tag>
      </div>
      {allowPay && (
        <div>
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to charge this card?"
            onConfirm={(e) => handleChargeCard(e, card)}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "green" }} />}
          >
            <Button disabled={paymentSuccess} type="primary" size="small">
              Pay Now
            </Button>
          </Popconfirm>
        </div>
      )}

      {allowDelete && (
        <div>
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to remove this card?"
            onConfirm={() => handleRemove(id)}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button type="link" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default CardListItem;
