import axios from "axios";
import {
  FETCH_SHOOTS,
  FETCH_SHOOT_BY_ID,
  FETCH_SHOOT_BY_HSN,
  UPDATE_SHOOT,
  CREATE_SHOOT,
  REMOVE_SHOOT,
  RESCHEDULE_SHOOT,
  FETCH_MEDIA_BY_ID,
  FETCH_CHANGE_REQUESTS,
  FETCH_SHOOTS_BY_DAY,
  FETCH_PENDING_SHOOTS,
  SET_SELECTED_SHOOT,
  UPDATE_SHARE,
  FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY,
  UPDATE_WEBSOCKET_SHOOT,
  FETCH_PHOTOGRAPHER_BY_ID,
} from "./types";

export const fetchShoots = (params) => async (dispatch) => {
  try {
    let res;
    if (params) {
      const {
        reqs,
        photographer,
        search,
        date,
        filter,
        sortField,
        sortOrder,
        client,
        share,
        skip,
        limit,
        serviceFilters,
        access,
      } = params;
      res = await axios.get(
        `${
          process.env.REACT_APP_ROOT_URL
        }/shoots?photographer=${photographer}&client=${client}&search=${search}&date=${date}&reqs=${reqs}&filter=${filter}${
          serviceFilters ? `&serviceFilters=${serviceFilters}` : ""
        }&sortField=${sortField}&sortOrder=${sortOrder}&share=${share?.toString()}&skip=${skip}&limit=${limit}&access=${access}`
      );
    } else {
      res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/shoots`);
    }
    dispatch({
      type: FETCH_SHOOTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPendingShoots = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/pending`
    );
    dispatch({
      type: FETCH_PENDING_SHOOTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const fetchShootsByDay = (params) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/schedule?from=${params.from}&to=${params.to}&access=${params.access}`
    );
    console.log(res.data);
    dispatch({
      type: FETCH_SHOOTS_BY_DAY,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const fetchChangeRequests = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/requests?resolved=false`
    );
    dispatch({
      type: FETCH_CHANGE_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchShootById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/${id}`
    );
    dispatch({
      type: FETCH_SHOOT_BY_ID,
      payload: res.data,
    });

    dispatch({
      type: FETCH_MEDIA_BY_ID,
      payload: res.data.media,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const fetchShootbyStreet = (street) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/street/${street}`
    );
    dispatch({
      type: FETCH_SHOOT_BY_ID,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    dispatch({
      type: FETCH_SHOOT_BY_ID,
      payload: {},
    });

    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchShootbyHsn = (hsn) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/hsn/${hsn}`
    );
    dispatch({
      type: FETCH_SHOOT_BY_HSN,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    dispatch({
      type: FETCH_SHOOT_BY_HSN,
      payload: {},
    });

    return {
      data: {},
      error: err.response,
    };
  }
};

export const createShoot = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/shoots`,
      formData,
      config
    );
    dispatch({
      type: CREATE_SHOOT,
      payload: res.data,
    });
    return res;
  } catch (err) {}
};

export const rescheduleShoot = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/shoots/reschedule`,
      formData,
      config
    );
    dispatch({
      type: RESCHEDULE_SHOOT,
      payload: res.data,
    });
    return res;
  } catch (err) {}
};

export const updateShoot = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/shoots/${id}`,
      formData
    );
    if (response.status === 200) {
      console.log("updateShoot ", response.data);
      dispatch({
        type: UPDATE_SHOOT,
        payload: response.data,
      });
      dispatch({
        type: SET_SELECTED_SHOOT,
        payload: response.data,
      });
      dispatch({
        type: UPDATE_WEBSOCKET_SHOOT,
        payload: response.data,
      });
      await fetchPendingShoots()(dispatch);
    }
    return { data: response.data, error: null, status: response.status };
  } catch (err) {
    console.log(err);
    return { data: null, error: err.message };
  }
};

export const updateShare = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/shoots/share/members`,
      { id, share: formData }
    );
    if (response.status === 200) {
      dispatch({
        type: UPDATE_SHARE,
        payload: response.data,
      });
      dispatch({
        type: SET_SELECTED_SHOOT,
        payload: response.data,
      });
    }
    return { data: response.data, error: null, status: response.status };
  } catch (err) {
    console.log(err);
    return { data: null, error: err.message };
  }
};

export const removeShoot = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/shoots/${id}`);
    dispatch({
      type: REMOVE_SHOOT,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPhotoGraphersUnAvailabilityForADay =
  (date) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/photographers/unavailability?date=${date}`
      );
      dispatch({
        type: FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY,
        payload: res.data,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  };

export const updateWebSocketShoot = (selectedShoot) => (dispatch) => {
  console.log("updateWebSocketShoot", selectedShoot.events.photographer);
  dispatch({
    type: UPDATE_WEBSOCKET_SHOOT,
    payload: selectedShoot,
  });
  dispatch({
    type: FETCH_PHOTOGRAPHER_BY_ID,
    payload: selectedShoot.events.photographer,
  });
  dispatch({
    type: UPDATE_SHOOT,
    payload: selectedShoot,
  });
  // dispatch({
  //   type: FETCH_SHOOTS_BY_DAY,
  //   payload: res.data,
  // });
};
