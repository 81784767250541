/* eslint-disable eqeqeq, array-callback-return */

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
import { Card, Modal, message, Row, Col, Button } from "antd";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import {
  convertISODateString,
  convertISOStrToDate,
} from "../../../../shared/utils/utils";
import OrderDetails from "../Common/OrderDetails.js";
import orderData from "../../../../content/DummyData.json";
import { updatePhotographerCalc } from "../../../../shared/payBreakdown/photographersPayCalc";
import moment from "moment";
import { Spin } from "antd";
import Axios from "axios";
import EditAvailability from "../../Photographers/EditAvailability";
import { Calendar } from "antd";
import { Draggable } from "@fullcalendar/interaction";

const Today = (props) => {
  const [visible, setVisible] = useState(false);
  const calendarComponentRef = useRef();
  const [state, setState] = useState({
    currDate: new Date(),
    calendarEvents: [],
    resources: [],
    shoots: [],
    // selectedShoot: {},
    photographers: [],
  });
  const [dashboard, setDashboard] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardState, setDashboardState] = useState(false);

  if (props.shoots.length && !dashboard.length && !dashboardState) {
    setDashboardState(true);
    setDashboard(props.shoots);
  }

  // useEffect(() => {
  //   const socket = socketIOClient.connect(process.env.REACT_APP_WS_URL, {
  //     transports: ["websocket"],
  //   });
  //   socket.on(`shoot-${selectedShoot._id}-updated`, (data) => {
  //     console.log(data);
  //   });
  // }, []);

  useEffect(() => {
    if (dashboard.length) {
      setLoader(true);

      let pndSht = props.shoots.map((shoot) => {
        let start, end;
        let from = moment(customDate).format("YYYY-MM-DD");
        const day = new Date(from + "T00:00:00");
        const events = shoot.details.events;
        if (events.start) {
          day.setHours(new Date(events.start).getHours());
          day.setMinutes(new Date(events.start).getMinutes());
          day.setSeconds(0);
        } else {
          day.setHours(7);
          day.setMinutes(0);
          day.setSeconds(0);
        }
        start = new Date(day);
        if (events.end) {
          const toDate = new Date(from + "T00:00:00");
          toDate.setHours(new Date(events.end).getHours());
          toDate.setMinutes(new Date(events.end).getMinutes());
          toDate.setSeconds(0);
          end = new Date(toDate);
        } else {
          end = new Date(day.setMinutes(day.getMinutes() + shoot.duration));
        }
        shoot.start = start;
        shoot.end = end;
        return shoot;
      });
      setDashboardData([...pndSht]);
      setDashboardState(true);
      setLoader(false);
    }
  }, [dashboard]);
  const tagsData = [
    { label: "9:00AM - 10:00AM", start: 9, end: 10 },
    { label: "10:00AM - 11:00AM", start: 10, end: 11 },
    { label: "11:00AM - 12:00PM", start: 11, end: 12 },
    { label: "12:00PM - 1:00PM", start: 12, end: 13 },
    { label: "1:00PM - 2:00PM", start: 13, end: 14 },
    { label: "2:00PM - 3:00PM", start: 14, end: 15 },
    { label: "3:00PM - 4:00PM", start: 15, end: 16 },
    { label: "4:00PM - 5:00PM", start: 16, end: 17 },
    { label: "5:00PM - 6:00PM", start: 17, end: 18 },
    { label: "6:00PM - 7:00PM", start: 18, end: 19 },
    { label: "7:00PM - 8:00PM", start: 19, end: 20 },
    { label: "8:00PM - 9:00PM", start: 20, end: 21 },
  ];
  const [loader, setLoader] = useState(false);
  const [viewAvail, setViewAvail] = useState(false);
  const [editAvail, setEditAvail] = useState(false);
  const [isDateModalVisible, setIsDateModalVisible] = useState(false);
  const [customDate, setCustomDate] = useState(new Date());
  const [weeklyAvailData, setWeeklyAvailData] = useState([]);
  const [newState, setNewState] = useState(false);
  const [finalDataArr, setFinalDataArr] = useState([]);

  const onViewAvail = async (photographerId) => {
    props.fetchPhotographerById(photographerId);
    const toDate = new Date(state.currDate.getTime());
    toDate.setDate(toDate.getDate() + 6);
    setLoader(true);
    try {
      const res = await Axios.get(
        `${
          process.env.REACT_APP_ROOT_URL
        }/photographers/unavailability?date=${convertISODateString(
          state.currDate
        )}&toDate=${convertISODateString(
          toDate
        )}&photographer=${photographerId}`
      );
      const availData = getAvailData(res.data);
      setWeeklyAvailData([...availData]);
      setViewAvail(true);
    } catch (e) {
      message.info("Something went wrong");
    }
    setLoader(false);
  };

  const getAvailData = (photographerData) => {
    const date = moment(state.currDate);
    const availData = [];
    const to = date.clone().add(6, "days");
    while (date.isSameOrBefore(to)) {
      if (date.day() === 0) {
        availData.push({
          date: moment(date),
          availableSlots: [],
        });
      } else {
        const dayData = photographerData.find(
          (data) => date.format().split("T")[0] === data.date.split("T")[0]
        );
        const totalAvailSlots = [
          { label: "9:00AM - 10:00AM", start: 9, end: 10 },
          { label: "10:00AM - 11:00AM", start: 10, end: 11 },
          { label: "11:00AM - 12:00PM", start: 11, end: 12 },
          { label: "12:00PM - 1:00PM", start: 12, end: 13 },
          { label: "1:00PM - 2:00PM", start: 13, end: 14 },
          { label: "2:00PM - 3:00PM", start: 14, end: 15 },
          { label: "3:00PM - 4:00PM", start: 15, end: 16 },
          { label: "4:00PM - 5:00PM", start: 16, end: 17 },
          { label: "5:00PM - 6:00PM", start: 17, end: 18 },
          { label: "6:00PM - 7:00PM", start: 18, end: 19 },
          { label: "7:00PM - 8:00PM", start: 19, end: 20 },
          { label: "8:00PM - 9:00PM", start: 20, end: 21 },
        ];
        if (dayData) {
          const availableSlots = totalAvailSlots.filter(
            (availSlot) =>
              dayData.unAvailableSlots.find(
                (unAvailSlot) =>
                  convertISOStrToDate(unAvailSlot.start).getHours() ===
                  availSlot.start
              ) === undefined
          );
          availData.push({
            date: moment(date),
            availableSlots,
          });
        } else {
          availData.push({
            date: moment(date),
            availableSlots: totalAvailSlots,
          });
        }
      }
      date.add(1, "days");
    }
    return availData;
  };

  const onViewClose = () => {
    setViewAvail(false);
  };

  const onEditClose = async () => {
    setEditAvail(false);
    setLoader(true);
    await props.fetchPhotoGraphersUnAvailabilityForADay(
      moment(state.currDate.toISOString()).format("YYYY-MM-DD")
    );
    setLoader(false);
  };

  const onDatePickerSelected = async () => {
    if (customDate) {
      setState({ ...state, currDate: customDate });
      props.changeDateView(
        moment(customDate.toString()).format("YYYY-MM-DD"),
        moment(customDate.toString()).format("YYYY-MM-DD")
      );
      const calendarApi = calendarComponentRef.current.getApi();
      calendarApi.gotoDate(customDate);
    }
    setDashboard(finalDataArr);
    setIsDateModalVisible(false);
  };

  const onDateChange = async (e) => {
    setCustomDate(e?._d);
  };

  const onEditAvail = async () => {
    onViewClose();
    setEditAvail(true);
  };

  useEffect(() => {
    setLoader(true);

    const fetch = async () => {
      await props.fetchPendingShoots();

      await props.fetchShootsByDay({
        from: moment(customDate).format("YYYY-MM-DD"),
        to: moment(customDate).format("YYYY-MM-DD"),
        access: props.access,
      });
    };
    fetch();
    setDashboard(props.shoots);
    setLoader(false);
    // const socket = socketIOClient.connect(process.env.REACT_APP_WS_URL, {
    //   transports: ["websocket"],
    // });
    // socket.on(`shoot-updated`, (data) => {
    //   console.log("shoot-updated", data);
    //   fetch();
    // });
  }, [newState, customDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const resources = [];
    props.photographers.forEach((pht) => {
      if (pht.active) {
        pht.title = pht.name.split(" ")[0];
        pht.id = pht.pg_id;
        pht.eventBackgroundColor = pht.bgColor;
        pht.eventBorderColor = pht.borderColor;
        pht.opacity = 0.2;
        pht.showInfoWindow = false;
        resources.push(pht);
      }
    });
    setState({
      ...state,
      photographers: resources,
      resources,
    });
  }, [props.shoots]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("id");
        return {
          title,
          id,
        };
      },
    });
  }, []);
  useEffect(() => {
    const shoots = [
      ...props.shoots.concat(props.photographerUnavailabilityEvents),
    ];
    if (shoots.length) {
      const activeShoots = shoots?.filter(
        (shoot) => shoot.status !== "pending"
      );
      setFinalDataArr([...activeShoots]);
    }
  }, [props.shoots]);
  const showModal = ({ event }) => {
    props.shoots.forEach((shoot) => {
      if (shoot?.details?._id === event?.id) {
        props.fetchEntityActivities(shoot?.id);
        let selectedShoot;
        event._def.resourceIds.length === 1 &&
        event._def.resourceIds[0] === "Pending"
          ? (selectedShoot = {
              ...shoot,
              // start: event.start?.toISOString(),
              // end: event.end?.toISOString(),
              details: {
                ...shoot.details,
                events: {
                  ...shoot.details.events,
                  // start: event.start?.toISOString(),
                  // end: event.end?.toISOString(),
                },
              },
            })
          : (selectedShoot = { ...shoot });
        setState({
          ...state,
          selectedShoot,
        });
        props.setSelectedShoot(selectedShoot?.details);
      }
    });
    setVisible(true);
  };

  const handleCreateActivity = async (activityText, userId, shootId) => {
    const payload = {
      user: userId,
      text: activityText,
      entity_id: shootId,
      entity_type: "Shoot",
      _created_at: new Date(),
    };
    await axios.post(`${process.env.REACT_APP_ROOT_URL}/activities`, payload);
  };

  const handleOk = () => {
    // setSelectedSchedule({});
    setVisible(false);
  };

  const handleCancel = () => {
    // setSelectedSchedule({});
    setDashboard(props.shoots);
    setVisible(false);
  };
  const geoCoordinates = async (
    shootSwifted,
    info,
    invoices,
    sqft,
    tc,
    dt,
    selectedShoot
  ) => {
    var driveT;
    var geocode;
    const driveTime = await Axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/getDistance/${info.newResource.extendedProps.home_address}/${shootSwifted.address}/${shootSwifted.id}`
    ).then((res) => {
      geocode = res.data;
    });
    driveT = geocode.photographer_drive_time;
    updatePhotographerCalc(
      invoices,
      info.newResource.extendedProps.pay_multiplier,
      sqft,
      tc,
      selectedShoot.events.start,
      selectedShoot.events.end,
      driveT,
      props
    );
  };

  const updateShoot = async (info) => {
    setLoader(true);
    let selectedShoot = {};
    let invoices;
    let sqft;
    let tc;
    let dt;
    let dur;
    let shootSwifted;
    const start = info.event.start.toISOString();
    let end;
    if (info.event.end) {
      end = info.event.end.toISOString();
    }
    let photographer = null;
    if (
      info.newResource &&
      info.newResource.id &&
      info.newResource.id !== "Pending"
    ) {
      photographer = info.newResource.id;
    }
    for (let i = 0; i < props.shoots.length; i++) {
      if (props.shoots[i].details._id === info.event.id) {
        sqft = props.shoots[i].details.booking_form.sqft;
        tc = props.shoots[i].details.trip_charge;
        dt = props.shoots[i].details.address.drive_time;
        dur =
          props.shoots[i].details.events.duration === 0
            ? 30
            : props.shoots[i].details.events.duration;
        invoices = [...props.shoots[i].details.invoice];
        selectedShoot = {
          _id: props.shoots[i].details._id,
          events: { ...props.shoots[i].details.events, duration: dur },
        };
        shootSwifted = props.shoots[i];
        break;
      }
    }
    if (photographer) {
      geoCoordinates(shootSwifted, info, invoices, sqft, tc, dt, selectedShoot);
    }
    selectedShoot.events.start = start;
    if (end) {
      selectedShoot.events.end = end;
    } else {
      const dt = convertISOStrToDate(start);
      const endTime = new Date(
        dt.setMinutes(dt.getMinutes() + selectedShoot.events.duration)
      );
      end = convertISODateString(endTime);
      selectedShoot.events.end = end;
    }
    if (photographer) {
      selectedShoot.events.photographer = photographer;
    }
    if (info.oldResource && info.oldResource.id === "Pending") {
      if (selectedShoot?.events?.title?.includes("Flex")) {
        selectedShoot.status = "Scheduled";
        const text = `Appointment status changed from Pending to Scheduled for ${moment(
          selectedShoot?.events?.start
        )
          .utc()
          .format("MM/DD/YYYY h:mm A")}-${moment(selectedShoot?.events?.end)
          .utc()
          .format("h:mm A")} and assigned to ${info.newResource.title}`;
        handleCreateActivity(text, props.userId, selectedShoot?._id);
      } else {
        selectedShoot.status = "Tentative";
        const text = `Appointment status changed from Pending to Tentative for ${moment(
          selectedShoot?.events?.start
        )
          .utc()
          .format("MM/DD/YYYY h:mm A")}-${moment(selectedShoot?.events?.end)
          .utc()
          .format("h:mm A")} and assigned to ${info.newResource.title}`;
        handleCreateActivity(text, props.userId, selectedShoot?._id);
      }
    }
    if (info.newResource && info.newResource.id === "Pending") {
      selectedShoot.status = "Pending";
      delete selectedShoot.events.photographer;
      const text = `Appointment status changed to Pending and the photographer has been unassigned.`;
      handleCreateActivity(text, props.userId, selectedShoot?._id);
    }

    if (
      info.newResource &&
      info.newResource.id !== "Pending" &&
      info.oldResource.id !== "Pending" &&
      info.newResource.id !== info.oldResource.id
    ) {
      const text = `Appointment reassigned from ${info.oldResource.title} to ${
        info.newResource.title
      } on ${moment(selectedShoot?.events?.start)
        .utc()
        .format("MM/DD/YYYY h:mm A")}-${moment(selectedShoot?.events?.end)
        .utc()
        .format("h:mm A")}.`;
      handleCreateActivity(text, props.userId, selectedShoot?._id);
    }
    const shootRes = await props.onUpdateShoot(info.event.id, selectedShoot);
    if (shootRes.status === 200) {
      setNewState(true);
    } else {
      message.error("Something went Wrong!");
    }
    setDashboard(props.shoots);
    setLoader(false);
  };

  const onShootCancel = (shootId) => {
    setVisible(false);
    props.removeShootfromCalender(shootId);
  };
  return (
    <React.Fragment>
      <Card>
        <Spin spinning={props.loading || loader}>
          <Row>
            <Col lg={4} sm={4} md={4}>
              <div
                id="external-events"
                style={{
                  padding: "5px",
                  width: "95%",
                  height: "auto",
                  maxHeight: "-webkit-fill-available",
                  position: "absolute",
                  zIndex: "2",
                  border: "1px solid #ccc",
                  background: "#eee",
                  overflowY: "scroll",
                }}
              >
                <h5 className="text-center p-1" style={{ marginBottom: 0 }}>
                  Pending ({props.pendingShoots.length})
                </h5>
                {props.pendingShoots?.map((event) => {
                  return (
                    <div
                      style={{
                        margin: "15px 2px 2px",
                        minHeight: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#c0ca33",
                        borderColor: "#c0ca33",
                        color: "#000",
                        cursor: "pointer",
                      }}
                      className="fc-event"
                      title={event.title}
                      id={event.id}
                      key={event.id}
                      onClick={() => {
                        const eventObj = {
                          event: {
                            id: event.id,
                            _def: {
                              resourceIds: [event.resourceId],
                            },
                          },
                        };
                        showModal(eventObj);
                      }}
                    >
                      {event.title.replace("null", "")}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col lg={20} sm={20} md={20}>
              <div>
                <FullCalendar
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  defaultView="resourceTimeGridDay"
                  defaultDate={convertISODateString(state.currDate)}
                  defaultTimedEventDuration="00:30" //override the default one hour time in fullcalender
                  editable={true}
                  displayEventTime={true}
                  selectable={true}
                  timeZone="UTC"
                  backgroundColor="#fff"
                  allDaySlot={false}
                  nowIndicator={false}
                  height="auto"
                  snapDuration="00:05:00"
                  //contentHeight="auto"
                  minTime="07:00:00"
                  maxTime="22:00:00"
                  scrollTime="07:00:00"
                  resources={state.resources}
                  plugins={[resourceTimeGridPlugin, interactionPlugin]}
                  ref={calendarComponentRef}
                  titleFormat={{
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                    weekday: "long",
                  }}
                  header={{
                    left: "prev datePicker",
                    center: "title",
                    right: "today next",
                  }}
                  resourceRender={(renderInfo) => {
                    var element = document.createElement("a");
                    element.onclick = function () {
                      onViewAvail(renderInfo.resource.id);
                    };
                    element.innerHTML = renderInfo.resource.title;
                    renderInfo.el.innerHTML = "";
                    renderInfo.el.appendChild(element);
                  }}
                  customButtons={{
                    prev: {
                      text: "<",
                      click: function () {
                        setDashboard(finalDataArr);
                        const newDate = new Date(
                          state.currDate.setDate(state.currDate.getDate() - 1)
                        );
                        setState({ ...state, currDate: newDate });
                        // updateEvts(newDate);
                        props.changeDateView(
                          moment(newDate.toString()).format("YYYY-MM-DD"),
                          moment(newDate.toString()).format("YYYY-MM-DD")
                        );
                        const calendarApi =
                          calendarComponentRef.current.getApi();
                        setDashboard([]);
                        setDashboardData([]);
                        setCustomDate(newDate);
                        calendarApi.gotoDate(newDate);
                      },
                    },
                    next: {
                      text: ">",
                      click: function () {
                        setDashboard(finalDataArr);
                        const newDate = new Date(
                          state.currDate.setDate(state.currDate.getDate() + 1)
                        );
                        setState({ ...state, currDate: newDate });
                        // updateEvts(newDate);
                        props.changeDateView(
                          moment(newDate.toString()).format("YYYY-MM-DD"),
                          moment(newDate.toString()).format("YYYY-MM-DD")
                        );
                        const calendarApi =
                          calendarComponentRef.current.getApi();
                        setDashboard([]);
                        setDashboardData([]);
                        setCustomDate(newDate);
                        calendarApi.gotoDate(newDate);
                      },
                    },
                    today: {
                      text: "today",
                      click: function () {
                        setDashboard(finalDataArr);
                        const newDate = new Date();
                        setState({ ...state, currDate: newDate });
                        props.changeDateView(
                          moment(newDate.toString()).format("YYYY-MM-DD"),
                          moment(newDate.toString()).format("YYYY-MM-DD")
                        );
                        const calendarApi =
                          calendarComponentRef.current.getApi();
                        setCustomDate(newDate);
                        calendarApi.gotoDate(newDate);
                      },
                    },
                    datePicker: {
                      text: "Choose a Day",
                      click: function () {
                        setIsDateModalVisible(true);
                      },
                    },
                  }}
                  events={finalDataArr}
                  eventClick={(e) => {
                    showModal(e);
                  }}
                  eventDrop={(info) => {
                    updateShoot(info);
                  }}
                  eventReceive={(info) => {
                    const calendarApi = calendarComponentRef.current.getApi();
                    calendarApi.getEventById(info.event.id).remove();
                    let selectedId = info.event._def.publicId;
                    let selectedShoot = props.pendingShoots?.find(
                      (shoot) => shoot.id === selectedId
                    );
                    let newResObj = state.resources.find(
                      (resource) =>
                        resource.id === info?.event?._def?.resourceIds[0]
                    );

                    let neededInfo = {
                      event: {
                        ...selectedShoot,
                        end: info.event._instance.range.end,
                        start: info.event._instance.range.start,
                      },
                      oldResource: { id: "Pending", title: "PENDING" },
                      newResource: {
                        ...info.event,
                        title: newResObj?.title,
                        extendedProps: newResObj,
                        id: info.event._def.resourceIds[0],
                      },
                    };
                    updateShoot(neededInfo);
                  }}
                  eventResize={(info) => {
                    updateShoot(info);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Spin>
      </Card>
      <Modal
        title="Order Details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ top: 20 }}
        footer={null}
        destroyOnClose={true}
      >
        <Card type="inner" size="small" style={{ marginTop: 5 }}>
          <OrderDetails
            onShootCancel={onShootCancel}
            order={orderData}
            isMapView={true}
            selectedSchedule={state.selectedShoot}
            selectedShoot={props.selectedShoot}
            photographers={state.photographers}
            onUpdateShoot={props.onUpdateShoot}
            activities={props.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            fetchEntityActivities={props.fetchEntityActivities}
            getSelectedPhotographer={props.getSelectedPhotographer}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
          />
        </Card>
      </Modal>
      <Modal
        width={1000}
        title={
          <>
            <Row align="center">{`Availability of ${props.currentPhotographer?.name}`}</Row>
            <Row align="center">
              {moment(state.currDate.toISOString()).format("MM/DD/YYYY") +
                " - " +
                moment(state.currDate.toISOString())
                  .add(6, "days")
                  .format("MM/DD/YYYY")}
            </Row>
          </>
        }
        visible={viewAvail}
        footer={null}
        onCancel={onViewClose}
      >
        {weeklyAvailData.map((dayData) => (
          <Row style={{ paddingBottom: "15px" }}>
            <Col span={6} className="avail-slot-label">
              <span>{dayData.date.format("dddd  MM/DD/YY")}</span>
              <span> - </span>
            </Col>
            <Col span={18} className="avail-slot-val">
              {dayData.availableSlots.length === 0
                ? "Not Available"
                : tagsData.map((slot) => {
                    const isAvail = dayData.availableSlots.find(
                      (val) => val.label === slot.label
                    );
                    return (
                      <p
                        className={`slot available ${!isAvail && "no"}`}
                        style={{ minWidth: "125px" }}
                      >
                        {slot.label}
                      </p>
                    );
                  })}
            </Col>
          </Row>
        ))}
        <Button type="primary" onClick={onEditAvail}>
          Edit
        </Button>
      </Modal>
      <Modal
        width={1100}
        visible={editAvail}
        footer={null}
        onCancel={onEditClose}
        title={
          <>
            <Row align="center">{`Edit Availability of ${props.currentPhotographer?.name}`}</Row>
          </>
        }
        destroyOnClose={true}
      >
        <EditAvailability initialDate={moment(state.currDate)} />
      </Modal>
      <Modal
        width={500}
        visible={isDateModalVisible}
        onCancel={() => setIsDateModalVisible(false)}
        onOk={onDatePickerSelected}
        title={"Choose a Day"}
      >
        <Calendar
          fullscreen={false}
          onChange={onDateChange}
          defaultValue={moment(state.currDate)}
          value={moment(customDate)}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Today;
