import axios from "axios";

export const geocode = async (add) => {
  try {
    const encodeAddress = encodeURI(add);
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/address/${encodeAddress}`
    );
    const geocode = res.data;
    const address = {
      drive_time: geocode.drive_time,
      street: `${geocode.streetNumber || ""} ${geocode.streetName || ""}`.trim(),
      street_lower_no_spaces: `${geocode.streetNumber || ""} ${geocode.streetName || ""}`
        .trim()
        .toLowerCase()
        .replace(/ /g, ""),
      full: geocode.formattedAddress,
      city_state_zip: `${geocode.city}, ${geocode.stateCode} ${geocode.zipcode}`,
      city: geocode.city,
      state: geocode.stateCode,
      zip: geocode.zipcode,
      geometry: {
        type: "Point",
        coordinates: [geocode.longitude, geocode.latitude],
      },
    };
    return {
      success: true,
      address,
    };
  } catch (err) {
    return {
      success: false,
      address: null,
    };
  }
};
