import axios from "axios";
import {
  FETCH_PHOTOGRAPHERS,
  CREATE_PHOTOGRAPHER_SUCCESS,
  CREATE_PHOTOGRAPHER_FAIL,
  REMOVE_PHOTOGRAPHER,
  UPDATE_PHOTOGRAPHER,
  FETCH_UPSELL_LEADERBOARD,
} from "./types";

export const fetchPhotographers = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers`
    );

    dispatch({
      type: FETCH_PHOTOGRAPHERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createPhotographer = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const photographerId = Math.random().toString(36).slice(6, 12);

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/photographers`,
      { ...formData, photog_id: photographerId },
      config
    );

    dispatch({
      type: CREATE_PHOTOGRAPHER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_PHOTOGRAPHER_FAIL,
      payload: err,
    });
  }
};

export const updatePhotographer = (id, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/photographers/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PHOTOGRAPHER,
      payload: formData,
    });
  } catch (err) {
    console.log(err);
  }
};
export const updatePhotographerForWeekTotal = (tempObj) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const id = tempObj.userId;
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/photographers/photographer-paybreakdown/${id}`,
      tempObj.data,
      config
    );
  } catch (err) {
    console.log(err);
  }
};

export const removePhotographer = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/photographers/${id}`);

    dispatch({
      type: REMOVE_PHOTOGRAPHER,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchUpsellLeaderboard = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers/leaderboard`
    );
    dispatch({
      type: FETCH_UPSELL_LEADERBOARD,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
