import axios from "axios";
import {
  FETCH_INVOICES,
  FETCH_CLIENT_INVOICES,
  FETCH_INVOICE_BY_SHOOT_ID,
  FETCH_INVOICE_BY_ID,
  UPDATE_INVOICE,
  CREATE_INVOICE,
  DELETE_INVOICE,
  FETCH_INVOICES_START,
} from "./types";

export const fetchInvoices = (params) => async (dispatch) => {
  try {
    const { client, search, filter, sortField, sortOrder, skip, limit } =
      params;
    dispatch({ type: FETCH_INVOICES_START });
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/invoices?client=${client}&search=${search}&filter=${filter}&sortField=${sortField}&sortOrder=${sortOrder}&skip=${skip}&limit=${limit}`
    );
    // const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/invoices`);
    dispatch({
      type: FETCH_INVOICES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err.message);
  }
};
export const fetchInvoiceById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/invoices/${id}`
    );
    dispatch({
      type: FETCH_INVOICE_BY_ID,
      payload: res.data,
    });
    return { data: res.data, error: null };
  } catch (err) {
    console.log("error", err.message);
    return { data: null, error: err.message };
  }
};
export const fetchInvoiceByShootId = (shootId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/invoices/shoot/${shootId}`
    );
    dispatch({
      type: FETCH_INVOICE_BY_SHOOT_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const fetchClientInvoices = (clientId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/invoices/client?client=${clientId}`
    );
    dispatch({
      type: FETCH_CLIENT_INVOICES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createInvoice = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/invoices/`,
      formData
    );
    dispatch({
      type: CREATE_INVOICE,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    return {
      data: null,
      error: err.message,
    };
  }
};
export const updateInvoice = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/invoices/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_INVOICE,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    console.log("Update invoice error", err.message);
    return {
      data: null,
      error: err.message,
    };
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/invoices/${id}`);

    dispatch({
      type: DELETE_INVOICE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
