import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import FormCard from "../../../shared/Components/FormCard/";

import { forgotPassword } from "../../../redux/action/authAction";

const FormItem = Form.Item;

class ForgotPasswordForm extends React.Component {
  handleSubmit = (values) => {
    const { forgotPassword, history } = this.props;
    const response = forgotPassword(values);
    if (response.error) {
      console.log(response.error);
    }
    message.success("Your request has been sent. Please check your email.", 10);
    history.push("/admin/login");
  };

  render() {
    return (
      <FormCard>
        <section className="form-v1-container">
          <h2>Forgot Password?</h2>
          <p className="additional-info col-lg-10 mx-lg-auto mb-3">
            Enter the email address you used when you joined and we’ll send you
            instructions to reset your password.
          </p>
          <Form onFinish={this.handleSubmit} className="form-v1">
            <FormItem
              className="mb-3"
              name="email"
              rules={[
                { type: "email", message: "The input is not valid E-mail!" },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input
                size="large"
                prefix={<MailOutlined style={{ fontSize: 13 }} />}
                placeholder="Email"
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-cta btn-block"
              >
                Send Reset Instructions
              </Button>
            </FormItem>
          </Form>
        </section>
      </FormCard>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, { forgotPassword })(ForgotPasswordForm)
);
