import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Input, Button, message, Row, Modal, Col } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  createAmenity,
  updateAmenity,
} from "../../../redux/action/amenityAction";
import Axios from "axios";
import { AddressModal } from "../../../user/Component/ShootForm/Address/AddressModal";
import Title from "antd/lib/skeleton/Title";
import Paragraph from "antd/lib/skeleton/Paragraph";

const AmenityForm = ({
  createAmenity,
  updateAmenity,
  amenity,
  mode,
  onOk,
  handleCancel,
  selectedSubdivisionId,
  initialCoordinates,
}) => {
  const [validated, setValidated] = useState(false);
  const [googleAddress, setGoogleAddress] = useState({
    address: "",
    contentType: "paragraph",
    messageText:
      'If the address we found is correct, click "Confirm" below. Otherwise you can edit your entry with the "Edit" button.',
    lat: initialCoordinates?.lat,
    lng: initialCoordinates?.lng,
  });
  const [formData, setFormData] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  });
  useEffect(() => {
    if (amenity) {
      setFormData({
        ...formData,
        name: amenity.name,
        street: amenity.street,
        city: amenity.city,
        state: amenity.state,
        zip: amenity.zip,
      });
      setGoogleAddress({
        ...googleAddress,
        lat: amenity.geometry?.coordinates[0] || initialCoordinates?.lat,
        lng: amenity.geometry?.coordinates[1] || initialCoordinates?.lng,
      });
    }
  }, [amenity]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const geoSubmitHandler = () => {
    if (
      formData.name &&
      formData.street &&
      formData.city &&
      formData.state &&
      formData.zip &&
      (validated || mode === "edit")
    ) {
      Modal.confirm({
        getContainer: () => document.querySelector("#root"),
        title: "Confirm Address",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to proceed with the address?",
        okText: "Proceed",
        cancelText: "Cancel",
        onOk: onSubmit,
      });
    } else {
      if (!formData.name) {
        message.error("Please enter amenity name to proceed");
      }
      if (!formData.street) {
        message.error("Please enter street to proceed");
      }
      if (!formData.city) {
        message.error("Please enter city to proceed");
      }
      if (!formData.state) {
        message.error("Please enter state to proceed");
      }
      if (!formData.zip) {
        message.error("Please enter zipcode to proceed");
      }
      if (!validated) {
        message.error("Please mark the location in map");
      }
    }
  };

  const confirmationText =
    "Please confirm and re-enter the address details and then drag the red pin on the map to the correct location of the property.";

  const onSubmit = async () => {
    if (
      formData.name &&
      formData.street &&
      formData.city &&
      formData.state &&
      formData.zip
    ) {
      const body = {
        name: formData.name,
        street: formData.street,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        subdivision: selectedSubdivisionId,
        geometry: {
          type: "Point",
          coordinates: [googleAddress.lat, googleAddress.lng],
        },
      };
      let res;
      if (mode === "create") {
        body.photos = {
          images: [],
        };
        res = await createAmenity(body);
        if (res) {
          message.success("Amenity created Successfully!");
        }
      } else {
        res = await updateAmenity(amenity._id, body);
        if (res) {
          message.success("Amenity updated Successfully!");
        }
      }
      setFormData({});
      onOk(res);
    }
  };

  const googleFetch = async (pos) => {
    setValidated(true);
    let address = {};
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/address/reversegeocode?lat=${pos.lat}&lng=${pos.lng}`
      ).then(({ data: geocode }) => {
        setGoogleAddress({
          ...googleAddress,
          lat: pos.lat,
          lng: pos.lng,
        });
        const foundState = geocode[0]?.stateCode ? geocode[0]?.stateCode : "TX";
        address = {
          name: formData.name,
          street: geocode[0]?.streetName,
          city: geocode[0]?.city,
          state: foundState, // TODO: Remove when we move outside of TX
          zip: geocode[0]?.zipcode,
        };
        setFormData({ ...address });
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Row>
        <Col span={24}>
          {googleAddress.lat && googleAddress.lng && (
            <AddressModal
              lat={googleAddress.lat}
              lng={googleAddress.lng}
              defaultZoom={16}
              defaultMapId={"hybrid"}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `311px`, width: `100%` }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              draggable={true}
              updateLocation={(pos) => {
                googleFetch(pos);
              }}
            />
          )}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={24}>
          <p className="text-muted">
            Drag the marker to the location of the shoot
          </p>
          <div className="form-group">
            <label>Amenity Name</label>
            <Input
              value={formData.name}
              name="name"
              onChange={(e) => onChange(e)}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="form-group">
            <label>Street</label>
            <Input
              value={formData.street}
              name="street"
              onChange={(e) => onChange(e)}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <div className="form-group">
            <label>City</label>
            <Input
              value={formData.city}
              name="city"
              onChange={(e) => onChange(e)}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <div className="form-group">
            <label>State </label>
            <Input
              value={formData.state}
              name="state"
              onChange={(e) => onChange(e)}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className="form-group">
            <label>Zip code </label>
            <Input
              value={formData.zip}
              name="zip"
              onChange={(e) => onChange(e)}
            />
          </div>
        </Col>
        <Col span={24} className="addressCnfMsg">
          <Paragraph ellipsis={{ rows: 5, expandable: true }}>
            {confirmationText}
          </Paragraph>
        </Col>
        <Col span={8} offset={8}>
          <Button type="primary" block onClick={geoSubmitHandler}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => ({
  subdivision: state.subdivision,
});
export default connect(mapStateToProps, {
  createAmenity,
  updateAmenity,
})(AmenityForm);
