import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Table } from "antd";
import { fetchBrokerageClients } from "../../../redux/action/brokerageAction";
import Loading from "../../../shared/loading/Loading.js";

const BrokerageClients = ({
  fetchBrokerageClients,
  brokerage: { selectedBrokerage, clients, isLoading },
}) => {
  const columns = [
    {
      title: "HSF#",
      dataIndex: "hsf",
      key: "hsf",
    },
    {
      title: "Name",
      dataIndex: "full",
      key: "hsf",
      render: (name, record) => (
        <Link
          to={`/admin/clients/${record._id}`}
        >{`${record.first} ${record.last}`}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telephone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
  ];
  useEffect(() => {
    fetchBrokerageClients(selectedBrokerage._id);
  }, [fetchBrokerageClients, selectedBrokerage]);

  return (
    <div>
      <Card style={{ marginLeft: "10px" }}>
        <h2>Team</h2>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clients && (
          <Table
            dataSource={clients}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={false}
          />
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brokerage: state.brokerages,
});

export default connect(mapStateToProps, { fetchBrokerageClients })(
  BrokerageClients
);
