import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Table, Button, Modal, Avatar, Tag, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../shared/loading/Loading.js";
import Can from "../../user/Component/Auth/Can";
import {
  fetchPhotographers,
  removePhotographer,
  fetchUpsellLeaderboard,
} from "../../redux/action/photographer";
import PhotographerCreate from "./Photographers/PhotographerCreate.jsx";
import PhotographerEdit from "./Photographers/PhotographerEdit.jsx";
import Leaderboard from "./Photographers/Leaderboard.jsx";

const Photographers = ({
  handleCurrentPath,
  fetchPhotographers,
  removePhotographer,
  fetchUpsellLeaderboard,
  upsellLeaderboard,
  photographer: { photographers, isLoading },
}) => {
  useEffect(() => {
    handleCurrentPath("Photographers", "7");
    fetchPhotographers();
    fetchUpsellLeaderboard();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    _id: "",
    name: "",
    home_address: "",
    color: "",
    pay_multiplier: "",
    sp_cost_per_hour: "",
    base_pay: "",
    skills: [],
    headshot_url: "",
    active: false,
  });

  const { confirm } = Modal;

  const columns = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      render: (color, record) =>
        record.headshot_url ? (
          <Avatar src={record.headshot_url} />
        ) : (
          <Avatar
            style={{ color: "#FFFFFF", backgroundColor: `${record.color}` }}
          >
            {record.name.charAt(0)}
          </Avatar>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <Link to={`/admin/photographers/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "home_address",
      key: "home_address",
      responsive: ["md"],
    },
    {
      title: "Photographer ID",
      dataIndex: "photog_id",
      key: "photog_id",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span>
          {active ? <Tag color="green">active</Tag> : <Tag>inactive</Tag>}
        </span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Can do="edit" on="Photographers">
            <Button
              onClick={() => showModal(record, true)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Photographers">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can>
        </span>
      ),
    },
  ];

  const showModal = (item, edit) => {
    setData({ ...item, editMode: edit });
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onConfirmRemove = (photographer) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${photographer.name}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removePhotographer(photographer._id);
      },
      onCancel() {
        handleOk();
      },
    });
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Card style={{ margin: "10px" }}>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && photographers && (
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Can do="create" on="Photographers">
                  <Button
                    onClick={() => showModal(null, false)}
                    type="primary"
                    style={{ float: "right" }}
                  >
                    <PlusOutlined /> Add a Photographer
                  </Button>
                </Can>
                <h2>Photographers</h2>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={4}
                style={{
                  backgroundColor: "#fafafa",
                  borderRight: "1px solid #f0f0f0",
                  paddingTop: "15px",
                }}
              >
                <h4>Upsell Leaderboard</h4>
                <Leaderboard upsellLeaderboard={upsellLeaderboard || []} />
              </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={20}>
                <Table
                  columns={columns}
                  dataSource={photographers}
                  rowKey={(record) => record._id}
                  pagination={false}
                  scroll={{ x: 500 }}
                />
              </Col>
            </Row>
          </div>
        )}
      </Card>
      <Modal
        title={data.editMode ? "Edit a Photographer" : "Add a Photographer"}
        onCancel={handleCancel}
        visible={visible}
        footer={false}
        mask={true}
        destroyOnClose={true}
      >
        <div>
          {data.editMode ? (
            <PhotographerEdit handleCancel={handleCancel} data={data} />
          ) : (
            <PhotographerCreate handleCancel={handleCancel} />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  photographer: state.photographer,
  upsellLeaderboard: state.photographer.upsellLeaderboard,
});

export default connect(mapStateToProps, {
  fetchPhotographers,
  removePhotographer,
  fetchUpsellLeaderboard,
})(Photographers);
