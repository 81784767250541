import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { createMedia } from "../../../redux/action/mediaAction";
import { createActivity } from "../../../redux/action/activityAction";
import { geocode } from "../../../shared/geoCode/geocode.js";
import {
  Select,
  Card,
  message,
  Button,
  Row,
  Modal,
  Col,
  DatePicker,
  Radio,
  InputNumber,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  HomeOutlined,
  FileDoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  fetchClients,
  fetchPaymentMethods,
} from "../../../redux/action/clientAction";
import { Input, Form } from "formik-antd";
import { fetchOfferings } from "../../../redux/action/offeringAction";
import { Formik } from "formik";
import { INITIALSHOOTSTATE } from "../../../redux/action/shoot/shootConstants";
import CardListItem from "../Clients/PaymentMethods/CardListItem";
import Loading from "../../../shared/loading/Loading.js";
import { calculateVRPrice } from "../../../shared/utils/utils";
import AddressAutocomplete from "./AddressAutocomplete";

const { Option } = Select;

const CreateShoot = ({
  clients: { clients },
  auth: { user },
  fetchClients,
  offering,
  fetchOfferings,
  fetchPaymentMethods,
  createMedia,
  history,
}) => {
  const [state, setState] = useState({
    newOfferingsIds: [],
    allOfferings: offering,
  });
  useEffect(() => {
    fetchClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [modalVisible, setModalVisible] = useState(false);
  const [updatedTripCharge, setUpdatedTripCharge] = useState();
  const [driveTime, setDriveTime] = useState();
  const handleAddServ = async () => {
    setModalVisible(true);
  };
  const handleCancel = (e) => {
    setModalVisible(false);
    setState({ ...state });
  };
  const handleDeselect = (value) => {
    const arr = [...newOfferingsIds];

    const tcOffering = offering.offerings.find(
      (off) => off.code === "TC"
      && off.category === "Additional - Fees"
    );
    if (driveTime > 49 && tcOffering && tcOffering._id === value) {
      return;
    }

    arr.splice(arr.indexOf(value), 1);
    setState({
      ...state,
      newOfferingsIds: arr,
    });
  };

  const [formData, setFormData] = useState(INITIALSHOOTSTATE);
  const [datepickerVisible, setDatepickerVisible] = useState(false);
  const [requestedDate, setRequestedDate] = useState();
  const [goWhen, setGoWhen] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [selectedShootType, setSelectedShootType] = useState();
  const [listPrice, setListPrice] = useState();
  const [sqftFootage, setSqftFootage] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [loading, setLoading] = useState(false);

  const { newOfferingsIds } = state;
  useEffect(() => {
    if (!offering?.length) {
      fetchOfferings();
    }
    if (!clients?.length) {
      fetchClients();
    }
  }, []);

  const handleOk = async () => {
    const modalState = state;
    setState({ ...state, visible: false, creditModal: false });
    setModalVisible(false);
  };

  async function handleChange(value, key) {
    setShowPaymentMethods(false);
    setLoading(true);

    const client = await clients.filter((clt) => clt._id === value)[0];

    // Fetch payment methods
    if (client) {
      const { data } = await fetchPaymentMethods(client?._id);
      if (data?.cards.length > 0) {
        setShowPaymentMethods(true);
        setPaymentMethods(data?.cards);
      }
      setLoading(false);
    }

    setFormData({
      ...formData,
      client_id: client?._id,
      client_email: client?.email,
      client_hsf: client?.hsf,
      client_name_first: client?.first,
      client_name_last: client?.last,
      client_phone: client?.phone,
      client_role: client?.role,
      client_headshot_url: client?.headshot_url,
      brokerage: client?.brokerage,
      user: client?.user,
    });
    setLoading(false);
  }
  async function handleServiceChange(value, key) {
    setShowPaymentMethods(false);
    setLoading(true);

    const client = await clients.filter((clt) => clt._id === value)[0];

    const subtotal = state.subtotal + offering.price;
    const arr = [...newOfferingsIds];
    arr.push(value);

    const tcOffering = offering.offerings.find(
      (off) => off.code === "TC"
      && off.category === "Additional - Fees"
    );
    if (driveTime > 49 && tcOffering && !arr.includes(tcOffering._id)) {
      arr.push(tcOffering._id);
    }
    setState({
      ...state,
      newOfferingsIds: arr,
      subtotal: subtotal,
    });

    setLoading(false);
  };
  const onDateRadioChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "Specific Date") {
      setDatepickerVisible(true);
    } else {
      setDatepickerVisible(false);
      setRequestedDate(null);
    }
    setGoWhen(selectedValue);
  };

  const inputFormatter = (value) => {
    let firstFormatted = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let [b4, afterDeci] = firstFormatted.split(".");
    let afterDeci1 = afterDeci?.replaceAll(",", "");
    return `${b4}${afterDeci1?.length ? `.${afterDeci1}` : ""}`;
  };
  const inputParser = (value) => Number(value.replace(/\$\s?|(,*)/g, ""));

  useEffect(() => {
    const calculateTripCharge = async () => {
      if (
        formData.address.street &&
        formData.address.city &&
        formData.address.state
      ) {
        const fullAddress = `${formData.address.street}, ${formData.address.city}, ${formData.address.state} ${formData.address.zip}`;
        const geoAddress = await geocode(fullAddress);
        const driveTime = geoAddress?.address?.drive_time;

        for (const off of offering.offerings) {
          setDriveTime(driveTime);
          if (off.code === "TC" && off.category === "Additional - Fees") {
            if (driveTime > 49) {
              const calc = (driveTime - 50) / 5;
              const rounded = Math.floor(calc);
              const updatedTripCharge = rounded * off.price + 10;
              setUpdatedTripCharge(updatedTripCharge);
              return;
            }
          }
        }
        setUpdatedTripCharge(0);
      }
    };
    calculateTripCharge();
  }, [formData, offering]);

  useEffect(() => {
    if (driveTime > 49) {
      const tcOffering = offering.offerings.find(
        (off) => off.code === "TC"
        && off.category === "Additional - Fees"
      );
      if (tcOffering && !newOfferingsIds.includes(tcOffering._id)) {
        setState((prev) => ({
          ...prev,
          newOfferingsIds: [...prev.newOfferingsIds, tcOffering._id]
        }));
      }
    }
  }, [
    driveTime,
    offering.offerings,
    newOfferingsIds,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={async (values) => {
        setSubmitting(true);
        // Create Media object
        const medReq = {
          photos: {
            status: "Processing",
            gallery: [],
          },
          video: {
            status: "Not Available",
            link: null,
          },
          dollHouse: {
            status: "Not Available",
            matterPort_id: null,
          },
          property_info: {
            street: values?.address?.street,
            city: values?.address?.city,
            state: values?.address?.state,
            zip: values?.address?.zip,
          },
          branding: {
            agent_info: {
              name: values?.client_name_first + " " + values?.client_name_last,
              email: values?.client_email,
              phone: values?.client_phone,
              headshot: values?.client_headshot_url,
            },
            brokerage_info: {
              name: values?.brokerage?.name,
              logo: values?.brokerage?.logo,
            },
          },
        };

        const mediaRes = await createMedia(medReq);
        // setMediaId(mediaRes.data._id)

        if (!mediaRes.error) {
          const fullAddress = `${values.address.street}, ${values.address.city}, ${values.address.state} ${values.address.zip}`;
          const geoAddress = await geocode(fullAddress);

          const foundState = geoAddress?.address?.state
            ? geoAddress?.address?.state
            : "TX";

          const offeringArr = offering.offerings.filter((val) =>
            newOfferingsIds.includes(val._id)
          );
          let finalSubTotal = 0;
          let shootReqs = [];
          let isDeluxe = false;
          offeringArr.forEach((offer) => {
            let price = offer.price;
            if (offer.code === "VR") {
              let updatedPrice = 0;
              if (sqftFootage > 0) {
                updatedPrice = calculateVRPrice(sqftFootage);
                price = updatedPrice;
                offer.price = updatedPrice;
              }
            }
            if (offer.code === "TC" && offer.category === "Additional - Fees") {
              price = updatedTripCharge;
              offer.price = updatedTripCharge;
            }
            finalSubTotal += price;

            shootReqs.push(offer.code);
            if (offer.code === "DLX" || offer.code === "ADLX") {
              isDeluxe = true;
            }
          });
          const calculate_tax = (finalSubTotal / 100) * (8.25).toFixed(2);
          const sales_tax = parseFloat(calculate_tax);

          const shootData = {
            ...values,
            booking_form: {
              ...values.booking_form,
              when: goWhen,
              google_address: fullAddress,
              street: values.address.street,
              city: values.address.city,
              state: values.address.state,
              zip_code: values.address.zip,
              apt: values.address.apt,
              requested_date: requestedDate,
              sqft: parseInt(sqftFootage),
              list_price: parseInt(listPrice),
              shoot_type: selectedShootType || "Residential",
              is_deluxe: isDeluxe,
            },
            reqs: shootReqs,
            invoice: {
              subtotal: finalSubTotal,
              discount: 0,
              total: finalSubTotal + sales_tax,
              balance: finalSubTotal + sales_tax,
              tax: sales_tax,
              products: offeringArr,
            },
            events: {
              ...values.events,
              requested_date: requestedDate,
              title: `${geoAddress?.address?.street} - ${values.client_name_first} ${values.client_name_last}`,
              description:
                "Photo Turnaround : Next Day by 12:00p Video Turnaround:  Requirements: Next Day by 12:00p",
              start: moment(),
              end: moment().add(15, "minutes"),
            },
            address: {
              drive_time: geoAddress?.address?.drive_time,
              street: values?.address?.street,
              apt: values?.address?.apt,
              street_lower_no_spaces:
                geoAddress?.address?.street_lower_no_spaces,
              full: geoAddress?.address?.full,
              city_state_zip: `${geoAddress?.address?.city}, ${foundState} ${geoAddress?.address?.zip}`,
              city: geoAddress?.address?.city,
              state: foundState,
              zip: geoAddress?.address?.zip,
              geometry: {
                type: "Point",
                coordinates: geoAddress?.address?.geometry?.coordinates,
              },
            },
            media: mediaRes.data._id,
            selectedCard: selectedCardId,
          };

          const res = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/shoots/basic`,
            shootData
          );
          const body = res.data;

          if (res.status === 200) {
            // Create Activity
            const newActivity = {
              user: user._id,
              text: `Shoot requested for ${body?.address?.full}`,
              entity_id: body._id,
              entity_type: "Shoot",
              _created_at: new Date(),
            };

            await axios.post(
              `${process.env.REACT_APP_ROOT_URL}/activities`,
              newActivity
            );

            // Route to detail page
            history.push(`/admin/shoots/${body._id}`);

            // Success message
            message.success({
              content: "Shoot created successfully",
              style: {
                marginTop: "10vh",
              },
            });
            setSubmitting(false);
          } else {
            message.error("Oops! Something went wrong");
            setSubmitting(false);
          }
        } else {
          message.error("Oops! Unable to add media");
        }
      }}
      render={({ setFieldValue, values, handleSubmit }) => (
        <div>
          <Row
            style={{
              margin: "20px",
            }}
          >
            <Col span={16} offset={4}>
              <Card>
                <Form>
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <div className="form-group">
                        <h4>TYPE OF SHOOT</h4>
                        <Select
                          defaultValue="Residential"
                          style={{ width: 200 }}
                          onChange={(val) => setSelectedShootType(val)}
                        >
                          <Option value="Residential">Residential</Option>
                          <Option value="Multi-family">Multi-family</Option>
                          <Option value="Commercial">Commercial</Option>
                          <Option value="Additional">Additional Shots</Option>
                        </Select>
                      </div>
                    </Col>
                    <Col span={24}>
                      <h3 className="text-muted">
                        <UserOutlined /> Client Info
                      </h3>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Select
                        size="large"
                        showSearch
                        style={{ width: "100%", padding: "5px" }}
                        placeholder="Select Client"
                        onChange={(val) => handleChange(val, "clientId")}
                        filterOption={(input, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }
                          return false;
                        }}
                      >
                        {clients.map((clt) => (
                          <Option key={clt._id} value={clt._id}>
                            {`${clt.full} (HSF# ${clt.hsf})`}
                          </Option>
                        ))}
                      </Select>
                    </Col>

                    {showPaymentMethods && (
                      <>
                        {loading ? (
                          <Loading />
                        ) : (
                          <Col className="mt-4" span={24}>
                            <div>
                              <h4>Select a Payment Method for this Shoot</h4>
                            </div>
                            <div className="inner-card">
                              {paymentMethods.map((pm) => (
                                <CardListItem
                                  card={pm}
                                  allowSelect={true}
                                  allowDelete={false}
                                  allowPay={false}
                                  setSelectedCardId={setSelectedCardId}
                                  selectedCardId={selectedCardId}
                                  key={pm.id}
                                />
                              ))}
                            </div>
                          </Col>
                        )}
                      </>
                    )}
                    <Col className="mt-4" span={24}>
                      <strong>SECONDARY CONTACT</strong>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Full Name </label>
                        <Input
                          className="newTextBox"
                          name="booking_form.secondary_contact.name"
                        />{" "}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Email </label>
                        <Input
                          className="newTextBox"
                          name="booking_form.secondary_contact.email"
                        />{" "}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Phone</label>
                        <Input
                          className="newTextBox"
                          name="booking_form.secondary_contact.phone"
                        />{" "}
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Role (e.g. Assistant, Homeowner)</label>
                        <Input
                          className="newTextBox"
                          name="booking_form.secondary_contact.role"
                        />{" "}
                      </div>
                    </Col>
                    {/* <Col className="mt-4" span={24}>
                      <div className="form-group">
                        <label>Team Members</label>
                        <Input className="newTextBox" name="booking_form.team_members" />{" "}
                      </div>
                    </Col> */}
                  </Row>
                  <Row gutter={[16, 8]} className="mt-5">
                    <Col span={24}>
                      <h3 className="text-muted">
                        <HomeOutlined /> Property Details
                      </h3>
                    </Col>
                    <Col span={24}>
                      {/* <div className="form-group"> */}
                      <div>
                        <strong>THE PROPERTY IS..</strong>
                      </div>

                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) =>
                          setFieldValue("booking_form.resi", e.target.value)
                        }
                      >
                        <Radio.Button value="Vacant">Vacant</Radio.Button>
                        <Radio.Button value="Occupied">Occupied</Radio.Button>
                      </Radio.Group>
                      {/* </div> */}
                    </Col>
                    <AddressAutocomplete
                      formData={formData}
                      setFormData={setFormData}
                      inputFormatter={inputFormatter}
                      inputParser={inputParser}
                      setListPrice={setListPrice}
                      setSqftFootage={setSqftFootage}
                    />
                  </Row>
                  <Row gutter={[16, 8]} className="mt-5">
                    <Col span={24}>
                      <h3 className="text-muted">
                        <LockOutlined /> Property Access
                      </h3>
                    </Col>
                    <Col span={24}>
                      <div className="m-2">
                        <strong>
                          WHEN WILL THE HOME BE READY TO HAVE THE PHOTOS TAKEN?
                        </strong>
                      </div>

                      <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={onDateRadioChange}
                            value={goWhen}
                          >
                            <Radio.Button value="Immediately">
                              Immediately
                            </Radio.Button>
                            <Radio.Button value="Specific Date">
                              Specific Date
                            </Radio.Button>
                          </Radio.Group>
                        </Col>
                        {datepickerVisible && (
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <DatePicker
                              name="requested_date"
                              disabledDate={(date) => {
                                return date.day() === 0 ? true : false;
                              }}
                              onChange={(value) =>
                                setRequestedDate(moment(value).format())
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col span={24}>
                      <div className="m-2">
                        <strong>
                          DO WE NEED AN APPOINTMENT TIME OR CAN WE GO ANYTIME ON
                          THE SPECIFIED DATE?
                        </strong>
                      </div>
                      <Row>
                        <Col span={12}>
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) =>
                              setFieldValue(
                                "booking_form.appt_type",
                                e.target.value
                              )
                            }
                          >
                            <Radio.Button value="Go Anytime">
                              Go Anytime
                            </Radio.Button>
                            <Radio.Button value="Need Appointment">
                              Need Appointment
                            </Radio.Button>
                          </Radio.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <div className="m-2">
                        <strong>METHOD OF ENTRY</strong>
                      </div>
                      <Row>
                        <Col span={12}>
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) =>
                              setFieldValue(
                                "booking_form.entry_method",
                                e.target.value
                              )
                            }
                          >
                            <Radio.Button value="Combo Lockbox">
                              Combo Lockbox
                            </Radio.Button>
                            <Radio.Button value="Supra Ibox">
                              Supra Ibox
                            </Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col span={12}>
                          <Input
                            name="booking_form.entry_id"
                            placeholder="Entry Code"
                          />{" "}
                        </Col>
                      </Row>
                    </Col>
                    <Row gutter={[16, 8]} className="mt-5">
                      <Col span={24}>
                        <div className="m-2">
                          <h3 className="text-muted">
                            <FileDoneOutlined /> Services
                          </h3>
                          <Row>
                            <Col>
                              <div className="m-2">
                                <strong>
                                  {offering.offerings
                                    .filter((val) =>
                                      newOfferingsIds.includes(val._id)
                                    )
                                    .map((offeringObj) => {
                                      return <p>{offeringObj.description}</p>;
                                    })}
                                </strong>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <div className="m-2">
                          <Button
                            type="secondary"
                            onClick={() => {
                              handleAddServ();
                            }}
                            style={{ color: "gray" }}
                          >
                            <PlusOutlined /> Add Servies
                          </Button>
                        </div>
                      </Col>
                      {modalVisible && (
                        <Modal
                          visible={modalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          destroyOnClose={true}
                          width={600}
                        >
                          <Col
                            span={24}
                            style={{ paddingTop: "20px", fontWeight: "bold" }}
                            align="middle"
                          >
                            Select your services below:
                          </Col>
                          <Col span={20} offset={2}>
                            <Select
                              mode="multiple"
                              showSearch
                              value={newOfferingsIds}
                              style={{ width: "100%", padding: "5px" }}
                              placeholder="Add new offering"
                              onSelect={(val) =>
                                handleServiceChange(val, "newOfferingsIds")
                              }
                              onDeselect={(val) => handleDeselect(val)}
                              filterOption={(input, option) => {
                                let offerings =
                                  option.children.props.children[0].props
                                    .children;
                                if (offerings) {
                                  return (
                                    offerings
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }
                                return false;
                              }}
                            >
                              {/* {offering.offerings &&
                                offering.offerings?.map((off) => (
                                  <Option value={off._id} key={off._id}>
                                    <Row key={off._id}>
                                      <Col span={20}>{`${
                                        off.category.split(" ")[0]
                                      } - ${off.description}`}</Col>
                                      <Col span={4} align="end">
                                        ${off.price}
                                      </Col>
                                    </Row>
                                  </Option>
                                ))} */}
                            {offering.offerings &&
                              offering.offerings?.map((off) => {
                                let price = off.price;
                                if (off.code === "VR") {
                                  let updatedPrice = 0;
                                  if (sqftFootage > 0) {
                                    updatedPrice = calculateVRPrice(sqftFootage);
                                  }
                                  price = updatedPrice.toFixed(2);
                                }
                                if (off.code === "TC" && off.category === "Additional - Fees") {
                                  price = updatedTripCharge.toFixed(2);
                                }
                                return (
                                  <Option value={off._id} key={off._id}>
                                    <Row key={off._id}>
                                      <Col span={20}>{`${off.category.split(" ")[0]} - ${off.description}`}</Col>
                                      <Col span={4} align="end">
                                        ${price}
                                      </Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        </Modal>
                      )}
                    </Row>
                    <Col span={24}>
                      <Button
                        onClick={handleSubmit}
                        type="primary"
                        style={{ marginTop: "20px" }}
                        size="large"
                        disabled={submitting}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  auth: state.auth,
  offering: state.offering,
});

export default connect(mapStateToProps, {
  fetchClients,
  fetchPaymentMethods,
  fetchOfferings,
  createMedia,
  createActivity,
})(CreateShoot);
