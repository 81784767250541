import React, { useEffect, useState } from "react";
import {
  Calendar,
  Select,
  Tag,
  Col,
  Row,
  Typography,
  Button,
  message,
  Spin,
  Modal,
  Form,
  DatePicker,
  Radio,
} from "antd";
import { connect } from "react-redux";
import {
  fetchPhotographerByUserId,
  fetchPhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
} from "../../../redux/action/photographerAction";
import {
  convertISODateString,
  convertISOStrToDate,
} from "../../../shared/utils/utils";
import EditAvailability from "./EditAvailability";
const moment = require("moment");
const { CheckableTag } = Tag;

const Availability = ({
  handleCurrentPath,
  photographer: { currentPhotographer, photographerUnavailableSlotsForDay },
  fetchPhotographerUnavailabilityForDay,
  fetchPhotographerByUserId,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
  auth,
}) => {
  useEffect(() => {
    handleCurrentPath("Availability", "4");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchPhotographerByUserId(auth.user._id);
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <EditAvailability />
    </div>
  );
};
const mapStateToProps = (state) => ({
  photographer: state.photographer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPhotographerByUserId,
  fetchPhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
})(Availability);
