import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Row, Col, Button, Tooltip, message } from "antd";
import { EyeOutlined, LinkOutlined } from "@ant-design/icons";
import { toTitleCase } from "../../../../../shared/utils/utils";

const ViewMediaItem = ({ shoot, icon, mediaType, drawerStyles }) => {
  const unBrandedUrl = `${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${shoot.hsn}/${shoot.address.street_lower_no_spaces}`;
  const brandedUrl = `${process.env.REACT_APP_DOMAIN_URL}/media/b/${shoot.hsn}/${shoot.address.street_lower_no_spaces}`;

  return (
    <>
      <Row style={drawerStyles.innerCard}>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} className="p-2">
          <Tooltip placement="top" title={toTitleCase(mediaType)}>
            <img width="100%" src={icon} alt={mediaType} />
          </Tooltip>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          {" "}
          <Button
            type="dashed"
            size="small"
            style={{ marginTop: "10px" }}
            onClick={() => {
              window.open(`${unBrandedUrl}?tab=${mediaType}`, "_blank");
            }}
          >
            <EyeOutlined /> View Unbranded
          </Button>
          <CopyToClipboard
            text={`${unBrandedUrl}?tab=${mediaType}`}
            onCopy={() => message.info("Link copied to clipboard")}
          >
            <Button type="link">
              <LinkOutlined /> Copy Link
            </Button>
          </CopyToClipboard>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <Button
            type="primary"
            size="small"
            style={{ marginTop: "10px" }}
            onClick={() => {
              window.open(`${brandedUrl}?tab=${mediaType}`, "_blank");
            }}
          >
            <EyeOutlined /> View Branded
          </Button>
          <CopyToClipboard
            text={`${brandedUrl}?tab=${mediaType}`}
            onCopy={() => message.info("Link copied to clipboard")}
          >
            <Button type="link">
              <LinkOutlined />
              Copy Link
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>
    </>
  );
};

export default ViewMediaItem;
