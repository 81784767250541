import {
  FETCH_INVOICES,
  FETCH_CLIENT_INVOICES,
  FETCH_INVOICE_BY_ID,
  FETCH_INVOICE_BY_SHOOT_ID,
  UPDATE_INVOICE,
  CREATE_INVOICE,
  DELETE_INVOICE,
  FETCH_INVOICES_START,
} from "../action/types";

const initialState = { invoices: [], isLoading: false };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_INVOICES_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_INVOICES:
      return {
        ...state,
        isLoading: false,
        invoices: payload.invoices,
        totalCount: payload.count,
      };
    case FETCH_CLIENT_INVOICES:
      return {
        ...state,
        isLoading: false,
        invoices: payload,
      };
    case FETCH_INVOICE_BY_ID:
      return {
        ...state,
        isLoading: false,
        selectedInvoice: payload,
      };
    case FETCH_INVOICE_BY_SHOOT_ID:
      return {
        ...state,
        isLoading: false,
        selectedInvoice: payload,
      };
    case CREATE_INVOICE:
      return {
        ...state,
        isLoading: false,
        selectedInvoice: payload,
        invoices: [payload, ...state.invoices],
      };
    case UPDATE_INVOICE:
      const updatedInvoices = state.invoices.map((invoice, index) => {
        if (invoice._id === payload._id) {
          const updInv = {
            ...payload,
            shoot: invoice.shoot,
            client: invoice.client,
          };
          return updInv;
        } else {
          return invoice;
        }
      });

      return {
        ...state,
        isLoading: false,
        invoices: [...updatedInvoices],
        selectedInvoice: payload,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice._id !== action.payload
        ),

        isLoading: false,
      };
    default:
      return state;
  }
}
