import React, { useState } from "react";
import { Modal, Button, Input, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const EditRequestModal = ({ imageUrl, fileName, shootId, address, hsn }) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [editRequestText, setEditRequestText] = useState(""); // State for input text

  // Function to open the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditRequestText(""); // Clear input on close
  };

  // Function to handle sending the edit request to Slack via Zapier
  const handleSendSlack = async () => {
    const shootDetailUrl = `${process.env.REACT_APP_DOMAIN_URL}/admin/shoots/${shootId}?tab=tour`;
    const text = `🖼️ Request for edit of ${fileName}\n\nAddress: ${address?.replace(
      "null",
      ""
    )}\nHSN: ${hsn}\n${shootDetailUrl}\n\n**Edit Request**: ${editRequestText}`;
    console.log(text);
    try {
      await fetch("https://hooks.zapier.com/hooks/catch/412492/26a49jo/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: text, imageUrl }),
      });
      message.success("Edit request sent successfully!");
      setEditRequestText(""); // Clear input after sending
      setIsModalVisible(false); // Close modal after sending
    } catch (error) {
      console.error(error);
      message.error("Failed to send edit request.");
    }
  };

  return (
    <>
      {/* Button to open modal */}
      <Button
        type="link"
        icon={<ExclamationCircleOutlined />}
        onClick={showModal}
      >
        Request Edit
      </Button>
      {/* Modal for viewing image and entering edit request */}
      <Modal
        title="Request an Edit"
        visible={isModalVisible}
        onOk={handleSendSlack} // Action on modal OK button
        onCancel={handleCancel} // Action on modal Cancel button
        okText="Send Request" // Custom text for OK button
        cancelText="Cancel" // Custom text for Cancel button
      >
        {/* Image thumbnail */}
        <div style={{ marginBottom: 16 }}>
          <img src={imageUrl} alt="Thumbnail" style={{ width: "100%" }} />
        </div>
        <div className="mb-3 text-danger">
          Edit requests are sent to the #important channel on Slack.
        </div>
        {/* Input for edit request text */}
        <Input.TextArea
          value={editRequestText}
          onChange={(e) => setEditRequestText(e.target.value)}
          placeholder="Enter your edit request here..."
          rows={4}
        />
      </Modal>
    </>
  );
};

export default EditRequestModal;
