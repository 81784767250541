import { SHOW_MODAL, HIDE_MODAL } from "../action/types";

const initialState = { isOpen: false };

export default function (state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
