import React from 'react';
import {connect} from "react-redux";
import { Form, Input, Button } from 'antd';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import {resetPassword} from "../../../redux/action/authAction";
import FormCard from "../../../shared/Components/FormCard/";
const FormItem = Form.Item;

class ResetPasswordForm extends React.Component {


    handleSubmit = (values) => {
        const { resetPassword, location, history } = this.props;
        const {token} = queryString.parse(location.search);
        const payload = {
            ...values,
            token
        };

        const response = resetPassword(payload);
        if (response.error) {
            console.log(response.error);
        }

        history.push('/admin/login');
    };

    render() {
        return (
            <FormCard>
                <section className="form-v1-container">
                    <h2>Reset Password</h2>
                    <p className="additional-info col-lg-10 mx-lg-auto mb-3">Enter new password to reset your password.</p>
                    <Form onFinish={this.handleSubmit} className="form-v1" layout="vertical">
                        <FormItem
                            name="password"
                            label="Password"
                            validateTrigger={["onBlur"]}
                            rules={[{ required: true, message: "Password is required" }]}
                        >
                            <Input.Password />
                        </FormItem>
                        <Form.Item
                            name="password_confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            validateTrigger={["onBlur"]}
                            rules={[
                                { required: true, message: "Confirm password is required" },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject("Password does not match!");
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <FormItem>
                            <Button type="primary" htmlType="submit" className="btn-cta btn-block">
                                Reset Password
                            </Button>
                        </FormItem>
                    </Form>
                </section>
            </FormCard>
        );
    }
}

const mapStateToProps = (state) => {

};

export default withRouter(connect(mapStateToProps, {resetPassword})(ResetPasswordForm));

