import React from "react";
import { Card } from "antd";
import "./styles.scss";
import logo from "../../../content/hs_logo.png";

const FormCard = (props) => (
  <section className="cover cover-page">
    <div
      className="cover-bg-img"
      style={{
        backgroundImage:
          "url(https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/02/portfolio-10.jpg)",
      }}
    ></div>
    <div className="cover-form-container">
      <div className="col-12 col-md-8 col-lg-6 col-xl-4">
        <Card>
          <div className="logo">
            <img src={logo} alt="Logo" width="100%" />
          </div>
          <div className="mt-1">{props.children}</div>
        </Card>
      </div>
    </div>
  </section>
);

export default FormCard;
