import React, { useState } from "react";
import { Row, Col, Typography, Upload, message } from "antd";
import { Input, Form } from "formik-antd";
import { PlusOutlined } from "@ant-design/icons";

const Brokerage = ({ flyer, client, setFieldValue }) => {
  const [state, setState] = useState({
    imageUrl: flyer.brokerage.logo_url,
  });
  const { imageUrl } = state;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("Image must smaller than 4MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChange = ({ file }) => {
    const avatar = new FormData();
    avatar.append("path", `brokerage/photos/high/${client.hsf}`);
    avatar.append("file", file.originFileObj);
    setFieldValue("brokerage.logo_url", avatar);
    getBase64(file.originFileObj, (imageUrl) => {
      setState({
        imageUrl,
        loading: false,
      });
    });
  };

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="form-group"
            name="brokerage.name"
            label="Company Name"
          >
            <Input name="brokerage.name" />
          </Form.Item>
          <Form.Item
            className="form-group"
            name="brokerage.tagLine"
            label="Tag Line"
          >
            <Input name="brokerage.tagLine" />
          </Form.Item>
          <Form.Item
            className="form-group"
            name="brokerage.address"
            label="Street Address"
          >
            <Input name="brokerage.address" />
          </Form.Item>
          <Form.Item
            className="form-group"
            name="brokerage.room"
            label="Suite/Room"
          >
            <Input name="brokerage.room" />
          </Form.Item>
          <Form.Item className="form-group" name="brokerage.city" label="City">
            <Input name="brokerage.city" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="form-group"
            name="brokerage.state"
            label="State"
          >
            <Input name="brokerage.state" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="form-group" name="brokerage.zip" label="Zip">
            <Input name="brokerage.zip" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="form-group"
            name="brokerage.website"
            label="Website"
          >
            <Input addonBefore="http://" name="brokerage.website" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="form-group"
            label="Logo"
            name="brokerage.logo_url"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
            {flyer.brokerage.logo_url ? (
              <Typography.Text>
                Note: Click to replace the logo.
              </Typography.Text>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Brokerage;
