import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import StatusNotFound from "../admin/Components/StatusNotFound";
import { PERMITTED_ROUTES } from "../utils/settings";

const PrivateRoute = ({
  auth: { isAuthenticated, isLoading, user, logout },
  component: Component,
  handleCurrentPath,
  path,
  ...rest
}) => {
  const is404 = !PERMITTED_ROUTES.ALLROUTES.includes(path);
  var isPermitted = false;
  if (!is404 && user) {
    user.access.forEach((access) => {
      if (PERMITTED_ROUTES[access].includes(path)) {
        isPermitted = true;
      }
    });
  }
  return !is404 && isPermitted ? (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !isLoading ? (
          <Redirect {...rest} to={{ pathname: "/admin/login", state: { from: rest.location.pathname, search: rest.location.search } }} />
        ) : (
            <Component {...props} handleCurrentPath={handleCurrentPath} />
          )
      }
    />
  ) : isAuthenticated ? (
    <Route>
      <StatusNotFound status={{ is404 }} />
    </Route>
  ) : logout ? (
    <Redirect {...rest} to="/admin/login" />
  ) : (
      <Redirect {...rest} to={{ pathname: "/admin/login", state: { from: rest.location.pathname, search: rest.location.search } }} />
  );
};
const mapStateToprops = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToprops)(PrivateRoute);


