import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//console.log("My Entry Point", process.env);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO - Remove this code in production
window.onUsersnapCXLoad = function (api) {
  api.init();
};
// var script = document.createElement("script");
// script.defer = 1;
// script.src =
//   "https://widget.usersnap.com/global/load/677defc4-9d98-4854-8628-b93dd35a4125?onload=onUsersnapCXLoad";
// document.getElementsByTagName("head")[0].appendChild(script);
