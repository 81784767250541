import {
  FETCH_PAYMENTS,
  CREATE_PAYMENT,
  REMOVE_PAYMENT,
  UPDATE_PAYMENT,
  FETCH_PAYMENTS_BY_INVOICE_ID,
} from "../action/types";

const initialState = { payments: [], isLoading: true };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return {
        ...state,
        isLoading: false,
        payments: action.payload,
      };
    case FETCH_PAYMENTS_BY_INVOICE_ID:
      return {
        ...state,
        isLoading: false,
        invoicePayments: action.payload,
      };
    case CREATE_PAYMENT:
      return {
        ...state,
        payments: [...state.payments, action.payload],
        isLoading: false,
      };
    case REMOVE_PAYMENT:
      return {
        ...state,
        payments: state.payments.filter(
          (payment) => payment._id !== action.payload
        ),

        isLoading: false,
      };
    case UPDATE_PAYMENT:
      return Object.assign(
        {},
        {
          ...state,
          payments: state.payments.map((payment) =>
            payment._id === action.payload._id ? action.payload : payment
          ),
          isLoading: false,
        }
      );
    default:
      return state;
  }
}
