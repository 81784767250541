import React, { useState } from "react";
import { Input, message } from "antd";
import "./styles.scss";

const InlineEdit = ({
  value,
  test,
  setValue,
  selectedItem,
  invoice,
  updateInvoice,
}) => {
  const [editingValue, setEditingValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [updatedItems, setUpdatedItems] = useState();

  const onChange = (event) => {
    // console.log("onChange", editingValue);
    setEditingValue(event.target.value);
    test(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      console.log("onKeyDown", editingValue);
      event.target.blur();
      setEditMode(false);
      handleUpdate();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      //   console.log("onBlur 1", editingValue);
      setValue(value);
      setEditMode(false);
    } else {
      //   console.log("onBlur 2", editingValue);
      setValue(event.target.value);
      setEditMode(false);
      handleUpdate();
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const handleUpdate = async () => {
    let products = invoice.products;
    let totalPay;
    const editedProducts = products.map((product) => {
      // Iterate to find the line item being edited
      if (product._id === selectedItem._id) {
        product.photographer_pay = parseFloat(editingValue);
      }
      return product;
    });

    const oldPay = invoice.photographer_total_pay - parseFloat(value);
    totalPay = oldPay + parseFloat(editingValue);

    const updatedInvoice = {
      ...invoice,
      products: editedProducts,
      photographer_total_pay: parseFloat(totalPay),
    };
    const res = await updateInvoice(invoice._id, updatedInvoice);

    if (!res.error) {
      message.success(`Updated successfully.`);
      test(res.data);
    } else {
      message.error("Oops! Something went wrong.");
    }
  };

  return (
    <div className="inline-edit">
      {!editMode ? (
        <span className="editable-item" onClick={handleEditMode}>
          {editingValue}
        </span>
      ) : (
        <Input
          type="text"
          aria-label="Field name"
          value={editingValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export default InlineEdit;
