import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSubdivisionById } from "../../../redux/action/subdivisionAction";
import {
  fetchAmenityBySubdivisionId,
  deleteAmenity,
  resetAmenity,
} from "../../../redux/action/amenityAction";
import { Button, Card, message, Modal, Table } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AmenityForm from "./AmenityForm";
import Can from "../../../user/Component/Auth/Can";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const SubDivision = ({
  match,
  Subdivision: { selectedSubdivision },
  fetchSubdivisionById,
  fetchAmenityBySubdivisionId,
  deleteAmenity,
  amenities,
  resetAmenity,
  user,
}) => {
  useEffect(() => {
    const fetch = async () => {
      let divisionID;
      if (match?.params?.id) {
        divisionID = match.params.id;
      }
      const res = await fetchSubdivisionById(divisionID);
      if (res?.data) {
        await fetchAmenityBySubdivisionId(res.data._id);
      }
    };
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [amenity, setAmenity] = useState();
  const [initialCoordinate, setInitialCoordinate] = useState({});

  useEffect(() => {
    const fetchInitialCoordinates = async () => {
      if (selectedSubdivision) {
        Axios.get(
          `${process.env.REACT_APP_ROOT_URL}/shoots/address/${selectedSubdivision.city}-${selectedSubdivision.state}-${selectedSubdivision.zip}`
        )
          .then((res) => {
            setInitialCoordinate({
              lat: res.data.latitude,
              lng: res.data.longitude,
            });
          })
          .catch((err) => console.log("error at fetching the Coordinates"));
      }
    };
    fetchInitialCoordinates();
  }, [selectedSubdivision]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const history = useHistory();
  const { confirm } = Modal;
  const onConfirmRemove = (amenity) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${amenity.name}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        const remove = async () => {
          const res = await deleteAmenity(amenity._id);
          if (res) {
            message.success("Amenity removed successfully!");
          }
        };
        remove();
      },
    });
  };

  const columns = [
    {
      title: "Name",
      width: 350,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street",
      render: (street) => (street ? street : "-"),
      responsive: ["md"],
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (city) => (city ? city : "-"),
      responsive: ["md"],
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => (state ? state : "-"),
      responsive: ["md"],
    },
    {
      title: "Zip code",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Can do="edit" on="Amenity">
            <Button
              onClick={() => {
                resetAmenity();
                history.push({
                  pathname: `/admin/amenity/${selectedSubdivision._id}`,
                  search: `?amenityId=${record._id}`,
                });
              }}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Amenity">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can>
        </span>
      ),
    },
  ];

  return (
    <div>
      {selectedSubdivision && (
        <Card
          style={{
            margin: "20px",
          }}
        >
          <h2>
            {selectedSubdivision?.name}{" "}
            <span style={{ color: "#868e96", fontSize: 16, marginLeft: 5 }}>
              {selectedSubdivision.city}, {selectedSubdivision.state}{" "}
              {selectedSubdivision.zip}
            </span>
          </h2>
        </Card>
      )}
      <Card
        style={{
          margin: "20px",
        }}
      >
        {amenities && (
          <div>
            <Button
              type="primary"
              style={{
                float: "right",
                marginBottom: 10,
              }}
              onClick={() => {
                resetAmenity();
                history.push({
                  pathname: `/admin/amenity/${selectedSubdivision._id}`,
                  search: `?amenityId=new`,
                });
              }}
            >
              <PlusOutlined /> Add an Amenity
            </Button>
            <h3>Available Amenities</h3>
            <Table
              columns={columns}
              dataSource={amenities}
              rowKey={(record) => record._id}
              scroll={{ x: 500 }}
            />
          </div>
        )}
      </Card>
      {visible && (
        <Modal
          title={
            setMode === "create" ? (
              <span style={{ color: "white" }}>Add a new Amenity</span>
            ) : (
              <span style={{ color: "white" }}>Edit an Amenity</span>
            )
          }
          getContainer={() => document.querySelector("#root")}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{ top: 20 }}
          destroyOnClose={true}
        >
          {mode === "create" ? (
            <AmenityForm
              selectedSubdivisionId={selectedSubdivision._id}
              mode={mode}
              onOk={handleOk}
              handleCancel={handleCancel}
              initialCoordinates={initialCoordinate}
            />
          ) : (
            <AmenityForm
              selectedSubdivisionId={selectedSubdivision._id}
              amenity={amenity}
              mode={mode}
              onOk={handleOk}
              handleCancel={handleCancel}
              initialCoordinates={initialCoordinate}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  Subdivision: state.subdivision,
  amenities: state.amenities.amenities,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  fetchSubdivisionById,
  fetchAmenityBySubdivisionId,
  deleteAmenity,
  resetAmenity,
})(SubDivision);
