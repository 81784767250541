import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import marker from "../../../../content/marker.png";

export const AddressModal = withScriptjs(
  withGoogleMap((props) => {
    const markerIcon = {
      url: marker,
      size: { width: 100, height: 60 },
      anchor: { x: 35, y: 50 },
      scaledSize: { width: 30, height: 50 },
      fill: "red",
    };
    const [position, setPosition] = useState({
      lat: props.lat,
      lng: props.lng,
    });
    useEffect(() => {
      setPosition({
        lat: props.lat,
        lng: props.lng,
      });
      props.updateLocation({ lat: props.lat, lng: props.lng });
    }, [props.lat, props.lng]);
    const onMarkerDragEnd = (coord) => {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      setPosition({ lat, lng });
      props.updateLocation({ lat, lng });
    };

    return (
      <GoogleMap
        zoom={props.defaultZoom ? props.defaultZoom : 15}
        MapTypeIds={["roadmap", "satellite", "hybrid", "terrain"]}
        defaultMapTypeId={props.defaultMapId ? props.defaultMapId : "roadmap"}
        center={{ lat: props.lat, lng: props.lng }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        <Marker
          draggable={props.draggable}
          icon={markerIcon}
          onDragEnd={(e) => onMarkerDragEnd(e)}
          position={position}
        />
      </GoogleMap>
    );
  })
);
