import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Card, Table, Tag } from "antd";
import Loading from "../../../shared/loading/Loading.js";
import { formatUsAddress, statusTagColor } from "../../../shared/utils/utils";
import queryString from "query-string";
import ShootTypeBadge from "../../../shared/Components/ShootTypeBadge.js";

const ClientShoots = ({ clientShoots, isLoading }) => {
  const [tableOpt, setOperation] = useState({
    pageSize: 10,
    skip: 1,
    pageSizeOptions: [10, 20, 50, 100],
  });
  const history = useHistory();
  const columns = [
    {
      title: "HS#",
      dataIndex: "hsn",
      key: "hsn",
      width: 150,
      render: (hsn, record) => (
        <>
          <span className="mr-2">
            {record?.booking_form?.shoot_type === "Residential" && (
              <ShootTypeBadge shootType="Residential" bgColor="#a155b9" />
            )}
            {record?.booking_form?.shoot_type === "Multi-family" && (
              <ShootTypeBadge shootType="Multi-Family" bgColor="#f765a3" />
            )}
            {record?.booking_form?.shoot_type === "Commercial" && (
              <ShootTypeBadge shootType="Commercial" bgColor="#ffa4b6" />
            )}
            {record?.booking_form?.shoot_type === "Additional" && (
              <ShootTypeBadge shootType="Additional" bgColor="#165baa" />
            )}
          </span>
          <Link to={`/admin/shoots/${record._id}`}>{hsn}</Link>
        </>
      ),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "hsn",
      width: 320,
      render: (address, record) => (
        <span>
          {formatUsAddress(
            record.address.full,
            record.address.street,
            record.address.apt,
            record.address.city,
            record.address.state,
            record.address.zip
          )}{" "}
        </span>
      ),
    },
    {
      title: "Photographer",
      dataIndex: "events.photographer",
      responsive: ["md", "lg", "xl"],
      render: (photographer, record) => (
        <span>
          {record.events?.photographer
            ? record.events?.photographer?.name
            : "---"}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Shoot Date",
      dataIndex: "start",
      width: 120,
      key: "start",
      render: (start, record) => (
        <span>{moment(record.events.start).format("MM/DD/YY")}</span>
      ),
      sorter: (a, b) => {
        const dateA = moment(a.events.start);
        const dateB = moment(b.events.start);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "hsn",
      render: (status, record) => (
        <Tag color={statusTagColor(status)}>{status}</Tag>
      ),
    },
  ];

  const setPageBasedOnParam = () => {
    let skip = 1;
    const value = queryString.parse(history.location.search);
    if (value.page) {
      skip = parseInt(value.page);
      setOperation({ ...tableOpt, skip });
    } else {
      setOperation({ ...tableOpt, skip });
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", setPageBasedOnParam);
    return () => {
      window.removeEventListener("popstate", setPageBasedOnParam);
    };
  }, []);
  useEffect(() => {
    setPageBasedOnParam();
  }, []);

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("tab", "Shoots");
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  return (
    <div>
      <Card>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clientShoots && (
          <Table
            dataSource={clientShoots}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              ...tableOpt,
              total: clientShoots?.length,
              current: tableOpt.skip,
              defaultCurrent: tableOpt.skip,
            }}
            rowKey={(record) => record._id}
            scroll={{ x: 500 }}
          />
        )}
      </Card>
    </div>
  );
};

export default ClientShoots;
