import {
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  FETCH_COUPON_BY_ID_SUCCESS,
  FETCH_COUPON_BY_ID_FAIL,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  UPDATE_COUPON_BY_CODE_FAIL,
  UPDATE_COUPON_BY_CODE_SUCCESS,
  REMOVE_COUPON_FAIL,
  REMOVE_COUPON_SUCCESS,
  FETCH_COUPONS_TABLEVIEW_SUCCESS,
  FETCH_COUPONS_TABLEVIEW_FAIL,
} from "../action/types";

const initialState = { coupons: [], selectedCoupon: {}, isLoading: true };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.payload,
        isLoading: false,
      };
    case FETCH_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        selectedCoupon: action.payload,
        isLoading: false,
      };
    case VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        validatedCoupon: action.payload,
        isLoading: false,
      };
    case FETCH_COUPONS_TABLEVIEW_SUCCESS:
      return {
        ...state,
        coupons: action.payload,
        totalCount: action.payload.count,
        isLoading: false,
      };
    case FETCH_COUPONS_FAIL ||
      FETCH_COUPON_BY_ID_FAIL ||
      FETCH_COUPONS_TABLEVIEW_FAIL ||
      VALIDATE_COUPON_FAIL ||
      CREATE_COUPON_FAIL ||
      UPDATE_COUPON_FAIL ||
      UPDATE_COUPON_BY_CODE_FAIL ||
      REMOVE_COUPON_FAIL:
      return { ...state, error: action.payload, isLoading: false };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: {
          coupons: state.coupons?.coupons
            ? [...state.coupons?.coupons, action.payload]
            : [],
          count: state.coupons.count + 1,
        },
      };
    case REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: {
          coupons: state.coupons?.coupons?.filter(
            (coup) => coup._id !== action.payload
          ),
          count: state.coupons.count - 1,
        },
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          coupons: state.coupons?.coupons?.map((coup) =>
            coup._id === action.payload._id ? action.payload : coup
          ),
        },
        clientCoupons: state.clientCoupons?.map((coup) =>
          coup._id === action.payload._id ? action.payload : coup
        ),
        selectedCoupon: action.payload,
      };
    case UPDATE_COUPON_BY_CODE_SUCCESS:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          coupons: state.coupons?.coupons?.map((coup) =>
            coup._id === action.payload._id ? action.payload : coup
          ),
        },
        clientCoupons: state.clientCoupons?.map((coup) =>
          coup._id === action.payload._id ? action.payload : coup
        ),
        selectedCoupon: action.payload,
      };

    default:
      return state;
  }
}
