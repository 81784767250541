/* eslint-disable eqeqeq, array-callback-return */

import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Modal, message, Spin } from "antd";
import Today from "./../TodayView/Today";
import "../orders.css";
import { updatePhotographerCalc } from "../../../../shared/payBreakdown/photographersPayCalc";
import { connect } from "react-redux";
import {
  fetchChangeRequests,
  fetchPendingShoots,
  fetchShoots,
  fetchShootsByDay,
  updateShoot,
  fetchPhotoGraphersUnAvailabilityForADay,
} from "../../../../redux/action/shootAction";
import { createActivity } from "../../../../redux/action/activityAction";
import {
  fetchPhotographerById,
  fetchPhotographers,
} from "../../../../redux/action/photographerAction";
import { fetchEntityActivities } from "../../../../redux/action/activityAction";
import { sortPhotographer } from "./../../../../shared/utils/utils.js";
import {
  SELECT_PHOTOGRAPHER,
  REMOVE_SHOOT_CALENDAR,
  CHANGE_CALENDAR_VIEW_DATE,
  SET_SELECTED_SHOOT,
} from "../../../../redux/action/types.js";
import { updateInvoice } from "../../../../redux/action/invoiceAction.js";
import { MyMapComponent } from "./Map.js";
import OrderDetails from "../Common/OrderDetails.js";
import orderData from "../../../../content/DummyData.json";
import { convertISODateString } from "../../../../shared/utils/utils";
import moment from "moment";
import Axios from "axios";

const OrderDetailsMapView = (props) => {
  const [visible, setVisible] = useState(false);
  const [shootOrders, setShootOrders] = useState(props.allOrders);
  const [requests, setRequests] = useState(props.changeRequests);
  const [loader, setLoader] = useState(true);
  const calendarComponentRef = useRef();

  const [state, setState] = useState({
    currDate: new Date(),
    calendarEvents: [],
    resources: [],
    shoots: [],
    selectedShoot: {},
    photographers: [],
  });
  useEffect(() => {
    const fetch = async () => {
      await props.fetchPendingShoots();

      await props.fetchShootsByDay({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        access: props.auth?.user?.access,
      });
      await props.fetchPhotoGraphersUnAvailabilityForADay(
        convertISODateString(moment()._d)
      );
      setLoader(false);
    };
    fetch();
    setLoader(true);
    props.fetchPhotographers();
    props.fetchChangeRequests();
  }, []);
  useEffect(() => {
    updateEvts(state.currDate);
  }, [props.shoots]);

  useEffect(() => {
    setShootOrders(props.allOrders);
    setRequests(props.changeRequests);
  }, [props.allOrders, props.changeRequests]);
  const showModal = ({ event }) => {
    state.shoots.forEach((shoot) => {
      if (shoot.details._id === event.id) {
        props.fetchEntityActivities(shoot.id);
        setState({
          ...state,
          selectedShoot: shoot,
        });
        props.setSelectedShoot(shoot?.details);
      }
    });
    setVisible(true);
  };
  const onUpdateShoot = async (id, data) => {
    const updatedShoot = await props.updateShoot(id, data);
    await props.fetchShootsByDay({
      from: moment(props.date.from).format("YYYY-MM-DD"),
      to: moment(props.date.to).format("YYYY-MM-DD"),
      access: props.auth?.user?.access,
    });
    return updatedShoot;
  };
  const changeDateView = async (from, to) => {
    setLoader(true);
    await props.changeDateView({ from, to });
    await props.fetchShootsByDay({
      from,
      to,
      access: props.auth?.user?.access,
    });
    await props.fetchPhotoGraphersUnAvailabilityForADay(from);
    setLoader(false);
  };
  const handleCancel = (e) => {
    setVisible(false);
  };

  const geoCoordinates = async (
    shootSwifted,
    info,
    invoices,
    sqft,
    tc,
    dt,
    selectedShoot
  ) => {
    var driveT;
    var geocode;
    const driveTime = await Axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/getDistance/${info.newResource.extendedProps.home_address}/${shootSwifted.address}/${shootSwifted.id}`
    ).then((res) => {
      geocode = res.data;
    }); // eslint-disable-next-line
    driveT = geocode.photographer_drive_time;
    updatePhotographerCalc(
      invoices,
      info.newResource.extendedProps.pay_multiplier,
      sqft,
      tc,
      selectedShoot.events.start,
      selectedShoot.events.end,
      driveT,
      props
    );
  };

  const updateEvts = (date) => {
    const currEvents = [];
    const currPhts = [];
    var avlbPhtgs = [];
    const currDate = convertISODateString(date);
    const currShoots = props.shoots?.filter((shoot) => {
      const { start } = shoot.details.events;
      if (start && start.split("T")[0] === currDate.split("T")[0]) {
        return shoot.details;
      }
    });
    props.photographers.forEach((pht) => {
      if (pht.active) {
        pht.title = pht.name;
        pht.id = pht.pg_id;
        pht.eventBackgroundColor = pht.bgColor;
        pht.eventBorderColor = pht.borderColor;
        pht.opacity = 0.2;
        pht.showInfoWindow = false;
        avlbPhtgs.push(pht);
      }
    });
    currShoots.map((shoot) => {
      const { events } = shoot.details;
      if (events.photographer && events.photographer != 0) {
        for (let j = 0; j < props.photographers.length; j++) {
          if (events.photographer._id === props.photographers[j].pg_id) {
            const pht = { ...props.photographers[j] };
            pht.title = pht.name;
            pht.id = pht.pg_id;
            pht.eventBackgroundColor = pht.bgColor;
            pht.eventBorderColor = pht.borderColor;
            pht.opacity = 1;
            pht.showInfoWindow = false;
            currPhts.push(pht);
            avlbPhtgs.push(pht);
            currEvents.push({
              id: shoot.details._id,
              resourceId: events.photographer._id,
              title: events.title,
              start:
                events.start
                  ?.replace(events.start.split(".")[1], "")
                  .replace(".", "") + "Z",
              end:
                events.end
                  ?.replace(events.end.split(".")[1], "")
                  .replace(".", "") + "Z",
            });
            break;
          }
        }
      }
    });
    setState({
      ...state,
      shoots: currShoots,
      photographers: avlbPhtgs,
      currDate: date,
      calendarEvents: currEvents,
      resources: currPhts,
    });
  };
  const onShootCancel = (shootId) => {
    setVisible(false);
    props.removeShootfromCalender(shootId);
  };
  const toggleInfoWindow = ({ type, arr, ind, status }) => {
    if (type === "shoot") {
      const newShoots = [...arr];
      if (status === true) {
        showModal({ event: newShoots[ind] });
      }
      newShoots[ind].showInfoWindow = status;
      setState({ ...state, shoots: newShoots });
    } else {
      const newPhtgs = state.photographers.concat();
      newPhtgs[ind].showInfoWindow = status;
      setState({ ...state, photographers: newPhtgs });
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={14} className={"map-view"}>
          <Card>
            <MyMapComponent
              isMarkerShown
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `800px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              shoots={state.shoots}
              photographers={state.photographers}
              toggleInfoWindow={toggleInfoWindow}
            />
          </Card>
        </Col>
        <Col span={10} className={"calendar"}>
          <Card className="todayTab">
            <Spin spinning={props.loading}>
              <Today
                removeShootfromCalender={props.removeShootfromCalender}
                shoots={shootOrders}
                photographerUnavailabilityEvents={
                  props.phgrapherUnAvailabilityEvnts
                }
                fetchPendingShoots={fetchPendingShoots}
                fetchShootsByDay={fetchShootsByDay}
                fetchPhotoGraphersUnAvailabilityForADay={
                  props.fetchPhotoGraphersUnAvailabilityForADay
                }
                currentPhotographer={props.currentPhotographer}
                fetchPhotographerById={props.fetchPhotographerById}
                fetchEntityActivities={props.fetchEntityActivities}
                updateInvoice={props.updateInvoice}
                photographers={props.photographers}
                onUpdateShoot={onUpdateShoot}
                activities={props.activity.activities}
                createActivity={(shoot, text) =>
                  props.createActivity(shoot, text)
                }
                getSelectedPhotographer={props.getSelectedPhotographer}
                setSelectedPhotographer={(phtg) =>
                  props.setSelectedPhotographer(phtg)
                }
                setSelectedShoot={props.setSelectedShoot}
                selectedShoot={props.selectedShoot}
                changeDateView={changeDateView}
                loading={loader}
                pendingShoots={props.pendingShoots || []}
                access={props.auth?.user?.access}
              />
            </Spin>
          </Card>
        </Col>
      </Row>
      {/*Order Details Popup*/}
      <Modal
        title="Order Details"
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width={"80%"}
        style={{ top: 20 }}
        destroyOnClose={true}
      >
        <Card type="inner" size="small" style={{ marginTop: 5 }}>
          <OrderDetails
            onShootCancel={onShootCancel}
            order={orderData}
            isMapView={true}
            selectedSchedule={state.selectedShoot}
            photographers={props.photographers}
            onUpdateShoot={props.onUpdateShoot}
            activities={props.activities}
            selectedShoot={props.selectedShoot}
            fetchEntityActivities={props.fetchEntityActivities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
          />
        </Card>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  let pg = [];
  let togglePg = [];
  pg.push({
    id: "0",
    pg_id: null,
    name: "New Order",
    bgColor: "#C0CA33",
    borderColor: "#C0CA33",
    dragBgColor: "#C0CA33",
  });
  togglePg.push({ id: "0", checked: true });
  sortPhotographer(state.photographer.photographers).forEach(
    (photographer, idx) => {
      pg.push({
        id: (idx + 1).toString(),
        pg_id: photographer._id,
        name: photographer.name,
        geometry: photographer.geometry,
        home_address: photographer.home_address,
        pay_multiplier: photographer.pay_multiplier,
        photog_id: photographer.photog_id,
        sp_cost_per_hour: photographer.sp_cost_per_hour,
        base_pay: photographer.base_pay,
        active: photographer.active,
        bgColor: photographer.color,
        borderColor: photographer.color,
        dragBgColor: photographer.color,
      });
      togglePg.push({ id: photographer._id, checked: true });
    }
  );
  return {
    shoots: state.shoots.dashboard.displayShoots,
    phgrapherUnAvailabilityEvnts: state.shoots.photographerUnAvailabilityEvents,
    selectedShoot: state.shoots.selectedShoot,
    isLoading: state.shoots.isLoading,
    allOrders: state.shoots.dashboard.displayShoots,
    changeRequests: state.shoots.changeRequests,
    activity: state.activity,
    photographers: pg,
    getSelectedPhotographer: state.photographer.selectedPhotographers,
    togglePhotographers: togglePg,
    auth: state.auth,
    view: state.shoots.dashboard.view,
    pendingShoots: state.shoots.dashboard.pendingShoots,
    date: state.shoots.dashboard.currentDate,
    currentPhotographer: state.photographer.currentPhotographer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedShoot: (selectedShoot) =>
      dispatch({ type: SET_SELECTED_SHOOT, payload: selectedShoot }),
    fetchEntityActivities: (id) => dispatch(fetchEntityActivities(id)),
    fetchPendingShoots: () => dispatch(fetchPendingShoots()),
    fetchShootsByDay: (params) => dispatch(fetchShootsByDay(params)),
    fetchShoots: (params) => dispatch(fetchShoots(params)),
    updateShoot: (id, data) => dispatch(updateShoot(id, data)),
    updateInvoice: (id, data) => dispatch(updateInvoice(id, data)),
    fetchPhotographers: () => dispatch(fetchPhotographers()),
    fetchPhotographerById: (id) => dispatch(fetchPhotographerById(id)),
    fetchChangeRequests: () => dispatch(fetchChangeRequests()),
    createActivity: (shoot, text) => dispatch(createActivity(shoot, text)),
    createRequestActivity: (activityData, text) =>
      dispatch(createActivity(activityData, text)),
    setSelectedPhotographer: (phtg) =>
      dispatch({ type: SELECT_PHOTOGRAPHER, payload: phtg }),
    removeShootfromCalender: (shootId) =>
      dispatch({ type: REMOVE_SHOOT_CALENDAR, payload: { id: shootId } }),
    changeDateView: (date) =>
      dispatch({ type: CHANGE_CALENDAR_VIEW_DATE, payload: date }),
    fetchPhotoGraphersUnAvailabilityForADay: (date) =>
      dispatch(fetchPhotoGraphersUnAvailabilityForADay(date)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailsMapView);
