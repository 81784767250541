import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#dddddd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#dddddd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const InvoiceTableFooter = ({ item }) => {
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Subtotal</Text>
        <Text style={styles.total}>
          {Number.parseFloat(item?.subtotal).toFixed(2)}
        </Text>
      </View>
      {item?.discount && (
        <View style={styles.row}>
          <Text style={styles.description}>Discount</Text>
          <Text style={styles.total}>
            -{Number.parseFloat(item?.discount).toFixed(2)}
          </Text>
        </View>
      )}
      <View style={styles.row}>
        <Text style={styles.description}>Tax</Text>
        <Text style={styles.total}>
          {Number.parseFloat(item?.tax).toFixed(2)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TOTAL</Text>
        <Text style={styles.total}>
          {Number.parseFloat(item?.total).toFixed(2)}
        </Text>
      </View>
      {item?.credit_used && (
        <View style={styles.row}>
          <Text style={styles.description}>Credit Used</Text>
          <Text style={styles.total}>
            -{Number.parseFloat(item?.credit_used).toFixed(2)}
          </Text>
        </View>
      )}
      <View style={styles.row}>
        <Text style={styles.description}>Balance Due</Text>
        <Text style={styles.total}>
          {Number.parseFloat(item?.balance).toFixed(2)}
        </Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
