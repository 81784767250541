import React, { useState } from "react";
import axios from "axios";
import { Input, Button, message, Col, Row } from "antd";
import { calculateTax } from "../../shared/utils/utils";

const ApplyCoupon = ({ invoice, updateInvoice }) => {
  const [couponCode, setCouponCode] = useState("");

  const handleSubmit = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/coupons/validate/${couponCode}?client=${invoice.client._id}&platform=web`
      );

      if (data.valid) {
        // Update invoice
        const updatedDiscount = data?.coupon?.amount;
        // const updatedSubtotal =
        //   parseFloat(invoice?.subtotal) - parseFloat(data?.amount);
        // const updatedTax = calculateTax(updatedSubtotal);

        const amountAlreadyPaid = invoice?.total - invoice?.balance;

        const updatedTotal = invoice?.subtotal - updatedDiscount;
        const updatedTax = calculateTax(updatedTotal);

        // parseFloat(updatedSubtotal) + parseFloat(updatedTax);
        const newInvoice = {
          ...invoice,
          discount: updatedDiscount,
          tax: updatedTax,
          total: updatedTotal + updatedTax,
          balance: updatedTotal + updatedTax - amountAlreadyPaid,
        };
        console.log(newInvoice);
        // TODO: Update invoice
        await updateInvoice(invoice._id, newInvoice);
        // debugger;
        message.success("Discount applied successfully!");
        setCouponCode("");

        // Update coupon
        const updatedCoupon = {
          redemptions: [
            ...data.coupon.redemptions,
            {
              hsn: invoice.hsn,
              client_id: invoice.client._id,
              client_name: `${invoice.client.first} ${invoice.client.last}`,
              client_email: invoice.client.email,
              redemption_date: new Date(),
              redemption_at: "Invoice",
            },
          ],
        };

        await axios.patch(
          `${process.env.REACT_APP_ROOT_URL}/coupons/${data?.coupon?._id}`,
          updatedCoupon
        );

        // setInvoice(newInvoice);
      } else {
        message.error(data.message);
      }
    } catch (err) {
      console.log(err);
      message.error(
        "Oops! Something went wrong! Please check the code and try again."
      );
    }
  };

  return (
    <Row>
      <Col flex="120px" className="mr-2">
        <Input
          size="small"
          placeholder="Coupon Code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </Col>
      <Col flex="auto">
        <Button
          type="primary"
          size="small"
          onClick={handleSubmit}
          disabled={couponCode === ""}
        >
          Apply
        </Button>
      </Col>
    </Row>
  );
};

export default ApplyCoupon;
