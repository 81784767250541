import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { List, Avatar, Button, Skeleton, Tag } from "antd";
import { HomeOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { updateMedia } from "../../../../redux/action/mediaAction";
import {
  fetchShoots,
  fetchShootById,
} from "../../../../redux/action/shootAction";
import {
  eventDateFormat,
  getShootColor,
  formatUsAddress,
} from "../../../../shared/utils/utils";
import "./style.scss";

const PhotogList = ({
  photographer,
  search,
  date,
  fetchShoots,
  fetchShootById,
  shoots,
  loading,
  pagination: { current, pageSize, total },
}) => {
  const history = useHistory();
  const hasPhotos = (shoot) => {
    return shoot?.media?.photos?.gallery.length === 0 ? false : true;
  };

  const [currentPage, setCurrentPage] = useState(current);

  return (
    <div>
      <List
        className="demo-loadmore-list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={shoots}
        pagination={{
          onChange: (page) => {
            setCurrentPage(page);
            fetchShoots({
              photographer,
              date,
              filter: "",
              search,
              sortField: "events.start",
              sortOrder: "descend",
              skip: page,
              limit: pageSize,
            });
          },
          pageSize: pageSize,
          total: total,
          current: currentPage,
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                className="ml-2 mt-2"
                onClick={async () => {
                  await fetchShootById(item._id);
                  history.push(`/admin/shoots/${item?._id}?tab=tour`);
                }}
              >
                View/Edit Photos
              </Button>,
              item.status === "Completed" && (
                <Button
                  className="ml-2 mt-2"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_DOMAIN_URL}/media/u/${item.hsn}/${item?.address.street_lower_no_spaces}`,
                      "_blank"
                    );
                  }}
                >
                  See Media Online
                </Button>
              ),
            ]}
          >
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
                avatar={
                  hasPhotos(item) ? (
                    <Avatar
                      shape="square"
                      size={64}
                      src={item?.media?.photos.gallery[0]}
                    />
                  ) : (
                    <Avatar shape="square" size={64} icon={<HomeOutlined />} />
                  )
                }
                title={
                  <Link
                    to={`/admin/shoots/${item._id}`}
                    style={{ fontSize: "1rem", color: "#096dd9" }}
                  >
                    {`HS# ${item?.hsn} - ${formatUsAddress(
                      item?.address?.full,
                      item?.address?.street,
                      item?.address?.apt,
                      item?.address?.city,
                      item?.address?.state,
                      item?.address?.zip
                    )} - ${item?.client_name_first} ${item?.client_name_last}`}
                  </Link>
                }
                description={
                  <>
                    <span className="mr-2">
                      <ClockCircleOutlined />{" "}
                      {eventDateFormat(item.events.start, item.events.end)}
                    </span>
                    <Tag color={getShootColor(item?.status)}>
                      {item?.status}
                    </Tag>
                    {item?.reqs
                      ?.toString()
                      .replace(/RSC/g, "")
                      .replace(/FREE,/g, "")
                      .replace(/,/g, ", ")}
                  </>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default connect(null, { updateMedia, fetchShoots, fetchShootById })(
  PhotogList
);
