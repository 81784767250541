import React from "react";
import { Provider } from "react-redux";

import store from "../redux/store";

import "../App.scss";

import TourRoutes from "../routes/TourRoutes";
import { BrowserRouter as Router } from "react-router-dom";

const TourLayout = () => {
  return (
    <Provider store={store}>
      <Router>
        <TourRoutes />
      </Router>
    </Provider>
  );
};

export default TourLayout;
