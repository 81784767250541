import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateTeamMembers } from "../../redux/action/teamAction";
import { Card, Col, Row, Result, Button } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
const Invitation = ({ match, updateTeamMembers }) => {
  const [status, setStatus] = useState();
  useEffect(() => {
    const getStatus = async () => {
      const update = await updateTeamMembers(
        match.params.team,
        match.params.member
      );
      setStatus(update?.data);
    };
    getStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  let style = {};
  return (
    <>
      <div className="invitation-container">
        <Row gutter={16} style={style} justify="center" align="middle">
          <Col>
            <Card className="login-card">
              <Row>
                <Col span={24}>
                  {status === "Already a Member" ? (
                    <Result
                      icon={<UsergroupAddOutlined />}
                      title="Oops! you are already a member of this team."
                    />
                  ) : (
                    <Result
                      icon={<UsergroupAddOutlined />}
                      title="Welcome to the team!"
                    />
                  )}
                  <h4 className="text-center">What's next?</h4>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <a href="/lets-book-a-shoot">
                        <Button type="primary" size="large" block>
                          Let's Book a Shoot
                        </Button>
                      </a>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <a href="/admin/teams">
                        <Button size="large" block>
                          Go to Team Page
                        </Button>
                      </a>
                    </Col>
                    {/* <Col span={24}>
                      <Button type="link" size="large" block>
                        LEARN MORE ABOUT TEAMS
                      </Button>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect(null, { updateTeamMembers })(Invitation);
