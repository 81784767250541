import {
  FETCH_ALL_DOLLHOUSES,
  UPDATE_DOLLHOUSE,
  CREATE_DOLLHOUSE,
  REMOVE_DOLLHOUSE,
  FETCH_DOLLHOUSE_BY_SHOOT_ID,
} from "../action/types";

const initialState = { allDollhouses: [], currentDollhouse: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_DOLLHOUSES:
      return {
        ...state,
        allDollhouses: action.payload,
      };

    case FETCH_DOLLHOUSE_BY_SHOOT_ID:
      return {
        ...state,
          currentDollhouse: action.payload,
      };

    case CREATE_DOLLHOUSE:
      return {
        ...state,
        currentDollhouse: action.payload,
      };
    case REMOVE_DOLLHOUSE:
      return {
        ...state,
        currentDollhouse: action.payload,
      };
    case UPDATE_DOLLHOUSE:
      return Object.assign(
        {},
        {
          ...state,
          currentDollhouse: action.payload,
        }
      );
    default:
      return state;
  }
}
