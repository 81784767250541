import {
  FETCH_SUBDIVISION,
  FETCH_SUBDIVISIONS_TABLEVIEW,
  CREATE_SUBDIVISION,
  FETCH_SUBDIVISION_BY_ID,
  UPDATE_SUBDIVISION,
  REMOVE_SUBDIVISION,
} from "../action/types";

const initialState = { divisions: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUBDIVISION:
      return {
        ...state,
        isLoading: false,
        divisions: payload,
      };
    case CREATE_SUBDIVISION:
      return {
        ...state,
        isLoading: false,
        selectedSubdivision: payload,
        divisions: [...state.divisions, payload],
      };
    case REMOVE_SUBDIVISION:
      return {
        ...state,
        divisions: state.divisions.filter(
          (division) => division._id !== payload
        ),
      };
    case UPDATE_SUBDIVISION:
      return {
        ...state,
        divisions: state.divisions.map((division) =>
          division._id === payload._id ? payload : division
        ),
      };
    case FETCH_SUBDIVISION_BY_ID:
      return {
        ...state,
        isLoading: false,
        selectedSubdivision: payload,
        divisions: [payload, ...state.divisions],
      };
    case FETCH_SUBDIVISIONS_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        subdivisions: payload.newSubdivisions,
        totalCount: payload.count,
      };
    default:
      return state;
  }
}
