import React from "react";
import { Switch, Select, Button, Modal } from "antd";
import { Input } from "formik-antd";
import { Formik } from "formik";

import "./OfferingsForm.css";

const OfferingsEdit = ({ offeringData, updateOffering, onOk }) => {
  const { Option, OptGroup } = Select;
  const modal = Modal;

  return (
    <Formik
      enableReinitialize
      initialValues={offeringData}
      onSubmit={async (values) => {
        await updateOffering(offeringData._id, values);

        onOk();
        modal.destroyAll();
      }}
      render={({ handleSubmit, setFieldValue }) => (
        <div>
          <div className="form-group">
            <label>Description</label>
            <Input name="description" />
          </div>
          <div className="form-group">
            <label>Code</label>
            <Input name="code" />
          </div>
          <div className="form-group">
            <label>Category </label>
            <br />
            <Select
              name="category"
              defaultValue={offeringData.category}
              style={{ width: 300 }}
              onChange={(value) => setFieldValue("category", value)}
            >
              <OptGroup label="Residential">
                <Option value="Residential - Drone Photos and HD Video">
                  Drone Photos and HD Video
                </Option>
                <Option value="Residential - Drone Photos">Drone Photos</Option>
                <Option value="Residential - Fees">Fees</Option>
                <Option value="Residential - Interior/Exterior Photos">
                  Interior/Exterior Photos
                </Option>
                <Option value="Residential - Photo Turnaround">
                  Photo Turnaround
                </Option>
                <Option value="Residential - Premier Offerings">
                  Premier Offerings
                </Option>
                <Option value="Residential - Video Turnaround">
                  Video Turnaround
                </Option>
                <Option value="Residential - Virtual Reality Tour">
                  Virtual Reality Tour
                </Option>
                <Option value="Residential - Specified Package">
                  Specified Package
                </Option>
                <Option value="Residential - Discretionary">
                  Discretionary
                </Option>
              </OptGroup>
              <OptGroup label="Multi-family">
                <Option value="Multifamily - Drone Photos and HD Video">
                  Drone Photos and HD Video
                </Option>
                <Option value="Multifamily - Drone Photos">Drone Photos</Option>
                <Option value="Multifamily - Fees">Fees</Option>
                <Option value="Multifamily - Interior/Exterior Photos">
                  Interior/Exterior Photos
                </Option>
                <Option value="Multifamily - Photo Turnaround">
                  Photo Turnaround
                </Option>
                <Option value="Multifamily - Premier Offerings">
                  Premier Offerings
                </Option>
                <Option value="Multifamily - Video Turnaround">
                  Video Turnaround
                </Option>
                <Option value="Multifamily - Virtual Reality Tour">
                  Virtual Reality Tour
                </Option>
                <Option value="Multifamily - Specified Package">
                  Specified Package
                </Option>
                <Option value="Multifamily - Discretionary">
                  Discretionary
                </Option>
              </OptGroup>
              <OptGroup label="Commercial">
                <Option value="Commercial - Drone Photos and HD Video">
                  Drone Photos and HD Video
                </Option>
                <Option value="Commercial - Drone Photos">Drone Photos</Option>
                <Option value="Commercial - Fees">Fees</Option>
                <Option value="Commercial - Interior/Exterior Photos">
                  Interior/Exterior Photos
                </Option>
                <Option value="Commercial - Photo Turnaround">
                  Photo Turnaround
                </Option>
                <Option value="Commercial - Premier Offerings">
                  Premier Offerings
                </Option>
                <Option value="Commercial - Video Turnaround">
                  Video Turnaround
                </Option>
                <Option value="Commercial - Virtual Reality Tour">
                  Virtual Reality Tour
                </Option>
                <Option value="Commercial - Specified Package">
                  Specified Package
                </Option>
                <Option value="Commercial - Discretionary">
                  Discretionary
                </Option>
              </OptGroup>
              <OptGroup label="Additional">
                <Option value="Additional - Drone Photos and HD Video">
                  Drone Photos and HD Video
                </Option>
                <Option value="Additional - Drone Photos">Drone Photos</Option>
                <Option value="Additional - Fees">Fees</Option>
                <Option value="Additional - Interior/Exterior Photos">
                  Interior/Exterior Photos
                </Option>
                <Option value="Additional - Photo Turnaround">
                  Photo Turnaround
                </Option>
                <Option value="Additional - Premier Offerings">
                  Premier Offerings
                </Option>
                <Option value="Additional - Video Turnaround">
                  Video Turnaround
                </Option>
                <Option value="Additional - Virtual Reality Tour">
                  Virtual Reality Tour
                </Option>
                <Option value="Additional - Specified Package">
                  Specified Package
                </Option>
                <Option value="Additional - Discretionary">
                  Discretionary
                </Option>
              </OptGroup>
            </Select>
          </div>
          <div className="form-group">
            <label>Price</label>
            <Input type="number" prefix="$" name="price" />
          </div>
          <div className="form-group">
            Discount{" "}
            <Switch
              defaultChecked={offeringData.discount_enabled}
              name="discount_enabled"
              onChange={(checked) => setFieldValue("discount_enabled", checked)}
            />
          </div>
          <div className="form-group">
            <label>Discount Price</label>
            <Input type="number" prefix="$" name="discount_price" />
          </div>
          <div className="form-group">
            <label>Base Pay</label>
            <Input type="number" prefix="$" name="pay" />
          </div>
          <div className="form-group">
            <label>Duration (optional)</label>
            <Input type="number" name="duration" />
          </div>
          <div className="form-group">
            <label>Example Image URL (optional)</label>
            <Input name="example_image" />
          </div>
          <div className="form-group">
            <label>Example Offering URL (optional)</label>
            <Input name="example_url" />
          </div>
          <div className="form-group">
            Active{" "}
            <Switch
              defaultChecked={offeringData.active}
              name="active"
              onChange={(checked) => setFieldValue("active", checked)}
            />
          </div>
          <div className="form-group">
            Visible on Booking Form{" "}
            <Switch
              defaultChecked={offeringData.bf_visible}
              name="bf_visible"
              onChange={(checked) => setFieldValue("bf_visible", checked)}
            />
          </div>
          <div className="form-group">
            Visible on Invoice{" "}
            <Switch
              defaultChecked={offeringData.inv_visible}
              name="inv_visible"
              onChange={(checked) => setFieldValue("inv_visible", checked)}
            />
          </div>
          <div className="form-group">
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginTop: "20px" }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default OfferingsEdit;
