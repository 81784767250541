import axios from "axios";
import {
  FETCH_CLIENTS,
  FETCH_CLIENTS_TABLEVIEW,
  FETCH_CLIENT,
  FETCH_CLIENT_SHOOTS,
  FETCH_CLIENT_BY_USER_ID,
  UPDATE_CLIENT,
  CREATE_CLIENT,
  CREATE_USER_CLIENT,
  FETCH_CLIENT_BY_NAME,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAIL,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAIL,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  REMOVE_PAYMENT_METHOD_FAIL,
  FETCH_CLIENT_STATS_SUCCESS,
  FETCH_CLIENT_STATS_FAIL,
} from "./types";
import setAuthToken from "../../utils/setAuthToken";

let config;
if (localStorage.hstoken) {
  setAuthToken(localStorage.hstoken);
  config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.hstoken,
    },
  };
}

export const fetchClients = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/clients`);
    dispatch({
      type: FETCH_CLIENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchClientsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );

    dispatch({
      type: FETCH_CLIENTS_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchClient = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/id/${id}`
    );

    dispatch({
      type: FETCH_CLIENT,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchClientByUserId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/user/${id}`
    );

    dispatch({
      type: FETCH_CLIENT_BY_USER_ID,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchClientByName = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/clients/name`,
      body
    );
    dispatch({
      type: FETCH_CLIENT_BY_NAME,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log("err", err);
    return {
      data: {},
      error: err.response,
    };
  }
};
export const fetchClientShoots = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/client/${id}`
    );
    dispatch({
      type: FETCH_CLIENT_SHOOTS,
      payload: res.data.shoots,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchClientStats = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/stats/${id}`
    );
    dispatch({
      type: FETCH_CLIENT_STATS_SUCCESS,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCH_CLIENT_STATS_FAIL,
      payload: err.message,
    });
    return {
      data: null,
      error: err.message,
    };
  }
};

export const createUserClient = (formData) => async (dispatch) => {
  try {
    const fullName = `${formData.first} ${formData.last}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/users/client`,
      { ...formData, name: fullName, access: ["CLIENT"] },
      config
    );
    dispatch({
      type: CREATE_USER_CLIENT,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err.response);
    return err.response;
  }
};

export const createClient = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/clients`,
      formData,
      config
    );
    dispatch({
      type: CREATE_CLIENT,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err.response);
    return err.response;
  }
};

export const updateClient = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/clients/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_CLIENT,
      payload: response.data,
    });

    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    return err.response;
  }
};

export const notifyClientEdit = (shoot, text) => async () => {
  try {
    let txt = text;
    const body = {
      user: JSON.parse(localStorage.getItem("authUser"))._id,
      text: txt,
      shootId: shoot._id,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/clients/notify`,
      body
    );
  } catch (err) {
    console.log(err);
  }
};

export const fetchPaymentMethods = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/payments/payment-methods/${id}`,
      config
    );
    dispatch({
      type: FETCH_PAYMENT_METHODS_SUCCESS,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCH_PAYMENT_METHODS_FAIL,
      payload: err.message,
    });
    return {
      data: null,
      error: err.message,
    };
  }
};

export const savePaymentMethod = (paymentInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/payments/save-card`,
      paymentInfo,
      config
    );
    if (res.data.success) {
      dispatch({
        type: SAVE_PAYMENT_METHOD_SUCCESS,
        payload: res.data,
      });
      return {
        data: res.data,
        error: null,
      };
    } else {
      dispatch({
        type: SAVE_PAYMENT_METHOD_FAIL,
        payload: res.data,
      });
      return {
        data: res.data,
        error: res.data.message,
      };
    }
  } catch (err) {
    console.log("err", err);
    dispatch({
      type: SAVE_PAYMENT_METHOD_FAIL,
      payload: err.message,
    });
    return {
      data: null,
      error: err.message,
    };
  }
};

export const removePaymentMethod = (cardInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/payments/remove-card`,
      cardInfo,
      config
    );
    dispatch({
      type: REMOVE_PAYMENT_METHOD_SUCCESS,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    dispatch({
      type: REMOVE_PAYMENT_METHOD_FAIL,
      payload: err.message,
    });
    return {
      data: null,
      error: err.message,
    };
  }
};
