import React, { useState } from "react";
import { Spin, Steps, Button, message } from "antd";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";
import { diff } from "deep-object-diff";

import Property from "./Stepper/Property";
import Brokerage from "./Stepper/Brokerage";
import Agent from "./Stepper/Agent";

import "./Flyer.css";
import Photos from "./Stepper/Photos";

import { upload } from "../../../../shared/s3Bucket/s3Service";

const FlyerInfo = ({ flyer, media, update, client }) => {
  const { Step } = Steps;
  const [state, setState] = useState({
    photos: [],
    agentPic: null,
    brokerageLogo: null,
    features: [],
    loader: false,
    current: 0,
    disableNext: false,
  });
  const { loader, current, disableNext } = state;

  const steps = [
    {
      title: "Property",
    },
    {
      title: "Agent",
    },
    {
      title: "Brokerage",
    },
    {
      title: "Photos",
    },
  ];

  const next = (val) => {
    setState({ ...state, current: current + 1 });
    const chagedVal = diff(flyer, val);
    if (Object.keys(chagedVal).length) {
      updateForm(val);
    }
  };

  const prev = () => {
    setState({ ...state, current: current - 1 });
  };

  const updateValues = (updatedState) => {
    setState({ ...state, updatedState });
  };

  function validateRequired(value) {
    const error = {};
    if (!value.headline) {
      error.headline = "Headline is required";
    }
    if (!value.property.street) {
      error.property = { street: "Street name is required" };
    }
    if (!value.property.description) {
      error.property = {
        ...error.property,
        description: "Description is required",
      };
    }
    if (!value.property.price) {
      error.property = { ...error.property, price: "Price is required" };
    }
    if (!value.property.sqft) {
      error.property = { ...error.property, sqft: "Sqft is required" };
    }
    if (!value.property.beds) {
      error.property = { ...error.property, beds: "Beds is required" };
    }
    if (!value.property.baths) {
      error.property = { ...error.property, baths: "Baths is required" };
    }
    if (value.property.price <= 0) {
      error.property = { ...error.property, price: "Enter valid price" };
    }
    if (value.property.sqft <= 0) {
      error.property = { ...error.property, sqft: "Enter valid sqft" };
    }
    if (value.property.beds <= 0) {
      error.property = { ...error.property, beds: "Enter valid beds" };
    }
    if (value.property.baths <= 0) {
      error.property = { ...error.property, baths: "Enter valid baths" };
    }
    if (!value.agent.name) {
      error.agent = { name: "Name is required" };
    }
    if (!value.agent.phone) {
      error.agent = { ...error.agent, phone: "Phone is required" };
    }
    if (
      value.agent.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.agent.email)
    ) {
      error.agent = { ...error.agent, email: "Email is invalid" };
    }
    if (!value.brokerage.name) {
      error.brokerage = { ...error.brokerage, name: "Name is required" };
    }
    if (!value.brokerage.address) {
      error.brokerage = { ...error.brokerage, address: "Address is required" };
    }
    if (!value.brokerage.city) {
      error.brokerage = { ...error.brokerage, city: "City is required" };
    }
    if (!value.brokerage.state) {
      error.brokerage = { ...error.brokerage, state: "State is required" };
    }
    if (!value.brokerage.zip) {
      error.brokerage = { ...error.brokerage, zip: "Zip is required" };
    }
    if (error[steps[current].title.toLowerCase()] && !disableNext) {
      setState({ ...state, disableNext: true });
    } else if (!error[steps[current].title.toLowerCase()] && disableNext) {
      setState({ ...state, disableNext: false });
    }
    return error;
  }

  const updateForm = async (values) => {
    const updatedFlyer = {
      ...values,
    };

    if (typeof updatedFlyer.agent.headshot_url === "object") {
      const imagesUrl = await upload(updatedFlyer.agent.headshot_url);
      updatedFlyer.agent.headshot_url = imagesUrl[0];
    }
    if (typeof updatedFlyer.brokerage.logo_url === "object") {
      const imagesUrl = await upload(updatedFlyer.brokerage.logo_url);
      updatedFlyer.brokerage.logo_url = imagesUrl[0];
    }
    const res = await update(values._id, updatedFlyer);
    if (!res.error && current === 3) {
      setState({ ...state, loader: false });
      message.success("Flyers updated successfully!!");
    }
  };
  return (
    <div className="form-container" style={{ marginRight: "20px" }}>
      <h2>Flyer Info</h2>
      <Spin spinning={loader}>
        <Formik
          enableReinitialize
          initialValues={flyer}
          validate={validateRequired}
          isValidating={false}
          onSubmit={async (values) => {
            setState({ ...state, loader: true });
            updateForm(values);
          }}
          render={({ handleSubmit, setFieldValue, values, errors }) => (
            <div className="form-container">
              <Form layout="vertical">
                <Form.Item label="Headline" name="headline">
                  <Input name="headline" />
                </Form.Item>

                <div style={{ marginTop: "10px" }}>
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  <div className="steps-content">
                    {steps[current].title === "Property" && flyer ? (
                      <Property
                        flyer={flyer}
                        updateValues={updateValues}
                        setFieldValue={setFieldValue}
                        formVal={values}
                      />
                    ) : null}
                    {steps[current].title === "Agent" && flyer ? (
                      <Agent
                        flyer={flyer}
                        setFieldValue={setFieldValue}
                        client={client}
                      />
                    ) : null}
                    {steps[current].title === "Brokerage" && flyer ? (
                      <Brokerage
                        flyer={flyer}
                        setFieldValue={setFieldValue}
                        client={client}
                      />
                    ) : null}
                    {steps[current].title === "Photos" && flyer ? (
                      <Photos
                        flyer={flyer}
                        gallery={media.photos.gallery}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}
                  </div>
                  <div className="steps-action">
                    {current > 0 && (
                      <Button
                        style={{ margin: "0 8px" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button
                        disabled={disableNext}
                        type="primary"
                        onClick={() => next(values, errors)}
                      >
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button type="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )}
        />
      </Spin>
    </div>
  );
};

export default FlyerInfo;
