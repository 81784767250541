import {
  FETCH_PHOTOGRAPHERS,
  UPDATE_PHOTOGRAPHER,
  CREATE_PHOTOGRAPHER,
  REMOVE_PHOTOGRAPHER,
  SELECT_PHOTOGRAPHER,
  FETCH_PHOTOG_DIST_SUCCESS,
  FETCH_PHOTOGRAPHER_BY_ID,
  FETCH_PHOTOGRAPHER_BY_USER_ID,
  FETCH_PHOTOG_SHOOTS_SUCCESS,
  FETCH_PHOTOG_SHOOTS_FAIL,
  FETCH_PHOTOGRAPHER_UNAVAILABILITY,
  FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED,
  FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY,
  UPDATE_PHOTOGRAPHER_FOR_WEEKTOTAL,
  FETCH_UPSELL_LEADERBOARD,
} from "../action/types";

const initialState = {
  photographers: [],
  isLoading: true,
  selectedPhotographers: {},
  currentPhotographer: {},
  photographerUnavailableSlotsForDay: {},
  allPhotographerUnavailSlotsForDay: [],
  TotalDistance: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PHOTOGRAPHERS:
      return {
        ...state,
        isLoading: false,
        photographers: action.payload,
      };
    case FETCH_PHOTOGRAPHER_BY_ID:
      return {
        ...state,
        isLoading: false,
        currentPhotographer: action.payload,
      };
    case FETCH_PHOTOGRAPHER_BY_USER_ID:
      return {
        ...state,
        isLoading: false,
        currentPhotographer: action.payload,
      };
    case FETCH_PHOTOG_SHOOTS_SUCCESS:
      return {
        ...state,
        photographerShoots: action.payload,
        isLoading: false,
      };
    case FETCH_PHOTOG_DIST_SUCCESS:
      return {
        ...state,
        TotalDistance: action.payload,
        isLoading: false,
      };
    case FETCH_PHOTOG_SHOOTS_FAIL:
      return {
        ...state,
        error: action.payload,
        photographerShoots: [],
        isLoading: false,
      };
    case SELECT_PHOTOGRAPHER:
      return {
        ...state,
        selectedPhotographers: action.payload,
        currentPhotographer: action.payload,
      };
    case CREATE_PHOTOGRAPHER:
      return {
        ...state,
        photographers: [...state.photographers, action.payload],
        isLoading: false,
      };
    case REMOVE_PHOTOGRAPHER:
      return {
        ...state,
        photographers: state.photographers.filter(
          (photographers) => photographers._id !== action.payload
        ),

        isLoading: false,
      };
    case UPDATE_PHOTOGRAPHER:
      return Object.assign(
        {},
        {
          ...state,
          photographers: state.photographers.map((photographers) =>
            photographers._id === action.payload._id
              ? action.payload
              : photographers
          ),
          isLoading: false,
        }
      );
    case UPDATE_PHOTOGRAPHER_FOR_WEEKTOTAL:
      return Object.assign(
        {},
        {
          ...state,
          photographers: state.photographers.map((photographers) =>
            photographers._id === action.payload._id
              ? action.payload
              : photographers
          ),
          isLoading: false,
        }
      );
    case FETCH_PHOTOGRAPHER_UNAVAILABILITY:
      return {
        ...state,
        photographerUnavailableSlotsForDay: action.payload
          ? action.payload
          : {},
      };
    case FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY:
      return {
        ...state,
        allPhotographerUnavailSlotsForDay: action.payload ? action.payload : [],
      };
    case FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED:
      return {
        ...state,
        allPhotographerUnavailSlotsForDay: action.payload ? action.payload : [],
      };
    case FETCH_UPSELL_LEADERBOARD:
      return {
        ...state,
        upsellLeaderboard: action.payload,
      };
    default:
      return state;
  }
}
