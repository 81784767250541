/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";
import { Select, Badge, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const { Option } = Select;

// Select a photographer [Functional Component].
export const AssignPhotoGrapher = (props) => {
  const [selected, setSelected] = useState(
    props.assigned !== 0 ? props.assigned : null
  );
  useEffect(() => {
    if (props.reset) {
      setSelected(null);
      props.setPhotographer(null);
      props.setReset(false);
    }
  }, [props.reset]);
  const onChange = (value) => {
    setSelected(value);
    props.setPhotographer(value);
  };
  const styles = {
    minWidth: 120,
    maxWidth: 200,
    background:
      selected && selected !== null
        ? props.photographers.filter((pg) => pg._id == selected)[0].bgColor
        : "#fff",
  };
  return (
    <Select
      /*size={"small"}*/
      showSearch
      style={styles}
      placeholder="Select a photographer"
      optionFilterProp="children"
      onChange={(event) => {
        onChange(event);
      }}
      value={selected}
      filterOption={(input, option) => {
        return (
          option?.children
            ?.toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      }}
      disabled={props.status === "completed"}
    >
      <Option value={null}>Unassigned</Option>
      {props.photographers &&
        props.photographers
          .filter(
            (p) =>
              p.active &&
              (selected === p._id ||
                !props.unavailablePhotographers.includes(p._id))
          )
          .map((pg, indx) =>
            indx >= 0 ? (
              <Option key={indx} value={pg._id}>
                <Badge
                  color={pg.bgColor ? pg.bgColor : pg.color}
                  // text={pg.name}
                />
                {pg.name}{" "}
                {props.unavailablePhotographers.includes(pg._id) && (
                  <Tooltip title="Confirm photographer availability.">
                    <span className="ml-1">
                      <WarningOutlined style={{ color: "red" }} />{" "}
                    </span>
                  </Tooltip>
                )}
              </Option>
            ) : (
              ""
            )
          )}
    </Select>
  );
};
