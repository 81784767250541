import {
  FETCH_CLIENTS,
  FETCH_CLIENTS_TABLEVIEW,
  FETCH_CLIENT,
  FETCH_CLIENT_SHOOTS,
  FETCH_CLIENT_BY_USER_ID,
  UPDATE_CLIENT,
  CREATE_CLIENT,
  CREATE_USER_CLIENT,
  FETCH_CLIENT_BY_NAME,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAIL,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAIL,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  REMOVE_PAYMENT_METHOD_FAIL,
  FETCH_CLIENT_STATS_SUCCESS,
  FETCH_CLIENT_STATS_FAIL,
} from "../action/types";

const initialState = { clients: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        isLoading: false,
        clients: payload,
      };
    case FETCH_CLIENTS_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        clients: payload.clients,
        totalCount: payload.count,
      };
    case FETCH_CLIENT:
      return {
        ...state,
        isLoading: false,
        selectedClient: payload,
      };
    case FETCH_CLIENT_BY_NAME:
      return {
        ...state,
        isLoading: false,
        clientObj: payload,
      };
    case FETCH_CLIENT_BY_USER_ID:
      return {
        ...state,
        isLoading: false,
        clientProfile: payload,
      };
    case FETCH_CLIENT_SHOOTS:
      return {
        ...state,
        isLoading: false,
        clientShoots: payload,
      };
    case FETCH_CLIENT_STATS_SUCCESS:
    case FETCH_CLIENT_STATS_FAIL:
      return {
        ...state,
        isLoading: false,
        stats: payload,
      };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentMethods: payload.cards,
      };
    case FETCH_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        isLoading: false,
        paymentMethods: payload.cards,
      };
    case SAVE_PAYMENT_METHOD_SUCCESS:
      const updatedList = state.paymentMethods
        ? [...state.paymentMethods, payload]
        : [payload];
      return {
        ...state,
        isLoading: false,
        paymentMethods: updatedList,
      };
    case SAVE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case REMOVE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentMethods: state.paymentMethods.filter(
          (card) => card.id !== action.payload.id
        ),
      };
    case REMOVE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_USER_CLIENT:
      return {
        ...state,
        isLoading: false,
        clients: [...state.clients, payload],
        selectedClient: payload,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        isLoading: false,
        clients: [...state.clients, payload],
        selectedClient: payload,
      };
    case UPDATE_CLIENT:
      const updatedClients = state.clients.concat();
      updatedClients.map((client, index) => {
        if (client._id === payload._id) {
          updatedClients[index] = payload;
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        clients: updatedClients,
        selectedClient: payload,
      };
    default:
      return state;
  }
}
