import axios from "axios";
import {
  FETCH_ENTITY_NOTES,
  FETCH_ENTITY_NOTES_FAIL,
  FETCH_CLIENT_NOTES,
  FETCH_CLIENT_NOTES_FAIL,
  FETCH_ACCESS_NOTES,
  FETCH_ACCESS_NOTES_FAIL,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAIL,
  CREATE_DUPLICATE_NOTES_SUCCESS,
  CREATE_DUPLICATE_NOTES_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  REMOVE_NOTE,
  UPDATE_WEBSOCKET_NOTE,
} from "./types";
import setAuthToken from "../../utils/setAuthToken";

let config;

if (localStorage.hstoken) {
  setAuthToken(localStorage.hstoken);
  config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.hstoken,
    },
  };
}

export const fetchEntityNotes = (entityId, query) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/notes/entity/${entityId}?access=${
        query ? query : null
      }`
    );
    dispatch({
      type: FETCH_ENTITY_NOTES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ENTITY_NOTES_FAIL,
      payload: [],
    });
    console.log(err);
  }
};

export const fetchClientNotes = (clientId, query) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/notes/client/${clientId}?access=${
        query ? query : null
      }`
    );
    dispatch({
      type: FETCH_CLIENT_NOTES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CLIENT_NOTES_FAIL,
      payload: [],
    });
    console.log(err);
  }
};

export const fetchAccessNotes = (entityId, query) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/notes/view-notes/${entityId}?access=${query}`
    );

    dispatch({
      type: FETCH_ACCESS_NOTES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ACCESS_NOTES_FAIL,
      payload: [],
    });
    console.log(err);
  }
};

export const createNote = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notes`,
      formData,
      config
    );
    dispatch({
      type: CREATE_NOTE_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    dispatch({
      type: CREATE_NOTE_FAIL,
    });

    console.log(err);
  }
};

export const createDuplicateNotes = (shootId, formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notes/duplicate/${shootId}`,
      formData,
      config
    );
    dispatch({
      type: CREATE_DUPLICATE_NOTES_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    dispatch({
      type: CREATE_DUPLICATE_NOTES_FAIL,
    });

    console.log(err);
  }
};

export const updateNote = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/notes/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_NOTE_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (err) {
    dispatch({
      type: UPDATE_NOTE_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const updateWebSocketNote = (note) => (dispatch) => {
  // console.log(auth);
  dispatch({
    type: UPDATE_WEBSOCKET_NOTE,
    payload: { note },
  });
};

export const removeNote = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/notes/${id}`);

    dispatch({
      type: REMOVE_NOTE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
