// import { useHttpClient } from "../../hooks/HttpHook";
import axios from "axios";
import {
  FETCH_OFFERINGS,
  UPDATE_OFFERING,
  CREATE_OFFERING,
  REMOVE_OFFERING,
  FETCH_OFFERINGS_TABLEVIEW,
} from "./types";

export const fetchOfferings = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/offerings`);
    dispatch({
      type: FETCH_OFFERINGS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchOfferingsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/offerings/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );
    dispatch({
      type: FETCH_OFFERINGS_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createOffering = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/offerings`,
      formData,
      config
    );

    dispatch({
      type: CREATE_OFFERING,
      payload: res.data,
    });
  } catch (err) {}
};

export const updateOffering = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/offerings/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_OFFERING,
      payload: response.data,
    });
  } catch (err) {
    return {
      data: null,
      error: err.response,
    };
  }
};

export const removeOffering = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/offerings/${id}`);

    dispatch({
      type: REMOVE_OFFERING,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
