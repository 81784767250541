import {
  FETCH_CREDITS_SUCCESS,
  FETCH_CREDITS_FAIL,
  FETCH_CLIENT_CREDITS_SUCCESS,
  FETCH_CLIENT_CREDITS_FAIL,
  FETCH_CREDIT_BY_ID_SUCCESS,
  CREATE_CREDIT_SUCCESS,
  CREATE_CREDIT_FAIL,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAIL,
  REMOVE_CREDIT_FAIL,
  REMOVE_CREDIT_SUCCESS,
  FETCH_CREDITS_TABLEVIEW_SUCCESS,
  FETCH_CREDITS_TABLEVIEW_FAIL,
} from "../action/types";

const initialState = {
  credits: [],
  selectedCredit: {},
  isLoading: true,
  clientCredits: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CREDITS_SUCCESS:
      return {
        ...state,
        credits: action.payload,
        isLoading: false,
      };
    case FETCH_CREDITS_TABLEVIEW_SUCCESS:
      return {
        ...state,
        credits: action.payload,
        isLoading: false,
      };
    case FETCH_CLIENT_CREDITS_SUCCESS:
      return {
        ...state,
        clientCredits: action.payload,
        isLoading: false,
      };

    case FETCH_CREDITS_FAIL ||
      CREATE_CREDIT_FAIL ||
      UPDATE_CREDIT_FAIL ||
      FETCH_CLIENT_CREDITS_FAIL ||
      FETCH_CREDITS_TABLEVIEW_FAIL ||
      REMOVE_CREDIT_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case FETCH_CREDIT_BY_ID_SUCCESS:
      return {
        ...state,
        selectedCredit: action.payload,
      };

    case CREATE_CREDIT_SUCCESS:
      return {
        ...state,
        credits: {
          credits: state.credits?.credits
            ? [action.payload, ...state.credits?.credits]
            : [],
          count: state.credits.count + 1,
        },
        clientCredits: state.clientCredits
          ? [action.payload, ...state.clientCredits]
          : [],
      };
    case REMOVE_CREDIT_SUCCESS:
      return {
        ...state,
        credits: {
          credits: state.credits?.credits?.filter(
            (cred) => cred._id !== action.payload
          ),
          count: state.credits.count - 1,
        },
      };
    case UPDATE_CREDIT_SUCCESS:
      return {
        ...state,
        credits: {
          ...state.credits,
          credits: state.credits?.credits?.map((cred) =>
            cred._id === action.payload._id ? action.payload : cred
          ),
        },
        clientCredits: state.clientCredits?.map((cred) =>
          cred._id === action.payload._id ? action.payload : cred
        ),
        selectedCredit: action.payload,
      };

    default:
      return state;
  }
}
