import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Card, Table, Button, Modal, Avatar, Row, Col, Input } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../shared/loading/Loading.js";
import {
  fetchClientsTableView,
  createClient,
  updateClient,
  createUserClient,
} from "../../redux/action/clientAction";
import { fetchBrokerages } from "../../redux/action/brokerageAction";
import ClientForm from "./Clients/ClientForm";
import Can from "../../user/Component/Auth/Can";
import { upload } from "../../shared/s3Bucket/s3Service.js";
import queryString from "query-string";

const Clients = ({
  handleCurrentPath,
  updateClient,
  createUserClient,
  fetchClientsTableView,
  fetchBrokerages,
  clients: { clients, isLoading, totalCount },
  brokerage: { brokerages },
  location,
}) => {
  const [state, setState] = useState({
    title: "Add Client",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });
  const { mode, modalVisiblity, initVal, title } = state;

  const formInitValue = {
    first: "",
    last: "",
    email: "",
    phone: "",
    role: "",
    headshot_url: "",
    brokerage: null,
  };

  const columns = [
    {
      title: "",
      key: "headshot_url",
      render: (record) =>
        record.headshot_url ? (
          <Avatar shape="square" src={record.headshot_url} />
        ) : (
          <Avatar
            shape="square"
            style={{ color: "#FFFFFF", backgroundColor: "#1079C6" }}
          >
            {`${record.first.charAt(0)}${record.last.charAt(0)}`}
          </Avatar>
        ),
    },
    {
      title: "HSF#",
      dataIndex: "hsf",
      key: "hsf",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "full",
      key: "full",
      width: 180,
      render: (name, record) => (
        <Link
          to={`/admin/clients/${record._id}`}
        >{`${record.first} ${record.last}`}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telephone",
      dataIndex: "phone",
      key: "phone",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      responsive: ["md"],
    },
    {
      title: "Brokerage",
      dataIndex: "brokerage",
      key: "brokerage",
      responsive: ["md"],
      render: (brokerageId) =>
        brokerages
          .filter((brok) => brok._id === brokerageId)
          .map((brokerage, index) => (
            <span key={index}>
              <Link to={`/admin/brokerages/${brokerage._id}`}>
                {brokerage.name}
              </Link>
            </span>
          )),
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Can do="edit" on="Clients">
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          {/* <Can do="delete" on="Clients">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can> */}
        </span>
      ),
    },
  ];

  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Client",
      modalVisiblity: true,
    });
  };

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const history = useHistory();

  const { search, skip, limit } = tableOpt;

  useEffect(() => {
    handleCurrentPath("Clients", "6");
    fetchClientsTableView({ search: "", skip, limit: 10 });
    if (!brokerages || !brokerages.length) {
      fetchBrokerages();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchClientsTableView();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchClientsTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchClientsTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };
  // const onConfirmRemove = (client) => {
  //   confirm({
  //     title: "Are you sure?",
  //     content: `Are you sure you want to remove ${client.full}`,
  //     okText: "Delete",
  //     okType: "danger",
  //     cancelText: "Cancel",
  //     onOk() {
  //       // removeClient(client._id);
  //       console.log("OK");
  //     },
  //     onCancel() {
  //       // handleOk();
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type, body) => {
    const reqBody = { ...body };
    if (type === "Add") {
      const res = await createUserClient(reqBody);
      if (typeof reqBody.headshot_url === "object" && res.data) {
        const avatar = new FormData();
        avatar.append("path", `clients/avatar/high/${res.data.hsf}`);
        avatar.append("file", reqBody.headshot_url);
        const imageUrl = await upload(avatar);
        reqBody.headshot_url = imageUrl[0];
        reqBody._id = res.data._id;
      } else {
        return res;
      }
    } else {
      if (typeof reqBody.headshot_url === "object") {
        const imageUrl = await upload(reqBody.headshot_url);
        reqBody.headshot_url = imageUrl[0];
      }
    }
    const res = await updateClient(reqBody._id, reqBody);
    return res;
  };

  return (
    <React.Fragment>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clients && (
          <div>
            <Row>
              <Col span={16}>
                <h2>Clients</h2>
              </Col>
              <Can do="create" on="Clients">
                <Col span={4}>
                  <Input.Search
                    placeholder="Search by HSF, name or email"
                    onChange={searchHandler}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={() => clickHandler("Add", formInitValue)}
                  >
                    <PlusOutlined /> Add a Client
                  </Button>
                </Col>
              </Can>
            </Row>
            <Table
              dataSource={clients}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
              }}
              onChange={handleTableChange}
              scroll={{ x: 500 }}
            />
          </div>
        )}
      </Card>
      <Modal
        title={title}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        destroyOnClose={true}
      >
        <ClientForm
          mode={mode}
          brokerages={brokerages}
          initVal={initVal}
          close={handleCancel}
          submitHandler={(values) => submitHandler(mode, values)}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  brokerage: state.brokerages,
});

export default connect(mapStateToProps, {
  fetchClientsTableView,
  fetchBrokerages,
  createClient,
  updateClient,
  createUserClient,
})(Clients);
