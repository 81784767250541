import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { fetchShootbyHsn } from "../redux/action/shootAction";
import { fetchClient } from "../redux/action/clientAction";
import "../App.scss";

import MediaTabs from "./MediaTabs/MediaTabs";
import Banner from "./Banner";
import logo from "../content/hs_logo_white.png";
const Tour = ({ fetchShootbyHsn, fetchClient }) => {
  const [bgImg, setBgImg] = useState("");
  const [showFooter, setShowFooter] = useState();
  const changeBg = (img) => {
    setBgImg(img);
  };
  useEffect(() => {
    fetchClientInfo();
  }, []);

  const fetchClientInfo = async () => {
    const hsn = parseInt(window.location.pathname.split("/")[3]);

    const shootRes = await fetchShootbyHsn(hsn);
    const shoot = shootRes.data;
    if (!shootRes.error) {
      const res = await fetchClient(shoot?.client_id);
      const clientPrefs = res.data.preferences;
      setShowFooter(clientPrefs.media.hs_branding);
    }
  };

  return (
    <>
      <div className="bg1">
        <div className="bg2">
          {window.location.search !== "?tab=dollhouse" && (
            <Row>
              <Col span={24}>
                <Banner />
              </Col>
            </Row>
          )}

          <MediaTabs changeBg={changeBg} />
        </div>
      </div>
      {showFooter && (
        <Row className="footer">
          <Col span={24} align="end" justify="center">
            {window.location.pathname.includes("/b/") && (
              <a href="/">
                <img src={logo} alt="" style={{ width: "174px" }} />
              </a>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default connect(null, {
  fetchShootbyHsn,
  fetchClient,
})(Tour);
