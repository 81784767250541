export const PERMITTED_ROUTES = {
  ALLROUTES: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/new",
    "/admin/shoots/:id",
    "/admin/invoices",
    "/admin/invoices/:id",
    "/admin/brokerages",
    "/admin/brokerages/:id",
    "/admin/clients",
    "/admin/clients/:id",
    "/admin/photographers",
    "/admin/photographers/:id",
    "/admin/settings",
    "/admin/offerings",
    "/admin/subdivision",
    "/admin/division/:id",
    "/admin/credits",
    "/admin/coupons",
    "/admin/profile",
    "/admin/teams",
    "/admin/team-invitation-link",
    "/admin/shoot-confirmation-link",
    "/admin/pay",
    "/admin/equipment",
    "/admin/equipment/:id",
    "/admin/availability",
    "/admin/blog",
    "/admin/reviews",
    "/admin/blog/new",
    "/admin/blog/edit/:id",
    "/admin/blog/:id",
    "/admin/amenity/:subdivisionId",
    "/admin/templates",
    "/admin/templates/new",
    "/admin/templates/edit/:id",
    "/admin/templates/:id",
  ],
  UBERADMIN: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/new",
    "/admin/shoots/:id",
    "/admin/invoices",
    "/admin/invoices/:id",
    "/admin/brokerages",
    "/admin/brokerages/:id",
    "/admin/clients",
    "/admin/clients/:id",
    "/admin/photographers",
    "/admin/photographers/:id",
    "/admin/settings",
    "/admin/offerings",
    "/admin/subdivision",
    "/admin/division/:id",
    "/admin/credits",
    "/admin/coupons",
    "/admin/teams",
    "/admin/team-invitation-link",
    "/admin/shoot-confirmation-link",
    "/admin/pay",
    "/admin/equipment",
    "/admin/equipment/:id",
    "/admin/reviews",
    "/admin/amenity/:subdivisionId",
    "/admin/templates",
    "/admin/templates/new",
    "/admin/templates/edit/:id",
    "/admin/templates/:id",
    "/admin/blog",
    "/admin/blog/new",
    "/admin/blog/edit/:id",
    "/admin/blog/:id",
  ],
  ADMIN: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/new",
    "/admin/shoots/:id",
    "/admin/invoices",
    "/admin/invoices/:id",
    "/admin/brokerages",
    "/admin/brokerages/:id",
    "/admin/clients",
    "/admin/clients/:id",
    "/admin/photographers",
    "/admin/photographers/:id",
    "/admin/settings",
    "/admin/offerings",
    "/admin/subdivision",
    "/admin/division/:id",
    "/admin/credits",
    "/admin/coupons",
    "/admin/teams",
    "/admin/team-invitation-link",
    "/admin/shoot-confirmation-link",
    "/admin/equipment",
    "/admin/reviews",
    "/admin/amenity/:subdivisionId",
    "/admin/templates",
    "/admin/templates/new",
    "/admin/templates/edit/:id",
    "/admin/templates/:id",
  ],
  COMMS: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
    "/admin/shoots/new",
    "/admin/invoices",
    "/admin/invoices/:id",
    "/admin/brokerages",
    "/admin/brokerages/:id",
    "/admin/clients",
    "/admin/clients/:id",
    "/admin/photographers",
    "/admin/photographers/:id",
    "/admin/settings",
    "/admin/offerings",
    "/admin/subdivision",
    "/admin/division/:id",
    "/admin/credits",
    "/admin/coupons",
    "/admin/teams",
    "/admin/team-invitation-link",
    "/admin/shoot-confirmation-link",
    "/admin/amenity/:subdivisionId",
  ],
  CLIENT: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
    "/admin/invoices",
    "/admin/invoices/:id",
    "/admin/profile",
    "/admin/teams",
    "/admin/team-invitation-link",
    "/admin/shoot-confirmation-link",
  ],
  EDITOR: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
    "/admin/amenity/:subdivisionId",
  ],
  VIDEO: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
  ],
  PHOTO: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
  ],
  DELUXE: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
  ],
  SOCIAL: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/dashboard",
    "/admin/shoots",
    "/admin/shoots/:id",
  ],
  PHOTOGRAPHER: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/shoots",
    "/admin/shoots/:id",
    "/admin/pay",
    "/admin/availability",
  ],
  MARKETING: [
    "/admin/login",
    "/admin/dashboard",
    "/admin/forgot-password",
    "/admin/reset-password",
    "/admin",
    "/admin/blog",
    "/admin/blog/new",
    "/admin/blog/edit/:id",
    "/admin/blog/:id",
  ],
};

export const ACTIVITIES_CLIENTS = [
  "Shoot requested",
  "Invoice ready",
  "Shoot scheduled",
  "Shoot paused",
  "Media ready",
  "Invoice past due",
];

export const STATUS_COLOR = {
  All: "#ffffff",
  Pending: "#c0ca33",
  Scheduled: "#1bb901",
  Postpone: "#ffd591",
  Completed: "#b6b6b6",
};
