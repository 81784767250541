import { connect } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Modal,
  Rate,
  Input,
  Divider,
  Drawer,
  message,
  Card,
  Typography,
  Spin,
  Empty,
  Tooltip,
  Switch,
  Alert,
  Popconfirm,
  Upload,
  Select,
  Progress,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  RetweetOutlined,
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  BankOutlined,
  UserOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
  SyncOutlined,
  LoadingOutlined,
  DropboxOutlined,
  DownloadOutlined,
  InstagramOutlined,
  FacebookOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { CopyToClipboard } from "react-copy-to-clipboard";

import DownloadButton from "../../../../shared/Components/DowloadButton";
import Can from "../../../../user/Component/Auth/Can";
import "./VirtualTour.scss";
import DragDrop from "./DragDrop";
// import fileDownload from "js-file-download";
import VirtualTourInfo from "./VirtualTourInfo.jsx";
import { upload } from "../../../../shared/s3Bucket/s3Service.js";
import { NOT_AVL, READY } from "../../../../redux/action/shoot/shootConstants";
import ReactPlayer from "react-player";
import ability from "../../../../user/Component/Auth/ability";
import confirm from "antd/lib/modal/confirm";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { slugifyString, toTitleCase } from "../../../../shared/utils/utils";
import {
  fetchAmenityBySubdivisionId,
  updateAmenity,
  updateAmenities,
} from "../../../../redux/action/amenityAction";
import EditRequestModal from "../../../../shared/Components/EditRequestModal";

const VirtualTour = ({
  updateMedia,
  updateShoot,
  media,
  selectedShoot,
  createActivity,
  auth,
  amenities,
  updateAmenity,
  updateAmenities,
  brokerages,
  denyMediaAccess,
  fetchAmenityBySubdivisionId,
  selectedClient,
}) => {
  const [amenityPhotos, setAmenityphotos] = useState({
    photos: [],
    existingPhotos: [],
    pictureWallLoader: false,
  });
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    url: "",
    existingPhotos: [],
    newPhotos: [],
    newBrandedVideo: null,
    newUnbrandedVideo: null,
    newSocialVideo: null,
    youtubeBranded: { url: "", description: "", type: "Branded" },
    youtubeUnbranded: { url: "", description: "", type: "Unbranded" },
    youtubeSocialVideo: { url: "", description: "", type: "socialMedia" },
    pictureWallLoader: false,
    visible: false,
    mode:
      window.location.href.split("=")[1] === "tour"
        ? "photos"
        : window.location.href.split("=")[1] === "amenity"
        ? "amenity"
        : window.location.href.split("=")[1] === "tour-video"
        ? "video"
        : window.location.href.split("=")[1] === "Youtube"
        ? "youtube"
        : "socialMedia",
  });
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  // const [file, setFile] = useState(null);
  const [videoBrandedFile, setVideoBrandedFile] = useState(null);
  const [socialMediaVideo, setSocialMediaVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videoUnbrandedFile, setVideoUnbrandedFile] = useState(null);
  const [score, setScore] = useState(selectedShoot?.rating?.score);
  const [comment, setComment] = useState(selectedShoot?.rating?.comment);
  const invoice = selectedShoot.details?.invoice[0];
  const [updatedPic, setUpdatedPic] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedAmenityFiles, setSelectedAmenityFiles] = useState([]);
  const [requestedAmenity, setRequestedAmenity] = useState();
  const [amenity, setAmenity] = useState();
  const [selectedAmenityId, setSelectedAmenityId] = useState();
  const [socialMediaPercentage, setSocialMediaPercentage] = useState();
  const [uploadBrandedPercentage, setBrandedUploadPercentage] = useState(0);
  const [uploadUnbrandedPercentage, setUnbrandedUploadPercentage] = useState(0);
  const [dropboxLink, setDropboxLink] = useState(null);
  const [brandedVideoDropboxLink, setBrandedVideoDropboxLink] = useState(null);
  const [unbrandedVideoDropboxLink, setUnbrandedVideoDropboxLink] =
    useState(null);
  const [photosDropboxLink, setPhotosDropboxLink] = useState("");

  useEffect(() => {
    if (media?.socialMedia?.dropBoxLinks[0]?.url) {
      setDropboxLink(media?.socialMedia?.dropBoxLinks[0]?.url);
    }
    if (media?.photos?.dropBoxLink) {
      setPhotosDropboxLink(media?.photos?.dropBoxLink);
    }
    if (media?.video?.dropboxBranded) {
      setBrandedVideoDropboxLink(media?.video?.dropboxBranded);
    }

    if (media?.video?.dropboxUnbranded) {
      setUnbrandedVideoDropboxLink(media?.video?.dropboxUnbranded);
    }
  }, [media]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#FFFFFF" }} spin />
  );

  const handleSubmitRating = async () => {
    const res = await updateShoot(selectedShoot._id, {
      ...selectedShoot,
      rating: { score, comment },
    });

    if (!res.error) {
      if (score > 0 && score < 3) {
        const notificationData = {
          shootId: selectedShoot?._id,
          clientName: auth?.user?.name,
          score,
          comment,
          hsn: selectedShoot?.hsn,
          address: `${selectedShoot?.address?.street}, ${selectedShoot?.address?.city}, ${selectedShoot?.address?.state} ${selectedShoot?.address?.zip}`,
        };
        await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/notifications/low-rating-alert`,
          notificationData
        );
      }

      if (score === 5) {
        // Add client info to "5-Start Raters" list on Mailchimp
        const firstName = auth?.user?.name?.split(" ")[0];
        const lastName = auth?.user?.name?.split(" ")[1];
        const mailchimpData = {
          email: auth?.user?.email,
          first_name: firstName,
          last_name: lastName,
          tag: "5-Star Raters",
        };
        await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/notifications/mailchimp/add-member`,
          mailchimpData
        );
      }
      message.success("Thank you for your feedback!");
    } else {
      message.error("Something went wrong! Unable to save your feedback.");
    }
  };
  const {
    previewVisible,
    previewImage,
    previewTitle,
    existingPhotos,
    newPhotos,
    visible,
    newSocialVideo,
    newBrandedVideo,
    newUnbrandedVideo,
    mode,
    youtubeSocialVideo,
    youtubeBranded,
    youtubeUnbranded,
  } = state;
  let brandingVal = {};
  if (media && Object.keys(media).length) {
    brandingVal = {
      street: media.property_info.street,
      city: media.property_info.city,
      state: media.property_info.state,
      hsf: selectedShoot.client_hsf,
      zip: media.property_info.zip,
      name: media.branding.agent_info.name,
      email: media.branding.agent_info.email,
      phone: media.branding.agent_info.phone,
      headshot_url: media.branding?.agent_info?.headshot,
      brokerage: media.branding?.brokerage_info?.logo,
    };
  }
  const { photos } = media;
  const [loader, setLoader] = useState(false);

  const [amenityLoader, setAmenityLoader] = useState(false);
  const [socialVideoLoader, setSocialVideoLoader] = useState(false);
  const [brandedVideoLoader, setBrandedVideoLoader] = useState(false);
  const [unbrandedVideoLoader, setUnbrandedVideoLoader] = useState(false);
  // const [downloadLoader, setDownloadLoader] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [circularPercentage, setCircularPercentage] = useState(0);
  const { TextArea } = Input;

  useEffect(() => {
    if (!amenity) {
      const filteredData = amenities.filter((amenity) =>
        selectedShoot?.amenities?.find((amen) =>
          amen._id === amenity._id ? amenity : null
        )
      );
      setRequestedAmenity(filteredData);
      setSelectedAmenityId(amenities[0]?._id);
      setAmenity(amenities[0]);
    }
  }, [amenities]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchAmenity = async () => {
      if (selectedShoot?.subdivision) {
        await fetchAmenityBySubdivisionId(selectedShoot?.subdivision?._id);
      }
    };
    fetchAmenity();
  }, [selectedShoot]);

  useEffect(() => {
    const arr = [];
    let ytBranded = null;
    let ytUnbranded = null;
    if (
      (photos && photos.gallery.length > 0) ||
      (photos && photos.amenityPhotos.length > 0)
    ) {
      let mediaImages = photos.gallery;
      let updatedImages = photos?.amenityPhotos;
      if (mode !== "amenity") {
        mediaImages.forEach((url, index) => {
          if (url) {
            const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
            const regex = /\d+(?=\.\w+$)/g;
            const extension = url?.split(".").pop();
            let filename = url?.match(regex);
            filename = filename ? filename[0] + "." + extension : altFileName;
            const obj = {};
            const len = url?.split("/").length;
            obj.name = url?.split("/")[len - 1].split("_")[1];
            obj.url = url;
            obj.thumbUrl = url.replace("/high/", "/thumb/");
            obj.uid = index;
            obj._id = index + 1;
            obj.order = index + 1;
            obj.filename = filename;
            arr.push(obj);
          }
        });
      }
      let type = "amenity";
      if (mode === "amenity") {
        updatedImages.forEach((url, index) => {
          if (url) {
            const photoType = type;
            const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
            const regex = /\d+(?=\.\w+$)/g;
            const extension = url?.split(".").pop();
            let filename = url?.match(regex);
            filename = filename ? filename[0] + "." + extension : altFileName;
            const obj = {};
            const len = url?.split("/").length;
            obj.name = url?.split("/")[len - 1].split("_")[1];
            obj.url = url;
            obj.thumbUrl = url.replace("/high/", "/thumb/");
            obj.uid = index;
            obj._id = index + 1;
            obj.order = index + 1;
            obj.filename = filename;
            obj.photoType = photoType;
            arr.push(obj);
          }
        });
      }
      setState({ ...state, existingPhotos: arr, newPhotos: [] });
    }
    if (
      (mode === "video" && media && media.video) ||
      ((mode === "slideShow" || mode === "youtube") &&
        media &&
        media.slideShow) ||
      (mode === "socialMedia" && media && media.socialMedia)
    ) {
      const video =
        mode === "video"
          ? { ...media.video }
          : mode === "slideShow" || mode === "youtube"
          ? { ...media.slideShow }
          : { ...media.socialMedia };
      video.youtubeLinks.forEach((youtube) => {
        if (youtube.type === "Branded") {
          ytBranded = { ...youtube };
        } else if (youtube.type === "Unbranded") {
          ytUnbranded = { ...youtube };
        }
      });
      setState({
        ...state,
        youtubeBranded: ytBranded
          ? ytBranded
          : {
              ...youtubeBranded,
              description:
                mode === "video"
                  ? selectedShoot.video.package
                  : "YoutubeSlideShow",
            },
        youtubeUnbranded: ytUnbranded
          ? ytUnbranded
          : {
              ...youtubeUnbranded,
              description:
                mode === "video"
                  ? selectedShoot.video.package
                  : "YoutubeSlideShow",
            },
      });
    }
    if (loader) {
      setLoader(false);
    }
  }, [media]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const arr = [];
    if (mode === "amenity" && amenity?.photos?.images.length > 0) {
      amenity.photos.images.forEach((url, index) => {
        if (url) {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          const obj = {};
          const len = url?.split("/").length;
          obj.name = url?.split("/")[len - 1].split("_")[1];
          obj.url = url;
          obj.thumbUrl = url.replace("/high/", "/thumb/");
          obj.uid = index;
          obj._id = index + 1;
          obj.order = index + 1;
          obj.filename = filename;
          arr.push(obj);
        }
      });
    }
    setAmenityphotos({ ...amenityPhotos, existingPhotos: arr });
    if (amenityLoader) {
      setAmenityLoader(false);
    }
  }, [amenity]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => setState({ ...state, previewVisible: false });
  const handleCancelModal = () => setState({ ...state, visible: false });

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleChange = async ({ fileList }) => {
    setCircularPercentage(0);
    setLoading(true);

    const newPhotos = [];
    await setSelectedFiles([...fileList]);

    for (let index = 0; index < fileList.length; index++) {
      const percentage = Math.round((index / fileList.length) * 100);
      setCircularPercentage(percentage);

      const base64Pic = await getBase64(fileList[index].originFileObj);
      newPhotos.push({
        originFileObj: fileList[index].originFileObj,
        url: base64Pic,
        thumbUrl: base64Pic,
        _id: existingPhotos.length + index + 1,
        order: existingPhotos.length + index + 1,
      });
    }
    setState({
      ...state,
      newPhotos,
    });
    setCircularPercentage(100);
    setLoading(false);
  };

  const handleAmenityChange = async ({ fileList }) => {
    const photos = [];
    setSelectedAmenityFiles([...fileList]);
    for (let index = 0; index < fileList.length; index++) {
      const base64Pic = await getBase64(fileList[index].originFileObj);
      photos.push({
        originFileObj: fileList[index].originFileObj,
        url: base64Pic,
        thumbUrl: base64Pic,
        _id: existingPhotos.length + index + 1,
        order: existingPhotos.length + index + 1,
      });
    }
    setAmenityphotos({ ...amenityPhotos, photos });
  };

  const handleChangeDelete = async ({ url, photoType }) => {
    try {
      setState({ ...state, pictureWallLoader: true });
      let reqBody;
      if (photoType !== "amenity") {
        const data = new FormData();
        data.append("file", url);
        const s3Bucket = await axios.delete(
          `${process.env.REACT_APP_ROOT_URL}/upload`,
          { data: { key: [url] } }
        );

        if (s3Bucket.status === 200) {
          const updatedPhotos = photos.gallery.filter((obj) => obj !== url);
          reqBody = {
            photos: {
              ...photos,
              gallery: updatedPhotos,
            },
          };
        } else {
          throw new Error("Oops ! Something went wrong while deleting.");
        }
      } else {
        const updatedAmenity = photos.amenityPhotos.filter(
          (obj) => obj !== url
        );
        reqBody = {
          photos: {
            ...photos,
            amenityPhotos: updatedAmenity,
          },
        };
      }
      const updMedia = await updateMedia(media._id, reqBody);
      if (updMedia.data) {
        const updatePhotos = [];
        if (mode !== "amenity") {
          updMedia.data.photos.gallery.map((url) => {
            const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
            const regex = /\d+(?=\.\w+$)/g;
            const extension = url?.split(".").pop();
            let filename = url?.match(regex);
            filename = filename ? filename[0] + "." + extension : altFileName;
            updatePhotos.push({ url, filename });
          });
        } else if (mode === "amenity") {
          updMedia.data.photos.amenityPhotos.map((url) => {
            const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
            const regex = /\d+(?=\.\w+$)/g;
            const extension = url?.split(".").pop();
            let filename = url?.match(regex);
            filename = filename ? filename[0] + "." + extension : altFileName;
            updatePhotos.push({ url, photoType: "amenity", filename });
          });
        }
        setUpdatedPic(updatePhotos);
        message.success("Photo deleted successfully");
        setState({
          ...state,
          pictureWallLoader: false,
        });
      } else {
        console.error(updMedia.error);
        setState({ ...state, pictureWallLoader: false });
        message.error("Oops!! Error occured while deleting the data");
      }
    } catch (err) {
      message.error(err);
    }
  };

  const handleChangeAmenityDelete = async (url) => {
    setAmenityphotos({ ...amenityPhotos, pictureWallLoader: true });
    const UpdatedGallery = amenity?.photos?.images.filter((obj) => obj !== url);

    const reqBody = {
      photos: {
        images: UpdatedGallery,
      },
    };
    const updAmenity = await updateAmenity(amenity?._id, reqBody);

    if (updAmenity.data) {
      updateAmenities(updAmenity.data);
      setAmenity(updAmenity.data);
      setSelectedAmenityId(updAmenity.data._id);
      message.success("Photo deleted successfully");
    } else {
      console.error(updAmenity.error);
      setAmenityphotos({ ...amenityPhotos, pictureWallLoader: false });
      message.error("Oops!! Error occured while deleting the data");
    }
  };

  const handleDeleteAll = async () => {
    setState({ ...state, pictureWallLoader: true });
    let url = [];
    existingPhotos.map(
      (key) => key.photoType !== "amenity" && url.push(key.url)
    );
    if (url.length > 0) {
      const s3Bucket = await axios.delete(
        `${process.env.REACT_APP_ROOT_URL}/upload`,
        { data: { key: url } }
      );
    }
    const reqBody = {
      photos: {
        ...photos,
        gallery: [],
      },
    };
    const updMedia = await updateMedia(media._id, reqBody);
    if (updMedia.data) {
      setUpdatedPic();
      message.success("Photos deleted successfully");
      setState({
        ...state,
        existingPhotos: [],
        pictureWallLoader: false,
      });
    } else {
      console.error(updMedia.error);
      setState({ ...state, pictureWallLoader: false });
      message.error("Oops!! Error occured while deleting the data");
    }
  };
  const handleDelete = async (image) => {
    const filteredData = selectedFiles.filter(
      (file) => file.uid !== image.originFileObj.uid
    );
    setSelectedFiles(filteredData);
    const deletePhotos = newPhotos.filter((target) => target.url !== image.url);
    setState({
      ...state,
      newPhotos: deletePhotos,
    });
  };

  const handleAmenityDelete = async (image) => {
    const filteredData = selectedAmenityFiles.filter(
      (file) => file.uid !== image.originFileObj.uid
    );
    setSelectedAmenityFiles(filteredData);
    const deletePhotos = amenityPhotos.photos.filter(
      (target) => target.url !== image.url
    );
    setAmenityphotos({
      ...amenityPhotos,
      photos: deletePhotos,
    });
  };

  const handleChangeVideoDelete = async (deleteVideo, type) => {
    confirm({
      title: "Are you sure you want to delete?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content:
        "You need to manually remove the video from the YouTube channel after deleting.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        type === "Branded"
          ? setBrandedVideoLoader(true)
          : setUnbrandedVideoLoader(true);
        const s3Bucket = await axios.delete(
          `${process.env.REACT_APP_ROOT_URL}/upload`,
          { data: { key: [deleteVideo.url] } }
        );
        if (s3Bucket.status === 200) {
          const video =
            mode === "video" ? { ...media.video } : { ...media.slideShow };
          video.s3Links = video.s3Links.filter(
            (video) => video.url !== deleteVideo.url
          );
          video.youtubeLinks = video.youtubeLinks.filter(
            (video) => video.type !== type
          );
          let reqBody = {};
          if (mode === "video") {
            reqBody = { video };
          } else {
            reqBody = { slideShow: { ...video } };
          }
          const updMedia = await updateMedia(media._id, reqBody);
          if (updMedia.data) {
            type === "Branded"
              ? setState({
                  ...state,
                  youtubeBranded: { ...youtubeBranded, url: "" },
                })
              : setState({
                  ...state,
                  youtubeUnbranded: { ...youtubeUnbranded, url: "" },
                });
            message.success("Video deleted successfully");
          } else {
            console.error(updMedia.error);
            message.error("Oops!! Error occured while deleting the data");
          }
        } else {
          message.error("Oops ! Something went wrong while deleting.");
        }
        type === "Branded"
          ? setBrandedVideoLoader(false)
          : setUnbrandedVideoLoader(false);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleChangeSocialVideoDelete = async (deleteSocialVideo, type) => {
    confirm({
      title: "Are you sure you want to delete?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        type === "socialMedia" && setSocialVideoLoader(true);
        const s3Bucket = await axios.delete(
          `${process.env.REACT_APP_ROOT_URL}/upload`,
          { data: { key: [deleteSocialVideo.url] } }
        );
        if (s3Bucket.status === 200) {
          const video =
            mode === "video"
              ? { ...media.video }
              : mode === "slideShow" && mode === "youtube"
              ? { ...media.slideShow }
              : { ...media.socialMedia };
          video.s3Links = video.s3Links.filter(
            (video) => video.url !== deleteSocialVideo.url
          );
          video.youtubeLinks = video.youtubeLinks.filter(
            (video) => video.type !== type
          );
          let reqBody = {};
          if (mode === "video") {
            reqBody = { video };
          } else if (mode === "slideShow" || mode === "youtube") {
            reqBody = { slideShow: { ...video } };
          } else {
            reqBody = { socialMedia: { ...video } };
          }
          const updMedia = await updateMedia(media._id, reqBody);
          if (updMedia.data) {
            type === "socialMedia" &&
              setState({
                ...state,
                youtubeSocialVideo: { ...youtubeSocialVideo, url: "" },
              });

            message.success("Video deleted successfully");
          } else {
            console.error(updMedia.error);
            message.error("Oops!! Error occured while deleting the data");
          }
        } else {
          message.error("Oops ! Something went wrong while deleting.");
        }
        type === "socialMedia" && setSocialVideoLoader(false);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const { Meta } = Card;

  const imageUpload = async (image) => {
    const amenityImages = [...photos.amenityPhotos];
    const updatedImages = amenityImages.concat(image.url);

    const reqMediaBody = {
      photos: {
        ...photos,
        amenityPhotos: updatedImages,
      },
    };

    const updMedia = await updateMedia(media._id, reqMediaBody);
    if (updMedia.data) {
      const updatePhotos = [];
      if (mode !== "amenity") {
        updMedia.data.photos.gallery.map((url) => {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          updatePhotos.push({ url, filename });
        });
      }
      if (mode === "amenity") {
        updMedia.data.photos.amenityPhotos.map((url) => {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          updatePhotos.push({ url, photoType: "amenity", filename });
        });
      }
      setUpdatedPic(updatePhotos);
      message.success("Image added in Photos successfully!");
    } else {
      console.error(updMedia.error);
      message.error("Oops!! Error occurred while adding the image");
    }
  };

  const handleUpload = async () => {
    setCircularPercentage(0);
    setLoading(true);

    const interval = setInterval(() => {
      setCircularPercentage((prev) =>
        prev > 98 ? 99 : prev >= 70 ? prev + 1 : prev + 10
      );
    }, 500);

    let data = new FormData();
    // Replace null or undefined with empty string
    const streetAddress = slugifyString(
      selectedShoot.address?.street.replace("null", ""),
      "_"
    );

    data.append(
      "path",
      `shoots/photos/high/${streetAddress}_${selectedShoot?.hsn}`
    );
    for (var x = 0; x < state.newPhotos.length; x++) {
      const file = state.newPhotos[x].originFileObj;
      data.append("file", file);
    }
    const upload = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/upload`,
      data
    );
    if (upload.status === 200) {
      setSelectedFiles([]);
      setState({
        ...state,
        newPhotos: [],
      });
      const updatedBucket = media?.photos?.gallery.concat(upload.data.urls);
      const reqBody = {
        photos: {
          ...photos,
          gallery: updatedBucket,
        },
      };

      const updMedia = await updateMedia(media._id, reqBody);
      const updatePhotos = [];
      if (mode !== "amenity") {
        updMedia.data.photos.gallery.map((url) => {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          updatePhotos.push({ url, filename });
        });
      }
      if (mode === "amenity") {
        updMedia.data.photos.amenityPhotos.map((url) => {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          updatePhotos.push({ url, photoType: "amenity", filename });
        });
      }
      setUpdatedPic(updatePhotos);
      if (updMedia.data) {
        message.success("Your images uploaded successfully!");
      } else {
        console.error(updMedia.error);
        message.error("Oops!! Error occurred while saving the data");
      }
    } else {
      console.error(upload);
      message.error("Oops!! Error occurred while uploading");
    }
    clearInterval(interval);
    setCircularPercentage(100);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleAmenityUpload = async () => {
    if (amenity) {
      setAmenityLoader(true);
      let data = new FormData();
      data.append("path", `shoots/photos/high/${selectedShoot.hsn}_`);
      for (var x = 0; x < amenityPhotos.photos.length; x++) {
        const file = amenityPhotos.photos[x].originFileObj;
        data.append("file", file);
      }
      const upload = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/upload`,
        data
      );
      if (upload.status === 200) {
        setSelectedAmenityFiles([]);
        const updatedBucket = amenity?.photos?.images.concat(upload.data.urls);
        const reqBody = {
          ...amenity,
          photos: {
            images: updatedBucket,
          },
        };

        const updAmenity = await updateAmenity(amenity?._id, reqBody);
        setAmenityphotos({
          ...amenityPhotos,
          photos: [],
        });
        if (updAmenity.data) {
          setAmenity(updAmenity.data);
          setSelectedAmenityId(updAmenity.data._id);
          updateAmenities(updAmenity.data);
          message.success("Your images uploaded successfully!");
        } else {
          console.error(updAmenity.error);
          message.error("Oops!! Error occurred while saving the data");
        }
      } else {
        console.error(upload);
        message.error("Oops!! Error occurred while uploading");
      }
    } else {
      message.error("Select the Amenity before uploading");
    }
  };
  const handleAmenity = (value) => {
    const filteredAmenity = amenities.find((amenity) => amenity._id === value);
    setAmenity(filteredAmenity);
    setSelectedAmenityId(value);
    setAmenityphotos({ ...amenityPhotos, existingPhotos: [], photos: [] });
  };

  // const uploadBrandedVideo = async (type) => {
  //   type === "Branded"
  //     ? setBrandedVideoLoader(true)
  //     : setSocialVideoLoader(true);
  //   let data = new FormData();
  //   const streetAddress = slugifyString(
  //     selectedShoot.address?.street.replace("null", ""),
  //     "_"
  //   );
  //   if (mode === "video") {
  //     data.append(
  //       "path",
  //       `shoots/video/high/${streetAddress}_${selectedShoot.hsn}_${type}`
  //     );
  //   } else if (mode === "socialMedia") {
  //     data.append(
  //       "path",
  //       `shoots/socialMedia/high/${streetAddress}_${selectedShoot.hsn}_${type}`
  //     );
  //     data.append("file", socialMediaVideo);
  //   } else {
  //     data.append(
  //       "path",
  //       `shoots/slideshow/high/${streetAddress}_${selectedShoot.hsn}_${type}`
  //     );
  //     data.append("file", videoBrandedFile);
  //   }

  //   // data.append("file", file);
  //   data.append("mediaType", type);
  //   const config = {
  //     onUploadProgress: (progressEvent) => {
  //       const percentCompleted = Math.round(
  //         (progressEvent.loaded * 100) / progressEvent.total
  //       );
  //       setBrandedUploadPercentage(percentCompleted);
  //       // setSocialMediaPercentage(percentCompleted);
  //       setSocialMediaPercentage(percentCompleted);
  //     },
  //   };
  //   const upload = await axios.post(
  //     `${process.env.REACT_APP_ROOT_URL}/upload/video`,
  //     data,
  //     config
  //   );
  //   if (upload.status === 200) {
  //     //window.href(upload.data);
  //     setState({
  //       ...state,
  //       newBrandedVideo: null,
  //     });
  //     let video =
  //       mode === "video"
  //         ? { ...media.video }
  //         : mode === "socialMedia"
  //         ? { ...media?.socialMedia }
  //         : { ...media?.slideShow };
  //     video.s3Links.push({
  //       url: upload.data?.url,
  //       description:
  //         mode === "video"
  //           ? selectedShoot.video.package
  //           : mode === "socialMedia"
  //           ? selectedShoot.socialMedia.package
  //           : "YoutubeSlideShow",
  //       type,
  //     });
  //     let reqBody = {};
  //     if (mode === "video") {
  //       reqBody = { video };
  //     } else if (mode === "socialMedia") {
  //       reqBody = { socialMedia: { ...video } };
  //     } else {
  //       reqBody = { slideShow: { ...video } };
  //     }
  //     const updMedia = await updateMedia(media._id, reqBody);
  //     if (updMedia.data) {
  //       message.success("Your video uploaded successfully!");
  //     } else {
  //       console.error(updMedia.error);
  //       message.error("Oops!! Error occurred while saving the data");
  //     }
  //   } else {
  //     console.error(upload);
  //     message.error("Oops!! Error occurred while uploading");
  //   }
  //   type === "Branded"
  //     ? setBrandedVideoLoader(false)
  //     : setSocialVideoLoader(false);
  // };
  const uploadBrandedVideo = async (type) => {
    setBrandedVideoLoader(true);
    let data = new FormData();
    const streetAddress = slugifyString(
      selectedShoot.address?.street.replace("null", ""),
      "_"
    );
    if (mode === "video") {
      data.append(
        "path",
        `shoots/video/high/${streetAddress}_${selectedShoot.hsn}_${type}`
      );
    } else {
      data.append(
        "path",
        `shoots/slideshow/high/${streetAddress}_${selectedShoot.hsn}_${type}`
      );
    }
    // data.append("file", file);
    data.append("file", videoBrandedFile);
    data.append("mediaType", type);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setBrandedUploadPercentage(percentCompleted);
      },
    };
    const upload = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/upload/video`,
      data,
      config
    );
    if (upload.status === 200) {
      //window.href(upload.data);
      setState({
        ...state,
        newBrandedVideo: null,
      });
      const video =
        mode === "video" ? { ...media.video } : { ...media.slideShow };
      video.s3Links.push({
        url: upload.data.url,
        description:
          mode === "video" ? selectedShoot.video.package : "YoutubeSlideShow",
        type,
      });
      let reqBody = {};
      if (mode === "video") {
        reqBody = { video };
      } else {
        reqBody = { slideShow: { ...video } };
      }
      const updMedia = await updateMedia(media._id, reqBody);
      if (updMedia.data) {
        message.success("Your video uploaded successfully!");
      } else {
        console.error(updMedia.error);
        message.error("Oops!! Error occurred while saving the data");
      }
    } else {
      console.error(upload);
      message.error("Oops!! Error occurred while uploading");
    }
    setBrandedVideoLoader(false);
  };

  const uploadUnbrandedVideo = async (type) => {
    setUnbrandedVideoLoader(true);
    let data = new FormData();
    if (mode === "video") {
      data.append(
        "path",
        `shoots/video/high/${selectedShoot.hsn}_${selectedShoot.address.street_lower_no_spaces}_${type}`
      );
    } else {
      data.append(
        "path",
        `shoots/slideshow/high/${selectedShoot.hsn}_${selectedShoot.address.street_lower_no_spaces}_${type}`
      );
    }
    data.append("file", videoUnbrandedFile);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUnbrandedUploadPercentage(percentCompleted);
      },
    };

    const upload = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/upload/video`,
      data,
      config
    );
    if (upload.status === 200) {
      setState({
        ...state,
        newUnbrandedVideo: null,
      });
      const video =
        mode === "video" ? { ...media.video } : { ...media.slideShow };
      video.s3Links.push({
        url: upload.data.url,
        description:
          mode === "video" ? selectedShoot.video.package : "YoutubeSlideShow",
        type,
      });
      let reqBody = {};
      if (mode === "video") {
        reqBody = { video };
      } else {
        reqBody = { slideShow: { ...video } };
      }
      const updMedia = await updateMedia(media._id, reqBody);
      if (updMedia.data) {
        message.success("Your video uploaded successfully!");
      } else {
        console.error(updMedia.error);
        message.error("Oops!! Error occurred while saving the data");
      }
    } else {
      console.error(upload);
      message.error("Oops!! Error occurred while uploading");
    }
    setUnbrandedVideoLoader(false);
  };
  //
  const uploadSocialVideo = async (type) => {
    setSocialVideoLoader(true);
    let data = new FormData();
    const streetAddress = slugifyString(
      selectedShoot.address?.street.replace("null", ""),
      "_"
    );
    if (mode === "video") {
      data.append(
        "path",
        `shoots/video/high/${streetAddress}_${selectedShoot.hsn}_${type}`
      );
    } else if (mode === "slideShow" || mode === "youtube") {
      data.append(
        "path",
        `shoots/slideshow/high/${streetAddress}_${selectedShoot.hsn}_${type}`
      );
    } else if (mode === "socialMedia") {
      data.append(
        "path",
        `shoots/socialMedia/high/${streetAddress}_${selectedShoot.hsn}_${type}`
      );
    }
    data.append("file", socialMediaVideo);
    data.append("mediaType", type);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setSocialMediaPercentage(percentCompleted);
      },
    };
    const socialUpload = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/upload/video`,
      data,
      config
    );
    if (socialUpload.status === 200) {
      //window.href(socialUpload.data);
      setState({
        ...state,
        newSocialVideo: null,
      });
      const video =
        mode === "video"
          ? { ...media.video }
          : mode === "slideShow" || mode === "youtube"
          ? { ...media.slideShow }
          : { ...media.socialMedia };
      video.s3Links.push({
        url: socialUpload.data.url,
        description:
          mode === "video"
            ? selectedShoot.video.package
            : mode === "slideShow" || mode === "youtube"
            ? "YoutubeSlideShow"
            : "socialMedia",
        type,
      });
      let reqBody = {};
      if (mode === "video") {
        reqBody = { video };
      } else if (mode === "slideShow" || mode === "youtube") {
        reqBody = { slideShow: { ...video } };
      } else {
        reqBody = { socialMedia: { ...video } };
      }
      const updMedia = await updateMedia(media._id, reqBody);
      if (updMedia.data) {
        message.success("Your video uploaded successfully!");
      } else {
        console.error(updMedia.error);
        message.error("Oops!! Error occurred while saving the data");
      }
    } else {
      console.error(socialUpload);
      message.error("Oops!! Error occurred while uploading");
    }
    setSocialVideoLoader(false);
  };
  // const handleDownload = async (type = "high") => {
  //   setDownloadLoader(type);

  //   const download = await axios.get(
  //     `${process.env.REACT_APP_ROOT_URL}/media/${media._id}/download?type=${type}`,
  //     {
  //       responseType: "arraybuffer",
  //     }
  //   );
  //   if (download.status === 200) {
  //     const zipName = `${selectedShoot.hsn}_${type}.zip`;
  //     fileDownload(download.data, zipName);

  //     message.success("Your images downloaded successfully!");
  //   } else {
  //     console.error(download);
  //     message.error("Oops! Error occurred while downloading");
  //   }
  //   setDownloadLoader("");
  // };

  const submitHandler = async (data) => {
    const {
      name,
      email,
      phone,
      headshot_url,
      brokerage,
      street,
      city,
      state,
      zip,
    } = data;
    const property_info = {
      street,
      city,
      state,
      zip,
    };
    const agent_info = {
      name,
      email,
      phone,
      headshot: headshot_url,
    };
    const brokerage_info = {
      logo: brokerage,
    };
    if (
      typeof agent_info.headshot === "object" &&
      typeof brokerage_info.logo === "object"
    ) {
      const [agent, brokerage] = await Promise.all([
        upload(agent_info.headshot),
        upload(brokerage_info.logo),
      ]);
      agent_info.headshot = agent[0];
      brokerage_info.logo = brokerage[0];
    } else {
      if (typeof agent_info.headshot === "object") {
        const imagesUrl = await upload(agent_info.headshot);
        agent_info.headshot = imagesUrl[0];
      }
      if (typeof brokerage_info.logo === "object") {
        const imagesUrl = await upload(brokerage_info.logo);
        brokerage_info.logo = imagesUrl[0];
      }
    }
    const res = await updateMedia(media._id, {
      ...media,
      property_info,
      branding: {
        agent_info,
        brokerage_info,
      },
    });
    return res;
  };

  const renderRatingWidget = (isClient) => {
    return (
      <Row style={{ margin: "0 0 20px 0" }}>
        <Col>
          <h3>How would you rate this shoot?</h3>
          <Rate
            onChange={(value) => setScore(value)}
            value={score}
            disabled={!isClient}
          />
          <p>
            Please give us your feedback below:
            <TextArea
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              rows={2}
              disabled={!isClient}
            />
          </p>
          {isClient && (
            <Button
              onClick={handleSubmitRating}
              style={{ marginTop: "10px" }}
              type="primary"
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
    );
  };
  const renderReady = (type) => {
    type =
      type === "youtube"
        ? "slideShow"
        : type === "socialMedia"
        ? "socialMedia"
        : type;

    const title =
      type === "photos"
        ? "Mark Photos As Ready:"
        : type === "slideShow" || type === "youtube"
        ? "Mark SlideShow As Ready:"
        : type === "socialMedia"
        ? "Mark Social Media As Ready:"
        : "Mark Video As Ready:";
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="photos-ready">
              {title}
              <span className="float-right">
                <Tooltip
                  title="NOTE: When you check this an email will be sent to the Client"
                  placement="topRight"
                >
                  <Switch
                    checked={media[type] && media[type].status === "Ready"}
                    onChange={(e) => onSwitchChange(e, type)}
                    checkedChildren={<CheckOutlined />}
                    disabled={
                      type === "photos"
                        ? media.photos && media.photos.gallery.length === 0
                        : type === "slideShow" || type === "youtube"
                        ? media.slideShow && media.slideShow.s3Links.length < 0
                        : type === "socialMedia"
                        ? media.socialMedia &&
                          media.socialMedia.s3Links.length < 0
                        : media.video && media?.video?.s3Links?.length < 0
                    }
                  />
                </Tooltip>
              </span>
            </div>
          </Col>
        </Row>
        <Divider />
      </div>
    );
  };

  const updateYTUrl = ({ target }, type) => {
    let youtube;
    if (type === "Branded") {
      youtube = { ...youtubeBranded };
      youtube.url = target.value;
      setState({ ...state, youtubeBranded: youtube });
    } else if (type === "Unbranded") {
      youtube = { ...youtubeUnbranded };
      youtube.url = target.value;
      setState({ ...state, youtubeUnbranded: youtube });
    }
  };

  const updateYoutube = async () => {
    setLoader(true);
    let isValid = true;
    const video =
      mode === "video"
        ? { ...media.video }
        : mode === "youtube"
        ? { ...media.slideShow }
        : { ...media.socialMedia };
    if (youtubeBranded._id) {
      video.youtubeLinks = video.youtubeLinks.filter(
        (yt) => yt._id !== youtubeBranded._id
      );
      video.youtubeLinks.push(youtubeBranded);
    } else {
      video.youtubeLinks.push(youtubeBranded);
    }
    if (youtubeUnbranded._id) {
      video.youtubeLinks = video.youtubeLinks.filter(
        (yt) => yt._id !== youtubeUnbranded._id
      );
      video.youtubeLinks.push(youtubeUnbranded);
    } else {
      video.youtubeLinks.push(youtubeUnbranded);
    }
    // if (youtubeBranded.url !== "") {
    //   isValid = video.s3Links.find((video) => video.type === "Branded");
    // }
    // if (isValid && youtubeUnbranded.url !== "") {
    //   isValid = video.s3Links.find((video) => video.type === "Unbranded");
    // }
    if (isValid) {
      let reqBody = {};
      if (mode === "video") {
        reqBody = { video };
      } else if (mode === "youtube") {
        reqBody = { slideShow: { ...video } };
      } else {
        reqBody = { socialMedia: { ...video } };
      }
      const updMedia = await updateMedia(media._id, reqBody);
      if (updMedia.data) {
        message.success("Youtube Links updated successfully!");
      } else {
        console.error(updMedia.error);
        message.error("Oops!! Error occurred while saving the data");
      }
    } else {
      message.error(
        "YouTube links cannot be updated without uploading the video"
      );
    }
    setLoader(false);
  };

  const onSwitchChange = async (checked, type) => {
    if (checked) {
      updateMedia(media._id, {
        ...media,
        selectedShoot,
        [type]: { ...media[type], status: "Ready" },
      });
      createActivity(
        selectedShoot,
        type === "photos"
          ? "Photos marked as ready."
          : type === "slideShow" || type === "youtube"
          ? "SlideShow marked as ready."
          : type === "socialMedia"
          ? "Social Media marked as ready."
          : "Videos marked as ready."
      );
      if (type === "photos") {
        message.success("Photos marked as Ready");
        updateShoot(selectedShoot._id, {
          checklist: { photos_delivered: true },
        });
      } else if (type === "slideShow" || type === "youtube") {
        message.success("SlideShow marked as Ready");
        updateShoot(selectedShoot._id, {
          checklist: { slideshow_delivered: true },
        });
      } else if (type === "socialMedia") {
        message.success("Social Media marked as Ready");
        updateShoot(selectedShoot._id, {
          checklist: { socialMedia_delivered: true },
        });
      } else {
        message.success("Videos marked as Ready");
        updateShoot(selectedShoot._id, {
          checklist: { videos_delivered: true },
        });
      }
      const emailData = {
        shootId: selectedShoot._id,
        clientEmail: selectedShoot.client_email,
        hsn: selectedShoot.hsn,
        address: selectedShoot.address,
        mediaType: type === "youtube" ? "slideShow" : type,
        clientFirst: selectedShoot?.client_name_first,
        clientLast: selectedShoot?.client_name_last,
        secondaryContact: selectedShoot?.booking_form?.secondary_contact,
        share: selectedShoot.share,
        socialMediaVideoUrl: dropboxLink || null,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/notifications/photos-ready`,
        emailData
      );
      if (res.status === 200) {
        createActivity(
          selectedShoot,
          `${toTitleCase(type)} ready email sent to client.`
        );
      }
    } else {
      updateMedia(media._id, {
        ...media,
        [type]: { ...media[type], status: "Processing" },
      });
      if (type === "photos") {
        message.info("Photos marked as Processing");
        updateShoot(selectedShoot._id, {
          checklist: { photos_delivered: false },
        });
      } else {
        if (type === "slideShow" || type === "youtube") {
          message.info("SlideShow marked as Processing");
          updateShoot(selectedShoot._id, {
            checklist: { slideshow_delivered: false },
          });
        } else if (type === "socialMedia") {
          message.info("Social Media marked as Processing");
          updateShoot(selectedShoot.id, {
            checklist: { socialMedia_delivered: false },
          });
        } else {
          message.info("Videos marked as Processing");
          updateShoot(selectedShoot._id, {
            checklist: { videos_delivered: false },
          });
        }
      }
    }
  };

  const handlePhotosDropboxLink = async () => {
    const updMedia = await updateMedia(media._id, {
      ...media,
      photos: {
        ...media.photos,
        dropBoxLink: photosDropboxLink,
      },
    });
    if (updMedia.data) {
      message.success("Dropbox link updated successfully!");
    } else {
      console.error(updMedia.error);
      message.error("Oops!! Error occurred while saving the Dropbox data");
    }
  };

  const handleSocialDropboxLink = async () => {
    const updMedia = await updateMedia(media._id, {
      ...media,
      socialMedia: {
        dropBoxLinks: [
          {
            url: dropboxLink,
            description: "Social Media Video",
            type: "Unbranded",
          },
        ],
      },
    });
    if (updMedia.data) {
      message.success("Youtube Links updated successfully!");
    } else {
      console.error(updMedia.error);
      message.error("Oops!! Error occurred while saving the data");
    }
  };

  const handleVideoDropboxLinks = async (videoType, url) => {
    const key = videoType === "Branded" ? "dropboxBranded" : "dropboxUnbranded";
    const updMedia = await updateMedia(media._id, {
      ...media,
      video: {
        ...media.video,
        [key]: url,
      },
    });
    if (updMedia.data) {
      message.success("Video Dropbox links updated successfully!");
    } else {
      console.error(updMedia.error);
      message.error("Oops!! Error occurred while saving the data");
    }
  };

  const handleOpenFileInput = () => {
    inputRef.current.click();
  };
  const handleOpenFileInput1 = () => {
    inputRef1.current.click();
  };
  const handleOpenFileInput2 = () => {
    inputRef2.current.click();
  };
  const renderDownloadMedia = (type) => {
    const title =
      type === "photos"
        ? "Download your photos!"
        : type === "slideShow" || type === "youtube"
        ? "Download your SlideShow!"
        : type === "socialMedia"
        ? "Download your Video!"
        : "Download your videos!";
    const description =
      type === "photos"
        ? "Click the button below to download these beautiful photos!"
        : type === "slideShow" || type === "youtube"
        ? "Click the button below to download these beautiful SlideShow!"
        : type === "socialMedia"
        ? "Click the button below to download these beautiful Social Media Video!"
        : "Click the button below to download these beautiful videos!";
    const label = type === "photos" ? " High Resolution" : "Branded";
    const label1 = type === "photos" ? " Low Resolution" : "Unbranded";
    // const label2 = type === "socialMedia" ? "Download" : null;
    return (
      <div>
        <Row>
          <Col>
            <h3>{title}</h3>
            <p>{description}</p>
            {mode !== "socialMedia" ? (
              <Tooltip placement="top" title={`Suitable for Print`}>
                <DownloadButton
                  media={media}
                  selectedShoot={selectedShoot}
                  type="high"
                  mediaType={type}
                  label={label}
                  isDisabled={
                    type === "photos"
                      ? media.photos &&
                        (media.photos.gallery.length === 0 || denyMediaAccess)
                      : type === "slideShow" || type === "youtube"
                      ? media.slideShow &&
                        (media.slideShow.s3Links.length === 0 ||
                          denyMediaAccess)
                      : media.video &&
                        (media.video.s3Links.length === 0 || denyMediaAccess)
                  }
                />
              </Tooltip>
            ) : null}
            {mode !== "socialMedia" ? (
              <Tooltip placement="top" title={`Suitable for Web`}>
                <DownloadButton
                  media={media}
                  selectedShoot={selectedShoot}
                  type="low"
                  mediaType={type}
                  label={label1}
                  isDisabled={
                    type === "photos"
                      ? media.photos &&
                        (media.photos.gallery.length === 0 || denyMediaAccess)
                      : type === "slideShow" || type === "youtube"
                      ? media.slideShow &&
                        (media.slideShow.s3Links.length === 0 ||
                          denyMediaAccess)
                      : media.video &&
                        (media.video.s3Links.length === 0 || denyMediaAccess)
                  }
                />
              </Tooltip>
            ) : null}
            {selectedClient?.preferences?.media?.resize && (
              <div className="mt-3">
                {mode === "photos" && (
                  <CopyToClipboard
                    text={photosDropboxLink}
                    onCopy={() => message.info("Link copied to clipboard")}
                  >
                    <Button
                      type="primary"
                      size="small"
                      disabled={!media?.photos?.dropBoxLink}
                    >
                      <DropboxOutlined /> Copy Dropbox Link
                    </Button>
                  </CopyToClipboard>
                )}
              </div>
            )}
            {mode === "socialMedia" ? (
              <>
                <CopyToClipboard
                  text={dropboxLink}
                  onCopy={() => message.info("Link copied to clipboard")}
                >
                  <Button
                    type="primary"
                    className="mt-4"
                    disabled={
                      !media?.socialMedia?.dropBoxLinks ||
                      media?.socialMedia?.dropBoxLinks?.length === 0
                    }
                  >
                    {" "}
                    <DropboxOutlined /> Go to Dropbox
                  </Button>
                </CopyToClipboard>
                <a
                  href={`${media?.socialMedia?.dropBoxLinks[0]?.url?.replace(
                    "dl=0",
                    "dl=1"
                  )}`}
                >
                  <Button
                    type="secondary"
                    className="mt-4 float-right"
                    disabled={
                      !media?.socialMedia?.dropBoxLinks ||
                      media?.socialMedia?.dropBoxLinks?.length === 0
                    }
                  >
                    <DownloadOutlined /> Download Now
                  </Button>
                </a>
              </>
            ) : // <Tooltip placement="top" title={`Suitable for Web`}>
            //   <DownloadButton
            //     media={media}
            //     selectedShoot={selectedShoot}
            //     type="low"
            //     mediaType={type}
            //     label={label2}
            //     isDisabled={!media.socialMedia}
            //   />
            // </Tooltip>
            null}
          </Col>
        </Row>
        <Divider />
      </div>
    );
  };

  const renderActions = (type, video) => {
    return video && video.length !== 0 ? (
      <Col span={24} align="middle">
        <Spin
          spinning={
            type === "Unbranded"
              ? unbrandedVideoLoader
              : type === "Branded"
              ? brandedVideoLoader
              : socialVideoLoader
          }
        >
          <Button
            icon={<DeleteOutlined />}
            onClick={() =>
              type === "youtube"
                ? handleChangeVideoDelete(video[0], type)
                : handleChangeSocialVideoDelete(video[0], type)
            }
            danger
          >
            Delete {type === "socialMedia" ? "video" : type}
          </Button>
        </Spin>
      </Col>
    ) : (
      <Col span={24} align="middle">
        <Spin
          spinning={
            type === "Unbranded"
              ? unbrandedVideoLoader
              : type === "Branded"
              ? brandedVideoLoader
              : socialVideoLoader
          }
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            disabled={
              type === "Unbranded"
                ? newUnbrandedVideo === null
                : type === "Branded"
                ? newBrandedVideo === null
                : newSocialVideo === null
            }
            onClick={() =>
              type === "Unbranded"
                ? uploadUnbrandedVideo("Unbranded")
                : type === "socialMedia"
                ? uploadSocialVideo("socialMedia")
                : uploadBrandedVideo("Branded")
            }
          >
            Upload {type === "socialMedia" ? "Video" : type}
          </Button>
        </Spin>
      </Col>
    );
  };

  const renderDescription = (video) => {
    if (video && video.length !== 0) {
      return video[0].description;
    } else {
      return mode === "video"
        ? selectedShoot?.video?.package
        : mode === "socialMedia"
        ? ""
        : "YoutubeSlideShow";
    }
  };
  const renderPlayer = (type, video) => {
    if (video && video.length !== 0) {
      return (
        <ReactPlayer
          url={video[0].url}
          width="100%"
          height="100%"
          controls={true}
        />
      );
    } else {
      let url;
      if (type === "Branded") {
        url = newBrandedVideo;
      } else if (type === "Unbranded") {
        url = newUnbrandedVideo;
      } else {
        url = newSocialVideo;
      }
      return url ? (
        <ReactPlayer url={url} width="100%" height="100%" controls={true} />
      ) : ability.can("edit", "Video") || ability.can("edit", "Social") ? (
        <Empty
          onClick={
            type === "Branded"
              ? handleOpenFileInput
              : type === "socialMedia"
              ? handleOpenFileInput2
              : handleOpenFileInput1
          }
          style={{ border: "1px dashed", margin: "0px" }}
          image={<PlusOutlined className="emptyImg" />}
          description={
            <Col span={24} align="middle">
              <Typography.Text type="secondary">
                Select the file to upload the video
              </Typography.Text>
            </Col>
          }
        >
          <input
            ref={
              type === "Branded"
                ? inputRef
                : type === "socialMedia"
                ? inputRef2
                : inputRef1
            }
            accept="video/mp4"
            type="file"
            name="file"
            style={{ display: "none" }}
            onChange={(e) => {
              type === "Branded"
                ? setVideoBrandedFile(e.target.files[0])
                : type === "socialMedia"
                ? setSocialMediaVideo(e.target.files[0])
                : setVideoUnbrandedFile(e.target.files[0]);
              if (type === "Branded") {
                setState({
                  ...state,
                  newBrandedVideo: URL.createObjectURL(e.target.files[0]),
                });
              } else if (type === "Unbranded") {
                setState({
                  ...state,
                  newUnbrandedVideo: URL.createObjectURL(e.target.files[0]),
                });
              } else {
                setState({
                  ...state,
                  newSocialVideo: URL.createObjectURL(e.target.files[0]),
                });
              }
            }}
          />
        </Empty>
      ) : (
        <Row justify="space-around" align="middle">
          <Col span={24} align="middle" style={{ fontSize: "16px" }}>
            <SyncOutlined style={{ color: "green", marginRight: 3 }} spin />

            <Typography.Text type="secondary">Processing...</Typography.Text>
          </Col>
        </Row>
      );
    }
  };
  const renderVideo = (type) => {
    const video = media.video?.s3Links.filter((video) => video.type === type);
    return (
      <Card
        cover={<>{renderPlayer(type, video)}</>}
        actions={[
          <>
            <Can do="edit" on="Video">
              {renderActions(type, video)}
            </Can>
          </>,
        ]}
      >
        <Meta
          title={type === "socialMedia" ? "" : type}
          description={renderDescription(video)}
        />
      </Card>
    );
  };

  const renderSlideShowVideo = (type) => {
    const video = media.slideShow?.s3Links.filter(
      (video) => video.type === type
    );
    return (
      <Card
        cover={<>{renderPlayer(type, video)}</>}
        actions={[
          <>
            <Can do="edit" on="Video">
              {renderActions(type, video)}
            </Can>
          </>,
        ]}
      >
        <Meta
          title={type === "socialMedia" ? "" : type}
          description={renderDescription(video)}
        />
      </Card>
    );
  };
  // const renderSocialVideo = (type) => {
  //   const socialVideo = media.socialMedia?.s3Links.filter(
  //     (socialVideo) => socialVideo.type === type
  //   );
  //   return (
  //     <Card
  //       cover={<>{renderPlayer(type, socialVideo)}</>}
  //       actions={[
  //         <>
  //           <Can do="edit" on="Social">
  //             {renderActions(type, socialVideo)}
  //           </Can>
  //         </>,
  //       ]}
  //     ></Card>
  //   );
  // };
  const renderViewMedia = (mode) => {
    return (
      mode !== "socialMedia" && (
        <div>
          <Row style={{ marginTop: "30px" }}>
            <Col>
              <h3>View &amp; share your media online </h3>
              {renderMediaLinks(mode)}

              <div className="mt-4">
                <h4>
                  Branding Info{" "}
                  <Tooltip placement="top" title="Edit Branding Info">
                    <Button
                      style={{ marginLeft: "5px" }}
                      size="small"
                      type="link"
                      onClick={() => setState({ ...state, visible: true })}
                    >
                      <EditOutlined /> Edit
                    </Button>
                  </Tooltip>
                </h4>
                <div>
                  <HomeOutlined /> &nbsp;&nbsp;
                  {`${media.property_info.street}, ${media.property_info.city}, ${media.property_info.state} ${media.property_info.zip}`}
                </div>
                <div>
                  <UserOutlined /> &nbsp;&nbsp;
                  {`${media.branding.agent_info.name}`}
                </div>
                <div>
                  <MailOutlined /> &nbsp;&nbsp;
                  {`${media.branding.agent_info.email}`}
                </div>
                <div>
                  <PhoneOutlined /> &nbsp;&nbsp;
                  {`${media.branding.agent_info.phone}`}
                </div>
                {media.branding?.brokerage_info?.name && (
                  <div>
                    <BankOutlined /> &nbsp;&nbsp;
                    {`${brandingVal.name}`}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const options = {
    settings: {
      overlayColor: "#333333",
      autoplaySpeed: 3000,
      transitionSpeed: 1200,
    },
    buttons: {
      backgroundColor: "#2f80ed",
      iconColor: "#FFFFFF",
      showDownloadButton: false,
    },
  };

  const mediaTab = (tab) => {
    let selectedTab = tab;
    switch (mode) {
      case "video":
        selectedTab = "video";
        break;
      case "youtube":
        selectedTab = "youtube";
        break;
      case "socialMedia":
        selectedTab = "socialMedia";
        break;
      case "photos":
        selectedTab = "gallery";
        break;
      default:
        selectedTab = "photos";
    }
    return selectedTab;
  };

  const renderMediaLinks = (mode) => {
    return (
      <Row justify="center" style={{ marginTop: "10px" }}>
        <Col span={12}>
          <div>
            <strong>Unbranded</strong>
          </div>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="small"
                style={{ marginTop: "10px" }}
                disabled={
                  (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                    selectedShoot?.media?.photos?.status !== READY) &&
                  (selectedShoot?.media?.video?.status !== NOT_AVL ||
                    selectedShoot?.media?.video?.status !== READY) &&
                  (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                    selectedShoot?.media?.slideShow?.status !== READY) &&
                  (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                    selectedShoot?.media?.dollHouse?.status !== READY) &&
                  denyMediaAccess
                }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${
                      selectedShoot.hsn
                    }/${
                      selectedShoot.address.street_lower_no_spaces
                    }?tab=${mediaTab(mode)}`,
                    "_blank"
                  );
                }}
              >
                {`${
                  mode === "slideShow" || mode === "youtube"
                    ? "Slideshow"
                    : toTitleCase(mode)
                } Only`}
              </Button>
              <CopyToClipboard
                text={`${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${
                  selectedShoot.hsn
                }/${
                  selectedShoot.address.street_lower_no_spaces
                }?tab=${mediaTab(mode)}`}
                onCopy={() => message.info("Link copied to clipboard")}
              >
                <Button
                  type="link"
                  disabled={
                    (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                      selectedShoot?.media?.photos?.status !== READY) &&
                    (selectedShoot?.media?.video?.status !== NOT_AVL ||
                      selectedShoot?.media?.video?.status !== READY) &&
                    (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                      selectedShoot?.media?.slideShow?.status !== READY) &&
                    (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                      selectedShoot?.media?.dollHouse?.status !== READY) &&
                    denyMediaAccess
                  }
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                size="small"
                style={{ marginTop: "10px" }}
                disabled={
                  (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                    selectedShoot?.media?.photos?.status !== READY) &&
                  (selectedShoot?.media?.video?.status !== NOT_AVL ||
                    selectedShoot?.media?.video?.status !== READY) &&
                  (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                    selectedShoot?.media?.slideShow?.status !== READY) &&
                  (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                    selectedShoot?.media?.dollHouse?.status !== READY) &&
                  denyMediaAccess
                }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`,
                    "_blank"
                  );
                }}
              >
                All Media
              </Button>
              <CopyToClipboard
                text={`${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`}
                onCopy={() => message.info("Link copied to clipboard")}
              >
                <Button
                  type="link"
                  disabled={
                    (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                      selectedShoot?.media?.photos?.status !== READY) &&
                    (selectedShoot?.media?.video?.status !== NOT_AVL ||
                      selectedShoot?.media?.video?.status !== READY) &&
                    (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                      selectedShoot?.media?.slideShow?.status !== READY) &&
                    (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                      selectedShoot?.media?.dollHouse?.status !== READY) &&
                    denyMediaAccess
                  }
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div>
            <strong>Branded</strong>
          </div>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="small"
                style={{ marginTop: "10px" }}
                disabled={
                  (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                    selectedShoot?.media?.photos?.status !== READY) &&
                  (selectedShoot?.media?.video?.status !== NOT_AVL ||
                    selectedShoot?.media?.video?.status !== READY) &&
                  (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                    selectedShoot?.media?.slideShow?.status !== READY) &&
                  (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                    selectedShoot?.media?.dollHouse?.status !== READY) &&
                  denyMediaAccess
                }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_DOMAIN_URL}/media/b/${
                      selectedShoot.hsn
                    }/${
                      selectedShoot.address.street_lower_no_spaces
                    }?tab=${mediaTab(mode)}`,
                    "_blank"
                  );
                }}
              >
                {`${
                  mode === "slideShow" || mode === "youtube"
                    ? "Slideshow"
                    : mode === "socialMedia"
                    ? "socialMedia"
                    : toTitleCase(mode)
                } Only`}
              </Button>
              <CopyToClipboard
                text={`${process.env.REACT_APP_DOMAIN_URL}/media/b/${
                  selectedShoot.hsn
                }/${
                  selectedShoot.address.street_lower_no_spaces
                }?tab=${mediaTab(mode)}`}
                onCopy={() => message.info("Link copied to clipboard")}
              >
                <Button
                  type="link"
                  disabled={
                    (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                      selectedShoot?.media?.photos?.status !== READY) &&
                    (selectedShoot?.media?.video?.status !== NOT_AVL ||
                      selectedShoot?.media?.video?.status !== READY) &&
                    (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                      selectedShoot?.media?.slideShow?.status !== READY) &&
                    (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                      selectedShoot?.media?.dollHouse?.status !== READY) &&
                    denyMediaAccess
                  }
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                size="small"
                style={{ marginTop: "10px" }}
                disabled={
                  (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                    selectedShoot?.media?.photos?.status !== READY) &&
                  (selectedShoot?.media?.video?.status !== NOT_AVL ||
                    selectedShoot?.media?.video?.status !== READY) &&
                  (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                    selectedShoot?.media?.slideShow?.status !== READY) &&
                  (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                    selectedShoot?.media?.dollHouse?.status !== READY) &&
                  denyMediaAccess
                }
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_DOMAIN_URL}/media/b/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`,
                    "_blank"
                  );
                }}
              >
                All Media
              </Button>
              <CopyToClipboard
                text={`${process.env.REACT_APP_DOMAIN_URL}/media/b/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`}
                onCopy={() => message.info("Link copied to clipboard")}
              >
                <Button
                  type="link"
                  disabled={
                    (selectedShoot?.media?.photos?.status !== NOT_AVL ||
                      selectedShoot?.media?.photos?.status !== READY) &&
                    (selectedShoot?.media?.video?.status !== NOT_AVL ||
                      selectedShoot?.media?.video?.status !== READY) &&
                    (selectedShoot?.media?.slideShow?.status !== NOT_AVL ||
                      selectedShoot?.media?.slideShow?.status !== READY) &&
                    (selectedShoot?.media?.dollHouse?.status !== NOT_AVL ||
                      selectedShoot?.media?.dollHouse?.status !== READY) &&
                    denyMediaAccess
                  }
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          xs={24}
          sm={24}
          md={mode === "amenity" ? 24 : 16}
          lg={mode === "amenity" ? 24 : 16}
          xl={mode === "amenity" ? 24 : 16}
        >
          {denyMediaAccess && (
            <Alert
              message={
                <div>
                  <a
                    href={`/admin/invoices/${selectedShoot.invoice[0]?._id}`}
                    style={{ color: "#1890ff" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Please pay your invoice
                  </a>{" "}
                  to access your media.
                </div>
              }
              style={{ marginBottom: 10 }}
              banner
            />
          )}
          {mode === "photos" ? (
            <div>
              <div>
                {
                  <Card
                    title={
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "center" }}
                      >
                        Your Photos
                      </Typography.Title>
                    }
                  >
                    {selectedClient?.preferences?.media?.resize && (
                      <>
                        {!auth?.user?.access.includes("CLIENT") && (
                          <Alert
                            message="Client has requested custom photo sizes. Please check the notes section for more information."
                            type="warning"
                            showIcon
                          />
                        )}
                        <div className="mt-3">
                          <strong>
                            {!auth?.user?.access.includes("CLIENT")
                              ? "Paste"
                              : "Copy"}{" "}
                            Dropbox Link Below:
                          </strong>
                        </div>
                        <Input
                          value={photosDropboxLink}
                          onChange={(e) => setPhotosDropboxLink(e.target.value)}
                          placeholder="Dropbox Link"
                          style={{ width: "100%" }}
                          disabled={
                            auth?.user?.access.includes("CLIENT") ||
                            !auth?.isAuthenticated
                          }
                        />
                        {auth?.user?.access.includes("CLIENT") ? (
                          <CopyToClipboard
                            text={photosDropboxLink}
                            onCopy={() =>
                              message.info("Link copied to clipboard")
                            }
                          >
                            <Button
                              type="primary"
                              className="mt-4"
                              disabled={!media?.photos?.dropBoxLink}
                            >
                              {" "}
                              <DropboxOutlined /> Copy Dropbox Link
                            </Button>
                          </CopyToClipboard>
                        ) : (
                          <Button
                            className="mt-4"
                            type="primary"
                            onClick={() => handlePhotosDropboxLink()}
                            disabled={auth?.user?.access.includes("CLIENT")}
                          >
                            Save Link
                          </Button>
                        )}
                        <Divider />
                      </>
                    )}
                    <div
                      className={`contentBody ${
                        denyMediaAccess ? "overlay" : ""
                      }`}
                    >
                      <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                          <Row gutter={[8, 8]} className="p-2" key="a">
                            {updatedPic && updatedPic.length
                              ? updatedPic.map((image, i) => (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    key={image.url}
                                  >
                                    <a href={image.url} data-attribute="SRL">
                                      <img
                                        src={image.url}
                                        width="100%"
                                        alt={image.url}
                                      />
                                    </a>
                                    <div>
                                      <span className="ellipsis">
                                        {image.filename}
                                      </span>
                                      {ability.can("delete", "Photos") && (
                                        <span className="close-btn">
                                          <Popconfirm
                                            title={`Are you sure you want to delete this image`}
                                            onConfirm={() =>
                                              handleChangeDelete(image)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              type="link"
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </span>
                                      )}
                                      {ability.can("manage", "Photos") && (
                                        <span className="close-btn">
                                          <Popconfirm
                                            title={`Are you sure you want to delete this image`}
                                            onConfirm={() =>
                                              handleChangeDelete(image)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              type="link"
                                              danger
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                ))
                              : state.existingPhotos.map((image, i) => (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    key={image.url}
                                  >
                                    <a href={image.url} data-attribute="SRL">
                                      <img
                                        src={image.thumbUrl}
                                        width="100%"
                                        alt={image.filename}
                                      />
                                    </a>
                                    <div>
                                      <span>{image.filename}</span>
                                      {ability.can("delete", "Photos") && (
                                        <span className="close-btn">
                                          <Popconfirm
                                            title={`Are you sure you want to delete this image`}
                                            onConfirm={() =>
                                              handleChangeDelete(image)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              type="link"
                                              danger
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </span>
                                      )}
                                      {ability.can("manage", "Photos") && (
                                        <EditRequestModal
                                          imageUrl={image?.url}
                                          fileName={image?.filename}
                                          shootId={selectedShoot?._id}
                                          address={
                                            selectedShoot?.address?.street
                                          }
                                          hsn={selectedShoot?.hsn}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                ))}
                          </Row>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </div>

                    {state.existingPhotos.length ? (
                      <Row>
                        <Col
                          span={12}
                          offset={6}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            type="primary"
                            icon={<RetweetOutlined />}
                            style={{ marginTop: "10px", marginRight: "25px" }}
                            onClick={() => showDrawer()}
                            disabled={denyMediaAccess}
                          >
                            Arrange
                          </Button>
                          {ability.can("manage", "Photos") && (
                            <Popconfirm
                              title={`Are you sure you want to delete ALL images`}
                              onConfirm={() => handleDeleteAll()}
                              okText="Yes"
                              cancelText="No"
                              disabled={
                                !invoice?.paid &&
                                (!auth?.isAuthenticated ||
                                  auth?.user?.access.includes("CLIENT"))
                              }
                            >
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                style={{ marginTop: "10px" }}
                              >
                                Delete All
                              </Button>
                            </Popconfirm>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                  </Card>
                }
                <Drawer
                  title={
                    <Typography.Title level={4} style={{ textAlign: "center" }}>
                      Re-order Photos
                    </Typography.Title>
                  }
                  placement={`top`}
                  closable={true}
                  onClose={onClose}
                  visible={drawerVisible}
                  key={`placement`}
                  height={500}
                >
                  <DragDrop
                    onClose={onClose}
                    photos={existingPhotos}
                    updatedOrder={(arr) =>
                      setState({ ...state, existingPhotos: arr })
                    }
                    saveHandler={async () => {
                      const newOrderedPhotos = [];
                      const exPhts = [...existingPhotos];
                      exPhts.forEach((obj, index) => {
                        obj.order = index;
                        newOrderedPhotos.push(obj.url);
                      });
                      const res = await updateMedia(media._id, {
                        photos: {
                          ...photos,
                          gallery: newOrderedPhotos,
                        },
                      });
                      if (res.error) {
                        message.error(
                          "Oops ! Something went wrong while updating"
                        );
                      } else {
                        setState({
                          ...state,
                          existingPhotos: exPhts,
                        });
                        message.success("Order updated successfully");
                        onClose();
                      }
                    }}
                  />
                </Drawer>
              </div>
              <Can do="upload" on="Photos">
                <div style={{ paddingTop: "20px" }}>
                  <Card
                    title={
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "center" }}
                      >
                        Upload New Photos
                      </Typography.Title>
                    }
                  >
                    <div className="contentBody">
                      <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                          <Row gutter={[8, 8]} className="p-2" key="b">
                            {state.newPhotos.map((image) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={6}
                                lg={6}
                                xl={6}
                                key={image.url}
                              >
                                <a href={image.url} data-attribute="SRL">
                                  <img src={image.url} width="50%" alt="" />
                                </a>
                                <div>
                                  <span className="close-btn">
                                    <Button
                                      type="link"
                                      danger
                                      icon={<DeleteOutlined />}
                                      onClick={() => handleDelete(image)}
                                    />
                                  </span>
                                </div>
                              </Col>
                            ))}

                            <label className="ant-upload-text">
                              <PlusOutlined className="icon-style" />
                              Add
                              <Upload
                                fileList={selectedFiles}
                                multiple
                                beforeUpload={() => false}
                                showUploadList={false}
                                onChange={handleChange}
                              />
                            </label>
                          </Row>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </div>
                    <Row>
                      <Col span={12} offset={6} style={{ textAlign: "center" }}>
                        {" "}
                        <Button
                          type="primary"
                          icon={<UploadOutlined />}
                          style={{ marginTop: "10px" }}
                          onClick={handleUpload}
                          disabled={state.newPhotos.length === 0}
                        >
                          {" "}
                          Upload
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                    width={900}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </Can>
            </div>
          ) : mode === "amenity" &&
            (auth?.user?.access.includes("ADMIN") ||
              auth?.user?.access.includes("COMMS") ||
              auth?.user?.access.includes("EDITOR") ||
              auth?.user?.access.includes("EDITOR") ||
              auth?.user?.access.includes("DELUXE")) &&
            (selectedShoot.reqs.includes("SP") ||
              selectedShoot.reqs.includes("MSP") ||
              selectedShoot.reqs.includes("CSP")) ? (
            <div>
              <div>
                {
                  <Card
                    title={
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "center" }}
                      >
                        Amenities Photos
                      </Typography.Title>
                    }
                  >
                    <div
                      className={`contentBody ${
                        denyMediaAccess ? "overlay" : ""
                      }`}
                    >
                      <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                          <Row gutter={[8, 8]} className="p-2">
                            {updatedPic
                              ? updatedPic.map((image, i) => (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={4}
                                    lg={4}
                                    xl={3}
                                    key={image.url}
                                    className="imageCard"
                                  >
                                    <div className="showImage">
                                      <a href={image.url} data-attribute="SRL">
                                        <img
                                          src={image.url}
                                          width="100%"
                                          alt={image.url}
                                        />
                                      </a>
                                    </div>
                                    <Row justify="space-between" align="middle">
                                      <span
                                        className="ellipsis"
                                        style={{ width: "80%" }}
                                      >
                                        {image.filename}
                                      </span>
                                      {ability.can("delete", "Amenity") && (
                                        <span>
                                          <Popconfirm
                                            title={`Are you sure you want to delete this image`}
                                            onConfirm={() =>
                                              handleChangeDelete(image)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              type="link"
                                              danger
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </span>
                                      )}
                                    </Row>
                                  </Col>
                                ))
                              : state.existingPhotos.map((image, i) => (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={4}
                                    lg={4}
                                    xl={3}
                                    key={image.url}
                                    className="imageCard"
                                  >
                                    <div className="showImage">
                                      <a href={image.url} data-attribute="SRL">
                                        <img
                                          src={image?.thumbUrl}
                                          width="100%"
                                          alt={image.filename}
                                        />
                                      </a>
                                    </div>
                                    <Row
                                      justify="space-between"
                                      align="middle"
                                      style={{ marginLeft: "5px" }}
                                    >
                                      <span
                                        className="ellipsis"
                                        style={{ width: "80%" }}
                                      >
                                        {image.filename}
                                      </span>
                                      {ability.can("delete", "Amenity") && (
                                        <span>
                                          <Popconfirm
                                            title={`Are you sure you want to delete this image`}
                                            onConfirm={() =>
                                              handleChangeDelete(image)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              type="link"
                                              danger
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        </span>
                                      )}
                                    </Row>
                                  </Col>
                                ))}
                          </Row>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </div>
                  </Card>
                }
              </div>
              <div>
                {
                  <Card
                    title={
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "center" }}
                      >
                        Amenity Photos
                      </Typography.Title>
                    }
                  >
                    <div>
                      <Alert
                        message={
                          <span>
                            <strong>Client Requested:</strong>{" "}
                            {requestedAmenity
                              ?.map((amenity) => amenity.name)
                              .join(", ")}
                          </span>
                        }
                      />
                    </div>
                    <Col
                      sm={24}
                      md={24}
                      xl={24}
                      xs={{ span: 6, offset: 8 }}
                      lg={{ span: 6, offset: 8 }}
                    >
                      <h4
                        style={{
                          marginBottom: 30,
                          marginTop: 30,
                          marginLeft: 65,
                        }}
                      >
                        Select amenity before uploading photos below
                      </h4>

                      <Select
                        style={{
                          display: "grid",
                          width: "50%",
                          marginBottom: "50px",
                        }}
                        onChange={handleAmenity}
                        value={selectedAmenityId}
                      >
                        {amenities.map((opt) => (
                          <Select.Option key={opt?._id} value={opt?._id}>
                            {opt?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>

                    <div
                      className={`contentBody ${
                        denyMediaAccess ? "overlay" : ""
                      }`}
                    >
                      <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                          <Row gutter={[8, 8]} className="p-2">
                            {amenityPhotos.existingPhotos.map((image) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={4}
                                lg={4}
                                xl={3}
                                key={image.url}
                                className="imageCard"
                              >
                                <div className="showImage">
                                  <Row justify="center" align="middle">
                                    <a href={image.url} data-attribute="SRL">
                                      <img
                                        src={image.url}
                                        width="100%"
                                        alt={image.filename}
                                      />
                                    </a>
                                  </Row>
                                  <Button
                                    disabled={photos.amenityPhotos.length === 5}
                                    onClick={() => imageUpload(image)}
                                  >
                                    <PlusOutlined />
                                  </Button>
                                </div>

                                <Row justify="space-between" align="middle">
                                  <span
                                    className="ellipsis"
                                    style={{ width: "80%" }}
                                  >
                                    {image.filename}
                                  </span>
                                  {ability.can("delete", "Photos") && (
                                    <span>
                                      <Popconfirm
                                        title={`Are you sure you want to delete this image`}
                                        onConfirm={() =>
                                          handleChangeAmenityDelete(image.url)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button
                                          type="link"
                                          danger
                                          icon={<DeleteOutlined />}
                                        />
                                      </Popconfirm>
                                    </span>
                                  )}
                                </Row>
                              </Col>
                            ))}
                          </Row>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </div>
                  </Card>
                }
              </div>
              <Can do="upload" on="Photos">
                <div style={{ paddingTop: "20px" }}>
                  <Card
                    title={
                      <Typography.Title
                        level={4}
                        style={{ textAlign: "center" }}
                      >
                        Upload or Select Amenity Photos
                      </Typography.Title>
                    }
                  >
                    <div
                      className={`contentBody ${
                        denyMediaAccess ? "overlay" : ""
                      }`}
                    >
                      <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                          <Row gutter={[8, 8]} className="p-2">
                            {amenityPhotos?.photos?.map((image) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={4}
                                lg={4}
                                xl={3}
                                key={image.url}
                                className="imageCard"
                              >
                                <div className="showImage">
                                  <a href={image.url} data-attribute="SRL">
                                    <img src={image.url} width="100%" alt="" />
                                  </a>
                                </div>
                                <Row justify="space-between" align="middle">
                                  <span
                                    className="ellipsis"
                                    style={{ width: "80%" }}
                                  >
                                    {image?.originFileObj?.name}
                                  </span>
                                  {ability.can("delete", "Amenity") && (
                                    <span className="close-btn">
                                      <Button
                                        type="link"
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() =>
                                          handleAmenityDelete(image)
                                        }
                                      />
                                    </span>
                                  )}
                                </Row>
                              </Col>
                            ))}

                            <label className="ant-upload-text">
                              <PlusOutlined className="icon-style" />
                              Add
                              <Upload
                                fileList={selectedAmenityFiles}
                                multiple
                                accept=".png,.jpeg,.jpg"
                                beforeUpload={() => false}
                                showUploadList={false}
                                onChange={handleAmenityChange}
                              />
                            </label>
                          </Row>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </div>
                    <Row>
                      <Col span={12} offset={6} style={{ textAlign: "center" }}>
                        <Spin spinning={amenityLoader}>
                          <Button
                            type="primary"
                            icon={<UploadOutlined />}
                            style={{ marginTop: "10px" }}
                            onClick={handleAmenityUpload}
                            disabled={amenityPhotos.photos.length === 0}
                          >
                            Upload
                          </Button>
                        </Spin>
                      </Col>
                    </Row>
                  </Card>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                    width={900}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </Can>
            </div>
          ) : mode === "slideShow" || mode === "youtube" ? (
            <div className={`contentBody ${denyMediaAccess ? "overlay" : ""}`}>
              <Row>
                <Col span={12}>
                  <Card title={null}>{renderSlideShowVideo("Branded")}</Card>
                </Col>
                <Col span={12}>
                  <Card title={null}>{renderSlideShowVideo("Unbranded")}</Card>
                </Col>
              </Row>
            </div>
          ) : mode === "socialMedia" ? (
            <div className={`contentBody ${denyMediaAccess ? "overlay" : ""}`}>
              <Row gutter={12}>
                <Col span={10}>
                  <Card title={null}>
                    <h3>Preview</h3>
                    {media?.socialMedia?.dropBoxLinks[0]?.url ? (
                      <div>
                        <ReactPlayer
                          url={`${media?.socialMedia?.dropBoxLinks[0]?.url}&raw=1`}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    ) : (
                      <p className="text-muted">
                        Waiting for the Dropbox link to the video
                      </p>
                    )}
                  </Card>
                </Col>
                <Col span={14}>
                  <Card title={null}>
                    <div>
                      <strong>
                        {ability.can("edit", "Social") ? "Paste" : "Copy"}{" "}
                        Dropbox Link Below:
                      </strong>
                    </div>
                    <Input
                      value={dropboxLink}
                      onChange={(e) => setDropboxLink(e.target.value)}
                      placeholder="Dropbox Link"
                      style={{ width: "100%" }}
                      disabled={
                        auth?.user?.access.includes("CLIENT") ||
                        !auth?.isAuthenticated
                      }
                    />
                    {auth?.user?.access.includes("CLIENT") ? (
                      <CopyToClipboard
                        text={dropboxLink}
                        onCopy={() => message.info("Link copied to clipboard")}
                      >
                        <Button
                          type="primary"
                          className="mt-4"
                          disabled={
                            media?.socialMedia?.dropBoxLinks &&
                            media?.socialMedia?.dropBoxLinks.length === 0
                          }
                        >
                          {" "}
                          <DropboxOutlined /> Copy Dropbox Link
                        </Button>
                      </CopyToClipboard>
                    ) : (
                      <Button
                        className="mt-4"
                        type="primary"
                        onClick={() => handleSocialDropboxLink()}
                        disabled={auth?.user?.access.includes("CLIENT")}
                      >
                        Save Link
                      </Button>
                    )}
                  </Card>
                  {selectedShoot?.booking_form?.social_media_links && (
                    <Card className="mt-3" title="Tag the Client">
                      <Row gutter={12}>
                        <Col span={24}>
                          <Row>
                            <Col flex="auto">
                              <span className="text-muted">
                                https://www.facebook.com/
                              </span>
                              <strong>
                                {
                                  selectedShoot?.booking_form
                                    ?.social_media_links?.facebook
                                }
                              </strong>
                              <CopyToClipboard
                                text={
                                  selectedShoot?.booking_form
                                    ?.social_media_links?.facebook
                                }
                                onCopy={() =>
                                  message.info("Link copied to clipboard")
                                }
                              >
                                <Button type="link" className="mt-4">
                                  {" "}
                                  <FacebookOutlined /> Copy
                                </Button>
                              </CopyToClipboard>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row>
                            <Col flex="auto">
                              <span className="text-muted">
                                https://www.instagram.com/
                              </span>
                              <strong>
                                {
                                  selectedShoot?.booking_form
                                    ?.social_media_links?.instagram
                                }
                              </strong>
                              <CopyToClipboard
                                text={
                                  selectedShoot?.booking_form
                                    ?.social_media_links?.instagram
                                }
                                onCopy={() =>
                                  message.info("Link copied to clipboard")
                                }
                              >
                                <Button type="link" className="mt-4">
                                  {" "}
                                  <InstagramOutlined /> Copy
                                </Button>
                              </CopyToClipboard>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div className={`contentBody ${denyMediaAccess ? "overlay" : ""}`}>
              <Row gutter={12}>
                <Col span={12}>
                  <Row>
                    <Col span={24} className="mb-3">
                      <Card title={null}>
                        {media?.video?.dropboxBranded &&
                          media?.video?.dropboxBranded !== "" && (
                            <div>
                              <ReactPlayer
                                url={`${media?.video?.dropboxBranded}&raw=1`}
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </div>
                          )}
                        {auth?.isAuthenticated && (
                          <>
                            <div>
                              <strong>
                                {ability.can("edit", "Video")
                                  ? "Paste "
                                  : "Copy "}{" "}
                                Branded Dropbox Link Below:
                              </strong>
                            </div>
                            <Input
                              value={brandedVideoDropboxLink}
                              onChange={(e) =>
                                setBrandedVideoDropboxLink(e.target.value)
                              }
                              placeholder="Dropbox Link"
                              style={{ width: "100%" }}
                              disabled={
                                auth?.user?.access.includes("CLIENT") ||
                                !auth?.isAuthenticated
                              }
                            />
                          </>
                        )}
                        <Row justify="space-between">
                          <Col span={12}>
                            {auth?.user?.access.includes("CLIENT") ? (
                              <a
                                href={brandedVideoDropboxLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  type="primary"
                                  className="mt-4"
                                  disabled={
                                    (media?.video?.dropboxBranded &&
                                      media?.video?.dropboxBranded === "") ||
                                    denyMediaAccess
                                  }
                                >
                                  <DropboxOutlined /> Take Me to Dropbox
                                </Button>
                              </a>
                            ) : (
                              auth?.isAuthenticated && (
                                <Button
                                  className="mt-4"
                                  type="primary"
                                  onClick={() =>
                                    handleVideoDropboxLinks(
                                      "Branded",
                                      brandedVideoDropboxLink
                                    )
                                  }
                                  disabled={auth?.user?.access.includes(
                                    "CLIENT" || denyMediaAccess
                                  )}
                                >
                                  {`${
                                    media?.video?.dropboxBranded &&
                                    media?.video?.dropboxBranded !== ""
                                      ? "Update"
                                      : "Save"
                                  } Link`}
                                </Button>
                              )
                            )}
                          </Col>
                          {media?.video?.dropboxBranded &&
                            media?.video?.dropboxBranded !== "" && (
                              <Col span={12}>
                                <a
                                  href={`${media?.video?.dropboxBranded?.replace(
                                    "dl=0",
                                    "dl=1"
                                  )}`}
                                >
                                  <Button
                                    type="secondary"
                                    className="mt-4 float-right"
                                    disabled={denyMediaAccess}
                                  >
                                    <DownloadOutlined /> Download
                                  </Button>
                                </a>
                              </Col>
                            )}
                        </Row>
                      </Card>
                    </Col>

                    {!brandedVideoDropboxLink && (
                      <Col span={24}>
                        <Card title={null}>{renderVideo("Branded")}</Card>
                        <div className="pl-3 pr-3">
                          {brandedVideoLoader && (
                            <Progress percent={uploadBrandedPercentage} />
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24} className="mb-3">
                      <Card title={null}>
                        {media?.video?.dropboxUnbranded &&
                          media?.video?.dropboxUnbranded !== "" && (
                            <div>
                              <ReactPlayer
                                url={`${media?.video?.dropboxUnbranded}&raw=1`}
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </div>
                          )}
                        {auth?.isAuthenticated && (
                          <>
                            <div>
                              <strong>
                                {ability.can("edit", "Video")
                                  ? "Paste "
                                  : "Copy "}
                                Unbranded Dropbox Link Below:
                              </strong>
                            </div>
                            <Input
                              value={unbrandedVideoDropboxLink}
                              onChange={(e) =>
                                setUnbrandedVideoDropboxLink(e.target.value)
                              }
                              placeholder="Dropbox Link"
                              style={{ width: "100%" }}
                              disabled={
                                auth?.user?.access.includes("CLIENT") ||
                                !auth?.isAuthenticated
                              }
                            />
                          </>
                        )}

                        <Row justify="space-between">
                          <Col span={12}>
                            {" "}
                            {auth?.user?.access.includes("CLIENT") ? (
                              <a
                                href={unbrandedVideoDropboxLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  type="primary"
                                  className="mt-4"
                                  disabled={
                                    (media?.video?.dropboxUnbranded &&
                                      media?.video?.dropboxUnbranded === "") ||
                                    denyMediaAccess
                                  }
                                >
                                  <DropboxOutlined /> Take Me to Dropbox
                                </Button>
                              </a>
                            ) : (
                              auth?.isAuthenticated && (
                                <Button
                                  className="mt-4"
                                  type="primary"
                                  onClick={() =>
                                    handleVideoDropboxLinks(
                                      "Unbranded",
                                      unbrandedVideoDropboxLink
                                    )
                                  }
                                  disabled={auth?.user?.access.includes(
                                    "CLIENT"
                                  )}
                                >
                                  {`${
                                    media?.video?.dropboxUnbranded &&
                                    media?.video?.dropboxUnbranded !== ""
                                      ? "Update"
                                      : "Save"
                                  } Link`}
                                </Button>
                              )
                            )}
                          </Col>
                          {media?.video?.dropboxUnbranded &&
                            media?.video?.dropboxUnbranded !== "" && (
                              <Col span={12}>
                                <a
                                  href={`${media?.video?.dropboxUnbranded?.replace(
                                    "dl=0",
                                    "dl=1"
                                  )}`}
                                >
                                  <Button
                                    type="secondary"
                                    className="mt-4 float-right"
                                    disabled={denyMediaAccess}
                                  >
                                    <DownloadOutlined /> Download
                                  </Button>
                                </a>
                              </Col>
                            )}
                        </Row>
                      </Card>
                    </Col>

                    {!unbrandedVideoDropboxLink && (
                      <Col span={24}>
                        <Card title={null}>{renderVideo("Unbranded")}</Card>
                        <div className="pl-3 pr-3">
                          {unbrandedVideoLoader && (
                            <Progress percent={uploadUnbrandedPercentage} />
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          {mode !== "photos" && mode !== "amenity" && mode !== "socialMedia" ? (
            <Can do="edit" on="Video">
              <Row>
                <Col span={12} align="middle">
                  <Input
                    value={youtubeBranded.url}
                    onChange={(e) => updateYTUrl(e, "Branded")}
                    placeholder="YouTube Branded Url"
                    style={{ marginTop: "40px", width: "90%" }}
                  />
                </Col>
                <Col span={12} align="middle">
                  <Input
                    value={youtubeUnbranded.url}
                    onChange={(e) => updateYTUrl(e, "Unbranded")}
                    placeholder="YouTube Unbranded Url"
                    style={{ marginTop: "40px", width: "90%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} align="middle">
                  <div style={{ marginTop: "40px" }}>
                    <Spin spinning={loader}>
                      <Button type="primary" onClick={updateYoutube}>
                        Update
                      </Button>
                    </Spin>
                  </div>
                </Col>
              </Row>
            </Can>
          ) : null}
        </Col>
        {mode === "amenity" ? null : (
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Can do="create" on="Ratings">
              {renderRatingWidget(true)}
              <Divider />
            </Can>
            <Can do="upload" on="Photos">
              {renderReady(mode)}
            </Can>
            {renderDownloadMedia(mode)}
            {media && Object.keys(media).length && renderViewMedia(mode)}
            <Can do="read" on="Ratings">
              <Divider />
              {renderRatingWidget(false)}
            </Can>
            <Modal
              title={
                <Typography.Title style={{ textAlign: "center" }} level={4}>
                  EDIT TOUR
                </Typography.Title>
              }
              visible={visible}
              onCancel={handleCancelModal}
              footer={null}
              style={{ top: 20 }}
            >
              <VirtualTourInfo
                submitHandler={submitHandler}
                brokerages={brokerages}
                close={handleCancelModal}
                initVal={brandingVal}
              />
            </Modal>
          </Col>
        )}
      </Row>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,
            backgroundColor: "rgba(0,0,0,.5)",
          }}
        >
          <Progress
            type="circle"
            percent={circularPercentage}
            strokeColor={"#f14b65"}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    amenities: state.amenities.amenities,
    selectedClient: state.clients.selectedClient,
  };
};
export default connect(mapStateToProps, {
  updateAmenity,
  updateAmenities,
  fetchAmenityBySubdivisionId,
})(VirtualTour);
