import React, { useState, useEffect } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  hasUnpaidInvoice,
  hasDeferredTerms,
} from "../../../../../shared/utils/utils";

const PreviewMedia = ({
  shoot,
  handleOpenDrawer,
  history,
  photos,
  clientProfile,
}) => {
  const [photoCount, setPhotoCount] = useState(0);
  const [photosReady, setPhotosReady] = useState(false);
  const [videosReady, setVideosReady] = useState(false);
  const [slideshowReady, setSlideshowReady] = useState(false);
  const [dollhouseReady, setDollhouseReady] = useState(false);

  useEffect(() => {
    setPhotoCount(shoot?.media?.photos?.gallery?.length);
    setPhotosReady(shoot?.media?.photos?.status === "Ready");
    setVideosReady(shoot?.media?.video?.status === "Ready");
    setSlideshowReady(shoot?.media?.slideShow?.status === "Ready");
    setDollhouseReady(shoot?.media?.dollHouse?.status === "Ready");
  }, [shoot]); // eslint-disable-line react-hooks/exhaustive-deps

  const previewThumbnails = [
    photos?.gallery[0],
    photos?.gallery[1],
    photos?.gallery[2],
  ];
  return (
    <Row justify="space-between">
      <Col flex="140px">
        {shoot?.status === "Processing" && (
          <span style={{ fontSize: 16, color: "#777" }}>
            <LoadingOutlined /> In progress
          </span>
        )}

        {photosReady && photoCount > 0 && (
          <>
            <Avatar.Group>
              {previewThumbnails.map((imgUrl) => (
                <Avatar
                  key={imgUrl}
                  shape="square"
                  src={imgUrl}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/admin/shoots/${shoot._id}?tab=tour`)
                  }
                />
              ))}
            </Avatar.Group>
            <span className="avatar-title">
              {" "}
              +{photoCount - previewThumbnails?.length}
            </span>
          </>
        )}
      </Col>
      <Col flex="auto">
        <div className="float-right">
          {!hasUnpaidInvoice(shoot?.invoice) ||
          hasDeferredTerms(clientProfile?.preferences?.billing?.terms) ? (
            <Button
              onClick={handleOpenDrawer}
              style={{ margin: "5px" }}
              size="small"
              disabled={
                !photosReady &&
                !videosReady &&
                !slideshowReady &&
                !dollhouseReady
              }
            >
              <DownloadOutlined /> Quick Download
            </Button>
          ) : (
            <Button
              style={{ color: "green", border: "solid 1px green" }}
              size="small"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_DOMAIN_URL}/admin/invoices/${shoot?.invoice?.[0]._id}`,
                  "_blank"
                );
              }}
            >
              Pay Invoice
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default PreviewMedia;
