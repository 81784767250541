import axios from "axios";
import {
    FETCH_AMENITIES_BY_SUBDIVISION_ID,
    FETCH_AMENITIES_BY_ID,
    REMOVE_AMENITY,
    CREATE_AMENITY,
    UPDATE_AMENITY,
    RESET_SELECTED_AMENITY,
    UPDATE_AMENITIES,
} from "./types";

export const createAmenity = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/amenity`,
            formData
        );
        if (res.status === 200) {
            dispatch({
                type: CREATE_AMENITY,
                payload: res.data,
            });
        }
        return { data: res.data, error: null };
    } catch (err) {
        console.log(err);
    }
};

export const updateAmenity = (id, formData) => async (dispatch) => {
    try {
        const res = await axios.patch(
            `${process.env.REACT_APP_ROOT_URL}/amenity/${id}`,
            formData
        );
        dispatch({
            type: UPDATE_AMENITY,
            payload: res.data,
        });
        return { data: res.data, error: null };
    } catch (err) {
        console.log(err.message);
    }
};

export const updateAmenities = (amenity) => (dispatch) => {
    dispatch({
        type: UPDATE_AMENITIES,
        payload: amenity,
    });
};

export const deleteAmenity = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/amenity/${id}`
        );
        if (res.status === 200) {
            dispatch({
                type: REMOVE_AMENITY,
                payload: id,
            });
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err);
    }
};

export const fetchAmenityBySubdivisionId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_ROOT_URL}/amenity/subdivision/${id}`
        );
        dispatch({
            type: FETCH_AMENITIES_BY_SUBDIVISION_ID,
            payload: res.data,
        });
        return { data: res.data, error: null };
    } catch (err) {
        console.log(err.message);
    }
};

export const fetchAmenityById = (id) => async (dispatch) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_ROOT_URL}/amenity/${id}`
        );
        dispatch({
            type: FETCH_AMENITIES_BY_ID,
            payload: res.data,
        });

        return { data: res.data, error: null };
    } catch (err) {
        console.log(err.message);
    }
};

export const fetchAmenityByIds = async (ids) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_ROOT_URL}/amenity/ids?id=${ids.join(",")}`
        );
        return { data: res.data, error: null };
    } catch (err) {
        console.log(err.message);
    }
};

export const resetAmenity = () => (dispatch) => {
    dispatch({
        type: RESET_SELECTED_AMENITY,
        payload: undefined,
    });
};

