import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchClientByUserId,
  updateClient,
  fetchPaymentMethods,
  savePaymentMethod,
  removePaymentMethod,
} from "../../../redux/action/clientAction";
import { Row, Col, Tabs, Button } from "antd";
import Loading from "../../../shared/loading/Loading.js";

import ProfileForm from "./ProfileForm";
import BrokerageForm from "../Brokerage/BrokerageForm";
import { useState } from "react";
import {
  createBrokerage,
  updateBrokerage,
} from "../../../redux/action/brokerageAction";
import ProfileSummary from "./ProfileSummary";
import PaymentMethods from "../Clients/PaymentMethods";

const { TabPane } = Tabs;

const Profile = ({
  fetchClientByUserId,
  createBrokerage,
  updateBrokerage,
  updateClient,
  fetchPaymentMethods,
  removePaymentMethod,
  savePaymentMethod,
  auth: { user, isAuthenticated },
  client: { clientProfile, paymentMethods, isLoading },
}) => {
  const [showBrokerage, setShowBrokerage] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchClientByUserId(user?._id);

      // const fetchData = async () => {
      //   await fetchPaymentMethods(clientProfile?._id);
      // };
      // fetchData();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientProfile) {
      console.log(clientProfile);
      fetchPaymentMethods(clientProfile?._id);
    }
  }, [clientProfile]);

  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const reqBody = { ...body, team: [clientProfile._id] };
      const res = await createBrokerage(reqBody);
      if (res.data) {
        updateClient(clientProfile._id, {
          ...clientProfile,
          user: clientProfile.user._id,
          brokerage: res.data._id,
        });
      }
      return res;
    } else {
      const res = await updateBrokerage(body._id, body);
      return res;
    }
  };

  return (
    <React.Fragment>
      <div>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clientProfile && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <ProfileSummary selectedClient={clientProfile} />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
              style={{ margin: "10px" }}
            >
              <div className="card-container">
                <Tabs type="card" size="large">
                  <TabPane tab="Profile" key="1">
                    <ProfileForm
                      clientProfile={clientProfile}
                      entity="client"
                    />
                  </TabPane>
                  <TabPane tab="Brokerage" key="2">
                    {clientProfile.brokerage ? (
                      <BrokerageForm
                        initVal={clientProfile.brokerage}
                        mode="register"
                        submitHandler={(values) =>
                          submitHandler("edit", values)
                        }
                      />
                    ) : !showBrokerage ? (
                      <div>
                        You are not associated with a Brokerage.{" "}
                        <Button
                          type="ghost"
                          style={{ border: "none" }}
                          onClick={() => setShowBrokerage(true)}
                        >
                          <strong>Add your Brokerage.</strong>
                        </Button>
                      </div>
                    ) : (
                      <BrokerageForm
                        initVal={{}}
                        mode="register"
                        submitHandler={(values) => submitHandler("Add", values)}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="Payment Methods" key="Payment-Methods">
                    <PaymentMethods
                      selectedClient={clientProfile}
                      cards={paymentMethods}
                      savePaymentMethod={savePaymentMethod}
                      removePaymentMethod={removePaymentMethod}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  client: state.clients,
});

export default connect(mapStateToProps, {
  fetchClientByUserId,
  createBrokerage,
  updateBrokerage,
  updateClient,
  fetchPaymentMethods,
  savePaymentMethod,
  removePaymentMethod,
})(Profile);
