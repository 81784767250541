import axios from "axios";
import {
  FETCH_ALL_FLYERS,
  FETCH_FLYER_BY_ID,
  UPDATE_FLYER,
  CREATE_FLYER,
  REMOVE_FLYER,
} from "./types";

export const fetchFlyers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/flyers`);
    dispatch({
      type: FETCH_ALL_FLYERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchFlyerByShootId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/flyers/shoot/${id}`
    );
    dispatch({
      type: FETCH_FLYER_BY_ID,
      payload: res.data,
    });

    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const createFlyer = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const brokerage = {
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      features: [],
      website: "",
      tagLine: "",
      logo_url: "",
    };
    if (formData.brokerage) {
      const brokRes = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/brokerages/id/${formData.brokerage}`
      );
      if (brokRes.data) {
        Object.assign(brokerage, brokRes.data);
      }
    }
    const flyer = {
      template: "",
      headline: "",
      property: {
        street: formData.booking_form.street,
        city: formData.booking_form.city,
        state: "",
        zip: formData.booking_form.zip_code,
        description: "",
        features: [],
        price: formData.booking_form.list_price,
        sqft: formData.booking_form.sqft,
      },
      agent: {
        name: formData.client_name_first + " " + formData.client_name_last,
        email: formData.client_email,
        phone: formData.client_phone,
        headshot_url: "",
      },
      brokerage,
      photos: [],
      shoot: formData._id,
      client: formData.client_id,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/flyers`,
      flyer,
      config
    );
    dispatch({
      type: CREATE_FLYER,
      payload: res.data,
    });
    return res;
  } catch (err) {}
};

export const updateFlyer = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/flyers/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_FLYER,
      payload: response.data,
    });

    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const removeFlyer = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/flyers/${id}`
    );

    dispatch({
      type: REMOVE_FLYER,
      payload: id,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
