import React, { useState } from "react";
import { Select, Row, Col, Spin, Button, message, Switch } from "antd";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";

const { Option } = Select;

const CreditForm = ({
  mode,
  initVal,
  clients,
  close,
  submitHandler,
  clientCreditDetails,
  auth,
}) => {
  const [state, setState] = useState({ loader: false });
  const { loader } = state;
  const [isRedeemed, setIsRedeemed] = useState(initVal?.redeemed);
  const handleSwitchChange = (checked) => {
    setIsRedeemed(checked);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        const res = await submitHandler({ ...values, redeemed: isRedeemed });
        if (res.status === 200) {
          mode === "Add"
            ? message.success("Credit created successfully")
            : message.success("Credit updated successfully!");
        } else {
          message.error("Oops! Error occured while updating credit.");
        }
        setState({ ...state, loader: false });
        close(res.data);
      }}
      render={({ handleSubmit, setFieldValue }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                {initVal.client._id &&
                !auth?.user?.access?.includes("ADMIN") ? (
                  <Col span={24}>
                    <strong>Client</strong>
                    <Select
                      defaultValue={initVal.client.full}
                      style={{ width: "100%", padding: "5px" }}
                      placeholder="Select Client"
                      onChange={(value) => setFieldValue("client", value)}
                      name="client._id"
                      disabled
                    >
                      {clients
                        ?.filter((cli) => cli._id === initVal.client._id)
                        ?.map((clt) => (
                          <Select.Option key={clt?._id} value={clt?._id}>
                            {clt?.full}
                          </Select.Option>
                        ))}
                      {clients?.map((clt) => (
                        <Select.Option key={clt?._id} value={clt?._id}>
                          {clt?.full}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                ) : (
                  <Col span={24}>
                    <strong>Client</strong>
                    <Select
                      defaultValue={initVal.client._id}
                      style={{ width: "100%", padding: "5px" }}
                      placeholder="Select Client"
                      onChange={(value) => setFieldValue("client", value)}
                      name="client._id"
                    >
                      {clients?.map((clt) => (
                        <Select.Option key={clt?._id} value={clt?._id}>
                          {clt?.full}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col span={12}>
                  <div>
                    <strong>Type of Credit</strong>
                  </div>
                  <Select
                    defaultValue={initVal.credit_type}
                    style={{ marginTop: "15px", width: "100%" }}
                    onChange={(value) => setFieldValue("credit_type", value)}
                  >
                    <Option value="Incidental">Incidental</Option>
                    <Option value="Promo">Promo</Option>
                    <Option value="Referral">Referral</Option>
                    <Option value="Refund">Refund</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Redemption Code"
                    name="code"
                    className="form-group"
                  >
                    <Input name="code" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    className="form-group"
                  >
                    <Input type="number" name="amount" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Redeemed"
                    name="redeemed"
                    className="form-group"
                  >
                    {isRedeemed ? "Yes" : "No"}{" "}
                    <Switch
                      checked={isRedeemed}
                      onChange={(checked) => handleSwitchChange(checked)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Reason for the Credit"
                    name="reason"
                    className="form-group"
                  >
                    <Input name="reason" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default CreditForm;
