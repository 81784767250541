import React, { useState } from "react";
import { Row, Col, Avatar, Button, Tooltip, Modal } from "antd";
import {
  EditOutlined,
  HomeOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { upload } from "../../../../../shared/s3Bucket/s3Service.js";
import VirtualTourInfo from "../../../Shoots/VirtualTour/VirtualTourInfo";

const Branding = ({ media, hsf, updateMedia, updateShoot, shoot }) => {
  const [visible, setVisible] = useState(false);

  const handleCancelModal = () => setVisible(false);
  let brandingVal = {};
  let brokerages = [];

  brandingVal = {
    street: media?.property_info.street,
    city: media?.property_info.city,
    state: media?.property_info.state,
    hsf,
    zip: media?.property_info.zip,
    name: media?.branding.agent_info.name,
    email: media?.branding.agent_info.email,
    phone: media?.branding.agent_info.phone,
    headshot_url: media?.branding?.agent_info?.headshot,
    brokerage: media?.branding?.brokerage_info?.logo,
  };

  const submitHandler = async (data) => {
    const {
      name,
      email,
      phone,
      headshot_url,
      brokerage,
      street,
      city,
      state,
      zip,
    } = data;
    const property_info = {
      street,
      city,
      state,
      zip,
    };
    const agent_info = {
      name,
      email,
      phone,
      headshot: headshot_url,
    };
    const brokerage_info = {
      logo: brokerage,
    };
    if (
      typeof agent_info.headshot === "object" &&
      typeof brokerage_info.logo === "object"
    ) {
      const [agent, brokerage] = await Promise.all([
        upload(agent_info.headshot),
        upload(brokerage_info.logo),
      ]);
      agent_info.headshot = agent[0];
      brokerage_info.logo = brokerage[0];
    } else {
      if (typeof agent_info.headshot === "object") {
        const imagesUrl = await upload(agent_info.headshot);
        agent_info.headshot = imagesUrl[0];
      }
      if (typeof brokerage_info.logo === "object") {
        const imagesUrl = await upload(brokerage_info.logo);
        brokerage_info.logo = imagesUrl[0];
      }
    }
    const res = await updateMedia(media._id, {
      ...media,
      property_info,
      branding: {
        agent_info,
        brokerage_info,
      },
    });
    updateShoot({
      ...shoot,
      media: res.data,
    });
    return res;
  };

  const renderTooltipContent = () => {
    return (
      <div>
        <div>Displayed on the branded tours</div>
        <img
          src="https://homesnappers-comms.s3.us-east-2.amazonaws.com/public/branding-example-header.png"
          width="100%"
          alt="branding example"
        />
      </div>
    );
  };
  return (
    <Row>
      <Col flex="45px" className="mr-2">
        {media?.branding?.agent_info?.headshot ? (
          <Avatar size={65} src={media?.branding?.agent_info?.headshot} />
        ) : (
          <Avatar
            size={65}
            style={{
              color: "#FFFFFF",
              backgroundColor: "#333333",
            }}
          >
            <span style={{ fontSize: "30px" }}>
              {" "}
              {media?.branding?.agent_info?.name.charAt(0)}
            </span>
          </Avatar>
        )}
      </Col>
      <Col flex="auto">
        <span className="float-right">
          <Tooltip placement="top" title={renderTooltipContent()}>
            <Button
              style={{ marginLeft: "5px" }}
              size="small"
              type="link"
              onClick={() => setVisible(true)}
            >
              <EditOutlined /> Edit Branding
            </Button>
          </Tooltip>
        </span>
        <div>
          <HomeOutlined /> &nbsp;&nbsp;
          {`${media.property_info.street}, ${media.property_info.city}, ${media.property_info.state} ${media.property_info.zip}`}
        </div>
        <div>
          <UserOutlined /> &nbsp;&nbsp;
          {`${media.branding.agent_info.name}`}
        </div>
        <div>
          <MailOutlined /> &nbsp;&nbsp;
          {`${media.branding.agent_info.email}`}
        </div>
        <div>
          <PhoneOutlined /> &nbsp;&nbsp;
          {`${media.branding.agent_info.phone}`}
        </div>
        {/* <div style={{ color: "#112350", fontWeight: 600, fontSize: 16 }}>
          {media?.branding?.agent_info?.name}
        </div>
        <div className="avatar-desc">{media?.branding?.agent_info?.phone}</div>
        <div className="avatar-desc">{media?.branding?.agent_info?.email}</div> */}
      </Col>
      <Modal
        visible={visible}
        onCancel={handleCancelModal}
        footer={null}
        style={{ top: 20 }}
        width={600}
      >
        <VirtualTourInfo
          submitHandler={submitHandler}
          brokerages={brokerages}
          close={handleCancelModal}
          initVal={brandingVal}
        />
      </Modal>
    </Row>
  );
};

export default Branding;
