import React, { useState, useEffect } from "react";
import { Popover, Progress, Timeline } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { toTitleCase } from "../../../../shared/utils/utils";
import ability from "../../../../user/Component/Auth/ability";

import "./ProgressChecklist.scss";

const ProgressChecklist = ({ checklist, shootId, updateShoot }) => {
  const [checklistState, setChecklistState] = useState(checklist);
  useEffect(() => {
    const itemsCount = Object.keys(checklistState).length;
    const completeCount = Object.keys(checklistState).filter(
      (k) => checklistState[k] === true
    );
    const percent = (completeCount.length / itemsCount) * 100;
    setPercentDone(percent.toFixed(0));
  }, [checklistState]);

  const [percentDone, setPercentDone] = useState(0);

  const handleClick = async (targetKey, value) => {
    const updatedValue = value === true ? false : true;
    const updatedChecklist = {
      checklist: { ...checklistState, [targetKey]: updatedValue },
    };
    const res = await updateShoot(shootId, updatedChecklist);

    setChecklistState(res.data.checklist);
  };

  const content = (
    <div className="timeline">
      <Timeline>
        {Object.entries(checklistState).map(([key, value]) =>
          ability.can("check", "Checklist") ? (
            <Timeline.Item
              dot={<CheckCircleFilled style={{ fontSize: "22px" }} />}
              color={value === true ? "green" : "gray"}
              key={key}
              onClick={() => handleClick(key, value)}
            >
              <span className={value === true ? "text-muted" : ""}>
                {toTitleCase(key)}
              </span>
            </Timeline.Item>
          ) : (
            <Timeline.Item
              dot={<CheckCircleFilled style={{ fontSize: "22px" }} />}
              color={value === true ? "green" : "gray"}
              key={key}
            >
              <span className={value === true ? "text-muted" : ""}>
                {toTitleCase(key)}
              </span>
            </Timeline.Item>
          )
        )}
      </Timeline>
    </div>
  );

  return (
    <div className="progress-checklist">
      <div>
        <Progress type="circle" percent={percentDone} width={35} />
      </div>
      <div className="label">
        <Popover content={content} title="Progress" trigger="click">
          <a href="!#">View Progress</a>
        </Popover>
      </div>
    </div>
  );
};

export default ProgressChecklist;
