import axios from "axios";
import {
  FETCH_SUBDIVISION,
  FETCH_SUBDIVISIONS_TABLEVIEW,
  FETCH_SUBDIVISION_BY_ID,
  UPDATE_SUBDIVISION,
  REMOVE_SUBDIVISION,
  CREATE_SUBDIVISION,
} from "./types";

export const createSubdivision = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/division`,
      formData
    );
    dispatch({
      type: CREATE_SUBDIVISION,
      payload: res.data,
    });
    return { data: res.data, error: null };
  } catch (err) {
    console.log(err);
  }
};

export const deleteSubdivision = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/division/${id}`
    );
    if (res.status === 200) {
      dispatch({
        type: REMOVE_SUBDIVISION,
        payload: id,
      });

      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateSubdivision = (id, formData) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/division/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_SUBDIVISION,
      payload: res.data,
    });
    return { data: res.data, error: null };
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchSubdivision = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/division`);
    dispatch({
      type: FETCH_SUBDIVISION,
      payload: res.data,
    });
    return { data: res.data, error: null };
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchSubdivisionsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/division/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );
    dispatch({
      type: FETCH_SUBDIVISIONS_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchSubdivisionById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/division/${id}`
    );
    dispatch({
      type: FETCH_SUBDIVISION_BY_ID,
      payload: res.data,
    });

    return { data: res.data, error: null };
  } catch (err) {
    console.log(err.message);
  }
};
