import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Divider, Spin } from "antd";
import {
  Input,
  Form,
  Button,
  Select,
  Avatar,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import {
  QuestionCircleOutlined,
  StarTwoTone,
  TeamOutlined,
} from "@ant-design/icons";
import {
  updateTeam,
  fetchTeam,
  createTeam,
} from "../../../redux/action/teamAction";
import { fetchClients } from "../../../redux/action/clientAction";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../Teams/teams.scss";

const { Meta } = Card;
const TeamForm = ({
  createTeam,
  updateTeam,
  data,
  clients,
  handleCancel,
  fetchClients,
  isClient,
  auth: { user },
  mode,
}) => {
  useEffect(() => {
    fetchClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [formData, setFormData] = useState({
    _id: data?._id ? data._id : null,
    hst: data?.hst ? data.hst : null,
    name: data?.name ? data.name : null,
    owner: data?.owner ? data.owner : isClient ? clients.clientProfile._id : "",
    members: data?.members
      ? data?.members
      : isClient
      ? [
          {
            client: clients.clientProfile,
            owner: true,
            notifications: "ALL",
          },
        ]
      : [],
    requestedEmail: [],
  });
  const [addFormData, setAddFormData] = useState({
    hst: data?.hst ? data.hst : null,
    name: data?.name ? data.name : null,
    owner: data?.owner ? data.owner : isClient ? clients.clientProfile._id : "",
    members: data?.members
      ? data?.members
      : isClient
      ? [
          {
            client: clients.clientProfile,
            owner: true,
            notifications: "ALL",
          },
        ]
      : [],
    requestedEmail: [],
  });

  let clientOwner =
    typeof addFormData.owner === "string"
      ? addFormData.owner
      : addFormData.owner._id;
  const [spinner, setSpinner] = useState(false);
  const { _id, name, owner, members } = formData;
  const onChange = (type, value) => {
    if (type === "team_name") {
      setFormData({ ...formData, name: value });
      setAddFormData({ ...addFormData, name: value });
    } else if (type === "member" && user.access?.includes("CLIENT")) {
      let selected = [];
      data.members.map((mem) =>
        value.map((val) => (val === mem._id ? selected.push(mem) : ""))
      );
      setFormData({
        ...formData,
        owner: data.owner,
        members: selected,
      });
      setAddFormData({
        ...addFormData,
        owner: data.owner,
        members: selected,
      });
    } else if (type === "member" && user.access?.includes("ADMIN")) {
      setFormData({ ...formData, members: value });
      setAddFormData({ ...addFormData, members: value });
    } else {
      if (!isClient) {
        const resettedClient = clients.clients.find(
          (client) => client._id === value
        );
        const adddedClientLeader = [
          ...addFormData.members,
          {
            client: value._id ? value : resettedClient,
            owner: true,
            notifications: "ALL",
          },
        ];
        const clientLeader = [
          ...formData.members,
          {
            client: value._id ? value : resettedClient,
            owner: true,
            notifications: "ALL",
          },
        ];
        setFormData({
          ...formData,
          owner: resettedClient,
          members: clientLeader,
        });
        setAddFormData({
          ...addFormData,
          owner: resettedClient,
          members: adddedClientLeader,
        });
      } else {
        setFormData({ ...formData, owner: value });
        setAddFormData({ ...addFormData, owner: value });
      }
    }
  };

  const formItemLayout = () => ({
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  });

  const formItemLayoutWithOutLabel = () => ({
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  });

  const teamMailIdChange = (e, index, type) => {
    const arr = formData.requestedEmail;
    const addArr = addFormData.requestedEmail;
    type === "delete" ? arr.splice(index, 1) : (arr[index] = e.target.value);
    type === "delete"
      ? addArr.splice(index, 1)
      : (addArr[index] = e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let addCondition1 = false;
    let addCondition2 = false;
    addCondition1 = formData.members.some(
      (data) => data.client._id === formData.owner._id
    );
    addCondition2 = addFormData.members.some(
      (data) => data.client._id === clientOwner
    );
    let adddedLeader;
    let adddedClientLeader;
    let ownerFirst = formData.members;
    if (!addCondition1) {
      adddedLeader = [
        {
          client: formData.owner,
          owner: true,
          notifications: "ALL",
        },
      ];
      ownerFirst.map((data) => adddedLeader.push(data));
    } else {
      const position = ownerFirst.findIndex(
        (data) => data.client._id === clientOwner
      );
      let ownerPosition = [ownerFirst[position]];
      ownerFirst.splice(position, 1);
      ownerFirst.map((data) => ownerPosition.push(data));
      const reformedMembers = ownerPosition;
      adddedLeader = reformedMembers;
      setFormData({ ...formData, members: reformedMembers });
    }
    if (!addCondition2) {
      adddedClientLeader = [
        ...addFormData.members,
        {
          client: addFormData.owner,
          owner: true,
          notifications: "ALL",
        },
      ];
    } else {
      if (
        addFormData.members.length === 1 &&
        addFormData.members[0].client._id === clientOwner
      ) {
        adddedClientLeader = addFormData.members;
      }
    }
    const updatedValue = { ...formData, members: adddedLeader };
    const addedUpdatedValue = { ...addFormData, members: adddedClientLeader };
    let team;
    setSpinner(true);
    if (mode === "ADD" && isClient === false) {
      team = await createTeam(updatedValue);
    } else if (mode === "ADD" && isClient === true) {
      team = await createTeam(addedUpdatedValue);
    } else {
      team = await updateTeam(_id, { ...updatedValue });
    }
    setSpinner(false);
    if (team.data) {
      message.success("Team updated successfully!");
      handleCancel();
    } else {
      message.error("Oops !! Something went wrong.");
    }
  };
  return (
    <Form layout="vertical">
      <Spin spinning={spinner}>
        <div className="form-container">
          {/* <Spin spinning={loader}> */}
          <Form>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <h3>
                  <TeamOutlined /> Team Details{" "}
                </h3>
              </Col>
              <Col span={12}>
                <div className="form-group">
                  <Form.Item label="Team Name">
                    <Input
                      name="name"
                      defaultValue={name}
                      onChange={(e) => onChange("team_name", e.target.value)}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="form-group">
                  <Form.Item label="Owner">
                    {user.access?.includes("CLIENT") ? (
                      <Select
                        name="owner"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        style={{ width: "100%" }}
                        onChange={(e) => onChange("owner", e)}
                        disabled={clients.clientProfile._id}
                        defaultValue={clients.clientProfile._id}
                        filterOption={(input, option) => {
                          return (
                            option?.children
                              ?.toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {clients.clients.map((client) => (
                          <Select.Option key={client._id} value={client._id}>
                            <span>
                              {client.headshot_url ? (
                                <Avatar src={client.headshot_url} />
                              ) : (
                                <Avatar
                                  style={{
                                    color: "#FFFFFF",
                                  }}
                                >
                                  {client.full}
                                </Avatar>
                              )}
                            </span>{" "}
                            {client.full}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        name="owner"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        defaultValue={
                          owner.first ? owner.first + " " + owner.last : ""
                        }
                        style={{ width: "100%" }}
                        onChange={(e) => onChange("owner", e)}
                        filterOption={(input, option) => {
                          return (
                            option?.children
                              ?.toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {clients.clients.map((client) => (
                          <Select.Option key={client._id} value={client._id}>
                            <span>
                              {client.headshot_url ? (
                                <Avatar src={client.headshot_url} />
                              ) : (
                                <Avatar
                                  style={{
                                    color: "#FFFFFF",
                                  }}
                                >
                                  {client.full}
                                </Avatar>
                              )}
                            </span>{" "}
                            {client.full}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="form-group">
                  {user.access?.includes("ADMIN") ? (
                    <Select
                      index
                      name="members"
                      showSearch
                      optionFilterProp="children"
                      // style={{ height }}
                      allowClear
                      defaultValue={
                        members.length
                          ? members.map((member) => member.client?._id)
                          : []
                      }
                      mode="multiple"
                      style={{ width: "100%" }}
                      //onChange={(e) => onChange("member", e)}
                      onSelect={(e) => {
                        const latest = [...formData.members];
                        latest.push({
                          client: clients.clients.filter(
                            (client) => client._id === e
                          )[0],
                          owner: false,
                          notifications: "ALL",
                        });
                        setFormData({ ...formData, members: latest });
                      }}
                      onDeselect={(e) => {
                        setFormData({
                          ...formData,
                          members: formData.members.filter(
                            (mem) => mem?.client?._id !== e
                          ),
                        });
                      }}
                      onClear={(e) => {
                        setFormData({ ...formData, members: [] });
                      }}
                      // onSearch={(e) => searchHandler("member", e)}
                      filterOption={(input, option) => {
                        return (
                          option?.children
                            ?.toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {clients.clients.map((client) => (
                        <Select.Option key={client._id} value={client._id}>
                          <span>
                            {client.headshot_url ? (
                              <Avatar src={client.headshot_url} />
                            ) : (
                              <Avatar
                                style={{
                                  color: "#FFFFFF",
                                  // backgroundColor: `${color}`,
                                }}
                              >
                                {client.full}
                              </Avatar>
                            )}
                          </span>{" "}
                          {client.full}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : <>
                      {" "}
                      <Select
                        name="members"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        defaultValue={members.map((member) => member?._id)}
                        mode="multiple"
                        style={{ width: "100%" }}
                        onChange={(e) => onChange("member", e)}
                        filterOption={(input, option) => {
                          return (
                            option?.children
                              ?.toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {members?.map((member) => (
                          <Select.Option key={member?._id} value={member?._id}>
                            <span>
                              {member?.headshot_url ? (
                                <Avatar src={member.headshot_url} />
                              ) : (
                                <Avatar
                                  style={{
                                    color: "#FFFFFF",
                                    // backgroundColor: `${color}`,
                                  }}
                                >
                                  {member?.full}
                                </Avatar>
                              )}
                            </span>{" "}
                            {member?.full}
                          </Select.Option>
                        ))}
                      </Select>
                    </> ? (
                    <>
                      {" "}
                      <Col style={{ paddingTop: "20px" }} span={24}>
                        <Form.Item name="requestedEmail">
                          <Form.List
                            name="requestedEmail"
                            rules={[
                              {
                                type: "email",
                                validator: async (_, names) => {
                                  if (!names || names.length < 2) {
                                    return Promise.reject(
                                      new Error("At least 1 email")
                                    );
                                  }
                                },
                              },
                            ]}
                          >
                            {(fields, { add, remove }, { errors }) => (
                              <>
                                {fields.map((field, index) => (
                                  <Form.Item
                                    onChange={(e) =>
                                      teamMailIdChange(e, index, "add")
                                    }
                                    {...(index === 0
                                      ? formItemLayout
                                      : formItemLayoutWithOutLabel)}
                                    label={index === 0 ? "Email" : ""}
                                    required={false}
                                    key={field.key}
                                  >
                                    <Form.Item
                                      {...field}
                                      validateTrigger={["onChange", "onBlur"]}
                                      rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message:
                                            "Please input client's email or delete this field.",
                                        },
                                      ]}
                                      noStyle
                                    >
                                      <Input
                                        placeholder="email"
                                        style={{ width: "60%" }}
                                        name={`requestedEmail.${index}`}
                                      />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={(e) => {
                                          remove(field.name);
                                          teamMailIdChange(e, index, "delete");
                                        }}
                                      />
                                    ) : null}
                                  </Form.Item>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: "100%" }}
                                    icon={<PlusOutlined />}
                                  >
                                    Add Email
                                  </Button>
                                  <Form.ErrorList errors={errors} />
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                </div>
                {
                  <div className="form-group">
                    <Form.Item>
                      <Card bodyStyle={{ background: "#f3f7ff" }}>
                        <Row justify="start" gutter={[4, 4]}>
                          {formData.members.map((mem) => (
                            <Col
                              xs={24}
                              sm={24}
                              md={8}
                              lg={6}
                              xl={6}
                              key={mem._id}
                            >
                              <Card hoverable>
                                <Row justify="center">
                                  <Col>
                                    {mem.client?.headshot_url ? (
                                      <Avatar
                                        size={100}
                                        src={mem.client.headshot_url}
                                      />
                                    ) : (
                                      <Avatar
                                        size={100}
                                        style={{
                                          color: "#1079c6",
                                          backgroundColor: "#f3f7ff",
                                        }}
                                      >
                                        <span style={{ fontSize: "2.8rem" }}>
                                          {mem?.client?.full?.trim().charAt(0)}
                                        </span>
                                      </Avatar>
                                    )}
                                    {mem.client?._id === clientOwner && (
                                      <div style={{ size: "3.8rem" }}>
                                        {" "}
                                        <StarTwoTone />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Divider />
                                <Row
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  <h4>{mem.client?.full}</h4>
                                </Row>
                                <Meta
                                  description={
                                    <>
                                      <div>
                                        <Row>
                                          <Col>
                                            <span className="mr-1">Owner</span>{" "}
                                            <Tooltip
                                              placement="top"
                                              title="Owners can add/remove team members."
                                            >
                                              <QuestionCircleOutlined />
                                            </Tooltip>
                                          </Col>
                                          <Col>
                                            <Checkbox
                                              className="row-align"
                                              checked={mem.owner}
                                              disabled={
                                                mem.client?._id === clientOwner
                                              }
                                              onChange={(e) => {
                                                const check = e.target.checked;
                                                const latest = [
                                                  ...formData.members,
                                                ];
                                                latest.forEach((val) => {
                                                  if (
                                                    val.client._id ===
                                                    mem.client._id
                                                  ) {
                                                    val.owner = check;
                                                  }
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                      <div>
                                        <Row>
                                          <Col>
                                            <span className="mr-1">Notify</span>{" "}
                                            <Tooltip
                                              placement="top"
                                              title="What type of notifications should this team member receive?"
                                            >
                                              <QuestionCircleOutlined />
                                            </Tooltip>
                                          </Col>
                                          <Col>
                                            <Select
                                              className="row-align"
                                              value={mem.notifications}
                                              style={{
                                                width: "100% !important",
                                              }}
                                              onChange={(notify) => {
                                                const latest = [
                                                  ...formData.members,
                                                ];
                                                latest.forEach((val) => {
                                                  if (
                                                    val.client._id ===
                                                    mem.client._id
                                                  ) {
                                                    val.notifications = notify;
                                                  }
                                                });
                                                setFormData({
                                                  ...formData,
                                                  members: latest,
                                                });
                                              }}
                                            >
                                              <Select.Option value="ALL">
                                                ALL
                                              </Select.Option>
                                              <Select.Option value="BILLING">
                                                INVOICE
                                              </Select.Option>
                                              <Select.Option value="MEDIA">
                                                MEDIA
                                              </Select.Option>
                                            </Select>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  }
                                ></Meta>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Card>
                    </Form.Item>
                  </div>
                }
              </Col>
              <Col span={8}>
                <div className="form-group">
                  <Button
                    type="primary"
                    onClick={(e) => onSubmit(e)}
                    style={{ marginTop: "20px" }}
                  >
                    Save
                  </Button>
                  <Button
                    type="link"
                    onClick={handleCancel}
                    style={{ marginTop: "20px" }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          {/* </Spin> */}
        </div>
      </Spin>
    </Form>
  );
};

const mapStateToProps = ({ clients, auth: { user } }) => {
  return {
    clients,
    auth: { user },
  };
};

export default connect(mapStateToProps, {
  createTeam,
  updateTeam,
  fetchClients,
  fetchTeam,
})(TeamForm);
