import React, { useState } from 'react';
import { Col, Input, InputNumber } from 'antd';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import { setKey } from "react-geocode";

const AddressAutocomplete = ({
    inputFormatter,
    inputParser,
    setListPrice,
    setSqftFootage,
    formData,
    setFormData
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleOnSelect = async (address, placeId) => {
        setIsLoading(true);
        setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

        const results = await geocodeByPlaceId(placeId);
        const addressObject = results[0];

        let street, city, state, zip;
        addressObject.address_components.forEach((component) => {
          if (component.types.includes("street_number")) {
            street = component.long_name;
          } else if (component.types.includes("route")) {
            street = street ? `${street} ${component.long_name}` : component.long_name;
          } else if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("postal_code")) {
            zip = component.long_name;
          }
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          address: {
            ...prevFormData.address,
            street,
            city,
            state,
            zip,
          }
        }));
        setIsLoading(false);
      };

    return (
        <>
            <Col className="mt-4" span={24}>
                <strong>ADDRESS OF PROPERTY BEING PHOTOGRAPHED</strong>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="form-group">
                    <label>Street Address</label>
                    <PlacesAutocomplete
                        value={formData.address.street}
                        onChange={(street_address) =>
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                                address: {
                                    ...prevFormData.address,
                                    street: street_address,
                                },
                            }))
                        }
                        onSelect={handleOnSelect}
                        >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                        }) => (
                            <div>
                            <Input
                                {...getInputProps({
                                placeholder: 'Start typing the address...',
                                className: 'newTextBox',
                                name: "address.street"
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                const style = suggestion.active
                                    ? { fontWeight: 900, cursor: "pointer" }
                                    : null;
                                return (
                                    <div className="auto-suggestions" key={suggestion.placeId}>
                                        <div
                                            {...getSuggestionItemProps(suggestion, { style })}
                                        >
                                            {suggestion.description}
                                        </div>
                                    </div>
                                );
                                })}
                            </div>
                            </div>
                        )}
                        </PlacesAutocomplete>
                    <div className="form-group mt10">
                </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="form-group">
                    <label>Apt/Unit</label>
                    <Input
                        className="newTextBox"
                        placeholder="e.g. 227"
                        name="address.apt"
                        value={formData.address.apt}
                        onChange={(e) =>{
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                            address: {
                                ...prevFormData.address,
                                apt: e.target.value,
                            },
                            }));
                        }}
                        disabled={isLoading}
                    />

                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="form-group">
                    <label>City</label>
                    <Input
                        className="newTextBox"
                        value={formData.address.city}
                        name="address.city"
                        onChange={(e) =>{
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                            address: {
                                ...prevFormData.address,
                                city: e.target.value,
                            },
                            }));
                        }}
                        disabled={isLoading}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="form-group">
                    <label>State</label>
                    <Input
                        value="TX"
                        className="newTextBox"
                        name="address.state"
                        onChange={(e) =>{
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                            address: {
                                ...prevFormData.address,
                                state: e.target.value,
                            },
                            }));
                        }}
                        disabled={isLoading}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <div className="form-group">
                    <label>Zip</label>
                    <Input
                        className="newTextBox"
                        name="address.zip"
                        value={formData.address.zip}
                        onChange={(e) =>
                            setFormData((prevFormData) => ({
                            ...prevFormData,
                            address: {
                                ...prevFormData.address,
                                zip: e.target.value,
                            },
                            }))
                        }
                        disabled={isLoading}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="form-group">
                    <label>Price</label>
                    <InputNumber
                        name="booking_form.list_price"
                        stringMode
                        style={{ width: '100%', paddingTop: '4px', boxShadow: 'none' }}
                        className="newTextBox"
                        placeholder="List Price"
                        formatter={inputFormatter}
                        parser={inputParser}
                        precision={2}
                        onChange={setListPrice}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="form-group">
                    <label>Sqft</label>
                    <InputNumber
                        name="booking_form.sqft"
                        stringMode
                        style={{ width: '100%', paddingTop: '4px', boxShadow: 'none' }}
                        className="newTextBox"
                        placeholder="Sqft"
                        formatter={inputFormatter}
                        parser={inputParser}
                        precision={2}
                        onChange={setSqftFootage}
                    />
                </div>
            </Col>
        </>
    )
};

export default AddressAutocomplete;
