import React from "react";
import axios from "axios";
import { Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";

const DownloadCsvInvoices = ({ clientId }) => {
  const downloadCsv = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/reports/download-invoices-csv?clientId=${clientId}`
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "homesnappers-invoices.csv");
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Button onClick={downloadCsv} type="secondary">
      <CloudDownloadOutlined /> Download CSV
    </Button>
  );
};

export default DownloadCsvInvoices;
