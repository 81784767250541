import React, { useState } from "react";
import { Row, Col, Upload, message, Typography } from "antd";
import { Input, Form } from "formik-antd";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

const Agent = ({ flyer, client, setFieldValue }) => {
  const [state, setState] = useState({
    imageUrl: flyer.agent.headshot_url,
  });
  const { imageUrl } = state;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("Image must smaller than 4MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChange = ({ file }) => {
    const avatar = new FormData();
    avatar.append("path", `clients/photos/high/${client.hsf}`);
    avatar.append("file", file.originFileObj);
    setFieldValue("agent.headshot_url", avatar);
    getBase64(file.originFileObj, (imageUrl) => {
      setState({
        imageUrl,
        loading: false,
      });
    });
    // }
  };

  return (
    <Form initialValues={flyer.agent} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="agent.name" label="Full Name" className="form-group">
            <Input name="agent.name" />
          </Form.Item>
          <Form.Item name="agent.email" label="Email" className="form-group">
            <Input name="agent.email" />
          </Form.Item>
          <Form.Item name="agent.phone" label="Phone" className="form-group">
            <Input name="agent.phone" />
          </Form.Item>
          <Form.Item
            name="agent.headshot_url"
            label="Photo"
            className="form-group"
          >
            <ImgCrop rotate>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
            {flyer.agent.headshot_url ? (
              <Typography.Text>
                Note: Click to replace the photo.
              </Typography.Text>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Agent;
