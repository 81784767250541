import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tag, Card, Table, Col, Row, Input, Button, Modal } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  removeOffering,
  updateOffering,
  fetchOfferingsTableView,
} from "../../redux/action/offeringAction";
import Loading from "../../shared/loading/Loading.js";
import OfferingsForm from "./Offerings/OfferingsForm";
import OfferingsEdit from "./Offerings/OfferingsEdit";
import Can from "../../user/Component/Auth/Can";
import queryString from "query-string";

const Offerings = ({
  removeOffering,
  updateOffering,
  handleCurrentPath,
  fetchOfferingsTableView,
  offering: { offerings, isLoading, totalCount },
}) => {
  const [offering, setOffering] = useState();
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });
  const history = useHistory();
  const { search, skip, limit } = tableOpt;
  const columns = [
    {
      title: "Service",
      width: 350,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (price ? `$${price.toFixed(2)}` : "-"),
      responsive: ["md"],
    },
    {
      title: "Base Pay",
      dataIndex: "pay",
      key: "pay",
      render: (pay) => (pay ? `$${pay.toFixed(2)}` : "-"),
      responsive: ["md"],
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: [
        {
          text: "Drone Photos",
          value: "Residential - Drone Photos",
        },
        {
          text: "Drone Photos & HD Video",
          value: "Residential - Drone Photos & HD Video",
        },
        {
          text: "Interior/Exterior Photos",
          value: "Residential - Interior/Exterior Photos",
        },
        {
          text: "Photo Turnaround",
          value: "Residential - Photo Turnaround",
        },
        {
          text: "Premier Offerings",
          value: "Residential - Premier Offerings",
        },
        {
          text: "Video Turnaround",
          value: "Residential - Video Turnaround",
        },
        {
          text: "Virtual Reality Tour",
          value: "Residential - Virtual Reality Tour",
        },
        {
          text: "Specified Package",
          value: "Residential - Specified Package",
        },
        {
          text: "Discretionary",
          value: "Residential - Discretionary",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
      sortDirections: ["descend"],
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <span>
          {active ? <Tag color="green">active</Tag> : <Tag>disabled</Tag>}
        </span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Can do="edit" on="Offerings">
            <Button
              onClick={() => onShowEdit(record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Offerings">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can>
        </span>
      ),
    },
  ];

  useEffect(() => {
    handleCurrentPath("Offerings", "4");
    fetchOfferingsTableView({ search: "", skip, limit: 10 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const setPageBasedOnParam = () => {
  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchOfferingsTableView();
    };
  }, []);
  // };

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchOfferingsTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  // useEffect(() => {
  //   window.addEventListener("popstate", setPageBasedOnParam);
  //   return () => {
  //     window.removeEventListener("popstate", setPageBasedOnParam);
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setPageBasedOnParam();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
    fetchOfferingsTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  // Modal
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const { confirm } = Modal;

  const showCreateModal = () => {
    setVisible(true);
    setMode("create");
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setVisible(false);
  };

  const onConfirmRemove = (offering) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${offering.description}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeOffering(offering._id);
        console.log("OK");
      },
      onCancel() {
        handleOk();
        console.log("Cancel");
      },
    });
  };

  const onShowEdit = (offering) => {
    setOffering(offering);
    setVisible(true);
    setMode("edit");
  };

  return (
    <div>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && offerings && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <h2>Offerings</h2>
              </Col>
              <Can do="create" on="Offerings">
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <Input.Search
                    placeholder="Search for a service"
                    onChange={searchHandler}
                    style={{ width: "60%", marginBottom: "5px" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                  <Button
                    type="primary"
                    style={{
                      float: "right",
                    }}
                    onClick={showCreateModal}
                  >
                    <PlusOutlined /> Add an Offering
                  </Button>
                </Col>
              </Can>
            </Row>
            <Table
              loading={isLoading}
              dataSource={offerings}
              columns={columns}
              rowKey={(record) => record._id}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
                showSizeChanger: false,
              }}
              onChange={handleTableChange}
              scroll={{ x: 500 }}
            />
          </div>
        )}
      </Card>
      <Modal
        title={<span style={{ color: "white" }}>Add a new offering</span>}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        {mode === "create" ? (
          <OfferingsForm mode={mode} onOk={handleOk} />
        ) : (
          <OfferingsEdit
            offeringData={offering}
            updateOffering={updateOffering}
            onOk={handleOk}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  offering: state.offering,
});

export default connect(mapStateToProps, {
  removeOffering,
  updateOffering,
  fetchOfferingsTableView,
})(Offerings);
