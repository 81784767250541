import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Card,
  Row,
  Col,
  Select,
  Spin,
  DatePicker,
  Upload,
  Typography,
  Modal,
  message,
  Form,
  Input,
} from "antd";

import { SRLWrapper } from "simple-react-lightbox";
import "./styles.scss";
import {
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { upload } from "../../../shared/s3Bucket/s3Service";
import RepairerForm from "./RepairerForm";
import PhotogForm from "./PhotogForm";

const { Option } = Select;
const options = {
  settings: {
    overlayColor: "#333333",
    autoplaySpeed: 3000,
    transitionSpeed: 1200,
  },
  buttons: {
    backgroundColor: "#2f80ed",
    iconColor: "#FFFFFF",
    showDownloadButton: false,
  },
};
const DeviceForm = ({
  initVal,
  submitHandler,
  close,
  mode,
  photographers,
  fetchPhotographerById,
}) => {
  const [state, setState] = useState({
    loader: false,
    imgUrl: initVal.thumbnail,
  });
  const { loader } = state;
  const [purchaseDate, setPurchaseDate] = useState(initVal?.purchase_date);
  const [currentAssignee, setCurrentAssignee] = useState(null);
  const [assigneeType, setAssigneeType] = useState(
    initVal?.current_assignee.assignee_type || "Photographer"
  );
  const [imageUrls, setImageUrls] = useState([
    ...initVal.current_assignee.images,
  ]);
  const [form] = Form.useForm();
  //
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);
  const [formInitValues, setFormInitValues] = useState({
    ...initVal,
    purchase_date: initVal.purchase_date ? moment(initVal.purchase_date) : null,
    current_assignee: {
      ...initVal.current_assignee,
      date_assigned: initVal.current_assignee.date_assigned
        ? moment(initVal.current_assignee.date_assigned)
        : null,
      date_returned: initVal.current_assignee.date_returned
        ? moment(initVal.current_assignee.date_returned)
        : null,
    },
  });

  // const validateRequired = (value) => {
  //   const error = {};
  //   if (!value.description) {
  //     error.description = "Description is required";
  //   }
  //   return error;
  // };

  const handleDateChange = (date) => {
    setPurchaseDate(date);
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const handleChange = async (file, setFieldValue) => {
    form.setFieldsValue({ thumbnail: file.originFileObj });
    const base64Pic = await getBase64Temp(file.originFileObj);
    setState({
      imgUrl: base64Pic,
      loader: false,
    });
  };

  function getBase64Temp(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleImageChange = async ({ fileList }) => {
    setSelectedFiles([...fileList]);
    const newPhts = [];
    for (let index = 0; index < fileList.length; index++) {
      const base64Pic = await getBase64Temp(fileList[index].originFileObj);
      newPhts.push({
        originFileObj: fileList[index].originFileObj,
        url: base64Pic,
        thumbUrl: base64Pic,
        _id: Math.random(),
      });
    }
    setNewPhotos([...newPhts]);
  };

  const handleDelete = async (image) => {
    const filteredData = selectedFiles.filter(
      (file) => file.uid !== image.originFileObj.uid
    );
    setSelectedFiles(filteredData);
    const deletePhotos = newPhotos.filter((target) => target.url !== image.url);
    setNewPhotos([...deletePhotos]);
  };

  const handleImgDelete = (url) => {
    const deletePhotos = imageUrls.filter((target) => target !== url);
    setImageUrls([...deletePhotos]);
  };

  // const props = {
  //   name: "file",
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  const handleAssignTo = (val) => {
    setImageUrls([]);
    if (val === "Repairer") {
      setAssigneeType("Repairer");
    } else {
      setAssigneeType("Photographer");
      const foundPhotog = photographers.find((photog) => photog._id === val);
      // setAssignedPhotog(val);
      const { name, preferred_email, phone, home_address } = foundPhotog;
      const photographer = {
        name,
        email: preferred_email,
        phone,
        address: home_address,
      };
      setCurrentAssignee(photographer);
    }
  };

  const onFinish = async (values) => {
    setState({ ...state, loader: true });
    const current = {
      ...values.current_assignee,
      ...currentAssignee,
      date_assigned: values.current_assignee.date_assigned,
      date_returned: values.current_assignee.date_returned
        ? values.current_assignee.date_returned
        : null,
      assignee_type: assigneeType,
      images: [...imageUrls],
    };
    try {
      let payload;
      if (
        currentAssignee === null ||
        initVal.current_assignee.name === current.name
      ) {
        payload = {
          ...values,
          purchase_date: purchaseDate,
          current_assignee: current,
          assignee_history: [...initVal.assignee_history],
        };
      } else {
        payload = {
          ...values,
          purchase_date: purchaseDate,
          current_assignee: current,
          assignee_history: [
            ...initVal.assignee_history,
            initVal.current_assignee,
          ],
        };
      }
      if (typeof payload.thumbnail === "object") {
        const thumbnailImg = new FormData();
        thumbnailImg.append("path", `devices/high`);
        thumbnailImg.append("file", payload.thumbnail);
        const imagesUrl = await upload(thumbnailImg);
        payload.thumbnail = imagesUrl[0];
      }

      //upload device photos
      if (selectedFiles.length > 0) {
        let data = new FormData();
        data.append("path", `devices/photos/high/`);
        for (var x = 0; x < selectedFiles.length; x++) {
          const file = selectedFiles[x].originFileObj;
          data.append("file", file);
        }
        const uploadUrls = await upload(data);
        payload.current_assignee.images =
          payload.current_assignee.images.concat(uploadUrls);
      }

      const res = await submitHandler(payload);
      if (res.status === 200) {
        mode === "Add"
          ? message.success("Device created successfully")
          : message.success("Device updated successfully!");
      } else {
        message.error("Oops! Error occured while updating device.");
      }

      setState({ ...state, loader: false });
      close(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSelectedPhotographer = (val) => {
  //   const { name, preferred_email, phone, home_address } = val;
  //   const photographer = {
  //     name,
  //     email: preferred_email,
  //     phone,
  //     address: home_address,
  //   };
  //   setCurrentAssignee(photographer);
  // };

  return (
    <Spin spinning={loader}>
      <Form
        form={form}
        initialValues={formInitValues}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Row>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item hidden={true} name="_id" />
                    <Form.Item
                      label="Thumnail Photo"
                      name="thumbnail"
                      className="form-group"
                    >
                      <div className="text-muted">
                        Upload a single stock photo of the equipment.
                      </div>
                      <Upload
                        name="thumbnail"
                        accept=".png,.jpg,.jpeg"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={({ fileList }) => handleChange(fileList[0])}
                        className="mt-3 ml-5"
                      >
                        {state.imgUrl ? (
                          <img
                            src={state.imgUrl}
                            alt="avatar"
                            style={{ width: "100%", maxHeight: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                      <Typography.Text>
                        {form.getFieldValue("thumbnail")
                          ? "*Click to change your logo"
                          : ""}
                      </Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label="Description"
                      name="description"
                      className="form-group"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the description!",
                        },
                      ]}
                    >
                      <Input name="description" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Serial Number"
                      name="serial_number"
                      className="form-group"
                    >
                      <Input name="serial_number" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Bought From"
                      name="bought_from"
                      className="form-group"
                    >
                      <Input name="bought_from" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label="Purchase Date"
                      name="purchase_date"
                      className="form-group"
                    >
                      <DatePicker
                        onChange={handleDateChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Purchase Price"
                      name="price"
                      className="form-group"
                    >
                      <Input name="price" type="number" prefix="$" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Warranty Information"
                      name="warranty_info"
                      className="form-group"
                    >
                      <Input name="warranty_info" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ marginTop: "10px" }}
                    >
                      Save
                    </Button>
                    <Button type="link" onClick={close}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Row>
              <Col span={24}>
                <Card style={{ backgroundColor: "#f5f5f5", borderRadius: 7 }}>
                  <h3>Assign To:</h3>
                  <div style={{ marginBottom: 18 }}>
                    <strong>Photographer/Repairer</strong>
                  </div>
                  <Row>
                    <Col span={24}>
                      <div className="form-group">
                        <Select
                          defaultValue={
                            initVal?.current_assignee?.assignee_type ===
                            "Repairer"
                              ? "Repairer"
                              : initVal?.current_assignee?.name
                          }
                          onChange={(value) => {
                            form.setFieldsValue({
                              current_assignee: {
                                ...form.getFieldsValue("current_assignee"),
                                name: "",
                                address: "",
                                phone: "",
                                email: "",
                                date_assigned: null,
                                date_returned: null,
                                repair_cost: 0,
                                condition: "",
                                quantity: 1,
                                repair_cost: 0,
                              },
                            });
                            handleAssignTo(value);
                          }}
                          style={{
                            width: "50%",
                            backgroundColor: "#fff",
                          }}
                          name="current_assignee"
                        >
                          <Option value={"Repairer"}>
                            <span
                              style={{
                                color: "#F00",
                              }}
                            >
                              Repairer
                            </span>
                          </Option>
                          {photographers.map((photog) => (
                            <Option value={photog?._id}>{photog?.name}</Option>
                          ))}
                        </Select>
                      </div>
                    </Col>

                    {assigneeType === "Photographer" ? (
                      <PhotogForm />
                    ) : (
                      <RepairerForm
                        setCurrentAssignee={setCurrentAssignee}
                        currentAssignee={currentAssignee}
                      />
                    )}

                    <Col span={24}>
                      <div style={{ marginBottom: 18 }}>
                        <h4>Photos</h4>
                        <span className="text-muted">
                          Upload multiple, detailed photos to show the condition
                          of the equipment when it was assigned.
                        </span>
                        <div>
                          <Card>
                            <div className="contentBody">
                              <SRLWrapper options={options}>
                                <Row gutter={[8, 8]} className="p-2">
                                  {imageUrls.map((url) => (
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      key={url}
                                    >
                                      <a href={url} data-attribute="SRL">
                                        <img
                                          src={url}
                                          width="100%"
                                          height="50%"
                                          alt=""
                                        />
                                      </a>
                                      <div>
                                        <span className="close-btn">
                                          <Button
                                            type="link"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleImgDelete(url)}
                                          />
                                        </span>
                                      </div>
                                    </Col>
                                  ))}
                                  {newPhotos.map((image) => (
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      key={image.url}
                                    >
                                      <a href={image.url} data-attribute="SRL">
                                        <img
                                          src={image.thumbUrl}
                                          width="100%"
                                          height="50%"
                                          alt=""
                                        />
                                      </a>
                                      <div>
                                        <span className="close-btn">
                                          <Button
                                            type="link"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleDelete(image)}
                                          />
                                        </span>
                                      </div>
                                    </Col>
                                  ))}

                                  <label
                                    className="ant-upload-text-device"
                                    style={{ height: "50%" }}
                                  >
                                    <PlusOutlined className="icon-style" />
                                    Add
                                    <Upload
                                      accept=".png,.jpg,.jpeg"
                                      fileList={selectedFiles}
                                      multiple
                                      beforeUpload={beforeUpload}
                                      showUploadList={false}
                                      onChange={handleImageChange}
                                    />
                                  </label>
                                </Row>
                              </SRLWrapper>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DeviceForm;
