import axios from "axios";
import {
  FETCH_PHOTOGRAPHERS,
  UPDATE_PHOTOGRAPHER,
  CREATE_PHOTOGRAPHER,
  REMOVE_PHOTOGRAPHER,
  FETCH_PHOTOGRAPHER_BY_ID,
  FETCH_PHOTOGRAPHER_BY_USER_ID,
  FETCH_PHOTOG_SHOOTS_SUCCESS,
  FETCH_PHOTOG_DIST_SUCCESS,
  FETCH_PHOTOG_SHOOTS_FAIL,
  FETCH_PHOTOGRAPHER_UNAVAILABILITY,
  FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED,
  FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY,
  FETCH_UPSELL_LEADERBOARD,
} from "./types";

export const fetchPhotographers = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers`
    );
    dispatch({
      type: FETCH_PHOTOGRAPHERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPhotographerById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers/id/${id}`
    );
    dispatch({
      type: FETCH_PHOTOGRAPHER_BY_ID,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchPhotographerByUserId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers/user/${id}`
    );
    dispatch({
      type: FETCH_PHOTOGRAPHER_BY_USER_ID,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchPhotographerShoots = (id, filters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${id}?${filters}`
    );
    dispatch({
      type: FETCH_PHOTOG_SHOOTS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({ type: FETCH_PHOTOG_SHOOTS_FAIL, payload: err.msg });
  }
};

export const fetchDistanceArrayAndTotal =
  (id, addressArray) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer-distance/${id}`,
        { params: { address: addressArray } }
      );
      dispatch({
        type: FETCH_PHOTOG_DIST_SUCCESS,
        payload: res.data,
      });
      return { data: res, success: true };
    } catch (err) {
      console.log(err);
      return { error: err, success: false };
    }
  };
export const createPhotographer = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/photographers`,
      formData,
      config
    );

    dispatch({
      type: CREATE_PHOTOGRAPHER,
      payload: res.data,
    });
    return res;
  } catch (err) {}
};

export const updatePhotographer = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/photographers/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_PHOTOGRAPHER,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      data: null,
      error: err.response,
    };
  }
};

export const removePhotographer = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/photographers/${id}`);

    dispatch({
      type: REMOVE_PHOTOGRAPHER,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPhotographerUnavailabilityForDay =
  (id, date) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/photographers/unavailability/${id}?date=${date}`
      );
      dispatch({
        type: FETCH_PHOTOGRAPHER_UNAVAILABILITY,
        payload: res.data,
      });
      return {
        data: res.data,
        error: null,
      };
    } catch (err) {
      return {
        data: {},
        error: err.response,
      };
    }
  };

export const savePhotographerUnavailabilityForDay =
  (id, body) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/photographers/unavailability/${id}`,
        body
      );
      dispatch({
        type: FETCH_PHOTOGRAPHER_UNAVAILABILITY,
        payload: res.data,
      });
      return {
        data: res.data,
        error: null,
      };
    } catch (err) {
      return {
        data: {},
        error: err.response,
      };
    }
  };

export const savePhotographerUnavailabilityForCustomDate =
  (id, body) => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/photographers/unavailability/custom/${id}`,
        body
      );

      return {
        data: res.data,
        error: null,
      };
    } catch (err) {
      return {
        data: {},
        error: err.response,
      };
    }
  };

export const fetchAllPhotoGraphersUnAvailabilityForADay =
  (date) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/photographers/unavailability?date=${date}`
      );
      dispatch({
        type: FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY,
        payload: res.data,
      });
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_ALL_PHOTOGRAPHER_UNAVAILABILITY_FAILED,
        payload: [],
      });
      console.log(err);
    }
  };
