import React from "react";
import { Button, message, DatePicker } from "antd";
import { Input } from "formik-antd";
import { Formik } from "formik";
import moment from "moment";
import axios from "axios";

const { TextArea } = Input;

const RequestForm = ({
  selectedRequest,
  updateShoot,
  approver,
  handleCancel,
  newStatus,
}) => {
  const sendDenyRequestEmail = (data) => {
    axios.post(`${process.env.REACT_APP_ROOT_URL}/notifications/deny-request`, {
      ...data,
      street_address: selectedRequest?.address?.street,
      requester_email: selectedRequest?.status_change_request?.requester?.email,
      requester_name: selectedRequest?.status_change_request?.requester?.name.split(
        " "
      )[0],
      denied_reason: data?.status_change_request?.denied_reason,
      shoot_id: selectedRequest?._id,
      status: newStatus,
      new_date: moment(data?.status_change_request?.new_date).format(
        "MMM D, YYYY"
      ),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        current_status: selectedRequest?.status,
        new_status: "Reschedule",
        current_date: selectedRequest?.events?.start,
        admin_approved: false,
        client_approved: false,
        resolved: false,
      }}
      onSubmit={async (values) => {
        const requestData = {
          status: "Reschedule",
          status_change_request: {
            admin_approved: true,
            client_approved: newStatus === "Deny" ? true : false,
            denied_reason: values?.denied_reason,
            new_date: values?.new_date,
            approver: approver,
            resolved: newStatus === "Deny" ? true : false,
            _request_created_at: new Date(),
          },
        };

        const res = await updateShoot(selectedRequest._id, requestData);

        if (res.status === 200) {
          sendDenyRequestEmail(requestData);
          console.log(requestData);

          message.success("Your response was sent successfully.");
        } else {
          message.error("Oops! Something went wrong.");
        }

        handleCancel();
      }}
      render={({ handleSubmit, setFieldValue }) => (
        <div className="reason">
          {newStatus === "Deny" && (
            <div className="form-group">
              <label>
                What is the reason for the denying the request? (required)
              </label>
              <TextArea name="denied_reason" rows={2} />
            </div>
          )}
          {newStatus === "Reschedule" && (
            <React.Fragment>
              <div className="mb-4 form-group">
                <label>Select an alternative date (optional)</label>
                <DatePicker
                  disabledDate={(date) => {
                    return date.day() === 0 ? true : false;
                  }}
                  onChange={(value) =>
                    setFieldValue("new_date", moment(value).format())
                  }
                />
              </div>
              <div className="form-group">
                <label>Include a comment (required)</label>
                <TextArea name="denied_reason" rows={2} />
              </div>
            </React.Fragment>
          )}

          <div className="form-group mt-4">
            <Button className="mr-2" onClick={handleSubmit} type="primary">
              Send
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      )}
    />
  );
};

export default RequestForm;
