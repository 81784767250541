import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { List, Avatar, Button, Skeleton, Tag, Badge } from "antd";
import {
  HomeOutlined,
  ClockCircleOutlined,
  VideoCameraOutlined,
  CameraOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { updateMedia } from "../../../../redux/action/mediaAction";
import { fetchShootById } from "../../../../redux/action/shootAction";
import { formatUsAddress, getShootColor } from "../../../../shared/utils/utils";
import "./style.scss";

const EditorList = ({
  changeHandler,
  shoots,
  loading,
  pagination: { current, pageSize, total },
}) => {
  const hasPhotos = (shoot) => {
    return shoot?.media?.photos?.gallery.length === 0 ? false : true;
  };
  const videoStatus = (shoot) => {
    return shoot?.media?.video?.status;
  };
  const photoStatus = (shoot) => {
    return shoot?.media?.photos?.status;
  };
  const slideShowStatus = (shoot) => {
    return shoot?.media?.slideShow?.status;
  };
  const [listOpt, setListOpt] = useState({
    skip: 1,
    limit: 10,
    photographer: null,
    reqs: null,
    sortField: "events.start",
    sortOrder: "ascend",
    filter: "Processing,Tentative,Scheduled,Completed,Reschedule",
  });
  const { reqs, photographer } = listOpt;

  const renderIcon = (service, status) => {
    const iconColor = status === "Ready" ? "green" : "#bbb";
    return (
      <>
        {service === "photos" && (
          <CameraOutlined style={{ color: iconColor, fontSize: "1.8em" }} />
        )}
        {service === "video" && (
          <VideoCameraOutlined
            style={{ color: iconColor, fontSize: "1.8em" }}
          />
        )}
        {service === "slideShow" && (
          <YoutubeOutlined style={{ color: iconColor, fontSize: "1.8em" }} />
        )}
      </>
    );
  };

  const handlePhotographer = (id) => {
    setListOpt({
      ...listOpt,
      skip: 1,
      photographer: photographer === id ? null : id,
    });
    changeHandler({
      ...listOpt,
      skip: 1,
      photographer: photographer === id ? null : id,
    });
  };

  const handleRequirement = (code) => {
    setListOpt({ ...listOpt, skip: 1, reqs: reqs !== code ? code : "" });
    changeHandler({ ...listOpt, skip: 1, reqs: reqs !== code ? code : "" });
  };

  const parseServiceCodes = (services) => {
    return services
      ?.filter((s) => s.code !== "FREE")
      .map((service) => {
        if (service.code === "T") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="gold"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                Twilight
              </Tag>
            </Button>
          );
        } else if (service.code === "4H!") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="#f50"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        } else if (service.code === "4HR") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="blue"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        } else if (service.code === "SD") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="#87d068"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        } else if (service.code === "1D12!") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="red"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        } else if (service.code === "1D9") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="green"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        } else if (service.code === "A") {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                color="#108ee9"
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                Aerial
              </Tag>
            </Button>
          );
        } else {
          return (
            <Button
              ghost={listOpt.reqs !== service.code}
              className={
                listOpt.reqs === service.code
                  ? "selectedTagButton"
                  : "tagButton"
              }
            >
              <Tag
                onClick={() => {
                  handleRequirement(service.code);
                }}
              >
                {service.code}
              </Tag>
            </Button>
          );
        }
      });
  };
  return (
    <div>
      <List
        className="demo-loadmore-list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={shoots}
        pagination={{
          onChange: (page) => {
            setListOpt({ ...listOpt, skip: page, limit: pageSize });
            changeHandler({ ...listOpt, skip: page, limit: pageSize });
          },
          pageSize: pageSize,
          total: total,
          current,
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Link to={`/admin/shoots/${item?._id}?tab=tour`}>
                {renderIcon("photos", photoStatus(item))}
              </Link>,
              item?.video?.turnaround !== "" && (
                <Link to={`/admin/shoots/${item?._id}?tab=tour-video`}>
                  {renderIcon("video", videoStatus(item))}
                </Link>
              ),
              item?.reqs?.includes("YT") && (
                <Link to={`/admin/shoots/${item?._id}?tab=Youtube`}>
                  {renderIcon("slideShow", slideShowStatus(item))}
                </Link>
              ),
              <Link to={`/admin/shoots/${item?._id}`}>
                <Button size="small" className="ml-2 mt-2">
                  Details
                </Button>
              </Link>,
            ]}
          >
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
                avatar={
                  hasPhotos(item) ? (
                    <Link to={`/admin/shoots/${item?._id}?tab=tour`}>
                      <Avatar
                        shape="square"
                        size={64}
                        src={item?.media?.photos.gallery[0]}
                      />
                    </Link>
                  ) : (
                    <Link to={`/admin/shoots/${item?._id}?tab=tour`}>
                      <Avatar
                        shape="square"
                        size={64}
                        icon={<HomeOutlined />}
                      />
                    </Link>
                  )
                }
                title={
                  <span>
                    HS# {item?.hsn} -{" "}
                    <strong>
                      {`${formatUsAddress(
                        item?.address.full,
                        item?.address.street,
                        item?.address.apt,
                        item?.address.city,
                        item?.address.state,
                        item?.address.zip
                      )} - ${item?.client_name_first} ${
                        item?.client_name_last
                      }`}
                    </strong>{" "}
                    <Tag className="ml-2" color={getShootColor(item?.status)}>
                      {item?.status}
                    </Tag>
                  </span>
                }
                description={
                  <>
                    <span>
                      {item?.status !== "Pending" &&
                        item?.events?.photographer && (
                          <>
                            <Button
                              ghost={
                                photographer !== item?.events?.photographer?._id
                              }
                              className={
                                photographer === item?.events?.photographer?._id
                                  ? "selectedTagButton"
                                  : "tagButton"
                              }
                              onClick={() =>
                                handlePhotographer(
                                  item?.events?.photographer?._id
                                )
                              }
                              size="small"
                              type="ghost"
                            >
                              <Badge
                                color={item?.events?.photographer?.color}
                                text={item?.events?.photographer?.name}
                                style={{ marginRight: "20px" }}
                              />
                            </Button>

                            <span className="mr-3">
                              <ClockCircleOutlined />{" "}
                              {moment.utc(item.events.start).format("MM/DD/YY")}
                            </span>
                          </>
                        )}
                      {parseServiceCodes(item?.invoice[0]?.products)}
                      {item?.client_details?.preferences?.media?.resize && (
                        <Tag color="red">
                          {item?.brokerage
                            ? item?.brokerage?.name
                            : "Resize Photos"}
                        </Tag>
                      )}
                    </span>
                  </>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};

export default connect(null, {
  updateMedia,
  fetchShootById,
})(EditorList);
