import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card, Col, Rate, Row, Statistic, Typography } from "antd";
import { HeartTwoTone, FrownTwoTone } from "@ant-design/icons";
import ListView from "../../../shared/Components/ListView";

const { Paragraph } = Typography;

const Reviews = ({ handleCurrentPath }) => {
  const [reviews, setReviews] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fiveStarRated, setfFiveStarRated] = useState(0);
  const [lowRated, setfLowRated] = useState(0);
  const [avgRating, setAvgRating] = useState(0);

  useEffect(() => {
    handleCurrentPath("BlogPosts", "13");
    fetchReviewsTableView({ skip: 1, limit: 10 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [tableOpt, setOperation] = useState({
    limit: 10,
    skip: 1,
  });

  const { skip, limit } = tableOpt;

  const fetchReviewsTableView = async ({
    skip: skip_new,
    limit: limit_new,
  }) => {
    // Fetching data from server
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/reviews?skip=${skip_new}&limit=${limit_new}`
    );
    setReviews(res.data.reviews);
    setTotalCount(res.data.count);
    setAvgRating(res.data.avgRating);
    setfFiveStarRated(res.data.fiveStarRated);
    setfLowRated(res.data.lowRated);
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    // history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchReviewsTableView({
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: "Rating",
      dataIndex: "rating",
      width: 200,
      key: "hsn",
      render: (rating, record) => (
        <Rate disabled defaultValue={record.rating.score} />
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.rating.score - b.rating.score,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "hsn",
      render: (comment, record) => (
        <div style={{ maxWidth: 700 }}>
          <Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
          >
            {record.rating.comment}
          </Paragraph>
        </div>
      ),
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      width: 100,
      key: "hsn",
      render: (text, record) => (
        <Link to={`/admin/shoots/${record._id}`}>{text}</Link>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      width: 150,
      key: "hsn",
      render(client, record) {
        return (
          <Link to={`/admin/clients/${record.client_id}`}>
            {record.client_name_first} {record.client_name_last}
          </Link>
        );
      },
    },
    {
      title: "Shoot Date",
      dataIndex: "date",
      width: 150,
      key: "hsn",
      render: (date, record) => (
        <span>{moment(record.events.start).format("M/D/YYYY")}</span>
      ),
    },
  ];

  return (
    <div>
      <Row justify="center" align="middle">
        <Col span={24} className="pl-4">
          <Row gutter={12}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Statistic title="Total Ratings" value={totalCount} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Statistic
                  title="Average Score"
                  value={avgRating}
                  precision={1}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Statistic
                  title="5 Star Ratings"
                  value={fiveStarRated}
                  suffix={
                    <HeartTwoTone
                      twoToneColor="#096dd9"
                      style={{ fontSize: 18, marginLeft: 5 }}
                    />
                  }
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Statistic
                  title={"Low Ratings"}
                  value={lowRated}
                  suffix={
                    <FrownTwoTone
                      style={{ fontSize: 18, marginLeft: 5 }}
                      twoToneColor="#f00"
                    />
                  }
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ListView
            title="Reviews"
            onClick={null}
            handleSearch={null}
            data={reviews}
            columns={columns}
            handleTableChange={handleTableChange}
            pagination={{
              current: skip,
              pageSize: limit,
              total: totalCount,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Reviews;
