import React, { useState, useEffect } from "react";
import { Button, Layout, Drawer, Menu, Affix } from "antd";
import {
  DashboardOutlined,
  DashOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { BrowserRouter as Router, Link } from "react-router-dom";
import UserRoutes from "../routes/UserRoutes";
import { Provider } from "react-redux";
import store from "../redux/store";
import "../App.scss";
import Navigator from "../user/Navigator";
import VerticalNavigator from "../user/VerticalNavigator";
import setAuthToken from "../utils/setAuthToken";
import { loadCurrentUser, logoutUser } from "../redux/action/authAction";
import useWindowSize from "../hooks/useWindowSize";
import logo from "../content/hs_logo_on_light.png";
import logoWebp from "../content/hs_logo_on_light.webp";

if (localStorage.hstoken) {
  setAuthToken(localStorage.hstoken);
}

const { Header, Content } = Layout;
const UserLayout = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [hideActionButtons, setHideActionButtons] = useState(false);
  useEffect(() => {
    store.dispatch(loadCurrentUser());
    const params = window.location.search;
    const pathString = window.location.pathname;
    const prefix = pathString.split("/")[2];
    setHideActionButtons(
      params || prefix === "c" || prefix === "p" ? true : false
    );
    console.log("hideActionButtons", hideActionButtons);
    // showNotification();
  }, []);

  useEffect(() => {
    const currUser = store.getState().auth.user;
    if (currUser) setCurrentUser(currUser);
  }, [store.getState().auth.user]);

  const [state, setState] = useState({
    currentPath: "/",
    menuKey: "",
  });
  const [visible, setVisible] = useState(false);
  const windowSize = useWindowSize();

  const handleCurrentPath = (path, index) => {
    setState({ currentPath: path.replace("/", ""), menuKey: index });
  };

  // const handleCloseNotification = (key) => {
  //   notification.close(key);
  //   localStorage.setItem("show-notification", false);
  // };

  // const showNotification = () => {
  //   const key = `open${Date.now()}`;
  //   const btn = (
  //     <Button
  //       type="primary"
  //       size="small"
  //       onClick={() => handleCloseNotification(key)}
  //     >
  //       Don't Show this Again
  //     </Button>
  //   );

  //   const showNotification = JSON.parse(
  //     localStorage.getItem("show-notification")
  //   );

  //   if (showNotification === null || showNotification) {
  //     notification.info({
  //       message: "Please Pardon the Delay",
  //       description:
  //         "Our support team is currently without power. We are sorry for the inconvenience and will return any calls  and messages as soon as we are able.",
  //       placement: "topRight",
  //       duration: 0,
  //       key,
  //       btn,
  //     });
  //   }
  // };

  const closeDrawer = () => {
    setVisible(false);
  };

  // const logout = () => {
  //   store.dispatch(logoutUser());
  //   setCurrentUser(null);
  // };

  // const menu = () => {
  //   return (
  //     <div>
  //       <Menu>
  //         <Menu.Item key="1">
  //           <Link to={"/admin/dashboard"} target="_blank">
  //             <Button type="link">Dashboard</Button>
  //           </Link>
  //         </Menu.Item>
  //         <Menu.Divider />
  //         <Menu.Item key="2">
  //           <Button onClick={logout} type="link">
  //             Sign Out
  //           </Button>
  //         </Menu.Item>
  //       </Menu>
  //     </div>
  //   );
  // };

  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Header className="appHeader">
            <Affix offsetTop={0}>
              <div className="menu-wrapper">
                <div className="logo">
                  <a href="/">
                    <picture>
                      <source srcSet={logoWebp} type="image/webp" />
                      <img src={logo} alt="Home Snappers logo" />
                    </picture>
                  </a>
                </div>
                {(windowSize === "lg" || windowSize === "xlg") && (
                  <>
                    <div className="nav-items">
                      <Navigator type="appBackground" index={state.menuKey} />
                    </div>
                    {!hideActionButtons && (
                      <div className="actions">
                        <div>
                          <Link to="/lets-book-a-shoot">
                            <Button className="mr-4" type="primary">
                              Book a Shoot
                            </Button>
                          </Link>
                          {currentUser ? (
                            <Link to={"/admin/dashboard"} target="_blank">
                              {windowSize === "xlg" && (
                                <Button danger>My Dashboard</Button>
                              )}
                              {windowSize === "lg" && (
                                <Button icon={<DashboardOutlined />} danger />
                              )}
                            </Link>
                          ) : (
                            <Link to={"/admin/login"} target="_blank">
                              <Button danger>Log In</Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {(windowSize === "sm" || windowSize === "md") && (
                  <div className="hamburger">
                    <Button
                      onClick={() => setVisible(true)}
                      type="link"
                      icon={<MenuOutlined />}
                      size="large"
                    />
                  </div>
                )}
              </div>
            </Affix>
          </Header>
          <Content>
            <UserRoutes handleCurrentPath={handleCurrentPath} />
            <Drawer
              title="MENU"
              placement="right"
              closable={true}
              onClose={closeDrawer}
              visible={visible}
              destroyOnClose={true}
            >
              <VerticalNavigator closeDrawer={closeDrawer} />
            </Drawer>
          </Content>
        </Router>
      </Layout>
    </Provider>
  );
};

export default UserLayout;
