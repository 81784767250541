import React from "react";
import { Row, Col, Input, DatePicker, Form } from "antd";

const RepairerForm = ({ currentAssignee, setCurrentAssignee }) => {
  return (
    <div>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            label="Repairer Name"
            name={["current_assignee", "name"]}
            className="form-group"
          >
            <Input
              onChange={(e) =>
                setCurrentAssignee({
                  ...currentAssignee,
                  name: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Address"
            name={["current_assignee", "address"]}
            className="form-group"
          >
            <Input
              onChange={(e) =>
                setCurrentAssignee({
                  ...currentAssignee,
                  address: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Phone"
            name={["current_assignee", "phone"]}
            className="form-group"
          >
            <Input
              onChange={(e) =>
                setCurrentAssignee({
                  ...currentAssignee,
                  phone: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Email"
            name={["current_assignee", "email"]}
            className="form-group"
          >
            <Input
              onChange={(e) =>
                setCurrentAssignee({
                  ...currentAssignee,
                  email: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Date Sent"
            name={["current_assignee", "date_assigned"]}
            className="form-group"
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Expected Return Date"
            name={["current_assignee", "date_returned"]}
            className="form-group"
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label="Repair Cost"
            name={["current_assignee", "repair_cost"]}
            className="form-group"
          >
            <Input
              type="number"
              prefix="$"
              onChange={(e) =>
                setCurrentAssignee({
                  ...currentAssignee,
                  repair_cost: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default RepairerForm;
