import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../admin/Components/Dashboard";
import Credits from "../admin/Components/Credits";
import Coupons from "../admin/Components/Coupons";
import Offerings from "../admin/Components/Offerings";
import Clients from "../admin/Components/Clients";
import Brokerages from "../admin/Components/Brokerages";
import ClientDetails from "../admin/Components/Clients/ClientDetails";
import Invoices from "../admin/Components/Invoices.jsx";
import Photographers from "../admin/Components/Photographers";
import Teams from "../admin/Components/Team";
import ShootTab from "../admin/Components/Shoots/ShootTab";
import CreateShoot from "../admin/Components/Shoots/CreateShoot";
import Shoots from "../admin/Components/Shoots";
import Profile from "../admin/Components/Profile";
import PrivateRoute from "./PrivateRoute";
import PhotographerDetails from "../admin/Components/Photographers/PhotographerDetails";
import AdminLogin from "../admin/Components/Auth/AdminLogin";
import ForgotPassword from "../admin/Components/Auth/ForgotPassword";
import ResetPassword from "../admin/Components/Auth/ResetPassword";
import Invoice from "../admin/Components/Invoices/Invoice";
import BlogPosts from "../admin/Components/Blog/Posts";
import Templates from "../admin/Components/Templates/Templates";
import BrokerageDetails from "../admin/Components/Brokerage/BrokerageDetails";
import UploadStatus from "../admin/Components/UploadStatus.jsx";
import StatusNotFound from "../admin/Components/StatusNotFound";
import Invitation from "../admin/Components/Invitation";
import Pay from "../admin/Components/Photographers/Pay";
import ShootConfirmation from "../admin/Components/ShootConfirmation";
import Availability from "../admin/Components/Photographers/Availability";
import Subdivision from "../admin/Components/SubDivision/Subdivision";
import SubDivision from "../admin/Components/SubDivision/SubdivisionDetails";
import Devices from "../admin/Components/Devices";
import DeviceDetail from "../admin/Components/Devices/DeviceDetail";
import Reviews from "../admin/Components/Reviews";
import AddEditAmenity from "../admin/Components/SubDivision/AddEditAmenity";

const AdminRoutes = ({ handleCurrentPath }) => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          path="/admin/availability"
          component={Availability}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <Route path="/admin/login" component={AdminLogin} exact />
        <Route path="/admin/forgot-password" component={ForgotPassword} exact />
        <Route path="/admin/reset-password" component={ResetPassword} exact />
        <Route
          path="/admin/team-invitation-link/:team/:member"
          component={Invitation}
          exact
        />
        <Route
          path="/admin/shoot-confirmation-link/:shoot/:status"
          component={ShootConfirmation}
          exact
        />
        <PrivateRoute
          path="/admin"
          component={Dashboard}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/dashboard"
          component={Dashboard}
          handleCurrentPath={handleCurrentPath}
        />
        <PrivateRoute
          path="/admin/shoots"
          component={Shoots}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute path="/admin/shoots/new" component={CreateShoot} exact />
        <Route
          path="/admin/shoots/:id"
          component={(props) => <ShootTab {...props} />}
          exact
        />
        <PrivateRoute
          path="/admin/invoices"
          component={Invoices}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <Route path="/admin/invoices/:id" component={Invoice} exact />
        <PrivateRoute
          path="/admin/brokerages"
          component={Brokerages}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/brokerages/:id"
          component={BrokerageDetails}
        />
        <PrivateRoute
          path="/admin/clients"
          component={Clients}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute path="/admin/clients/:id" component={ClientDetails} />
        <PrivateRoute
          path="/admin/photographers"
          component={Photographers}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/teams"
          component={Teams}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/photographers/:id"
          component={PhotographerDetails}
        />
        <Route path="/admin/upload/status/error">
          <UploadStatus status="error" />
        </Route>
        <Route path="/admin/upload/status/success">
          <UploadStatus status="success" />
        </Route>
        <PrivateRoute
          path="/admin/offerings"
          component={Offerings}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/subdivision"
          component={Subdivision}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute path="/admin/division/:id" component={SubDivision} />
        <PrivateRoute
          path="/admin/credits"
          component={Credits}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/coupons"
          component={Coupons}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/profile"
          component={Profile}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/pay"
          component={Pay}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/blog"
          component={BlogPosts}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/templates"
          component={Templates}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/equipment"
          component={Devices}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/equipment/:id"
          component={DeviceDetail}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/reviews"
          component={Reviews}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <PrivateRoute
          path="/admin/amenity/:subdivisionId"
          component={AddEditAmenity}
          handleCurrentPath={handleCurrentPath}
          exact
        />
        <Route>
          <StatusNotFound status={{ is404: true, isPermitted: null }} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default AdminRoutes;
