import moment from "moment";
import {
  FETCH_SHOOTS,
  FETCH_SHOOT_BY_ID,
  FETCH_SHOOT_BY_HSN,
  CREATE_SHOOT,
  REMOVE_SHOOT,
  UPDATE_SHOOT,
  RESCHEDULE_SHOOT,
  FETCH_CHANGE_REQUESTS,
  UPDATE_SHOOT_MEDIA,
  REMOVE_SHOOT_CALENDAR,
  FETCH_SHOOTS_BY_DAY,
  FETCH_PENDING_SHOOTS,
  CHANGE_CALENDAR_VIEW,
  CHANGE_CALENDAR_VIEW_DATE,
  SET_SELECTED_SHOOT,
  FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY,
  UPDATE_WEBSOCKET_SHOOT,
} from "../action/types";

import {
  getDate,
  toHyphenCase,
  getShootColor,
  convertISODateString,
  convertISOStrToDate,
  setOpacity,
} from "./../../shared/utils/utils.js";

const initialState = {
  isLoading: true,
  shoots: [],
  dashboard: {
    view: "",
    range: "day",
    currentDate: new Date(),
    pendingShoots: [],
    displayShoots: [],
  },
  photographerUnAvailabilityEvents: [],
};

const getCalendarViewEvents = (shoot, idx, state) => {
  let currentTime;
  if (state?.dashboard.view === "Today" || state?.dashboard.view === "") {
    if (
      convertISOStrToDate(shoot._created_at).getHours() > 7 &&
      convertISOStrToDate(shoot._created_at).getHours() < 22
    ) {
      currentTime = new Date();
      currentTime.setHours(convertISOStrToDate(shoot._created_at).getHours());
      currentTime.setMinutes(
        convertISOStrToDate(shoot._created_at).getMinutes()
      );
      currentTime.setSeconds(
        convertISOStrToDate(shoot._created_at).getSeconds()
      );
    } else {
      currentTime = new Date();
      currentTime.setHours(8);
      currentTime.setMinutes(0);
      currentTime.setSeconds(0);
    }
  } else {
    currentTime = new Date();
    currentTime.setHours(7);
    currentTime.setMinutes(0);
    currentTime.setSeconds(0);
  }
  const currentTimePlus = new Date(
    currentTime.setMinutes(currentTime.getMinutes() + shoot.events.duration)
  );
  const requested_date = shoot.booking_form.requested_date
    ? getDate(shoot.booking_form.requested_date)
    : moment();
  return {
    key: idx,
    id: shoot._id,
    title: shoot.events.title ? shoot.events.title : "",
    duration: shoot.events.duration ? shoot.events.duration : 0,
    description: shoot.events.description ? shoot.events.description : "",
    name: shoot.client_name_first + " " + shoot.client_name_last,
    email: shoot.client_email,
    phone: shoot.client_phone,
    contact: shoot.primary_phone,
    hsn: shoot.hsn,
    flexible: shoot.flexible,
    status: toHyphenCase(shoot.status),
    shootStatus: shoot.status,
    reschedules: shoot.reschedules,
    client_id: shoot.client_id,
    user: shoot.user,
    orderDate: shoot._created_at,
    address: shoot.address.full,
    driveTime: shoot.address.drive_time,
    calendarId: shoot.events.photographer
      ? shoot.events.photographer._id
      : null,
    resourceId: shoot.events.photographer
      ? shoot.events.photographer._id
      : "Pending",
    category: "time",
    start: shoot.events.start
      ? shoot.events.start
      : convertISODateString(currentTime),
    end: shoot.events.end
      ? shoot.events.end
      : convertISODateString(new Date(currentTimePlus)),
    isAllDay: false,
    isPrivate: false,
    showInfoWindow: false,
    servicesNCost: shoot.invoice,
    notes: shoot.booking_form.clientNote,
    requested_date,
    secondary_contact:
      shoot?.booking_form?.secondary_contact?.name !== ""
        ? shoot?.booking_form?.secondary_contact
        : null,
    share: shoot.booking_form.share,
    details: {
      ...shoot,
    },
    iconColor: getShootColor(shoot.status),
    backgroundColor:
      shoot.events.photographer && shoot.status !== "Pending"
        ? setOpacity(shoot?.status, shoot?.events?.photographer?.color)
        : "#c0ca33",
    color: "white",
  };
};

const getUnavailabilityEvent = (resourceId, slot) => {
  return {
    rendering: "background",
    overlap: false,
    resourceId,
    start: slot.start,
    end: slot.end,
    key: resourceId,
    backgroundColor: "grey",
  };
};

const getOrders = (action, state) => {
  let orders = [];
  action.payload.shoots.forEach((shoot, idx) => {
    const obj = getCalendarViewEvents(shoot, idx, state);
    orders.push(obj);
  });
  return orders;
};

const convertToEvents = (phGraphersUnAvailabilities) => {
  let events = [];
  phGraphersUnAvailabilities.forEach((phGrapherUnAvailability) => {
    phGrapherUnAvailability.unAvailableSlots.forEach((slot) => {
      const event = getUnavailabilityEvent(
        phGrapherUnAvailability.photographer,
        slot
      );
      events.push(event);
    });
  });
  return events;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SHOOTS:
      return {
        ...state,
        isLoading: false,
        shoots: action.payload.shoots,
        totalCount: action.payload.count,
      };
    case FETCH_CHANGE_REQUESTS:
      return {
        ...state,
        isLoading: false,
        changeRequests: action.payload,
      };
    case CHANGE_CALENDAR_VIEW:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          view: action.payload,
        },
      };
    case FETCH_PENDING_SHOOTS:
      const pendingShoots = getOrders(action, state);
      return {
        ...state,
        isLoading: false,
        dashboard: {
          ...state.dashboard,
          pendingShoots: [...pendingShoots],
        },
      };
    case CHANGE_CALENDAR_VIEW_DATE:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          currentDate: action.payload,
        },
      };
    case FETCH_SHOOTS_BY_DAY:
      return {
        ...state,
        isLoading: false,
        dashboard: {
          ...state.dashboard,
          displayShoots: state.dashboard.pendingShoots.concat(
            getOrders(action, state)
          ),
        },
      };
    case FETCH_SHOOT_BY_ID:
      return {
        ...state,
        isLoading: false,
        selectedShoot: action.payload,
      };
    case SET_SELECTED_SHOOT:
    case UPDATE_WEBSOCKET_SHOOT:
      return {
        ...state,
        isLoading: false,
        selectedShoot: action.payload,
      };
    case FETCH_SHOOT_BY_HSN:
      return {
        ...state,
        isLoading: false,
        selectedShoot: action.payload,
      };
    case CREATE_SHOOT:
      return {
        ...state,
        shoots: [...state.shoots, action.payload],
        isLoading: false,
      };
    case RESCHEDULE_SHOOT:
      return {
        ...state,
        shoots: [...state.shoots, action.payload],
        isLoading: false,
      };
    case REMOVE_SHOOT:
      return {
        ...state,
        shoots: state.shoots.filter((shoots) => shoots._id !== action.payload),

        isLoading: false,
      };
    case UPDATE_SHOOT:
      let dashboard = { ...state.dashboard };
      let updatePndShts = [...state.dashboard.pendingShoots];
      if (dashboard.length !== 0 && updatePndShts.length !== 0) {
        const updatedShoot = state.dashboard.displayShoots.map((shoots) =>
          shoots.id === action.payload._id
            ? getCalendarViewEvents(action.payload, shoots.key)
            : shoots
        );
        if (action.payload.status !== "Pending") {
          updatePndShts = updatePndShts.filter(
            (shoot) => shoot.id !== action.payload._id
          );
        } else if (action.payload.status === "Pending") {
          const check = updatePndShts.filter(
            (shoot) => shoot.id === action.payload._id
          );
          if (check.length) {
            updatePndShts = updatePndShts.map((shoot) =>
              shoot.id === action.payload._id
                ? getCalendarViewEvents(action.payload, shoot.key)
                : shoot
            );
          } else {
            updatePndShts.push(
              getCalendarViewEvents(
                action.payload,
                updatedShoot.filter(
                  (shoot) => shoot.id === action.payload._id
                )[0]["key"]
              )
            );
          }
        }
      }
      return {
        ...state,
        selectedShoot: action.payload,
        shoots: state.shoots.map((shoots) =>
          shoots.id === action.payload._id ? action.payload : shoots
        ),
        dashboard,
      };
    case UPDATE_SHOOT_MEDIA:
      return Object.assign(
        {},
        {
          ...state,
          shoots: state.shoots.map((shoot) =>
            action.payload._id === shoot.media._id
              ? { ...shoot, media: action.payload }
              : shoot
          ),
          isLoading: false,
        }
      );
    case REMOVE_SHOOT_CALENDAR:
      return {
        ...state,
        shootOrders: state.shootOrders.filter(
          (order) => order.id !== action.payload.id
        ),
      };
    case FETCH_PHOTOGRAPHER_UNAVAILABILITY_FOR_DAY:
      return {
        ...state,
        photographerUnAvailabilityEvents: convertToEvents(action.payload),
      };
    // case UPDATE_WEBSOCKET_SHOOT:
    //   return {
    //     ...state,
    //     shoots: {
    //       ...state.shoots,
    //       selectedShoot: action.payload.selectShoot,
    //     },
    //   };
    default:
      return state;
  }
}
