import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, Select, Col, Typography } from "antd";
import { fetchAmenityBySubdivisionId } from "../../../redux/action/amenityAction";
import { fetchSubdivision } from "../../../redux/action/subdivisionAction";
import { UPDATE_BOOKSHOOT_STATE } from "../../../redux/action/types";

const AmenityModal = (props) => {
  const [availableSubdivisions, setAvailableSubdivisions] = useState([]);
  const [availableAmenities, setAvailableAmenities] = useState([]);
  const [selectedSubDivison, setSelectedSubDivison] = useState();
  // props.updSubdivision || props.invoice?.shoot?.subdivision
  const [selectedAmenity, setSelectedAmenity] = useState();
  // ...(props.updAmenity || props.invoice?.shoot?.amenities),
  useEffect(() => {
    const fetchdivision = async () => {
      await props.fetchSubdivision();
    };
    fetchdivision();
    setSelectedSubDivison(
      props.updSubdivision || props.invoice?.shoot?.subdivision
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAvailableSubdivisions([...props.subdivisions]);
  }, [props.subdivisions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAvailableAmenities([...props.amenities]);
  }, [props.amenities]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.invoice?.shoot?.subdivision) {
      const fetchAmenities = async () => {
        if (props.updAmenity) {
          setSelectedAmenity([...props.updAmenity]);
        } else {
          const subDivProd = props.invoice.products.find(
            (prod) =>
              prod.code === "SP" || prod.code === "CSP" || prod.code === "MSP"
          );
          setSelectedAmenity([...subDivProd?.amenities]);
        }
        if (props.updSubdivision) {
          await props.fetchAmenityBySubdivisionId(
            props.updSubdivision || props.invoice?.shoot?.subdivision
          );
        } else {
          await props.fetchAmenityBySubdivisionId(
            props.invoice?.shoot?.subdivision
          );
        }
      };
      fetchAmenities();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { Title } = Typography;

  const onSubdivisionChange = async (value) => {
    // const fetchAmenities = async () => {
    await props.fetchAmenityBySubdivisionId(value);
    setSelectedSubDivison(value);
    setSelectedAmenity([]);
    // };
    // fetchAmenities();
    // props.updateShootState(UPDATE_BOOKSHOOT_STATE, {
    //   amenities: [],
    // });
    // let filteredSub = props.subdivisions.find((sub) => sub._id == value);
    // props.updSub(filteredSub);

    // if (value !== "Other") {
    //   const selectedSubdivision = props.subdivisions.find(
    //     (div) => div._id === value
    //   );
    // await props.fetchAmenityBySubdivisionId(value);
    // }
  };

  const handleOk = async () => {
    // let product = [...props.invoice?.products];
    // product.filter((prod) =>
    //   prod.description === "Subdivision Package"
    //     ? (prod.amenities = selectedAmenity)
    //     : (prod.amenities = [])
    // );
    props.updAmen(selectedAmenity);
    props.updSub(selectedSubDivison);
    props.closeModal();
  };

  const handleCancel = () => {
    props.closeModal();
    // setIsModalVisible(false);
  };

  const handleAmenity = (value) => {
    setSelectedAmenity([...value]);
    // let amenity = selectedAmenity.concat();
    // const index = amenity.indexOf(value);
    // if (index === -1) {
    //   amenity.push(value);
    // } else {
    //   amenity.splice(index, 1);
    // }
    // setState(amenity);
    // setSelectedAmenity(amenity);
  };
  return (
    <>
      {/* <Button style={{ marginLeft: "50px" }} type="primary" onClick={showModal}>
        View Amenity
      </Button> */}
      <Modal
        title="Add Amenities"
        visible={props.show}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col span={24}>
          <Title
            style={{
              textAlign: "start",
              fontSize: "medium",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            Select Subdivision
          </Title>
          <Select
            // defaultValue={props.invoice?.shoot?.sub_division?.name}
            // disabled={props.invoice?.shoot?.sub_division?.name}
            onChange={onSubdivisionChange}
            style={{ width: 420 }}
            value={selectedSubDivison}
          >
            {availableSubdivisions &&
              availableSubdivisions.map((division) => (
                <Select.Option value={division._id} key={division._id}>
                  {division.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col>
          <Title
            style={{
              textAlign: "start",
              fontSize: "medium",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            Select Amenities
          </Title>
          <Select
            mode="multiple"
            value={selectedAmenity}
            onChange={handleAmenity}
            style={{ width: 420 }}
          >
            {availableAmenities &&
              availableAmenities.map((amenity) => (
                <Select.Option allowClear value={amenity._id} key={amenity._id}>
                  {amenity.name}
                </Select.Option>
              ))}
          </Select>
        </Col>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  amenities: state.amenities.amenities,
  subdivisions: state.subdivision.divisions,
});

const mapDispatchToProps = (dispatch) => {
  const updateShootState = (type, payload) => dispatch({ type, payload });
  return {
    fetchAmenityBySubdivisionId: (id) =>
      dispatch(fetchAmenityBySubdivisionId(id)),
    fetchSubdivision: (id) => dispatch(fetchSubdivision(id)),
    updateShootState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmenityModal);
