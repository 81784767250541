import {
  FETCH_TEAMS,
  FETCH_TEAM_BY_CLIENT_ID,
  UPDATE_TEAM,
  CREATE_TEAM,
  REMOVE_TEAM,
  FETCH_TEAM_BY_OWNER_ID,
  ADD_MEMBER_BY_TEAM_ID,
  FETCH_TEAM_BY_ID,
} from "../action/types";

const initialState = { teams: [], isLoading: true, ownerTeams: [], team: {} };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TEAMS:
      return {
        ...state,
        isLoading: false,
        teams: payload,
      };
    case FETCH_TEAM_BY_ID:
      return {
        ...state,
        team: payload,
      };
    case CREATE_TEAM:
      return {
        ...state,
        teams: [...state.teams, action.payload],
        //isLoading: false,
      };
    case ADD_MEMBER_BY_TEAM_ID:
      return {
        ...state,
        teams: state.teams.map((team) =>
          team._id === action.payload._id ? action.payload : team
        ),
      };
    case UPDATE_TEAM:
      return {
        ...state,
        teams: state.teams.map((team) =>
          team._id === action.payload._id ? action.payload : team
        ),
      };
    case REMOVE_TEAM:
      return {
        ...state,
        teams: state.teams.filter((team) => team._id !== action.payload),
      };
    case FETCH_TEAM_BY_CLIENT_ID:
      return {
        ...state,
        isLoading: false,
        teams: action.payload,
      };
    case FETCH_TEAM_BY_OWNER_ID:
      return {
        ...state,
        isLoading: false,
        ownerTeams: action.payload,
      };
    default:
      return state;
  }
}
