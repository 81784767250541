import axios from "axios";
import {
  FETCH_BROKERAGES,
  FETCH_BROKERAGE,
  FETCH_BROKERAGE_CLIENTS,
  CREATE_BROKERAGE,
  UPDATE_BROKERAGE,
  REMOVE_BROKERAGE,
  FETCH_BROKERAGES_TABLEVIEW,
} from "./types";

export const fetchBrokerages = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/brokerages`);
    dispatch({
      type: FETCH_BROKERAGES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchBrokerage = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/brokerages/id/${id}`
    );
    dispatch({
      type: FETCH_BROKERAGE,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createBrokerage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/brokerages`,
      formData,
      config
    );
    dispatch({
      type: CREATE_BROKERAGE,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err);
  }
};

export const updateBrokerage = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/brokerages/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_BROKERAGE,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const fetchBrokeragesTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/brokerages/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );
    dispatch({
      type: FETCH_BROKERAGES_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
export const removeBrokerage = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/brokerages/${id}`);
    dispatch({
      type: REMOVE_BROKERAGE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchBrokerageClients = (brokerageId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/brokerage/${brokerageId}`
    );
    dispatch({
      type: FETCH_BROKERAGE_CLIENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
