import {
  FETCH_ENTITY_NOTES,
  FETCH_ENTITY_NOTES_FAIL,
  FETCH_CLIENT_NOTES,
  FETCH_CLIENT_NOTES_FAIL,
  FETCH_ACCESS_NOTES,
  FETCH_ACCESS_NOTES_FAIL,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAIL,
  CREATE_DUPLICATE_NOTES_SUCCESS,
  CREATE_DUPLICATE_NOTES_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  REMOVE_NOTE,
  UPDATE_WEBSOCKET_NOTE,
} from "../action/types";

const initialState = { notes: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ENTITY_NOTES:
      return {
        ...state,
        isLoading: false,
        notes: payload,
      };
    case FETCH_CLIENT_NOTES:
      return {
        ...state,
        isLoading: false,
        clientNotes: payload,
      };
    case FETCH_ACCESS_NOTES:
      return {
        ...state,
        isLoading: false,
        accessNotes: payload,
      };
    case CREATE_NOTE_SUCCESS:
      const notes = [...state.notes];
      const index = notes.findIndex((note) => note._id === action.payload._id);
      if (index === -1) {
        notes.unshift(action.payload);
      }
      return {
        ...state,
        notes: notes,
        isLoading: false,
      };
    case CREATE_DUPLICATE_NOTES_SUCCESS:
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        isLoading: false,
      };
    case FETCH_ENTITY_NOTES_FAIL:
      return {
        ...state,
        isLoading: false,
        notes: payload,
      };
    case FETCH_CLIENT_NOTES_FAIL:
      return {
        ...state,
        isLoading: false,
        clientNotes: payload,
      };
    case FETCH_ACCESS_NOTES_FAIL:
      return {
        ...state,
        isLoading: false,
        accessNotes: payload,
      };
    case CREATE_NOTE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_DUPLICATE_NOTES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_NOTE_SUCCESS:
      return Object.assign(
        {},
        {
          ...state,
          notes: state.notes.map((note) =>
            note._id === action.payload._id ? action.payload : note
          ),
          isLoading: false,
        }
      );
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case REMOVE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((note) => note._id !== action.payload),

        isLoading: false,
      };
    case UPDATE_WEBSOCKET_NOTE:
      const updatedNote = payload.note;
      const userAccess = payload.userAccess; // Access the user access array from the payload
      // Check if the user has access to the note
      const hasAccess = updatedNote?.note?.access.some((role) =>
        userAccess.includes(role)
      );
      // If the user doesn't have access to the updated note, return the current state unchanged
      if (!hasAccess) {
        return state;
      }

      let updatedNotes = [...state.notes];

      if (updatedNote.action === "add") {
        const index = updatedNotes.findIndex(
          (note) => note._id === updatedNote.note._id
        );
        if (index === -1) {
          updatedNotes.unshift(updatedNote.note);
        }
      } else if (updatedNote.action === "edit") {
        updatedNotes = updatedNotes.map((note) =>
          note._id === updatedNote.note._id ? updatedNote.note : note
        );
      } else if (updatedNote.action === "delete") {
        updatedNotes = updatedNotes.filter(
          (note) => note._id !== updatedNote.note._id
        );
      }

      return {
        ...state,
        isLoading: false,
        notes: updatedNotes,
      };
    // case UPDATE_WEBSOCKET_NOTE:
    //   const updatedNote = payload.note;
    //   // const userAccess = state.auth.user.access;
    //   console.log({ payload });
    //   let updatedNotes = [...state.notes];
    //   if (updatedNote.action === "add") {
    //     const index = updatedNotes.findIndex(
    //       (note) => note._id === updatedNote.note._id
    //     );
    //     if (index === -1) {
    //       updatedNotes.unshift(updatedNote.note);
    //     }
    //   } else if (updatedNote.action === "edit") {
    //     let tempUpdatedNotes = updatedNotes.map((note) =>
    //       note._id === updatedNote.note._id ? updatedNote.note : note
    //     );
    //     updatedNotes = tempUpdatedNotes;
    //   } else {
    //     let tempUpdatedNotes = updatedNotes.filter(
    //       (note) => note._id !== updatedNote.note._id
    //     );
    //     updatedNotes = tempUpdatedNotes;
    //   }
    //   return {
    //     ...state,
    //     isLoading: false,
    //     notes: updatedNotes,
    //   };
    default:
      return state;
  }
}
