import React, { useState, useEffect } from "react";
import { CreditCardOutlined } from "@ant-design/icons";
import { Button, Alert, Space, message, Popconfirm } from "antd";

import Loading from "../../../../shared/loading/Loading.js";

import "./style.scss";
import CardListItem from "./CardListItem";

const CardList = ({
  cards,
  selectedCard,
  removePaymentMethod,
  selectedClient,
  allowDelete,
  allowPay,
  handleChargeCard,
  paymentSuccess,
  setSelectedCardId,
  removePaymentMethodForShoot,
  allowRemovePaymentMethodForShoot,
}) => {
  const [loading, setLoading] = useState(true);
  const selectedPayment = cards?.find((card) => card.id === selectedCard);
  useEffect(() => {
    if (cards) setLoading(false);
  }, [cards]);

  const renderEmptyState = () => {
    return (
      <div style={{ padding: 10, color: "#666666", fontSize: 16 }}>
        <CreditCardOutlined className="mr-2" /> No cards available on file
      </div>
    );
  };

  const renderEmptyCard = () => {
    return (
      <div style={{ padding: 10, color: "#666666", fontSize: 16 }}>
        <CreditCardOutlined className="mr-2" /> No selected card available
      </div>
    );
  };

  return (
    <div className="inner-card">
      {loading && <Loading />}
      {selectedPayment && (
        <>
          <h4>Preferred Card</h4>
          {allowRemovePaymentMethodForShoot && (
            <Alert
              message="Remove preferred card for this shoot"
              type="warning"
              action={
                <Space>
                  <Popconfirm
                    placement="topRight"
                    title="Are you sure you want to remove this card?"
                    onConfirm={removePaymentMethodForShoot}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small" type="ghost">
                      Remove
                    </Button>
                  </Popconfirm>
                </Space>
              }
            />
          )}
          <div className="card-wrapper">
            {selectedPayment ? (
              <CardListItem
                card={selectedPayment}
                allowDelete={allowDelete}
                allowPay={allowPay}
                handleChargeCard={handleChargeCard}
                paymentSuccess={paymentSuccess}
                removePaymentMethod={removePaymentMethod}
                selectedClient={selectedClient}
                setSelectedCardId={setSelectedCardId}
              />
            ) : (
              renderEmptyCard()
            )}
          </div>
        </>
      )}
      {cards && (
        <>
          <h4>Cards on File</h4>
          <div className="card-wrapper">
            {cards?.length === 0 && renderEmptyState()}
            {cards &&
              cards?.length > 0 &&
              cards
                ?.filter((card) => card?.id !== selectedPayment?.id)
                .map((card) => (
                  <CardListItem
                    card={card}
                    allowDelete={allowDelete}
                    allowPay={allowPay}
                    handleChargeCard={handleChargeCard}
                    paymentSuccess={paymentSuccess}
                    removePaymentMethod={removePaymentMethod}
                    selectedClient={selectedClient}
                    setSelectedCardId={setSelectedCardId}
                  />
                ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CardList;
