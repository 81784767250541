import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import NumberFormat from "react-number-format";
import {
  Card,
  Table,
  Button,
  Tag,
  Input,
  Modal,
  Typography,
  message,
  Row,
  Col,
  Alert,
} from "antd";
import {
  MailOutlined,
  EditOutlined,
  ZoomInOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  fetchInvoices,
  fetchClientInvoices,
  updateInvoice,
  createInvoice,
  deleteInvoice,
} from "../../redux/action/invoiceAction";
import { fetchOfferings } from "../../redux/action/offeringAction";
import { updateShoot } from "../../redux/action/shootAction";
import {
  fetchClientByUserId,
  fetchClients,
} from "../../redux/action/clientAction";
import { createActivity } from "../../redux/action/activityAction";
import { createCredit, updateCredit } from "../../redux/action/creditAction";
import Loading from "../../shared/loading/Loading.js";
import { diff } from "deep-object-diff";
import InvoiceModal from "./Invoices/InvoiceModal";
import ability from "../../user/Component/Auth/ability";
import {
  photoCodes,
  photographersPayCalc,
} from "../../shared/payBreakdown/photographersPayCalc";
import { calculateTax } from "../../shared/utils/utils";
import queryString from "query-string";
import DownloadCsvInvoices from "../../shared/Components/DownloadCsvInvoices";

// import { invoices } from "./Invoices/data";

const { Search } = Input;

const Invoices = ({
  fetchInvoices,
  fetchClients,
  fetchClientInvoices,
  fetchClientByUserId,
  fetchOfferings,
  handleCurrentPath,
  createInvoice,
  createCredit,
  updateInvoice,
  deleteInvoice,
  updateShoot,
  createActivity,
  updateCredit,
  client: { clients, clientProfile },
  invoice: { invoices, totalCount, isLoading },
  auth: { user },
  offering: { offerings },
  location,
}) => {
  const [state, setState] = useState({
    invoiceModal: false,
    creditModal: false,
    mode: "add",
    modalTitle: null,
  });
  const { invoiceModal, mode, modalTitle, creditModal } = state;
  let diffAmount = 0;
  const [creditReason, setCreditReason] = useState("");
  const [updAmenity, setUpdAmenity] = useState();
  const [updSubdivision, setUpdSubdivision] = useState();
  const [current, setCurrent] = useState();
  const [showReasonAlert, setShowReasonAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "HS#",
      dataIndex: "hsn",
      key: "hsn",
      responsive: ["sm"],
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "client_name",
      width: 180,
      key: "client_name",
      render: (full, record) => (
        <span>
          {user.access.includes("ADMIN") || user.access.includes("COMMS") ? (
            <Link to={`/admin/clients/${record.client?._id}`}>
              {record.client?.full}
            </Link>
          ) : (
            record.client?.full
          )}
        </span>
      ),
      sorter: true,
      ellipsis: true,
      responsive: ["md", "lg"],
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 400,
      render: (address, record) => <span>{record.address} </span>,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <span>
          <NumberFormat
            value={record.balance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      ),
      sorter: true,
      ellipsis: true,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "paid",
      key: "paid",
      render: (status, record) => (
        <div>
          <>
            {record.canceled && <Tag>Cancelled</Tag>}
            {record.sent === true && <Tag color="gold">Sent</Tag>}
            {record.paid ? (
              true && <Tag color="green">Paid</Tag>
            ) : (
              <Tag color="red">Unpaid</Tag>
            )}
            {record.reschedule === true && <Tag color="pink">Rescheduled</Tag>}
            {record.refunded && <Tag color="purple">Refunded</Tag>}
          </>
        </div>
      ),
      sorter: true,
      ellipsis: true,
      width: 180,
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Button
            onClick={() => history.push(`/admin/invoices/${record._id}`)}
            size="small"
            type="primary"
            style={{ margin: "0 5px 5px 0" }}
            icon={<ZoomInOutlined />}
          >
            View
          </Button>
          {ability.can("edit", "Invoices") && (
            <Button
              onClick={() => showModal(record)}
              size="small"
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          )}
          {record.paid === false && ability.can("send", "Invoices") ? (
            <Button
              onClick={() => sendInvoice(record)}
              size="small"
              type="dashed"
              style={{ margin: "0 5px 5px 0" }}
              icon={<MailOutlined />}
            >
              {record.sent === false && !record.paid ? "Send" : "Resend"}
            </Button>
          ) : null}
        </span>
      ),
    },
  ];
  const [data, setData] = useState({});
  const currentClient = (value) => {
    setCurrent(value);
  };
  const updAmen = (value) => {
    setUpdAmenity(value);
  };
  const updSub = (value) => {
    setUpdSubdivision(value);
  };
  const [tableOpt, setOperation] = useState({
    client: null,
    search: "",
    filter: "",
    sortOrder: "descend",
    sortField: "_created_at",
    limit: 10,
    skip: 1,
  });
  const { search, filter, sortOrder, sortField, client, skip, limit } =
    tableOpt;
  let history = useHistory();
  useEffect(() => {
    handleCurrentPath("Invoices", "3");
    fetchMyInvoices();
    if (!offerings?.length) {
      fetchOfferings();
    }
    if (!clients?.length) {
      fetchClients();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      fetchMyInvoices();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMyInvoices = async () => {
    let skip = 1;
    const value = queryString.parse(history.location.search);
    if (value.page) {
      skip = parseInt(value.page);
      setOperation({ ...tableOpt, skip });
    } else {
      setOperation({ ...tableOpt, skip });
    }
    if (
      user &&
      (user?.access?.includes("ADMIN") || user?.access?.includes("COMMS"))
    ) {
      fetchInvoices({
        client: null,
        filter,
        search,
        sortOrder: "descend",
        sortField: "_created_at",
        skip,
        limit,
      });
    } else {
      setLoading(true);
      const client = await fetchClientByUserId(user._id);
      setOperation({
        ...tableOpt,
        client: client.data._id,
        skip,
      });
      fetchInvoices({
        client: client.data._id,
        filter,
        search,
        sortOrder: "descend",
        sortField: "_created_at",
        skip,
        limit,
      });
      setLoading(false);
    }
  };

  const myRef = useRef();

  const sendInvoice = async (invoice) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/invoices/send`,
        { ...invoice }
      );

      if (res.status === 200) {
        updateInvoice(invoice._id, {
          ...invoice,
          sent: true,
          _updated_at: new Date(),
        });
        if (!invoice.sent) {
          createActivity({ ...invoice.shoot, user: user._id }, "Invoice sent.");
        } else {
          createActivity(
            { ...invoice.shoot, user: user._id },
            "Invoice re-sent."
          );
        }
        message.success("Invoice sent successfully!");
      } else {
        message.error("Oops! Invoice could not be sent!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Modal
  const showModal = (item) => {
    setData({ ...item });
    setState({
      ...state,
      invoiceModal: true,
      mode: "edit",
      modalTitle: "Edit Package",
    });
  };
  const handleOk = async () => {
    var updatedInvoice = {};
    var total_pay = 0.0;
    const modalState = myRef.current.getModalState();
    const { currentOfferings } = modalState;
    var checkForChanges = false;
    let text = "Invoice Edit. ";
    if (mode === "edit") {
      //activity
      const prevOfferings = data.products;
      const deletedOff = [];
      if (prevOfferings.length != currentOfferings.length) {
        prevOfferings.forEach((prevOff) => {
          const deleted = currentOfferings.find(
            (prodOff) => prodOff?._id === prevOff?._id
          );
          if (deleted === undefined) {
            deletedOff.push({ ...prevOff });
          }
        });
      }

      const newOff = [];
      const { updatedOfferings, newOfferingsIds } = modalState;

      updatedOfferings.map((off) => {
        newOfferingsIds.map((offId) => {
          if (off?._id === offId) {
            newOff.push({ ...off });
          }
        });
      });

      let deletedOffText = "These services: ";
      deletedOff.map((off, index) => {
        deletedOffText += off.description;
        if (deletedOff.length - 1 !== index) {
          deletedOffText += ", ";
        }
      });

      let newOffText = "These services: ";
      newOff.map((off, index) => {
        newOffText += off.description;
        if (newOff.length - 1 !== index) {
          newOffText += ". ";
        }
      });

      if (deletedOffText.length) {
        text += deletedOffText + " were deleted. ";
      }

      if (newOffText.length) {
        if (deletedOffText.length) {
          text += newOffText + " were added.";
        } else {
          text += newOffText + " were added.";
        }
      }
    }

    if (modalState.newOfferingsIds.length > 0) {
      for (let i = 0; i < modalState.newOfferingsIds.length; i++) {
        for (let j = 0; j < modalState.updatedOfferings.length; j++) {
          if (
            modalState.updatedOfferings[j]?._id ===
            modalState.newOfferingsIds[i]
          ) {
            currentOfferings.push(modalState.updatedOfferings[j]);
            break;
          }
        }
      }
    }
    currentOfferings.forEach((prod) => {
      if (prod.code === "SP" || prod.code === "MSP" || prod.code === "CSP") {
        if (updAmenity) {
          prod.amenities = [...updAmenity];
        }
      }
      if (
        photoCodes?.includes(prod.code) &&
        data?.shoot?.events?.photographer
      ) {
        const pay = photographersPayCalc(
          prod?.code,
          data?.shoot?.events?.photographer?.pay_multiplier,
          data?.shoot?.booking_form?.sqft,
          data?.shoot?.trip_charge,
          data?.shoot?.events.start,
          data?.shoot?.events.end,
          data?.shoot?.photographer_drive_time,
          prod.amenities
        );
        total_pay += pay;
        prod.photographer_pay = pay;
      }
    });
    if (mode === "edit") {
      currentOfferings.forEach((currOff) => {
        const prevOff = data.products.filter(
          (prodOff) => prodOff?._id === currOff?._id
        );
        const res = diff(prevOff, currOff);
        if (Object.keys(res).length) {
          checkForChanges = true;
        }
      });
    }
    if (mode === "edit" && checkForChanges) {
      const tax = calculateTax(modalState.subtotal);
      const subtotalDiff = modalState.subtotal - data.subtotal;
      const newTax = calculateTax(subtotalDiff);
      const total =
        data.balance === data.total
          ? data.subtotal + subtotalDiff + tax
          : data.total + subtotalDiff + newTax;
      let balance = parseFloat(
        (data.balance + (total - data.total)).toFixed(2)
      );
      if (balance < 0.0) {
        diffAmount = balance * -1;
        balance = 0;
      }
      if (diffAmount > 0 && !creditModal) {
        setState({ ...state, creditModal: true });
        return;
      }
      updatedInvoice = { ...data };
      updatedInvoice.sent = false;
      updatedInvoice.products = currentOfferings;
      updatedInvoice.subtotal = modalState.subtotal;
      updatedInvoice.total = total.toFixed(2);
      updatedInvoice.balance = balance.toFixed(2);
      updatedInvoice.paid = balance > 0 ? false : true;
      updatedInvoice.tax = tax;
      updatedInvoice.photographer_total_pay = total_pay;
      updatedInvoice.shoot.photos.turnaround = "";
      updatedInvoice.shoot.photos.ie_package = "";
      updatedInvoice.shoot.video.package = "";
      updatedInvoice.shoot.video.turnaround = "";
      // updatedInvoice.shoot.amenities = updAmenity
      //   ? updAmenity
      //   : updatedInvoice.shoot.amenities;
      updatedInvoice.shoot.subdivision = updSubdivision
        ? updSubdivision
        : updatedInvoice.shoot.subdivision;
      updatedInvoice.shoot.reqs = updatedInvoice.products.map(
        (off) => off.code
      );
      var currDuration = 0;
      var newPhtoTnd = " : ";
      var newVdTnd = " : ";
      var newReqArr = [];
      var newReqs = "";
      currentOfferings.forEach((off) => {
        currDuration = currDuration + (!off.duration ? 0 : off.duration);
        if (off.category.includes("Photo Turnaround")) {
          newPhtoTnd = newPhtoTnd + off.description + " ";
          updatedInvoice.shoot.photos.turnaround = off.description;
        }
        if (off.category.includes("Video Turnaround")) {
          newVdTnd = newVdTnd + off.description + " ";
          updatedInvoice.shoot.video.turnaround = off.description;
        }
        if (off.category.includes("Drone Photos and HD Video")) {
          updatedInvoice.shoot.video.package = off.description;
        }
        if (off.category.includes("Interior/Exterior Photos")) {
          updatedInvoice.shoot.photos.ie_package = off.description;
        }
        newReqArr.push(off.description);
      });
      if (newReqArr.length > 1) {
        newReqs = " : " + newReqArr.toString().replace(/,/g, ", ") + " ";
      } else {
        newReqs = " : " + newReqArr.toString() + " ";
      }
      updatedInvoice.shoot.events.duration = currDuration;
      updatedInvoice.shoot.invoice = {
        _id: updatedInvoice?._id,
        products: [...currentOfferings],
        duration: currDuration,
      };
      let currDescrption = updatedInvoice?.shoot?.events?.description;
      const desc1 = currDescrption?.replace(
        currDescrption
          ?.split("Photo Turnaround")[1]
          ?.split("Video Turnaround")[0],
        newPhtoTnd
      );
      const desc2 = desc1?.replace(
        currDescrption.split("Video Turnaround")[1].split("Requirements")[0],
        newVdTnd
      );
      const newDescription = desc2?.replace(
        currDescrption.split("Requirements")[1].split("Focus on")[0],
        newReqs
      );
      updatedInvoice.shoot.events.duration = currDuration;
      updatedInvoice.shoot.events.description = newDescription;
      const updatedShoot = { ...updatedInvoice.shoot };
      updatedInvoice.shoot = updatedShoot?._id;
      if (diffAmount > 0 && creditModal) {
        const [invoiceRes, shootRes, creditRes] = await Promise.all([
          updateInvoice(updatedInvoice?._id, updatedInvoice),
          updateShoot(updatedShoot?._id, updatedShoot),
          createCredit({
            valid: true,
            redeemed: false,
            client: updatedInvoice.client?._id, // Add client
            amount: diffAmount,
            reason: creditReason, // Add the value of referred_by
            code: "REFUND",
            credit_type: "Refund Credit",
          }),
        ]);
        if (creditRes.data) {
          message.success("The balance has been transferred to a credit.");
        } else {
          message.error("Oops! Balance transfer failed!");
        }
        if (invoiceRes.data && shootRes.data) {
          createActivity({ _id: data.shoot?._id }, text);
          message.success("Package updated successfully!");
        } else {
          message.error("Oops! Updating process failed!");
        }
        setState({ ...state, creditModal: false });
      } else {
        if (!updAmenity) {
          const [invoiceRes, shootRes] = await Promise.all([
            updateInvoice(updatedInvoice?._id, updatedInvoice),
            updateShoot(updatedShoot?._id, updatedShoot),
          ]);

          if (invoiceRes?.data && shootRes?.data) {
            createActivity({ _id: data.shoot?._id }, text);
            message.success("Package updated successfully!");
          } else {
            message.error("Oops! Updating process failed!");
          }
        } else {
          await updateInvoice(updatedInvoice?._id, updatedInvoice);
          await updateShoot(updatedShoot?._id, updatedShoot);
          createActivity({ _id: data.shoot?._id }, text);
          message.success("Package updated successfully!");
        }
      }
    } else {
      if (!modalState.shootId) {
        // message.error("Please select Shoot to create invoice.");
        const tax = calculateTax(modalState.subtotal);
        const total = modalState.subtotal + tax;
        const balance = modalState.subtotal + tax;
        updatedInvoice = {
          sent: false,
          products: currentOfferings,
          subtotal: modalState.subtotal,
          total: total,
          balance: balance,
          tax: tax,
          discount: modalState.discount,
          photographer_total_pay: total_pay,
          tax_exempt: false,
          shoot: null,
          client: modalState.clientId,
          brokerage: modalState.brokerageId ? modalState.brokerageId : null,
          hsn: "",
          client_name: current?.full,
          email: current?.email,
          address: "",
        };
      } else if (!modalState.newOfferingsIds.length) {
        message.error("No content to create invoice.");
        return;
      } else {
        const tax = calculateTax(modalState.subtotal);
        const total = modalState.subtotal + tax;
        const balance = modalState.subtotal + tax;
        updatedInvoice = {
          sent: false,
          products: currentOfferings,
          subtotal: modalState.subtotal,
          total: total,
          balance: balance,
          tax: tax,
          discount: modalState.discount,
          photographer_total_pay: total_pay,
          tax_exempt: false,
          shoot: modalState.shootId,
          client: modalState.clientId,
          brokerage: modalState.brokerageId ? modalState.brokerageId : null,
          hsn: modalState.shoot.hsn,
          client_name:
            modalState.shoot.client_name_first +
            " " +
            modalState.shoot.client_name_last,
          address: modalState.shoot.address.full,
        };
      }

      const resultInvoice = await createInvoice(updatedInvoice);
      if (resultInvoice.data) {
        updateShoot(modalState.shootId, {
          invoice: { _id: resultInvoice.data?._id },
        });
        message.success("Package updated successfully!");
      } else {
        message.error("Oops! Updating process failed!");
      }
    }
    setState({ ...state, invoiceModal: false, creditModal: false });
    setUpdAmenity(undefined);
    setUpdSubdivision(undefined);
  };
  const handleCancel = (e) => {
    setUpdAmenity(undefined);
    setUpdSubdivision(undefined);
    setData();
    setState({ ...state, invoiceModal: false });
  };
  const handleCreditCancel = (e) => {
    setState({ ...state, creditModal: false });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      filter: filters.status ? filters.status.toString() : "",
      sortOrder: "descend",
      sortField: "_created_at",
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchInvoices({
      client,
      filter: filters.status ? filters.status.toString() : "",
      sortOrder: "descend",
      sortField: "_created_at",
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      search: target.value,
      skip: 1,
    });
    fetchInvoices({
      client,
      filter,
      sortField,
      sortOrder,
      search: target.value,
      skip: 1,
      limit,
    });
  };

  return (
    <React.Fragment>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {loading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!loading && invoices && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <h2>Invoices</h2>
              </Col>
              <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                <Search
                  placeholder="Search by HSN, address or client"
                  onChange={searchHandler}
                  style={{ width: "80%", marginBottom: "5px" }}
                />
                {/* <DatePicker
                  style={{
                    marginBottom: "10px",
                  }}
                /> */}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                {user &&
                  clientProfile &&
                  ability.can("download", "Invoices") && (
                    <DownloadCsvInvoices clientId={clientProfile?._id} />
                  )}
                {user && ability.can("create", "Invoices") && (
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        setState({
                          ...state,
                          invoiceModal: true,
                          modalTitle: "Create Invoice",
                          mode: "add",
                        })
                      }
                    >
                      <PlusOutlined /> Add an Invoice
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Table
              loading={isLoading}
              dataSource={invoices}
              columns={columns}
              rowKey={(record) => record?._id}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
              }}
              onChange={handleTableChange}
              scroll={{ x: 500 }}
            />
          </div>
        )}
      </Card>
      <Modal
        title={
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            {modalTitle}
          </Typography.Title>
        }
        visible={invoiceModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        destroyOnClose={true}
      >
        <div>
          {invoiceModal && (
            <InvoiceModal
              auth={{ user }}
              clientDetails={currentClient}
              mode={mode}
              ref={myRef}
              updAmenity={updAmenity}
              updSubdivision={updSubdivision}
              updAmen={updAmen}
              updSub={updSub}
              updShoot={updateShoot}
              invoice={data}
              offerings={offerings}
            />
          )}
        </div>
      </Modal>
      <Modal
        //style={{ marginTop: "150px" }}
        title={
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            Credit
          </Typography.Title>
        }
        visible={creditModal}
        onOk={() => (creditReason ? handleOk() : setShowReasonAlert(true))}
        onCancel={handleCreditCancel}
        style={{ top: 150 }}
        destroyOnClose={true}
      >
        <>
          <ExclamationCircleOutlined style={{ color: "#FFC300" }} />
          <Typography.Text
            style={{
              paddingLeft: "0.5em",
              marginBottom: 25,
            }}
          >
            Removing this service will create a client credit for the balance.
          </Typography.Text>
        </>
        {showReasonAlert && (
          <Alert
            style={{ marginTop: "30px" }}
            message="Please give a reason for the credit."
            type="error"
          />
        )}
        <div className="form-group mt-3">
          <label>Reason for the credit (required): </label>
          <Input
            style={{ marginTop: "0.5em" }}
            placeholder="Enter your reason"
            allowClear
            onChange={(event) => setCreditReason(event.target.value)}
          ></Input>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  client: state.clients,
  invoice: state.invoice,
  offering: state.offering,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchClients,
  fetchInvoices,
  fetchClientInvoices,
  fetchClientByUserId,
  fetchOfferings,
  createInvoice,
  createActivity,
  updateInvoice,
  deleteInvoice,
  updateShoot,
  updateCredit,
  createCredit,
})(Invoices);
