import axios from "axios";
import {
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  FETCH_COUPON_BY_ID_SUCCESS,
  FETCH_COUPON_BY_ID_FAIL,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAIL,
  UPDATE_COUPON_BY_CODE_SUCCESS,
  UPDATE_COUPON_BY_CODE_FAIL,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  REMOVE_COUPON_SUCCESS,
  REMOVE_COUPON_FAIL,
  FETCH_COUPONS_TABLEVIEW_SUCCESS,
  FETCH_COUPONS_TABLEVIEW_FAIL,
} from "./types";

export const fetchCoupons = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/coupons`);
    dispatch({
      type: FETCH_COUPONS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_COUPONS_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const fetchCouponById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/coupons/${id}`
    );
    dispatch({
      type: FETCH_COUPON_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_COUPON_BY_ID_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const validateCoupon =
  (couponCode, currentClientId) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/coupons/validate/${couponCode}?client=${currentClientId}`
      );
      dispatch({
        type: VALIDATE_COUPON_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VALIDATE_COUPON_FAIL,
      });
      return {
        data: null,
        error: err.response,
      };
    }
  };

export const fetchCouponsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/coupons/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );

    dispatch({
      type: FETCH_COUPONS_TABLEVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_COUPONS_TABLEVIEW_FAIL,
    });
    return {
      data: null,
    };
  }
};

export const createCoupon = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/coupons`,
      formData
    );
    console.log(res.data);
    dispatch({
      type: CREATE_COUPON_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.error("Error occured while creating the coupon as " + err.message);
    dispatch({
      type: CREATE_COUPON_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const updateCoupon = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/coupons/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_COUPON_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("Error occured while updating the coupon as " + err.message);
    dispatch({
      type: UPDATE_COUPON_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const updateCouponByCode = (code, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/coupons/code/${code}`,
      formData
    );
    dispatch({
      type: UPDATE_COUPON_BY_CODE_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("Error occured while updating the coupon as " + err.message);
    dispatch({
      type: UPDATE_COUPON_BY_CODE_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const removeCoupon = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/coupons/${id}`
    );
    dispatch({
      type: REMOVE_COUPON_SUCCESS,
      payload: id,
    });
    return res;
  } catch (err) {
    dispatch({
      type: REMOVE_COUPON_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};
