import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { fetchTemplates } from "../../../../redux/action/templateAction";
import { fetchClientByUserId } from "../../../../redux/action/clientAction";
import { createActivity } from "../../../../redux/action/activityAction";
import SunEditor from "suneditor-react";
import { Button, Drawer, Select, Row, Col, Input, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";

const { Option } = Select;

const SendEmail = ({
  firstName,
  lastName,
  appointmentDate,
  appointmentTime,
  appointmentAddress,
  cutOffTime,
  shoot,
  primaryEmail,
  secondaryEmail,
  templates: { templates, isLoading },
  auth: { user },
  fetchTemplates,
  fetchClientByUserId,
  createActivity,
}) => {
  const editor = useRef();
  const [visible, setVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [to, setTo] = useState("");
  const [cc, setCc] = useState(secondaryEmail || "");
  const [subject, setSubject] = useState(appointmentAddress || "");
  const [body, setBody] = useState("");

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const currUser = await fetchClientByUserId(user._id);
      setCurrentUser(currUser?.data);
    };
    fetchCurrentUser();
    fetchTemplates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTo(primaryEmail);
    setCc(secondaryEmail);
    setSubject(appointmentAddress);
  }, [appointmentAddress, primaryEmail, secondaryEmail]);

  const renderSignature = () => {
    return `
    <div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td align="top">
            <table
              width="140px"
              border="0"
              cellpadding="5"
              cellspacing="5"
            >
              <tbody>
                <tr>
                  <td width="140" align="top">
                    <img
                      src=${currentUser?.headshot_url}
                      width="140"
                      height="140"
                      alt=""
                    />
                  </td>
                  <td width="30">
                    <table
                      width="1"
                      border="0"
                      align="center"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td width="5" bgcolor="#008bd0">
                            <img
                              src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/spacer.gif"
                              width="3"
                              height="135"
                              alt=""
                              paddingTop="12px"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      width="100%"
                      border="0"
                      align="center"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td
                            align="top"
                            style="padding-bottom:5px;"
                          >
                            <span
                            style="font-family: 'Open Sans', Arial, sans-serif; font-size: 18px; line-height: 18px; font-weight: 700; color: #008bd0; text-transform: uppercase;"
                            >
                              ${currentUser?.full}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="top"
                            style="padding-bottom:15px;"
                          >
                            <span
                            style={{ fontFamily: 'Open Sans, Arial, sans-serif', fontSize: '16px', lineHeight: '16px', fontWeight: 700, color: '#384241' }}
                            >
                              ${currentUser?.designation}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                          style="font-family: 'Open Sans', Arial, sans-serif; font-size: 14px; line-height: 14px; font-weight: 400; color: #647875; padding-bottom: 5px;"
                          >
                            book@homesnappers.com
                          </td>
                        </tr>
                        <tr>
                          <td
                          style={{ fontFamily: 'Open Sans, Arial, sans-serif', fontSize: '14px', lineHeight: '14px', fontWeight: '400', color: '#647875' }}
                          >
                            <a
                              href="tel"
                              style="color: #647875 !important; text-decoration: none !important;"
                            >
                              <span
                                style="color:#647875;text-decoration:none",
                                }}
                              >
                                ${currentUser?.phone || "972.533.6621"}
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <tr>
                            <td
                              style="font-family: "Open Sans, Arial, sans-serif";
                              font-size: 14px;
                              line-height: 14px;
                              font-weight: 400;
                              color: #647875;
                              padding-bottom: 5px;
                              padding-top: 12px;
                            >
                              <img
                                src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/hs-email-logo.png"
                                alt="Home Snappers logo"
                              ></img>
                            </td>
                          </tr>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table></div>`;
  };

  const renderAcceptButton = () => {
    return `   
    <button style="padding: 10px 15px; background-color: #4E89C8; border-radius: 6px; color: white; margin-right: 25px;">
      <a href="${process.env.REACT_APP_DOMAIN_URL}/admin/shoot-confirmation-link/${shoot?._id}/accepted" style="padding: 10px 15px; background-color: #4E89C8; border-radius: 6px; text-decoration: none; color: white;">
      <singleline>Accept</singleline>
        </a>
      </button>
    `;
  };

  const renderRescheduleButton = () => {
    return `
    <button style="padding: 10px 15px; background-color: #4E89C8; border-radius: 6px; color: white; margin-right: 25px;">
      <a href="${process.env.REACT_APP_DOMAIN_URL}/admin/shoot-confirmation-link/${shoot?._id}/declined" style="padding: 10px 15px; background-color: #4E89C8; border-radius: 6px; text-decoration: none; color: white;">
      <singleline>Reschedule</singleline>
        </a>
      </button>
   
  `;
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSendEmailContent = async () => {
    const emailContent = {
      from: process.env.REACT_APP_ADMIN_EMAIL || "book@homesnappers.com",
      to,
      cc,
      subject,
      body,
    };
    try {
      const res = await fetch(
        "https://hooks.zapier.com/hooks/catch/412492/3y19vc4/",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailContent),
        }
      );

      if (res) {
        setVisible(false);
        setBody("");
        setTo("");
        setCc("");
        setSubject("");

        // Create activity
        const activityText = `${selectedTemplate.title} email sent to ${primaryEmail}`;
        await createActivity({ ...shoot, user: user?._id }, activityText);
      }

      message.success("Email sent successfully");
    } catch (error) {
      message.error("Error sending email");
      console.log(error);
    }
  };

  const handleTemplateSelect = (value) => {
    const templateName = value;
    const selectedTemplate = templates.find(
      (template) => template.title === templateName
    );

    const formattedContent = selectedTemplate.text
      .replace("{{client_first_name}}", firstName)
      .replace("{{client_last_name}}", lastName)
      .replace("{{appointment_day}}", appointmentDate)
      .replace("{{appointment_time}}", appointmentTime)
      .replace("{{appointment_address}}", appointmentAddress)
      .replace("{{cutoff_time}}", cutOffTime)
      .replace("{{signature}}", renderSignature())
      .replace("{{accept_button}}", renderAcceptButton())
      .replace("{{reschedule_button}}", renderRescheduleButton());

    editor.current.setContents(formattedContent);
    setSelectedTemplate(selectedTemplate);
    setBody(formattedContent);
    setSubject(`${selectedTemplate.title}: ${appointmentAddress.trim()}`);
  };

  const handleEditorChange = (content) => {
    setBody(content);
  };

  return (
    <>
      <Button size="small" danger onClick={showDrawer}>
        <SendOutlined /> Send Email
      </Button>
      <Drawer
        title="Email Preview"
        placement="right"
        destroyOnClose={true}
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ background: "white" }}
        width={720}
        footer={
          <>
            <Button onClick={() => onSendEmailContent()} type="primary">
              Send
            </Button>
            <Button type="link" onClick={onClose}>
              Cancel
            </Button>
          </>
        }
      >
        <div className="send-email">
          <div>
            <Row gutter={24} className="mb-3">
              <Col span={24}>
                <span className="mr-1">Select a Template: </span>
                <span>
                  <Select
                    style={{ width: 200, paddingBottom: "10px" }}
                    placeholder="Select a template"
                    optionFilterProp="children"
                    onChange={handleTemplateSelect}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {templates.map((template) => (
                      <Option key={template._id} value={template.title}>
                        {template.title}
                      </Option>
                    ))}
                  </Select>
                </span>
              </Col>
              <Col span={24} className="mb-3">
                <span className="mr-1 text-muted">To: </span>
                <span>
                  <Input value={to} onChange={(e) => setTo(e.target.value)} />
                </span>
              </Col>
              <Col span={24} className="mb-3">
                <span className="mr-1 text-muted">Cc: </span>
                <span>
                  <Input value={cc} onChange={(e) => setCc(e.target.value)} />
                </span>
              </Col>
              <Col span={24} className="mb-3">
                <span className="mr-1 text-muted">Subject: </span>
                <span>
                  <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </span>
              </Col>
            </Row>
            <SunEditor
              autoFocus={true}
              className={"manipulateDisabledButton"}
              lang="en"
              setContents={selectedTemplate ? selectedTemplate.content : ""}
              setOptions={{
                showPathLabel: false,
                minHeight: "40vh",
                maxHeight: "40vh",
                placeholder: "Add your text here...",
                lineAttrReset: true,
                plugins: plugins,
                templates: templates,
                value: body,
                attributesWhitelist: {
                  all: "style",
                },
                buttonList: [
                  ["undo", "redo"],
                  ["fontSize", "formatBlock"],
                  ["paragraphStyle"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  // Line break
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link", "codeView"],
                ],
                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                font: [
                  "Arial",
                  "Calibri",
                  "Comic Sans",
                  "Courier",
                  "Garamond",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Palatino Linotype",
                  "Segoe UI",
                  "Tahoma",
                  "Times New Roman",
                  "Trebuchet MS",
                ],
              }}
              getSunEditorInstance={(sunEditor) => {
                editor.current = sunEditor;
              }}
              onChange={handleEditorChange}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.templates,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchTemplates,
  fetchClientByUserId,
  createActivity,
})(SendEmail);
