import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import ImageGallery from "react-image-gallery";
import AudioButton from "./AudioButton";
import useWindowSize from "../../../hooks/useWindowSize";

import "./gallery.scss";

import "../../../App.scss";

import processIcon from "../../../content/process.png";
import { READY } from "../../../redux/action/shoot/shootConstants";

const Gallery = ({ photos, changeBg }) => {
  const windowSize = useWindowSize();

  const [state, setState] = useState({ images: [], background: "" });
  const [touchEnded, setTouchEnded] = useState(false);

  useEffect(() => {
    const amenityPhotos = [...photos.amenityPhotos];
    const gallery = [...photos.gallery];
    let updatedImages = gallery.concat(amenityPhotos);
    updatedImages.forEach((element, index) => {
      const image = {
        original: element.replace("/high/", "/web/"),
        thumbnail: element.replace("/high/", "/thumb/"),
      };
      gallery[index] = image;
    });
    changeBg(photos.gallery[photos.gallery.length - 1]);
    setState({ images: gallery, background: photos.gallery[1] });
  }, [photos]); // eslint-disable-line react-hooks/exhaustive-deps

  const bgCheck = (index) => {
    const len = photos.gallery.length;
    index === 0
      ? changeBg(photos.gallery[len - 1])
      : changeBg(photos.gallery[index - 1]);
    setState({
      ...state,
      background:
        index + 1 >= len ? photos.gallery[0] : photos.gallery[index + 1],
    });
  };
  return (
    state.images.length && (
      <div
        style={{
          backgroundImage: `url(${state.background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row
          style={{
            width: "100%",
            background: "rgba(56, 67, 79, 0.8)",
            filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2))",
          }}
        >
          {photos.status === READY ? (
            <Col
              xs={24}
              sm={24}
              md={{ span: 18, offset: 3 }}
              lg={{ span: 18, offset: 3 }}
              xl={{ span: 18, offset: 3 }}
            >
              <div className="img-gallery-wrapper">
                <ImageGallery
                  items={state.images}
                  autoPlay={true}
                  showFullscreenButton={true}
                  showPlayButton={true}
                  slideInterval={touchEnded ? 10000 : 5000}
                  slideDuration={1000}
                  onBeforeSlide={bgCheck}
                  showIndex={true}
                  thumbnailPosition="right"
                  useBrowserFullscreen={windowSize === "sm" ? false : true}
                  onTouchEnd={() => {
                    if (!touchEnded) {
                      setTouchEnded(true);
                      setTimeout(() => {
                        setTouchEnded(false);
                      }, 1000);
                    }
                  }}
                />
              </div>
            </Col>
          ) : (
            <Col
              span={16}
              align="middle"
              style={{ position: "relative", top: "25%" }}
            >
              <img src={processIcon} style={{ height: "35vh" }} alt="" />
              <Col span={24} align="middle">
                <Typography.Text type="secondary" style={{ color: "white" }}>
                  We are processing your work.
                </Typography.Text>
              </Col>
            </Col>
          )}
        </Row>
        <Row>
          <Col
            className="p-1"
            span={24}
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              zIndex: 1,
            }}
          >
            <AudioButton soundSrc="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/tours/windswept.mp3" />
          </Col>
        </Row>
      </div>
    )
  );
};

export default Gallery;
