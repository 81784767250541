import React from "react";
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";
const ErrorUpload = ({ status }) => {
  return (
    <div style={{ height: "80vh" }}>
      {status === "success" ? (
        <Result
          icon={<SmileOutlined />}
          title="Great, Video uploaded to Youtube successfully!"
          extra={<Button type="primary">Next</Button>}
        />
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, Something is bad happend."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </div>
  );
};

export default ErrorUpload;
