import React, { useState, useRef } from "react";
import moment from "moment";
import SunEditor, { buttonList } from "suneditor-react";

import {
  Button,
  Row,
  Col,
  Select,
  Spin,
  Switch,
  DatePicker,
  message,
} from "antd";
import { Formik } from "formik";
import { Input, Form } from "formik-antd";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import "./styles.scss";

const { Option } = Select;

const BlogForm = ({ initVal, submitHandler, close, mode }) => {
  const [state, setState] = useState({ loader: false });
  const { loader } = state;
  const [publishDate, setPublishDate] = useState(
    mode === "Edit" ? initVal?.published_at : Date.now()
  );
  const [isPublished, setIsPublished] = useState(
    mode === "Edit" ? initVal?.published : false
  );
  const [blogText, setBlogText] = useState(
    mode === "Edit" ? initVal?.text : ""
  );
  const [featuredImgUrl, setFeaturedImgUrl] = useState(
    mode === "Edit" ? initVal?.featuredImage : ""
  );
  const editor = useRef();

  const handleChange = (content) => {
    setBlogText(content);
  };
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const validateRequired = (value) => {
    const error = {};
    if (!value.title) {
      error.title = "Title is required";
    }

    return error;
  };

  const handleSwitch = (checked) => {
    setIsPublished(checked);
  };

  const handleDateChange = (date, dateString) => {
    setPublishDate(date);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      validate={validateRequired}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        try {
          const payload = {
            ...values,
            category: values.category === "" ? "Helpful Tips" : values.category,
            text: blogText,
            published_at: publishDate,
            published: isPublished,
            featuredImage: featuredImgUrl,
          };

          const res = await submitHandler(payload);
          if (res.status === 200) {
            mode === "Add"
              ? message.success("Post created successfully")
              : message.success("Post updated successfully!");
          } else {
            message.error("Oops! Error occured while updating post.");
          }

          setState({ ...state, loader: false });
          close(res.data);
        } catch (error) {
          close(error);
          message.error(`Oops! Error occured. ${error}`);
        }
      }}
      render={({ handleSubmit, setFieldValue, values }) => (
        <div className="form-container blog-post-form">
          <Spin spinning={loader}>
            <Form layout="vertical">
              <Row>
                <Col className="p-4" xs={24} sm={24} md={18} lg={18} xl={18}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                      <Form.Item
                        label="Title"
                        name="title"
                        className="form-group"
                      >
                        <Input name="title" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <div style={{ marginBottom: 18 }}>
                        <strong>Category</strong>
                      </div>
                      <div className="form-group">
                        <Select
                          defaultValue={
                            initVal?.category && initVal?.category === ""
                              ? initVal?.category
                              : "Helpful Tips"
                          }
                          onChange={(value) => setFieldValue("category", value)}
                          style={{ width: "100%" }}
                          name="category"
                        >
                          <Option value="Helpful Tips">Helpful Tips</Option>
                          <Option value="Spotlight">Spotlight</Option>
                          <Option value="Why Home Snappers">
                            Why Home Snappers
                          </Option>
                        </Select>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <SunEditor
                          autoFocus={true}
                          className={"manipulateDisabledButton"}
                          lang="en"
                          setOptions={{
                            showPathLabel: false,
                            minHeight: "80vh",
                            maxHeight: "80vh",
                            placeholder: "Enter your text here!!!",
                            lineAttrReset: true,
                            plugins: plugins,
                            attributesWhitelist: {
                              all: "style",
                            },
                            buttonList: [
                              ["undo", "redo"],
                              ["fontSize", "formatBlock"],
                              ["paragraphStyle"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["table", "link", "image"],
                            ],
                            formats: [
                              "p",
                              "div",
                              "h1",
                              "h2",
                              "h3",
                              "h4",
                              "h5",
                              "h6",
                            ],
                            font: [
                              "Arial",
                              "Calibri",
                              "Comic Sans",
                              "Courier",
                              "Garamond",
                              "Georgia",
                              "Impact",
                              "Lucida Console",
                              "Palatino Linotype",
                              "Segoe UI",
                              "Tahoma",
                              "Times New Roman",
                              "Trebuchet MS",
                            ],
                          }}
                          getSunEditorInstance={getSunEditorInstance}
                          onChange={handleChange}
                          defaultValue={blogText}
                        />
                      </div>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Tags"
                        name="tags"
                        className="form-group"
                      >
                        <Input placeholder="comma separated" name="tags" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Button
                        onClick={handleSubmit}
                        type="primary"
                        style={{ marginTop: "10px" }}
                      >
                        Save
                      </Button>
                      <Button type="link" onClick={close}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <div
                    style={{
                      backgroundColor: "#eee",
                      borderRadius: 5,
                      padding: 15,
                    }}
                  >
                    <h3>Publish</h3>
                    <div className="form-group mb-4">
                      <strong>Status</strong>{" "}
                      <Switch
                        defaultChecked={initVal?.published}
                        checkedChildren="Published"
                        unCheckedChildren="Draft"
                        onChange={handleSwitch}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <strong>Publish Date</strong>{" "}
                      <div>
                        <DatePicker
                          defaultValue={moment(initVal?.published_at)}
                          style={{ width: "100%" }}
                          onChange={handleDateChange}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default BlogForm;
