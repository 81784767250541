import {
  UPDATE_MEDIA,
  CREATE_MEDIA,
  REMOVE_MEDIA,
  FETCH_MEDIA_BY_ID,
} from "../action/types";

const initialState = { media: null };

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEDIA_BY_ID:
      return {
        ...state,
        media: action.payload,
      };

    case CREATE_MEDIA:
      return {
        ...state,
        media: action.payload,
      };
    case REMOVE_MEDIA:
      return {
        ...state,
        media: action.payload,
      };
    case UPDATE_MEDIA:
      return Object.assign(
        {},
        {
          ...state,
          media: action.payload,
        }
      );
    default:
      return state;
  }
}
