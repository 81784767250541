import {
  FETCH_BLOG_POSTS,
  FETCH_BLOG_POSTS_TABLEVIEW,
  FETCH_BLOG_POST,
  CREATE_BLOG_POST,
  UPDATE_BLOG_POST,
  REMOVE_BLOG_POST,
} from "../action/types";

const initialState = { blogPosts: [], selectedBlogPost: {}, isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BLOG_POSTS:
      return {
        ...state,
        isLoading: false,
        blogPosts: payload,
      };
    case FETCH_BLOG_POSTS_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        blogPosts: payload.posts,
        totalCount: payload.count,
      };
    case FETCH_BLOG_POST:
      return {
        ...state,
        isLoading: false,
        selectedBlogPost: payload,
      };
    case CREATE_BLOG_POST:
      return {
        ...state,
        isLoading: false,
        blogPosts: [...state.blogPosts, payload],
        selectedBlogPost: payload,
      };
    case UPDATE_BLOG_POST:
      const updatedBlogPosts = state.blogPosts.concat();
      updatedBlogPosts.map((post, index) => {
        if (post._id === payload._id) {
          updatedBlogPosts[index] = payload;
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        blogPosts: updatedBlogPosts,
        selectedBlogPost: payload,
      };
    case REMOVE_BLOG_POST:
      return {
        ...state,
        isLoading: false,
        blogPosts: state.blogPosts.filter((post) => post._id !== payload),
      };
    default:
      return state;
  }
}
