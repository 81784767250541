import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Card, Table, Button, Modal, Row, Col, Input } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  fetchSubdivisionsTableView,
  deleteSubdivision,
} from "../../../redux/action/subdivisionAction";
import Can from "../../../user/Component/Auth/Can";
import queryString from "query-string";
import SubDivisionForm from "../SubDivision/SubdivisionForm";

const Subdivision = ({
  fetchSubdivisionsTableView,
  subdivision: { subdivisions, isLoading, totalCount },
  handleCurrentPath,
  deleteSubdivision,
  auth: { user },
}) => {
  useEffect(() => {
    handleCurrentPath("Subdivision", "11");
    fetchSubdivisionsTableView({ search: "", skip, limit: 10 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [subDivision, setSubDivision] = useState();
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });
  const { confirm } = Modal;
  const history = useHistory();
  const { search, skip, limit } = tableOpt;
  const columns = [
    {
      title: "Name",
      width: 350,
      dataIndex: "name",
      key: "name",
      render: (full, record) => (
        <span>
          {user.access.includes("ADMIN") || user.access.includes("COMMS") ? (
            <Link to={`/admin/division/${record?._id}`}>{record.name}</Link>
          ) : (
            record.name
          )}
        </span>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (city) => (city ? city : "-"),
      responsive: ["md"],
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => (state ? state : "-"),
      responsive: ["md"],
    },
    {
      title: "Zip code",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <span>
          <Can do="edit" on="Offerings">
            <Button
              onClick={() => onShowEdit(record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Offerings">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can>
        </span>
      ),
    },
  ];

  // const setPageBasedOnParam = () => {
  //   let skip = 1;
  //   const value = queryString.parse(history.location.search);
  //   if (value.page) {
  //     skip = parseInt(value.page);
  //     setOperation({ ...tableOpt, skip });
  //   } else {
  //     setOperation({ ...tableOpt, skip });
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("popstate", setPageBasedOnParam);
  //   return () => {
  //     window.removeEventListener("popstate", setPageBasedOnParam);
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setPageBasedOnParam();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      console.log(e.state);
      let skip = 1;
      const value = queryString.parse(history.location.search);
      console.log(value);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchSubdivisionsTableView();
    };
  }, []);

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchSubdivisionsTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
    fetchSubdivisionsTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  // Modal
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();

  const showCreateModal = () => {
    setVisible(true);
    setMode("create");
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const onConfirmRemove = (subDivision) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${subDivision.name}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteSubdivision(subDivision._id);
      },
      onCancel() {
        handleOk();
      },
    });
  };

  const onShowEdit = (subDivision) => {
    setMode("edit");
    setSubDivision(subDivision);
    setVisible(true);
  };

  return (
    <div>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {subdivisions && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <h2>Subdivisions</h2>
              </Col>
              <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                <Input.Search
                  placeholder="Search name"
                  onChange={searchHandler}
                  style={{ width: "60%", marginBottom: "5px" }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                <Button
                  type="primary"
                  style={{
                    float: "right",
                  }}
                  onClick={showCreateModal}
                >
                  <PlusOutlined /> Add an Subdivision
                </Button>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={subdivisions}
              pagination={{
                loading: { isLoading },
                current: skip,
                pageSize: limit,
                total: totalCount,
                showSizeChanger: false,
              }}
              rowKey={(record) => record._id}
              onChange={handleTableChange}
              scroll={{ x: 500 }}
            />
          </div>
        )}
      </Card>
      <Modal
        title={
          mode === "create" ? (
            <span style={{ color: "white" }}>Add a new subdivision</span>
          ) : (
            <span style={{ color: "white" }}>Edit an subdivision</span>
          )
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        {mode === "create" ? (
          <SubDivisionForm
            mode={mode}
            onOk={handleOk}
            handleCancel={handleCancel}
          />
        ) : (
          <SubDivisionForm
            subDivision={subDivision}
            mode={mode}
            onOk={handleOk}
            handleCancel={handleCancel}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subdivision: state.subdivision,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchSubdivisionsTableView,
  deleteSubdivision,
})(Subdivision);
