import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  Card,
  Table,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Typography,
  Tag,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../shared/loading/Loading.js";
import {
  fetchCouponsTableView,
  fetchCouponById,
  createCoupon,
  updateCoupon,
  removeCoupon,
} from "../../../redux/action/couponAction";
import NumberFormat from "react-number-format";
import queryString from "query-string";
import CouponForm from "./CouponForm";

const { Text } = Typography;

const Coupons = ({
  handleCurrentPath,
  fetchCouponsTableView,
  fetchCouponById,
  createCoupon,
  updateCoupon,
  removeCoupon,
  coupon: {
    coupons: { coupons },
    selectedCoupon,
    isLoading,
    totalCount,
  },
  auth: { user },
}) => {
  const [state, setState] = useState({
    title: "Add Coupon",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });

  const { mode, modalVisiblity, initVal } = state;
  const { confirm } = Modal;

  const formInitValue = {
    creator: user?._id,
    description: "",
    code: "",
    activation_date: moment().format("YYYY-MM-DD"),
    expiration_date: null,
    percentage_discount: 0,
    amount: 0,
    active: true,
    single_use: false,
  };

  const [loading, setLoading] = useState(false);
  // const [coupons, setCoupons] = useState([]);
  const [redemptionsVisible, setRedemptionsVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    handleCurrentPath("Coupons", "11");
    fetchCouponsTableView({ search: "", skip, limit: 10 });

    setLoading(false);
  }, []);

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const { search, skip, limit } = tableOpt;
  

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createCoupon(body);
      return res;
    } else {
      const res = await updateCoupon(body._id, body);
      return res;
    }
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchCouponsTableView();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchCouponsTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchCouponsTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Coupon",
      modalVisiblity: true,
    });
  };

  const onConfirmRemove = (coupon) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete coupon code ${coupon?.code}.`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeCoupon(coupon?._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => <Text ellipsis={true}>{text}</Text>,
    },
    {
      title: "Amount",
      key: "amount",
      render: (amount, record) => (
        <span>
          <NumberFormat
            value={record.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      ),
    },
    {
      title: "Activation Date",
      key: "activation_date",
      render: (activation_date, record) =>
        moment(record.activation_date).format("MM/D/YY"),
    },
    {
      title: "Expiration Date",
      key: "expiration_date",
      render: (expiration_date, record) =>
        record.expiration_date
          ? moment(record.expiration_date).format("MM/D/YY")
          : "None",
    },
    {
      title: "Created By",
      dataIndex: "creator",
      render: (creator, record) => (
        <Text ellipsis={true}>{record.creator?.name}</Text>
      ),
    },
    {
      title: "",
      key: "active",
      render: (active, record) => (
        <>
          <span>
            {record.active ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag>Inactive</Tag>
            )}
          </span>
          <span>
            {record.single_use && <Tag color="magenta">One-time Use</Tag>}
          </span>
          <span>
            {moment(record.expiration_date).isBefore(moment()) && (
              <Tag color="red">Expired</Tag>
            )}
          </span>
        </>
      ),
    },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          <Button
            onClick={() => clickHandler("Edit", record)}
            style={{ margin: "0 5px 5px 0" }}
            icon={<EditOutlined />}
          />
          {/* <Can do="delete" on="Credits"> */}
          <Button
            onClick={() => onConfirmRemove(record)}
            danger
            icon={<DeleteOutlined />}
            disabled={record.redeemed}
          />
          {/* </Can> */}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && coupons && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <h2>Coupons</h2>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Input.Search
                  placeholder="Search by coupon code"
                  onChange={searchHandler}
                />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => clickHandler("Add", formInitValue)}
                >
                  <PlusOutlined /> Add a Coupon
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={coupons}
              rowKey={(record) => record._id}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
              }}
              onChange={handleTableChange}
              scroll={{ x: 500 }}
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    style={{
                      margin: 0,
                      padding: 20,
                    }}
                  >
                    <h5>Redemptions</h5>
                    {record?.redemptions.map((red, index) => (
                      <Row style={{ margin: "20px 0" }} key={index}>
                        <Col span={8}>
                          <strong>Client: </strong>
                          {red.client_name}
                        </Col>
                        <Col span={8}>
                          <strong>Redemption Date: </strong>
                          {moment(red.redemption_date).format("M/D/YYYY")}
                        </Col>
                        <Col span={8}>
                          <strong>Status: </strong>
                          {red.status}
                        </Col>
                      </Row>
                    ))}
                  </div>
                ),
              }}
            />
          </div>
        )}
      </Card>
      <Modal
        visible={redemptionsVisible}
        closeable={true}
        maskClosable={true}
        onCancel={() => setRedemptionsVisible(false)}
      >
        <div>{selectedCoupon?.code}</div>
      </Modal>
      <Modal
        title={"Add a Coupon"}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        destroyOnClose={true}
      >
        <CouponForm
          initVal={initVal}
          mode={mode}
          close={handleCancel}
          submitHandler={(values) => submitHandler(mode, values)}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  coupon: state.coupon,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchCouponsTableView,
  fetchCouponById,
  createCoupon,
  updateCoupon,
  removeCoupon,
})(Coupons);
// export default Coupons;
