import React, { useState, useRef } from "react";
import moment from "moment";
import SunEditor, { buttonList } from "suneditor-react";

import { Button, Row, Col, Select, Spin, message, Tooltip } from "antd";
import { Formik } from "formik";
import { Input, Form } from "formik-antd";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import "./styles.scss";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Option } = Select;

const TemplateForm = ({ initVal, submitHandler, close, mode }) => {
  const [state, setState] = useState({ loader: false });
  const { loader } = state;
  const [templateText, setTemplateText] = useState(
    mode === "Edit" ? initVal?.text : ""
  );

  const editor = useRef();

  const handleChange = (content) => {
    setTemplateText(content);
  };
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const validateRequired = (value) => {
    const error = {};
    if (!value.title) {
      error.title = "Title is required";
    }

    return error;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      validate={validateRequired}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        try {
          const payload = {
            ...values,
            category:
              values.category === "" ? "Miscellaneous" : values.category,
            text: templateText,
          };

          const res = await submitHandler(payload);
          if (res.status === 200) {
            mode === "Add"
              ? message.success("Post created successfully")
              : message.success("Post updated successfully!");
          } else {
            message.error("Oops! Error occured while updating post.");
          }

          setState({ ...state, loader: false });
          close(res.data);
        } catch (error) {
          close(error);
          message.error(`Oops! Error occured. ${error}`);
        }
      }}
      render={({ handleSubmit, setFieldValue, values }) => (
        <div className="form-container blog-post-form">
          <Spin spinning={loader}>
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                  <Form.Item label="Title" name="title" className="form-group">
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <div style={{ marginBottom: 18 }}>
                    <strong>Category</strong>
                  </div>
                  <div className="form-group">
                    <Select
                      defaultValue={initVal?.category}
                      onChange={(value) => setFieldValue("category", value)}
                      style={{ width: "100%" }}
                      name="category"
                    >
                      <Option value="Miscellaneous">Miscellaneous</Option>
                      <Option value="Before Appointment">
                        Before Appointment
                      </Option>
                      <Option value="After Appointment">
                        After Appointment
                      </Option>
                    </Select>
                  </div>
                </Col>
                <Col>
                  <span className="text-info">
                    {" "}
                    Use the following template tags for information that will be
                    added dynamically{" "}
                    <Tooltip
                      title={`{{client_first_name}} | {{appointment_date}} |
                  {{appointment_time}} | {{appointment_address}} | {{signature}} | {{reschedule_button}} | {{accept_button}}`}
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  </span>
                </Col>
                <Col>
                  <div>
                    <SunEditor
                      autoFocus={true}
                      className={"manipulateDisabledButton"}
                      lang="en"
                      setOptions={{
                        showPathLabel: false,
                        minHeight: "40vh",
                        maxHeight: "40vh",
                        placeholder: "Add your text here...",
                        lineAttrReset: true,
                        plugins: plugins,
                        attributesWhitelist: {
                          all: "style",
                        },
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          ["paragraphStyle"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          // Line break
                          ["outdent", "indent"],
                          ["align", "horizontalRule", "list", "lineHeight"],
                          ["table", "link", "image"],
                        ],
                        formats: [
                          "p",
                          "div",
                          "h1",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        font: [
                          "Arial",
                          "Calibri",
                          "Comic Sans",
                          "Courier",
                          "Garamond",
                          "Georgia",
                          "Impact",
                          "Lucida Console",
                          "Palatino Linotype",
                          "Segoe UI",
                          "Tahoma",
                          "Times New Roman",
                          "Trebuchet MS",
                        ],
                      }}
                      getSunEditorInstance={getSunEditorInstance}
                      onChange={handleChange}
                      defaultValue={templateText}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default TemplateForm;
