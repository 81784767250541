import React from "react";
import { Doughnut, Bar } from "react-chartjs-2";

const Chart = ({ chartData }) => {
  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // position: "right",
      },
      title: {
        display: false,
        text: "Total",
      },
    },
  };
  //   console.log(chartData.labels);
  const data = {
    labels: chartData?.labels,
    datasets: [
      {
        label: "Total",
        data: chartData?.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      {chartData ? <Bar data={data} options={options} /> : "Loading..."}
    </div>
  );
};

export default Chart;
