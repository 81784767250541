import axios from "axios";
import {
  FETCH_TEAMS,
  UPDATE_TEAM,
  CREATE_TEAM,
  REMOVE_TEAM,
  FETCH_TEAM_BY_ID,
  FETCH_TEAM_BY_CLIENT_ID,
  FETCH_TEAM_BY_OWNER_ID,
  ADD_MEMBER_BY_TEAM_ID,
} from "./types";

export const fetchTeam = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/teams`);
    dispatch({
      type: FETCH_TEAMS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchTeamById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/teams/${id}`
    );
    dispatch({
      type: FETCH_TEAM_BY_ID,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchTeamByClientId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/teams/member/${id}`
    );
    dispatch({
      type: FETCH_TEAM_BY_CLIENT_ID,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};

export const fetchTeamByOwnerId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/teams/owner/${id}`
    );
    dispatch({
      type: FETCH_TEAM_BY_OWNER_ID,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      data: {},
      error: err.response,
    };
  }
};
export const updateTeamMembers = (id, data) => async (dispatch) => {
  try {
    const updateData = {
      team: id,
      member: data,
    };
    const response = await axios.put(
      `${process.env.REACT_APP_ROOT_URL}/teams/team-invite`,
      updateData
    );
    dispatch({
      type: ADD_MEMBER_BY_TEAM_ID,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (e) {
    return {
      data: {},
      error: e.response,
    };
  }
};

export const createTeam = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/teams`,
      formData,
      config
    );
    dispatch({
      type: CREATE_TEAM,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log("ERROR", err);
    return {
      data: null,
      error: err.message,
    };
  }
};

export const updateTeam = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/teams/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_TEAM,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    console.log("ERROR", err);
    return {
      data: null,
      error: err.message,
    };
  }
};

export const removeTeam = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/teams/${id}`
    );
    if (res.status === 200) {
      dispatch({
        type: REMOVE_TEAM,
        payload: id,
      });
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};
