import React, { useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Row, Col, Button, Spin, Upload, message, Alert } from "antd";
import { UserOutlined, PlusOutlined, LockOutlined } from "@ant-design/icons";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";
import { updateClient } from "../../../redux/action/clientAction";
import ImgCrop from "antd-img-crop";
import { upload } from "../../../shared/s3Bucket/s3Service";
import { changePassword } from "../../../redux/action/authAction";

const ProfileForm = ({ clientProfile, updateClient, changePassword }) => {
  const [loader, setLoader] = useState(false);
  const [passwordBanner, setPasswordBanner] = useState({
    message: null,
    show: false,
  });
  const [profile, setProfile] = useState({ ...clientProfile });

  const [imgUrl, setImgUrl] = useState(clientProfile.headshot_url);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = ({ file }) => {
    let imgFile = file.originFileObj;
    if (file.originFileObj instanceof Blob) {
      imgFile = new File([file.originFileObj], file.name, {
        lastModified: new Date().getTime(),
        type: file.originFileObj.type,
      });
    }
    const profileImage = new FormData();
    profileImage.append("path", `clients/photos/high/${profile.hsf}`);
    profileImage.append("file", imgFile);
    setProfile({
      ...profile,
      headshot_url: profileImage,
    });
    getBase64(imgFile, (imageUrl) => {
      setImgUrl(imageUrl);
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={clientProfile}
      onSubmit={async (values, actions) => {
        if (values.profile) {
          const user = await changePassword(values.user._id, values.profile);
          debugger;
          if (user.status !== 200) {
            if (user.status === 401) {
              message.error("Current password is incorrect.");
              setPasswordBanner({
                show: true,
                type: "error",
                message: "Current password mismatch!",
              });
              return;
            } else {
              message.error("Something went wrong on updating password");
              setPasswordBanner({
                show: true,
                type: "error",
                message: "Failed to update password!",
              });
              return;
            }
          } else {
            setPasswordBanner({
              show: true,
              type: "success",
              message:
                "Your Password updated successfully.You can now use your new password to Login.",
            });
          }
        }
        const reqBody = { ...values };
        setLoader(true);
        if (typeof profile.headshot_url === "object") {
          const imageUrl = await upload(profile.headshot_url);
          reqBody.headshot_url = imageUrl[0];
        }
        delete reqBody.profile;
        const res = await updateClient(profile._id, reqBody);
        if (res.error && res.error.status === 500) {
          message.error("Profile update failed");
          setLoader(false);
        } else {
          message.success("Profile updated successfully");
          setLoader(false);
        }
      }}
      render={({ setFieldValue, values, handleSubmit }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <h3>
                    <UserOutlined /> Profile Details
                  </h3>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>First Name </label>
                    <Input
                      name="first"
                      placeholder="First Name"
                      value={profile.first}
                      onChange={(e) => onChange(e)}
                    />{" "}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Last Name </label>
                    <Input
                      name="last"
                      placeholder="Last Name"
                      value={profile.last}
                      onChange={(e) => onChange(e)}
                    />{" "}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Email </label>
                    <Input
                      name="email"
                      placeholder="Email"
                      value={profile.email}
                      onChange={(e) => onChange(e)}
                    />{" "}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Phone </label>
                    <NumberFormat
                      className="ant-input"
                      name="phone"
                      placeholder="Phone"
                      value={values.phone}
                      onValueChange={(e) =>
                        setFieldValue("phone", e.formattedValue)
                      }
                      allowEmptyFormatting
                      format="+1 (###) ### ####"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Role </label>
                    <Input
                      name="role"
                      placeholder="Role"
                      value={profile.role}
                      onChange={(e) => onChange(e)}
                    />{" "}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Headshot </label>
                    <ImgCrop rotate>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imgUrl ? (
                          <img
                            src={imgUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                  </div>
                </Col>
                {/* TODO: Add password reset functionality */}
                <Col span={24}>
                  <h3>
                    <LockOutlined /> Reset Password
                  </h3>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>Current Password</label>
                    <Input
                      name="profile.password"
                      placeholder="Current Password"
                      type="password"
                    />{" "}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label>New Password</label>
                    <Input
                      name="profile.newPassword"
                      placeholder="New Password"
                      type="password"
                    />{" "}
                  </div>
                </Col>
                <Col span={16} offset={4}>
                  {passwordBanner.show && (
                    <Alert
                      banner
                      type={passwordBanner.type}
                      message={
                        passwordBanner.type.charAt(0).toUpperCase() +
                        passwordBanner.type.slice(1)
                      }
                      description={passwordBanner.message}
                      showIcon
                    />
                  )}
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default connect(null, { updateClient, changePassword })(ProfileForm);
