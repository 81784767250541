import axios from "axios";
import {
  FETCH_PAYMENTS,
  UPDATE_PAYMENT,
  CREATE_PAYMENT,
  REMOVE_PAYMENT,
  FETCH_PAYMENTS_BY_INVOICE_ID,
} from "./types";

export const fetchPayments = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/payments`);
    dispatch({
      type: FETCH_PAYMENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchPaymentsByInvoiceId = (invoiceId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/payments/invoice/${invoiceId}`
    );
    dispatch({
      type: FETCH_PAYMENTS_BY_INVOICE_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createPayment = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/payments`,
      formData,
      config
    );

    dispatch({
      type: CREATE_PAYMENT,
      payload: res.data,
    });
  } catch (err) {}
};

export const updatePayment = (id, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/payments/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PAYMENT,
      payload: formData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const removePayment = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/payments/${id}`);

    dispatch({
      type: REMOVE_PAYMENT,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
