import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  message,
  Tooltip,
  Drawer,
  Avatar,
  Select,
} from "antd";
import { UsergroupAddOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import { fetchTeamByClientId } from "../../../redux/action/teamAction";
import { updateShare } from "../../../redux/action/shootAction";

const { Option, OptGroup } = Select;

const ShareShootButton = ({
  selectedShoot,
  teams,
  fetchTeamByClientId,
  updateShare,
}) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedValue, setSelectedvalue] = useState([]);
  useEffect(() => {
    fetchTeamByClientId(selectedShoot.client_id);
  }, [selectedShoot]);

  const onChange = (value) => {
    setSelectedvalue(value);
    if (value.length === 0) {
      setSelected();
      setSelectedMembers([]);
    } else {
      const sltMembers = value.map((val) => val.split("_")[0]);
      setSelected(value[0].split("_")[1]);
      setSelectedMembers([...sltMembers]);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setSelected();
    setSelectedMembers([]);
    setSelectedvalue();
  };

  const handleSubmit = async () => {
    const formData = [...selectedShoot.share, ...selectedMembers];
    const res = await updateShare(selectedShoot._id, {
      updatedShare: formData,
    });
    if (res) {
      setSelectedvalue();
      setSelectedMembers([]);
      message.success("Shoot updated successfully!");
    }
  };

  const removeMemberAccess = async (selectedMember) => {
    const updatedShare = selectedShoot.share.filter(
      (mem) => mem._id !== selectedMember._id
    );
    const res = await updateShare(selectedShoot?._id, {
      updatedShare,
    });

    if (res.data) {
      message.success("Shoot updated successfully!");
    }
  };

  const colors = [
    "#528FC4",
    "#3C7369",
    "#D6A334",
    "#C88564",
    "#AF5656",
    "#808080",
    "#7378AD",
    "#058A8A",
    "#800000",
    "#76802B",
    "#A0A04C",
    "#82C291",
    "#5E925E",
    "#2D9393",
    "#419C9C",
    "#828EC2",
    "#7575D5",
    "#DC85DE",
    "#C765C7",
  ];

  const renderMemberAvatar = (member, i, index) => {
    return (
      <>
        {member?.headshot_url ? (
          <Tooltip title={member?.full}>
            <Avatar
              style={{ border: "0.5px solid #EEEEEE" }}
              src={member?.headshot_url}
            />
          </Tooltip>
        ) : (
          <Tooltip title={member?.full}>
            <Avatar
              style={{
                color: "#FFFFFF",
                fontSize: "30px",
                backgroundColor:
                  colors[((index + 1) * (i + 1) * member?.full?.length) % 19],
              }}
            >
              {member?.full?.trim().charAt(0)}
            </Avatar>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      {teams?.length > 0 ? (
        <Col>
          <Tooltip
            placement="topRight"
            title="Allow team members to access this page"
          >
            <Button onClick={showDrawer} icon={<UsergroupAddOutlined />}>
              Share
            </Button>
          </Tooltip>
          <Drawer
            title={
              <div>
                <Button onClick={onClose} className="mr-2">
                  Close
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!selectedMembers.length}
                  className="float-right"
                >
                  Save
                </Button>
              </div>
            }
            placement="right"
            onClose={onClose}
            closable={false}
            visible={visible}
            width={400}
          >
            {selectedShoot.share?.length > 0 ? (
              <>
                <h4>Shared with:</h4>
                <Row justify="start" gutter={16}>
                  {selectedShoot.share.map((member, index) => (
                    <Col key={member?._id}>
                      {renderMemberAvatar(member.client, index, index + 1)}
                      <Tooltip title="Remove access" placement="right">
                        <div
                          style={{
                            position: "absolute",
                            right: 4,
                            bottom: -9,
                            cursor: "pointer",
                          }}
                          onClick={() => removeMemberAccess(member)}
                        >
                          <CloseCircleTwoTone
                            twoToneColor="#F00"
                            style={{ fontSize: 14 }}
                          />
                        </div>
                      </Tooltip>
                    </Col>
                  ))}
                </Row>
                <hr />
              </>
            ) : (
              <p className="text-muted">
                To allow team members to access this shoot please select them
                below
              </p>
            )}
            <h4>Add Team Members:</h4>
            <Row>
              <Col span={24}>
                <Select
                  mode="multiple"
                  virtual={false}
                  value={selectedValue}
                  style={{ width: "100%" }}
                  placeholder="Select team members"
                  onChange={(val) => onChange(val)}
                >
                  {teams.map((team) => (
                    <OptGroup key={team._id} label={team.name}>
                      {team.members
                        .filter(
                          (data) =>
                            data.client._id !== selectedShoot.client_id &&
                            selectedShoot?.share?.findIndex(
                              (member) => member.client._id === data.client._id
                            ) === -1
                        )
                        .map((mem, i) => (
                          <Option
                            value={mem._id + "_" + team._id}
                            key={i}
                            disabled={selected && team._id !== selected}
                          >
                            {mem.client.headshot_url ? (
                              <div>
                                <Avatar src={mem.client.headshot_url} />{" "}
                                {mem.client.full}
                              </div>
                            ) : (
                              <>
                                <Avatar
                                  style={{
                                    color: "#333",
                                    fontSize: "30px",
                                  }}
                                >
                                  {mem?.full?.trim().charAt(0)}
                                </Avatar>{" "}
                                {mem.client.full}
                              </>
                            )}
                          </Option>
                        ))}
                    </OptGroup>
                  ))}
                </Select>
              </Col>
            </Row>
          </Drawer>
        </Col>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.team?.teams,
  };
};

export default connect(mapStateToProps, {
  fetchTeamByClientId,
  updateShare,
})(ShareShootButton);
