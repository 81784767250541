import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, Modal, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ListView from "../../../shared/Components/ListView";
import BlogPostForm from "./BlogForm";
import ability from "../../../user/Component/Auth/ability";
import {
  fetchBlogPostsTableView,
  removeBlogPost,
  updateBlogPost,
  createBlogPost,
} from "../../../redux/action/blogPostAction";
import queryString from "query-string";

const Posts = ({
  handleCurrentPath,
  fetchBlogPostsTableView,
  blogPosts: { blogPosts, totalCount, isLoading },
  removeBlogPost,
  updateBlogPost,
  createBlogPost,
  auth: { user },
}) => {
  const { confirm } = Modal;
  const [state, setState] = useState({
    title: "Add Post",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });
  const { mode, modalVisiblity, initVal, title } = state;

  const formInitValue = {
    title: "",
    text: "",
    category: "",
    tags: [],
    published: false,
    published_at: moment(),
    author: user?._id,
  };

  useEffect(() => {
    handleCurrentPath("BlogPosts", "blog");
    fetchBlogPostsTableView({ search: "", skip, limit: 10 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const history = useHistory();

  const { search, skip, limit } = tableOpt;

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchBlogPostsTableView();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchBlogPostsTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchBlogPostsTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (author, record) => <span>{author.name}</span>,
    },
    {
      title: "Publish Date",
      dataIndex: "published_at",
      key: "published_at",
      render: (published_at, record) => (
        <span>{moment(published_at).format("M/D/YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "published",
      render: (published, record) => (
        <span>
          {published ? <Tag color="green">Published</Tag> : <Tag>Draft</Tag>}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          {ability.can("edit", "Posts") && (
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          )}
          {ability.can("edit", "Posts") && (
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
              disabled={record.redeemed}
            />
          )}
        </span>
      ),
    },
  ];

  const onConfirmRemove = (post) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove "${post.title}"?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeBlogPost(post._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createBlogPost(body);
      return res;
    } else {
      const res = await updateBlogPost(body._id, body);
      return res;
    }
  };

  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Post",
      modalVisiblity: true,
    });
  };

  return (
    <>
      <ListView
        title="Blog Posts"
        onClick={() => clickHandler("Add", formInitValue)}
        handleSearch={searchHandler}
        buttonText="Add a Post"
        data={blogPosts}
        columns={columns}
        handleTableChange={handleTableChange}
        pagination={{
          current: skip,
          pageSize: limit,
          total: totalCount,
        }}
      ></ListView>
      <Modal
        title={<span style={{ color: "white" }}>{title}</span>}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        maskClosable={false}
        destroyOnClose={true}
        style={{ top: 20 }}
        width={1000}
      >
        <BlogPostForm
          initVal={initVal}
          close={handleCancel}
          mode={mode}
          submitHandler={(values) => submitHandler(mode, values)}
          user={user}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  blogPosts: state.blogPosts,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchBlogPostsTableView,
  removeBlogPost,
  updateBlogPost,
  createBlogPost,
})(Posts);
