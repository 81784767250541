import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Card,
  Row,
  Col,
  Tag,
  List,
  Button,
  message,
  Modal,
  Spin,
} from "antd";
import {
  CheckCircleTwoTone,
  UserOutlined,
  HomeTwoTone,
  PhoneTwoTone,
  MailTwoTone,
  CalendarOutlined,
  CarOutlined,
} from "@ant-design/icons";
import Loading from "../../../shared/loading/Loading.js";
import DistanceTravelled from "./PhotoGrapherDistTravelled";
import { fetchPhotographerById } from "../../../redux/action/photographerAction";
import Can from "../../../user/Component/Auth/Can";
import PayBreakdown from "./PayBreakdown.jsx";
import {
  convertISOStrToDate,
  renderIconLine,
} from "../../../shared/utils/utils";

import "./styles.scss";
import axios from "axios";
import moment from "moment";
import EditAvailability from "./EditAvailability.jsx";

const PhotographerDetails = ({
  match,
  fetchPhotographerById,
  photographer: { currentPhotographer, isLoading },
}) => {
  const [viewAvail, setViewAvail] = useState(false);
  const [viewDistTravel, setViewDistTravel] = useState(false);
  const [editAvail, setEditAvail] = useState(false);
  const [weeklyAvailData, setWeeklyAvailData] = useState([]);
  const [dateRange, setDateRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [loader, setLoader] = useState(false);
  const [newloader, setNewLoader] = useState(false);
  useEffect(() => {
    fetchPhotographerById(match.params.id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onViewAvail = async () => {
    setLoader(true);
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_ROOT_URL
        }/photographers/unavailability?date=${dateRange.from.format()}&toDate=${dateRange.to.format()}&photographer=${
          match.params.id
        }`
      );
      const availData = getAvailData(res.data);
      setWeeklyAvailData([...availData]);
      setViewAvail(true);
    } catch (e) {
      message.info("Something went wrong");
    }
    setLoader(false);
  };

  const onDistTravel = async () => {
    setNewLoader(true);
    try {
      // fetchDistanceArrayAndTotal()
      setViewDistTravel(true);
    } catch (e) {
      message.info("Something went wrong");
    }
    setNewLoader(false);
  };

  const getAvailData = (photographerData) => {
    const availData = [];
    const date = dateRange.from.clone();
    while (date.isSameOrBefore(dateRange.to)) {
      if (date.day() === 0) {
        availData.push({
          date: moment(date),
          availableSlots: [],
        });
      } else {
        const dayData = photographerData.find(
          (data) => date.format().split("T")[0] === data.date.split("T")[0]
        );
        const totalAvailSlots = [
          { label: "9:00AM - 10:00AM", start: 9, end: 10 },
          { label: "10:00AM - 11:00AM", start: 10, end: 11 },
          { label: "11:00AM - 12:00PM", start: 11, end: 12 },
          { label: "12:00PM - 1:00PM", start: 12, end: 13 },
          { label: "1:00PM - 2:00PM", start: 13, end: 14 },
          { label: "2:00PM - 3:00PM", start: 14, end: 15 },
          { label: "3:00PM - 4:00PM", start: 15, end: 16 },
          { label: "4:00PM - 5:00PM", start: 16, end: 17 },
          { label: "5:00PM - 6:00PM", start: 17, end: 18 },
          { label: "6:00PM - 7:00PM", start: 18, end: 19 },
          { label: "7:00PM - 8:00PM", start: 19, end: 20 },
          { label: "8:00PM - 9:00PM", start: 20, end: 21 },
        ];
        if (dayData) {
          const availableSlots = totalAvailSlots.filter(
            (availSlot) =>
              dayData.unAvailableSlots.find(
                (unAvailSlot) =>
                  convertISOStrToDate(unAvailSlot.start).getHours() ===
                  availSlot.start
              ) === undefined
          );
          availData.push({
            date: moment(date),
            availableSlots,
          });
        } else {
          availData.push({
            date: moment(date),
            availableSlots: totalAvailSlots,
          });
        }
      }
      date.add(1, "days");
    }
    return availData;
  };

  const onViewClose = () => {
    setViewAvail(false);
  };
  const onViewCloseDistTravel = () => {
    setViewDistTravel(false);
  };

  const onEditClose = () => {
    setEditAvail(false);
  };

  const onEditAvail = () => {
    onViewClose();
    setEditAvail(true);
  };

  return (
    <div>
      <div className="small d-flex flex-column">
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && currentPhotographer && (
          <Row gutter={8} style={{ margin: "10px" }}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card>
                <Row>
                  <Col span={24}>
                    <div className="mb-4">
                      {currentPhotographer.headshot_url ? (
                        <Avatar
                          size={120}
                          src={currentPhotographer.headshot_url}
                        />
                      ) : (
                        <Avatar
                          size={120}
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: `${currentPhotographer.color}`,
                          }}
                        >
                          <UserOutlined
                            style={{ fontSize: "40px", paddingTop: "15px" }}
                          />
                          {/* {currentPhotographer.name.charAt(0)} */}
                        </Avatar>
                      )}
                      <br />
                      <Can do="manage" on="Photographers">
                        <span>
                          ID: <strong>{currentPhotographer.photog_id}</strong>
                        </span>
                      </Can>
                    </div>
                  </Col>
                  <Col span={24}>
                    <h3>
                      {currentPhotographer.name}{" "}
                      <span>
                        {currentPhotographer.active ? (
                          <Tag color="green" className="ml-2">
                            active
                          </Tag>
                        ) : (
                          <Tag>inactive</Tag>
                        )}
                      </span>
                    </h3>
                    <div className="pl-2">
                      {renderIconLine(
                        HomeTwoTone,
                        currentPhotographer.home_address
                      )}{" "}
                      {renderIconLine(
                        MailTwoTone,
                        currentPhotographer.preferred_email
                      )}
                      {renderIconLine(PhoneTwoTone, currentPhotographer.phone)}
                      <div style={{ display: "inline-block" }}>
                        <Spin spinning={loader}>
                          <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={onViewAvail}
                          >
                            <CalendarOutlined /> View availability
                          </Button>
                        </Spin>
                      </div>
                      <br />
                      <div style={{ display: "inline-block" }}>
                        <Spin spinning={newloader}>
                          <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={onDistTravel}
                          >
                            <CarOutlined /> Distance Travelled
                          </Button>
                        </Spin>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <h4>Skills</h4>
                      <List
                        size="small"
                        dataSource={currentPhotographer.skills}
                        renderItem={(item) => (
                          <List.Item>
                            <CheckCircleTwoTone
                              className="mr-2"
                              twoToneColor="#52c41a"
                              style={{ border: "none" }}
                            />{" "}
                            {item}
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <PayBreakdown
                currentPhotographer={currentPhotographer._id}
                match={match.params.id}
                setDateRange={setDateRange}
              />
            </Col>
          </Row>
        )}
      </div>
      <Modal
        width={1100}
        visible={editAvail}
        footer={null}
        onCancel={onEditClose}
        title={
          <>
            <Row align="center">Edit Availability</Row>
          </>
        }
        destroyOnClose={true}
      >
        <EditAvailability initialDate={dateRange.from} />
      </Modal>
      <Modal
        width={1000}
        title={
          <>
            <Row align="center">Availability</Row>
            <Row align="center">
              {dateRange.from?.format("MM/DD/YYYY") +
                " - " +
                dateRange.to?.format("MM/DD/YYYY")}
            </Row>
          </>
        }
        visible={viewAvail}
        footer={null}
        onCancel={onViewClose}
      >
        {weeklyAvailData.map((dayData) => (
          <Row style={{ paddingBottom: "15px" }}>
            <Col span={6} className="avail-slot-label">
              <span>{dayData.date.format("dddd  MM/DD/YY")}</span>
              <span> - </span>
            </Col>
            <Col span={18} className="avail-slot-val">
              {dayData.availableSlots.length === 0
                ? "Not Available"
                : dayData.availableSlots.map((slot) => (
                    <span style={{ minWidth: "125px" }}>{slot.label}</span>
                  ))}
            </Col>
          </Row>
        ))}
        <Button type="primary" onClick={onEditAvail}>
          Edit
        </Button>
      </Modal>

      <Modal
        width={1000}
        title={"Distance Travelled"}
        visible={viewDistTravel}
        footer={null}
        onCancel={onViewCloseDistTravel}
      >
        <DistanceTravelled />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  photographer: state.photographer,
});

export default connect(mapStateToProps, { fetchPhotographerById })(
  PhotographerDetails
);
