import React from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "../redux/action/authAction";
import "../App.scss";
import "./user.scss";

const { SubMenu } = Menu;

const VerticalNavigator = ({ closeDrawer }) => {
  const history = useHistory();
  return (
    <Menu mode="vertical" className="user">
      <Menu.Item key="1">
        <Link
          to={"/"}
          className="nav"
          style={{ color: "#1e1e1e" }}
          onClick={closeDrawer}
        >
          Home
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          to={"/about-us"}
          className="nav"
          style={{ color: "#1e1e1e" }}
          onClick={closeDrawer}
        >
          About Us
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          to={"/faq"}
          className="nav"
          style={{ color: "#1e1e1e" }}
          onClick={closeDrawer}
        >
          FAQ
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link
          to={"/portfolio"}
          className="nav"
          style={{ color: "#1e1e1e" }}
          onClick={closeDrawer}
        >
          Portfolio
        </Link>
      </Menu.Item>
      <SubMenu title="Services" onTitleClick={() => history.push("/services")}>
        <Menu.Item key="real-estate-photography">
          <Link to={"/real-estate-photography"} className="nav">
            Real Estate Photography
          </Link>
        </Menu.Item>
        <Menu.Item key="real-estate-videography">
          <Link to={"/real-estate-videography"} className="nav">
            Real Estate Videography
          </Link>
        </Menu.Item>
        <Menu.Item key="real-estate-drone-photography">
          <Link to={"/real-estate-drone-photography"} className="nav">
            Real Estate Drone Photography
          </Link>
        </Menu.Item>
        <Menu.Item key="real-estate-virtual-tour">
          <Link to={"/real-estate-virtual-tour"} className="nav">
            Real Estate Virtual Tour
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="pricing">
        <Link to={"/pricing"} className="nav">
          Pricing
        </Link>
      </Menu.Item>
      <Menu.Item key="9">
        <Link to={"/blog"} className="nav">
          Blog
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <a
          href="/admin/dashboard"
          target="_blank"
          rel="noopener noreferrer"
          className="nav"
          style={{ color: "#1e1e1e" }}
          onClick={closeDrawer}
        >
          My Dashboard
        </a>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to={"/lets-book-a-shoot"} className="nav primaryBtn pl-3 pr-3">
          Book a Shoot
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser }, null, { pure: false })(
  VerticalNavigator
);
