import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, message, Button, Input, Tooltip } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { HistoryOutlined } from "@ant-design/icons";
import {
  createDuplicateNotes,
  fetchEntityNotes,
} from "../../../../redux/action/note";

const RescheduleShoot = ({
  originalShoot,
  rescheduleShoot,
  createInvoice,
  createMedia,
  updateInvoice,
  updateShoot,
  user,
  createDuplicateNotes,
  fetchEntityNotes,
  note: { notes },
}) => {
  let history = useHistory();

  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState("None given.");

  useEffect(() => {
    fetchEntityNotes(originalShoot._id);
  }, []);

  const handleSubmit = async () => {
    // Create Media
    const medReq = {
      photos: {
        status: "Processing",
        gallery: [],
      },
      video: {
        status: "Not Available",
        link: null,
      },
      dollHouse: {
        status: "Not Available",
        matterPort_id: null,
      },
      property_info: {
        street: originalShoot.address?.street,
        city: originalShoot.address?.city,
        state: originalShoot.address?.state,
        zip: originalShoot.address?.zip,
      },
      branding: {
        agent_info: {
          name:
            originalShoot.client_name_first +
            " " +
            originalShoot.client_name_last,
          email: originalShoot.client_email,
          phone: originalShoot.client_phone,
          headshot: originalShoot.client_headshot_url,
        },
        brokerage_info: {
          name: originalShoot.brokerage?.name,
          logo: originalShoot.brokerage?.logo,
        },
      },
    };

    const mediaRes = await createMedia(medReq);

    if (!mediaRes.error) {
      const originalInvoice = originalShoot.invoice[0];
      const originalInvoiceId = originalShoot.invoice[0]._id;
      delete originalInvoice._id;
      delete originalInvoice.hsn;
      delete originalInvoice._created_at;
      delete originalInvoice._updated_at;

      // Parse through products and zero out photographer pay

      // Create Invoice
      let newInvoice, updatedOrigInvoice;
      const rescheduleFee = {
        price: 99,
        active: true,
        bf_visible: false,
        _id: "5ea9a735788a314d86ad645d",
        description: "Reschedule Fee",
        category: "Fees",
        code: "RF",
        duration: null,
        photographer_pay: 0,
      };

      const rescheduleCompensation = {
        photographer_pay: 20,
        _id: "61031f49f57f970bbb164a48",
        price: 0,
        inv_visible: false,
        description: "Reschedule Compensation",
        category: "Fees",
        code: "RSC",
      };

      const updatedProducts = originalInvoice.products.map((obj) =>
        obj.photographer_pay > 0 ? { ...obj, photographer_pay: 0 } : obj
      );

      const newSubtotal = originalInvoice.subtotal + 99.0;
      // const newTax = calculateTax(newSubtotal);

      if (!originalInvoice.paid) {
        // If current invoice is unpaid...

        // Zero out current invoice + mark as rescheduled
        // Remove photographer pay from original invoice
        updatedOrigInvoice = {
          ...originalInvoice,
          products: [...updatedProducts, rescheduleCompensation],
          photographer_total_pay: 20,
          reschedule: true,
          total: 0.0,
          balance: 0.0,
          credit: null,
        };

        // Add reschedule fee to new invoice
        newInvoice = {
          ...originalInvoice,
          products: [...originalInvoice.products, rescheduleFee],
          subtotal: newSubtotal,
          total: newSubtotal,
          balance: newSubtotal,
        };
      } else {
        // Remove photographer pay from original invoice
        updatedOrigInvoice = {
          ...originalInvoice,
          photographer_total_pay: 20,
          products: [...updatedProducts, rescheduleCompensation],
        };

        // Create new invoice with only a reschedule fee
        newInvoice = {
          ...originalInvoice,
          products: [...originalInvoice.products, rescheduleFee],
          paid: false,
          subtotal: 99.0,
          tax: 0.0,
          total: 99.0,
          balance: 99.0,
          credit: null,
        };
      }

      const invoiceRes = await createInvoice(newInvoice);
      await updateInvoice(originalInvoiceId, updatedOrigInvoice);
      if (!invoiceRes.error) {
        const originalShootId = originalShoot._id;
        const originalHsn = originalShoot.hsn;

        // Create shoot
        delete originalShoot._id;
        delete originalShoot.hsn;
        delete originalShoot._created_at;
        delete originalShoot._updated_at;

        const shootData = {
          ...originalShoot,
          invoice: [invoiceRes.data._id],
          media: mediaRes.data._id,
          original_shoot: originalShootId,
          original_hsn: originalHsn,
          status: "Tentative",
          reason,
          reqs: originalInvoice.paid
            ? [...originalShoot.reqs]
            : [...originalShoot.reqs, "RF"],
          events: { ...originalShoot.events, photographer: null },
          creatorId: user._id,
        };

        const res = await rescheduleShoot(shootData);

        const body = res.data;

        if (res.status === 200) {
          // Update new invoice
          await updateInvoice(invoiceRes.data._id, {
            hsn: body.hsn.toString(),
            shoot: body._id,
          });
          // Create Activity
          const newActivityData = {
            user: user._id,
            text: `Rescheduled shoot for HS# ${originalHsn}. Reason: ${reason}`,
            entity_id: body._id,
            entity_type: "Shoot",
            _created_at: new Date(),
          };
          await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/activities`,
            newActivityData
          );
          await updateShoot(originalShootId, {
            status: "Reschedule",
            rescheduled_shoot: body._id,
            reschedules: originalShoot.reschedules
              ? originalShoot.reschedules + 1
              : 1,
            reqs: originalInvoice.paid
              ? [...originalShoot.reqs, "RF"]
              : [...originalShoot.reqs],
          });

          // Create Duplicate Notes on the new shoots
          if (notes && notes.length > 0) {
            await createDuplicateNotes(body?._id, notes);
          }

          // Route to shoots
          history.push(`/admin/shoots/`);

          setVisible(false);
          // Success message
          message.success({
            content: "Reschedule shoot created successfully",
            style: {
              marginTop: "10vh",
            },
          });
        } else {
          message.error("Oops! Failed to create shoot");
        }
      } else {
        console.log("Unable to create invoice");
      }
    } else {
      console.log("Unable to create media");
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onOk={() => handleSubmit()}
        okText="Yes"
        onCancel={() => setVisible(false)}
      >
        <div className="form-group mt-3 mb-3">
          <p>Are you sure you want to reschedule this shoot?</p>
          <div className="form-group mb-4">
            <label>Reason:</label>
            <Input
              onChange={(e) => setReason(e.target.value)}
              placeholder="e.g. Bad weather."
              name="comp_reason"
            />
          </div>
        </div>
      </Modal>
      <Tooltip
        title="Duplicates shoot and services. Adds Reschedule Fee to invoice."
        placement="bottom"
      >
        <Button
          onClick={() => setVisible(true)}
          disabled={originalShoot.rescheduled_shoot}
        >
          <HistoryOutlined /> Reschedule
        </Button>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
});

export default connect(mapStateToProps, {
  createDuplicateNotes,
  fetchEntityNotes,
})(RescheduleShoot);
