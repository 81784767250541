/* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sendAppointmentConfirmation } from "../../../../redux/action/notifications";
import {
  deleteInvoice,
  updateInvoice,
  fetchInvoiceByShootId,
} from "../../../../redux/action/invoiceAction";
import { fetchAllPhotoGraphersUnAvailabilityForADay } from "../../../../redux/action/photographerAction";
import {
  Row,
  Col,
  Tabs,
  Descriptions,
  Timeline,
  Table,
  Button,
  Drawer,
  message,
  Menu,
} from "antd";
import { SendOutlined, ArrowRightOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import "../orders.css";
import { MyMapComponent } from "../MapView/Map.js";
import moment from "moment";
import {
  convertISODateString,
  roundedCurrency,
  convertISOStrToDate,
  eventDateFormat,
  formatUsAddress,
} from "./../../../../shared/utils/utils.js";
import { updateShoot } from "../../../../redux/action/shootAction";
import { updatePhotographerCalc } from "../../../../shared/payBreakdown/photographersPayCalc";
import AppointmentConfirmation, {
  onSendEmail,
} from "../../Emails/AppointmentConfirmation";
import Axios from "axios";
import DetailsModal from "../../Shoots/Details/DetailsModal";
import ShootTypeBadge from "../../../../shared/Components/ShootTypeBadge";
const { TabPane } = Tabs;

const columns = [
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "",
    dataIndex: "price",
    key: "price",
    render: (price) => <span style={{ float: "right" }}>{price}</span>,
  },
];

//Order Details Component...
const OrderDetails = (props) => {
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(props.selectedSchedule?.status);
  const onSendEmailContent = () => {
    onSendEmail(props.selectedSchedule);
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const [startChange, setStartChange] = useState();
  const [endChange, setEndChange] = useState();

  useEffect(() => {
    props.fetchInvoiceByShootId(props.selectedShoot._id);
  }, []);

  async function callback(key) {
    if (key === "3") {
      await props.fetchEntityActivities(props.selectedShoot._id);
    }
  }

  const getProducts = (invoices) => {
    const products = [];
    for (let i = 0; i < invoices.length; i++) {
      for (let j = 0; j < invoices[i].products?.length; j++) {
        products.push(invoices[i].products[j]);
      }
    }
    return products;
  };

  /*Calender Properies for Updation...*/
  let shootData = {};
  if (props.selectedSchedule) {
    shootData = {
      ...props.selectedSchedule.details,
    };
  }
  const [isUpdate, setIsUpdate] = useState(false);
  const [title, setTitle] = useState(
    props.selectedSchedule && props.selectedSchedule.title
  );
  const [photographer, setPhotographer] = useState(null);
  const [date, setDate] = useState(
    props.selectedSchedule && convertISOStrToDate(props.selectedSchedule.start)
  );

  const [time, setTime] = useState({
    start:
      props.selectedSchedule &&
      convertISOStrToDate(props.selectedSchedule.start),
    end:
      props.selectedSchedule && convertISOStrToDate(props.selectedSchedule.end),
  });
  const { start, end } = time;
  const serviceNCost = (inv) => {
    const dataSource = [];
    if (props.selectedSchedule && props.selectedSchedule.servicesNCost) {
      inv.products.forEach((product, idx) => {
        dataSource.push({
          key: (idx + 1).toString(),
          product: product.category + " | " + product.description,
          price: roundedCurrency(product.price),
        });
      });
      dataSource.push({
        key: "",
        product: "",
        description: "Sub Total",
        price: roundedCurrency(inv.subtotal),
      });
      dataSource.push({
        key: "",
        product: "",
        description: "Tax",
        price: roundedCurrency(inv.tax),
      });
      dataSource.push({
        key: "",
        product: "",
        description: "Discount",
        price: roundedCurrency(inv.discount),
      });
      dataSource.push({
        key: "",
        product: "",
        description: "Total",
        price: roundedCurrency(inv.total),
      });
    }
    return dataSource;
  };
  // Show Order details...
  // const handleDescription = (details) => {
  //   let description = [];
  //   details &&
  //     Object.keys(details).forEach((field, index) => {
  //       if (typeof details[field] !== "object") {
  //         let value = "";
  //         if (typeof details[field] === "boolean") {
  //           value = details[field] ? "True" : "False"; //details[field].toString();
  //         } else {
  //           value = details[field].toString() ? details[field].toString() : "";
  //         }
  //         description.push(
  //           <React.Fragment key={index + 1}>
  //             <p className="detail-cell">
  //               <span style={{ fontWeight: "bold" }}>
  //                 {toTitleCase(field)} :{" "}
  //               </span>
  //               <span>{value} </span>
  //             </p>
  //           </React.Fragment>
  //         );
  //       } else {
  //         description.push(
  //           <React.Fragment key={index + 1}>
  //             <div className="detail" span={24}>
  //               <p style={{ fontWeight: "bold", color: "#1890ff" }}>
  //                 {toTitleCase(field)}
  //               </p>
  //               {handleDescription(details[field])}
  //             </div>
  //           </React.Fragment>
  //         );
  //       }
  //     });
  //   return description;
  // };

  const renderOrderDetails = () => {
    return (
      <div>
        <Row>
          <DetailsModal
            allPhotographerUnavailSlotsForDay={
              props.allPhotographerUnavailSlotsForDay
            }
            fetchAllPhotoGraphersUnAvailabilityForADay={
              props.fetchAllPhotoGraphersUnAvailabilityForADay
            }
            manualFetch={() => {
              props.manualFetch(true);
            }}
            createActivity={props.createActivity}
            auth={props.auth}
            update={props.onUpdateShoot}
            selectedPhotographers={props.selectedPhotographers}
            setSelectedPhotographer={props.setSelectedPhotographer}
            currentPhotographer={props.selectedShoot?.events.photographer}
            photographers={props.photographers}
            selectedShoot={props.selectedShoot}
            invoice={props.invoice}
            offerings={props.offerings}
            updateInvoice={props.updateInvoice}
          />
        </Row>
      </div>
    );
  };

  // [NOTE: On Date Change function return wrong date so manually increement in month.]

  const geoCoordinates = async (startTime, endTme, updatedShoot) => {
    let properties = props;
    let invoices;
    let sqft;
    let tc;
    let driveT;
    photographer && (updatedShoot.events.photographer = photographer._id);
    sqft = shootData.booking_form.sqft;
    tc = shootData.trip_charge;
    invoices = [...shootData.invoice];
    var geocode;
    const driveTime = await Axios.get(
      `${process.env.REACT_APP_ROOT_URL}/shoots/getDistance/${
        photographer
          ? photographer.home_address
          : updatedShoot.events.photographer.home_address
      }/${shootData.address.full}/${shootData._id}`
    ).then((res) => {
      geocode = res.data;
    });
    driveT = geocode.photographer_drive_time;
    updatePhotographerCalc(
      invoices,
      photographer
        ? photographer.pay_multiplier
        : updatedShoot.events.photographer.pay_multiplier,
      sqft,
      tc,
      startTime,
      endTme,
      driveT,
      properties
    );
    props.setSelectedPhotographer(photographer);
  };
  //TO DO
  const scheduleUpdate = async () => {
    var startPrevious = moment.utc(props.selectedSchedule.start).format();
    var endPrevious = moment.utc(props.selectedSchedule.end).format();

    await setIsUpdate(true);
    const updatedShoot = { events: shootData.events };
    delete updatedShoot.invoice;
    if (
      (photographer?._id && photographer?._id != 0) ||
      startPrevious !== startChange ||
      endPrevious !== endChange
    ) {
      geoCoordinates(startChange, endChange, updatedShoot);
    }

    if (status === "Cancelled") {
      updatedShoot.events.photographer = null;
      // Delete invoice
      props.selectedSchedule.servicesNCost.forEach((inv) => {
        props.deleteInvoice(inv._id);
      });
      // Make invoice null on shoot
      updatedShoot.invoice = [];
    }
    const startTime = new Date(
      date.toDateString() + " " + start.toTimeString()
    );
    const endTime = new Date(date.toDateString() + " " + end.toTimeString());
    updatedShoot.events.start = convertISODateString(startTime);
    updatedShoot.events.end = convertISODateString(endTime);
    if (moment(props.selectedSchedule.requested_date).isValid()) {
      updatedShoot.events.date = props.selectedSchedule.requested_date;
    }
    updatedShoot.events.title = title;
    updatedShoot.events.description = props.selectedSchedule.description;
    // updatedShoot.events.kind = photographer != 0 ? "Scheduled" : "Pending";
    // updatedShoot.status = photographer != 0 ? "Scheduled" : "Pending";
    updatedShoot.status = capitalize(status);
    const res = await props.onUpdateShoot(
      props.selectedSchedule.id,
      updatedShoot
    );
    let activityInfo = "";
    if (status !== "Cancelled") {
      if (props.getSelectedPhotographer?.name) {
        activityInfo = `Shoot scheduled for ${eventDateFormat(
          updatedShoot.events.start,
          updatedShoot.events.end
        )} with ${props.getSelectedPhotographer?.name}`;
      } else {
        activityInfo = `Shoot scheduled for ${eventDateFormat(
          updatedShoot.events.start,
          updatedShoot.events.end
        )}`;
      }
    } else {
      activityInfo = `Shoot is Cancelled`;
    }
    props.createActivity(
      { ...props.selectedSchedule, _id: props.selectedSchedule.id },
      activityInfo
    );
    if (res.status === 200) {
      message.success("Shoot has been updated.");
    } else {
      message.error("Oops! Error while updating shoot.");
    }
    await setIsUpdate(false);
  };

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const buttonMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item onClick={showDrawer} key="2" icon={<SendOutlined />} danger>
        Send Confirmation
      </Menu.Item>
    </Menu>
  );

  const handleStatusChange = (value) => {
    if (value) {
      setStatus(value);
    }
  };
  // const onUpdate = () => {
  //   if (status === "Cancelled") {
  //     confirm({
  //       title: "Are you sure you want to cancel this shoot?",
  //       icon: <ExclamationCircleOutlined syle={{ color: "red" }} />,
  //       async onOk() {
  //         await scheduleUpdate(status);
  //         props.onShootCancel(props.selectedSchedule.id);
  //       },
  //       onCancel() {},
  //     });
  //   } else {
  //     scheduleUpdate();
  //   }
  // };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const operations = (
    <a href={`/admin/shoots/${props?.selectedShoot?._id}`}>
      <Button>
        Go to Shoot Details <ArrowRightOutlined />
      </Button>
    </a>
  );

  return (
    <React.Fragment>
      <Row gutter={[2, 2]}>
        <Row justify="space-between" style={{ margin: "10px" }}>
          <Col>
            {" "}
            <h3 className="mt-3">
              <span className="mr-1">
                {props.selectedShoot?.booking_form?.shoot_type ===
                  "Residential" && (
                  <ShootTypeBadge shootType="Residential" bgColor="#a155b9" />
                )}
                {props.selectedShoot?.booking_form?.shoot_type ===
                  "Multi-family" && (
                  <ShootTypeBadge shootType="Multi-Family" bgColor="#f765a3" />
                )}
                {props.selectedShoot?.booking_form?.shoot_type ===
                  "Commercial" && (
                  <ShootTypeBadge shootType="Commercial" bgColor="#ffa4b6" />
                )}
                {props.selectedShoot?.booking_form?.shoot_type ===
                  "Additional" && (
                  <ShootTypeBadge shootType="Additional" bgColor="#165baa" />
                )}
              </span>
              {formatUsAddress(
                props.selectedShoot?.address.full,
                props.selectedShoot?.address.street,
                props.selectedShoot?.address.apt,
                props.selectedShoot?.address.city,
                props.selectedShoot?.address.state,
                props.selectedShoot?.address.zip
              )}{" "}
            </h3>
          </Col>
        </Row>
      </Row>
      <Tabs
        defaultActiveKey="1"
        type="card"
        onChange={callback}
        tabBarExtraContent={operations}
      >
        <TabPane tab="Order Details" key="1">
          <Descriptions bordered size={"small"}>
            {props.selectedSchedule &&
              renderOrderDetails(props.selectedSchedule.details)}
          </Descriptions>
        </TabPane>
        <TabPane tab="Order Activity" key="3">
          {props.activities?.length ? (
            props.activities.map((acty) => (
              <Timeline.Item
                color={acty.entity_type === "Shoot" ? "orange" : "green"}
              >
                <Row>
                  <Col>{acty.user?.name}</Col>
                  <Col className="timestamp" offset={1}>
                    {moment(acty._created_at).format("MM/DD/YY LT")}
                  </Col>
                </Row>
                {acty.text}
              </Timeline.Item>
            ))
          ) : (
            <>No Activities Yet.</>
          )}
        </TabPane>
        {!props.isMapView && (
          <TabPane tab="Map View" key="4">
            <MyMapComponent
              isMarkerShown
              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </TabPane>
        )}

        <TabPane tab="Services & Costs" key="5">
          <br />
          {props.selectedShoot?.invoice.map((inv) => (
            <Table
              dataSource={serviceNCost(inv)}
              columns={columns}
              pagination={false}
              size="small"
              rowKey={(record) => record._id}
            />
          ))}
        </TabPane>
      </Tabs>
      <Drawer
        title="Email Preview"
        placement="right"
        destroyOnClose={true}
        closable={false}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ background: "white" }}
        width={720}
        footer={
          <>
            <Button onClick={onSendEmailContent} type="primary">
              Send
            </Button>
            <Button type="link" onClick={onClose}>
              Cancel
            </Button>
          </>
        }
      >
        <AppointmentConfirmation data={props.selectedSchedule} />
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offerings: state.offering.offerings,
  currentPhotographer: state.photographer.currentPhotographer,
  selectedPhotographers: state.photographer.selectedPhotographers,
  photographers: state.photographer.photographers,
  invoice: state.invoice,
  allPhotographerUnavailSlotsForDay:
    state.photographer.allPhotographerUnavailSlotsForDay,
});

export default connect(mapStateToProps, {
  sendAppointmentConfirmation,
  deleteInvoice,
  updateInvoice,
  fetchInvoiceByShootId,
  updateShoot,
  fetchAllPhotoGraphersUnAvailabilityForADay,
})(OrderDetails);
