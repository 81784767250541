import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { List, Card, Button, Tag, Badge, Row, Col } from "antd";
import {
  CalendarOutlined,
  ArrowRightOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import {
  fetchPhotographerByUserId,
  updatePhotographer,
} from "../../../../redux/action/photographerAction";
import { createCoupon } from "../../../../redux/action/couponAction";
import {
  formatUsAddress,
  getShootColor,
  eventDateFormat,
} from "../../../../shared/utils/utils";
import ReferralCard from "./ReferralCard";
import Loading from "../../../../shared/loading/Loading.js";
import "./style.scss";

const PhotogDashboard = ({
  fetchPhotographerByUserId,
  updatePhotographer,
  createCoupon,
  photographer: { currentPhotographer, isLoading },
  auth,
}) => {
  useEffect(() => {
    const userId = auth?.user?._id;
    if (userId) {
      fetchPhotographerByUserId(userId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPhotographer?._id) {
      const photographerId = currentPhotographer?._id;

      if (currentPhotographer) {
        fetchTodayShoots(photographerId);
        fetchUpcomingShoots(photographerId);
      }

      if (currentPhotographer?.referral_code) {
        setReferralCode(currentPhotographer?.referral_code);
      }
    }
  }, [currentPhotographer]);

  const [todayShoots, setTodayShoots] = useState([]);
  const [upcomingShoots, setUpcomingShoots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [referralCode, setReferralCode] = useState(null);

  const fetchTodayShoots = async (photogId) => {
    const today = moment().format("YYYY-MM-DD");
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const filters = `startDate=${today}&endDate=${tomorrow}&status=Scheduled:Reschedule`;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${photogId}?${filters}`
      );
      setTodayShoots(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetchUpcomingShoots = async (photogId) => {
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    const week = moment().add(6, "day").format("YYYY-MM-DD");
    const filters = `startDate=${tomorrow}&endDate=${week}&status=Scheduled:Reschedule`;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/photographer/${photogId}?${filters}`
      );
      setUpcomingShoots(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const renderCardTitle = (title, icon) => {
    return (
      <span className="reminder-card-title">
        {" "}
        {icon} {title}
      </span>
    );
  };

  const renderList = (title, badgeColor, badgeStatus, data) => {
    return (
      <Col xs={24} sm={24} md={9} lg={9} xl={9} className="mb-4">
        {loading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!loading && data && (
          <Card>
            <List
              header={
                <h4>
                  <Badge color={badgeColor} status={badgeStatus} /> {title}
                </h4>
              }
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Link
                        to={`/admin/shoots/${item._id}`}
                        style={{ fontSize: "1rem", color: "#096dd9" }}
                      >
                        {formatUsAddress(
                          item.address.full,
                          item.address.street,
                          item.address.apt,
                          item.address.city,
                          item.address.state,
                          item.address.zip
                        )}
                      </Link>
                    }
                    description={
                      <>
                        {item.status === "Scheduled" && (
                          <span>
                            <Tag icon={<CalendarOutlined />}>
                              {eventDateFormat(
                                item.events.start,
                                item.events.end
                              )}
                            </Tag>
                            <Tag color={getShootColor(item.status)}>
                              {item.status}
                            </Tag>

                            <Link to={`/admin/shoots/${item._id}`}>
                              <Button
                                size="small"
                                style={{ float: "right", marginTop: "5px" }}
                              >
                                Details
                              </Button>
                            </Link>
                          </span>
                        )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        )}
      </Col>
    );
  };

  return (
    <div
      className="container-fluid photog-dashboard"
      style={{ marginTop: "20px", marginBottom: "10px" }}
    >
      <div className="row justify-content-md-center">
        <div className="col-md-12 col-sm-12 m-b-4">
          {loading && (
            <div className="loading-container">
              <Loading />
            </div>
          )}
          {!loading && (
            <React.Fragment>
              <div className="float-right">
                <Link to={"/admin/shoots"}>
                  <Button type="primary">
                    View All Shoots <ArrowRightOutlined />
                  </Button>
                </Link>
              </div>

              <h3>My Shoots</h3>

              <div className="list-container mt-4">
                <Row gutter={24}>
                  {renderList("Today", "#f50", "processing", todayShoots)}
                  {renderList("Upcoming", "orange", "warning", upcomingShoots)}
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className="mb-4">
                    <ReferralCard
                      createCoupon={createCoupon}
                      currentPhotographer={currentPhotographer}
                      referralCode={referralCode}
                      setReferralCode={setReferralCode}
                      updatePhotographer={updatePhotographer}
                      renderCardTitle={renderCardTitle(
                        "Referrals",
                        <LikeOutlined />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  photographer: state.photographer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPhotographerByUserId,
  updatePhotographer,
  createCoupon,
})(PhotogDashboard);
