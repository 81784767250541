import React from "react";
import { Card, Button, Row, Col, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const ListView = ({
  handleSearch,
  title,
  onClick,
  buttonText,
  columns,
  data,
  skip,
  limit,
  totalCount,
  handleTableChange,
  pagination = {},
}) => {
  return (
    <div>
      <Card
        style={{
          margin: "20px",
        }}
      >
        <div>
          <Row justify="space-between">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row justify="space-between">
                <Col span={12}>
                  <h2>{title}</h2>
                </Col>
                {/* <Col className="ml-4" span={12}>
                  <Input.Search
                    placeholder="Search by title or category"
                    onChange={handleSearch}
                  />
                </Col> */}
              </Row>
            </Col>
            {onClick && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={onClick}
                >
                  <PlusOutlined /> {buttonText}
                </Button>
              </Col>
            )}
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record._id}
            pagination={{
              current: skip,
              pageSize: limit,
              total: totalCount,
              ...pagination,
            }}
            onChange={handleTableChange}
            scroll={{ x: 500 }}
          />
        </div>
      </Card>
    </div>
  );
};

export default ListView;
