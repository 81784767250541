import React, { useState, useEffect } from "react";
import OrderDetails from "./Common/OrderDetails.js";
import {
  Row,
  Col,
  Radio,
  Input,
  Modal,
  Card,
  Button,
  Drawer,
  List,
  Tooltip,
  message,
} from "antd";
import {
  EnvironmentOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckOutlined,
  StopOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import OrderDetailsMapView from "./MapView/OrderDetailsMapView.js";
import CalendarView from "./CalenderView/CalendarView.js";
import Today from "./TodayView/Today";
import RequestForm from "./RequestForm";

import ability from "../../../user/Component/Auth/ability";

import "./orders.css";
import moment from "moment";
import { connect } from "react-redux";
import {
  fetchChangeRequests,
  fetchPendingShoots,
  fetchShoots,
  fetchShootsByDay,
  updateShoot,
  fetchPhotoGraphersUnAvailabilityForADay,
} from "../../../redux/action/shootAction";
import { createActivity } from "../../../redux/action/activityAction";
import {
  fetchPhotographerById,
  fetchPhotographers,
} from "../../../redux/action/photographerAction";
import { fetchEntityActivities } from "../../../redux/action/activityAction";
import {
  convertISODateString,
  sortPhotographer,
} from "./../../../shared/utils/utils.js";
import {
  SELECT_PHOTOGRAPHER,
  REMOVE_SHOOT_CALENDAR,
  CHANGE_CALENDAR_VIEW_DATE,
  CHANGE_CALENDAR_VIEW,
  SET_SELECTED_SHOOT,
} from "../../../redux/action/types.js";
import { updateInvoice } from "../../../redux/action/invoiceAction.js";

const { Search } = Input;
const NewOrders = (props) => {
  const [viewType, setViewType] = useState("Today"); //todayview | mapview | calendarview
  const [shootOrders, setShootOrders] = useState(props.allOrders);
  const [requests, setRequests] = useState(props.changeRequests);
  const [visible, setVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [newStatus, setNewStatus] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [childDrawerVisible, setChildDrawerVisible] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      await props.fetchPendingShoots();

      await props.fetchShootsByDay({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        access: props.auth?.user?.access,
      });
      await props.fetchPhotoGraphersUnAvailabilityForADay(
        convertISODateString(moment()._d)
      );
      setLoader(false);
    };
    fetch();
    setLoader(true);
    props.fetchPhotographers();
    props.fetchChangeRequests();
  }, [viewType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShootOrders(props.allOrders);
    setRequests(props.changeRequests);
  }, [props.allOrders, props.changeRequests]); // eslint-disable-line react-hooks/exhaustive-deps

  //Update an Order...
  const onUpdateShoot = async (id, data) => {
    const updatedShoot = await props.updateShoot(id, data);
    await props.fetchShootsByDay({
      from: moment(props.date.from).format("YYYY-MM-DD"),
      to: moment(props.date.to).format("YYYY-MM-DD"),
      access: props.auth?.user?.access,
    });

    return updatedShoot;
  };

  const handleViewChange = (e) => {
    setViewType(e.target.value);
  };

  const calendarSearch = ({ target }) => {
    const regex = new RegExp(target.value, "gi");
    setShootOrders(
      props.allOrders.filter(
        (shoot) =>
          regex.test(shoot.name) ||
          regex.test(shoot.address) ||
          regex.test(shoot.email)
      )
    );
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setChildDrawerVisible(false);
  };

  const handleApproveRequest = async (shoot) => {
    const res = await onUpdateShoot(shoot?._id, {
      status: shoot?.status_change_request?.new_status,
      status_change_request: {
        admin_approved: true,
        client_approved: true,
        resolved: true,
        approver: props.auth?.user?._id,
      },
    });

    if (res.status === 200) {
      message.success("Status updated successfully");
      const activityData = {
        user: props.auth?.user?._id,
        entity_id: shoot?._id,
      };
      props.createRequestActivity(
        activityData,
        `Request to ${
          shoot?.status_change_request?.new_status
            ? shoot?.status_change_request?.new_status
            : "change"
        } the Shoot approved by ${props?.auth?.user?.name}.`
      );
    } else {
      message.error("Oops! Something went wrong");
    }
  };

  const handleSelectedRequest = (selectedShoot, newStatus) => {
    setNewStatus(newStatus);
    setSelectedRequest(selectedShoot);
    setChildDrawerVisible(true);
  };
  const changeDateView = async (from, to) => {
    setLoader(true);
    await props.changeDateView({ from, to });
    await props.fetchShootsByDay({
      from,
      to,
      access: props.auth?.user?.access,
    });
    await props.fetchPhotoGraphersUnAvailabilityForADay(from);
    setLoader(false);
  };
  const changeCalendarView = async (viewType) => {
    await props.changeCalendarView({ viewType });
  };
  const renderRequests = () => {
    return (
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={requests}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <>
                  <strong>
                    <a href={`/admin/shoots/${item?._id}`}>
                      {item?.address?.street}
                    </a>
                  </strong>{" "}
                  {item?.status_change_request?.new_status
                    ? ` - ${item?.status_change_request?.new_status}`
                    : ""}{" "}
                  <span
                    style={{
                      color: "#888888",
                      fontSize: "11px",
                      fontStyle: "italic",
                      marginLeft: "35px",
                    }}
                  >
                    {item?.status_change_request?.requester &&
                      `-- ${item?.status_change_request?.requester?.name}`}
                    {item.status_change_request._request_created_at &&
                      moment(
                        item?.status_change_request?._request_created_at
                      ).format("MMM D, YYYY H:mmA")}
                  </span>
                </>
              }
              description={
                <React.Fragment>
                  <div style={{ color: "#333" }}>
                    {item?.status_change_request?.request_reason
                      ? item?.status_change_request?.request_reason
                      : item?.status_change_request?.denied_reason}
                  </div>
                  <div>
                    {!item?.status_change_request?.client_approved &&
                      "Awaiting client response"}
                  </div>
                </React.Fragment>
              }
            />
            <div className="mt-3">
              {ability.can("approve", "Request") && (
                <Tooltip placement="top" title="Approve/Resolve">
                  <Button
                    size="small"
                    className="mr-2"
                    style={{ color: "green" }}
                    icon={<CheckOutlined />}
                    onClick={() => handleApproveRequest(item)}
                  />
                </Tooltip>
              )}
              {ability.can("reschedule", "Request") && (
                <Tooltip placement="top" title="Reschedule">
                  <Button
                    size="small"
                    className="mr-2"
                    style={{ color: "orange" }}
                    icon={<HistoryOutlined />}
                    onClick={() => handleSelectedRequest(item, "Reschedule")}
                  />
                </Tooltip>
              )}
              {ability.can("deny", "Request") && (
                <Tooltip placement="top" title="Deny">
                  <Button
                    size="small"
                    style={{ color: "red" }}
                    icon={<StopOutlined />}
                    onClick={() => handleSelectedRequest(item, "Deny")}
                  />
                </Tooltip>
              )}
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <React.Fragment>
      <Row gutter={[8, 8]}>
        <Col md={24} style={{ textAlign: "center" }}>
          <Radio.Group size="medium" onChange={handleViewChange}>
            <Radio.Button
              value="Today"
              className={viewType === "Today" ? "toggle-active" : ""}
              onChange={() => changeCalendarView("Today")}
            >
              <CarryOutOutlined /> Today
            </Radio.Button>
            <Radio.Button
              value="Calendar"
              className={viewType === "Calendar" ? "toggle-active" : ""}
              onChange={() => changeCalendarView("Calendar")}
            >
              <CalendarOutlined /> Calendar
            </Radio.Button>
            <Radio.Button
              value="Map"
              className={viewType === "Map" ? "toggle-active" : ""}
              onChange={() => changeCalendarView("Map")}
            >
              <EnvironmentOutlined /> Map
            </Radio.Button>
          </Radio.Group>
        </Col>
        {/* <Col span={2}>
          <span className="notification">
            <Badge
              count={requests ? requests.length : 0}
              size="small"
              offset={[0, 10]}
            >
              <Button
                onClick={handleShowRequests}
                type="link"
                icon={<BellOutlined />}
              />
            </Badge>
          </span>
        </Col> */}
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={8} offset={8}>
          <div>
            <Search
              placeholder="Search by Client or Address"
              onChange={calendarSearch}
              //style={{ width: 200 }}
            />
          </div>
        </Col>
      </Row>

      {/* Today View */}
      {viewType === "Today" && (
        <React.Fragment>
          <Today
            removeShootfromCalender={props.removeShootfromCalender}
            shoots={shootOrders}
            photographerUnavailabilityEvents={
              props.phgrapherUnAvailabilityEvnts
            }
            fetchPendingShoots={props.fetchPendingShoots}
            fetchShootsByDay={props.fetchShootsByDay}
            fetchPhotoGraphersUnAvailabilityForADay={
              props.fetchPhotoGraphersUnAvailabilityForADay
            }
            currentPhotographer={props.currentPhotographer}
            fetchPhotographerById={props.fetchPhotographerById}
            fetchEntityActivities={props.fetchEntityActivities}
            updateInvoice={props.updateInvoice}
            photographers={props.photographers}
            onUpdateShoot={onUpdateShoot}
            activities={props.activity.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            getSelectedPhotographer={props.getSelectedPhotographer}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
            setSelectedShoot={props.setSelectedShoot}
            selectedShoot={props.selectedShoot}
            changeDateView={changeDateView}
            loading={loader}
            userId={props.auth?.user?._id}
            access={props.auth?.user?.access}
            pendingShoots={props.pendingShoots || []}
          />
        </React.Fragment>
      )}
      {/* Map View */}
      {!props.isLoading && viewType === "Map" && (
        <React.Fragment>
          <OrderDetailsMapView
            updateInvoice={props.updateInvoice}
            removeShootfromCalender={props.removeShootfromCalender}
            shoots={shootOrders}
            fetchEntityActivities={props.fetchEntityActivities}
            photographers={props.photographers}
            onUpdateShoot={onUpdateShoot}
            activities={props.activity.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            getSelectedPhotographer={props.getSelectedPhotographer}
            setSelectedShoot={props.setSelectedShoot}
            selectedShoot={props.selectedShoot}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
            changeDateView={changeDateView}
            loading={loader}
          />
        </React.Fragment>
      )}
      {/* Calendar View */}
      {!props.isLoading && viewType === "Calendar" && (
        <React.Fragment>
          <CalendarView
            removeShootfromCalender={props.removeShootfromCalender}
            shoots={shootOrders}
            photographers={props.photographers}
            togglePhotographers={props.togglePhotographers}
            onUpdateShoot={onUpdateShoot}
            fetchEntityActivities={props.fetchEntityActivities}
            activities={props.activity.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            getSelectedPhotographer={props.getSelectedPhotographer}
            setSelectedShoot={props.setSelectedShoot}
            selectedShoot={props.selectedShoot}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
            changeDateView={changeDateView}
            loading={loader}
          />
        </React.Fragment>
      )}
      <Modal
        title="Order Details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ top: 20 }}
        footer={null}
        destroyOnClose={true}
      >
        <Card type="inner" size="small" style={{ marginTop: 5 }}>
          <OrderDetails
            selectedSchedule={props.selectedShoot}
            onUpdateShoot={onUpdateShoot}
            photographers={props.photographers}
            activities={props.activity.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            getSelectedPhotographer={props.getSelectedPhotographer}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
          />
        </Card>
      </Modal>
      <Drawer
        title="Change Requests"
        width={520}
        closable={true}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        {renderRequests()}
        <Drawer
          title="Respond to Request"
          width={420}
          closable={true}
          onClose={() => setChildDrawerVisible(false)}
          visible={childDrawerVisible}
        >
          <RequestForm
            selectedRequest={selectedRequest}
            handleCancel={handleCancel}
            updateShoot={onUpdateShoot}
            approver={props.auth?.user?._id}
            newStatus={newStatus}
          />
        </Drawer>
      </Drawer>
    </React.Fragment>
  );
};

/*export default NewOrders;*/

const mapStateToProps = (state) => {
  let pg = [];
  let togglePg = [];
  pg.push({
    id: "0",
    pg_id: null,
    name: "New Order",
    bgColor: "#C0CA33",
    borderColor: "#C0CA33",
    dragBgColor: "#C0CA33",
  });
  togglePg.push({ id: "0", checked: true });
  sortPhotographer(state.photographer.photographers).forEach(
    (photographer, idx) => {
      pg.push({
        id: (idx + 1).toString(),
        pg_id: photographer._id,
        name: photographer.name,
        geometry: photographer.geometry,
        home_address: photographer.home_address,
        pay_multiplier: photographer.pay_multiplier,
        photog_id: photographer.photog_id,
        sp_cost_per_hour: photographer.sp_cost_per_hour,
        base_pay: photographer.base_pay,
        active: photographer.active,
        bgColor: photographer.color,
        borderColor: photographer.color,
        dragBgColor: photographer.color,
      });
      togglePg.push({ id: photographer._id, checked: true });
    }
  );
  return {
    shoots: state.shoots.dashboard.displayShoots,
    phgrapherUnAvailabilityEvnts: state.shoots.photographerUnAvailabilityEvents,
    selectedShoot: state.shoots.selectedShoot,
    isLoading: state.shoots.isLoading,
    allOrders: state.shoots.dashboard.displayShoots,
    changeRequests: state.shoots.changeRequests,
    activity: state.activity,
    photographers: pg,
    getSelectedPhotographer: state.photographer.selectedPhotographers,
    togglePhotographers: togglePg,
    auth: state.auth,
    view: state.shoots.dashboard.view,
    pendingShoots: state.shoots.dashboard.pendingShoots,
    date: state.shoots.dashboard.currentDate,
    currentPhotographer: state.photographer.currentPhotographer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedShoot: (selectedShoot) =>
      dispatch({ type: SET_SELECTED_SHOOT, payload: selectedShoot }),
    fetchEntityActivities: (id) => dispatch(fetchEntityActivities(id)),
    fetchPendingShoots: () => dispatch(fetchPendingShoots()),
    fetchShootsByDay: (params) => dispatch(fetchShootsByDay(params)),
    fetchShoots: (params) => dispatch(fetchShoots(params)),
    updateShoot: (id, data) => dispatch(updateShoot(id, data)),
    updateInvoice: (id, data) => dispatch(updateInvoice(id, data)),
    fetchPhotographers: () => dispatch(fetchPhotographers()),
    fetchPhotographerById: (id) => dispatch(fetchPhotographerById(id)),
    fetchChangeRequests: () => dispatch(fetchChangeRequests()),
    createActivity: (shoot, text) => dispatch(createActivity(shoot, text)),
    createRequestActivity: (activityData, text) =>
      dispatch(createActivity(activityData, text)),
    setSelectedPhotographer: (phtg) =>
      dispatch({ type: SELECT_PHOTOGRAPHER, payload: phtg }),
    removeShootfromCalender: (shootId) =>
      dispatch({ type: REMOVE_SHOOT_CALENDAR, payload: { id: shootId } }),
    changeDateView: (date) =>
      dispatch({ type: CHANGE_CALENDAR_VIEW_DATE, payload: date }),
    changeCalendarView: (view) => {
      return dispatch({ type: CHANGE_CALENDAR_VIEW, payload: view.viewType });
    },
    fetchPhotoGraphersUnAvailabilityForADay: (date) =>
      dispatch(fetchPhotoGraphersUnAvailabilityForADay(date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrders);
