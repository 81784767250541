import React from "react";
import { connect } from "react-redux";
import { Menu, Avatar, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import { logoutUser } from "../../redux/action/authAction";
import {
  TeamOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  UserOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  LockOutlined,
  MenuOutlined,
  SafetyOutlined,
  FieldTimeOutlined,
  PicLeftOutlined,
} from "@ant-design/icons";
import "../../App.scss";
// import NavMenu from "./NavMenu"; TODO: Make menu responsive

const { SubMenu } = Menu;
const Navigation = ({ index, logoutUser, auth: { isAuthenticated, user } }) => {
  const logout = () => {
    logoutUser();
  };

  return (
    <React.Fragment>
      {isAuthenticated && (
        <Menu
          mode="horizontal"
          selectedKeys={index}
          overflowedIndicator={<MenuOutlined />}
        >
          <Menu.Item key="1">
            <Link to={"/admin/dashboard"}>
              <DashboardOutlined /> Dashboard
            </Link>
          </Menu.Item>
          {!user?.access?.includes("MARKETING") && (
            <Menu.Item key="2">
              <Link to={"/admin/shoots"}>
                <ScheduleOutlined /> Shoots
              </Link>
            </Menu.Item>
          )}
          {user?.access?.includes("PHOTOGRAPHER") && (
            <Menu.Item key="3">
              <Link to={"/admin/pay"}>
                <DollarCircleOutlined /> Pay Breakdown
              </Link>
            </Menu.Item>
          )}
          {user?.access?.includes("PHOTOGRAPHER") && (
            <Menu.Item key="4">
              <Link to={"/admin/availability"}>
                <FieldTimeOutlined /> Availability
              </Link>
            </Menu.Item>
          )}
          {user?.access?.includes("MARKETING") && (
            <Menu.Item key="14">
              <Link to={"/admin/blog"}>
                <PicLeftOutlined /> Blog
              </Link>
            </Menu.Item>
          )}
          {(user?.access?.includes("ADMIN") ||
            user?.access?.includes("COMMS") ||
            user?.access?.includes("CLIENT")) && (
            <Menu.Item key="3">
              <Link to={"/admin/invoices"}>
                <DollarCircleOutlined /> Invoices
              </Link>
            </Menu.Item>
          )}
          {(user?.access?.includes("ADMIN") ||
            user?.access?.includes("COMMS")) && (
            <SubMenu icon={<OrderedListOutlined />} title="Services">
              <Menu.Item key="4">
                <Link to={"/admin/offerings"}>Offerings</Link>
              </Menu.Item>
              <Menu.Item key="12">
                <Link to={"/admin/subdivision"}>Subdivisions</Link>
              </Menu.Item>
            </SubMenu>
          )}
          {(user?.access?.includes("ADMIN") ||
            user?.access?.includes("COMMS")) && (
            <SubMenu icon={<TeamOutlined />} title="People">
              <Menu.Item key="5">
                <Link to={"/admin/brokerages"}>Brokerages</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to={"/admin/clients"}>Clients</Link>
              </Menu.Item>
              <Menu.Item key="7">
                {" "}
                <Link to={"/admin/photographers"}>Photographers</Link>
              </Menu.Item>
              <Menu.Item key="8">
                <Link to={"/admin/teams"}>Teams</Link>
              </Menu.Item>
            </SubMenu>
          )}
          {(user?.access?.includes("ADMIN") ||
            user?.access?.includes("COMMS")) && (
            <SubMenu icon={<SafetyOutlined />} title="Admin">
              <Menu.Item key="10">
                <Link to={"/admin/credits"}>Credits</Link>
              </Menu.Item>
              <Menu.Item key="11">
                <Link to={"/admin/coupons"}>Coupons</Link>
              </Menu.Item>
              {user?.access?.includes("ADMIN") && (
                <>
                  <Menu.Item key="templates">
                    <Link to={"/admin/templates"}>Email Templates</Link>
                  </Menu.Item>
                  <Menu.Item key="13">
                    <Link to={"/admin/equipment"}>Equipment</Link>
                  </Menu.Item>
                </>
              )}
              <Menu.Item key="14">
                <Link to={"/admin/reviews"}>Reviews</Link>
              </Menu.Item>
              {user?.access?.includes("UBERADMIN") && (
                <Menu.Item key="blog">
                  <Link to={"/admin/blog"}>Blog</Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {isAuthenticated ? (
            <SubMenu
              icon={
                <span style={{ marginRight: "5px" }}>
                  <Avatar size="small" icon={<UserOutlined />} />
                </span>
              }
              title={<span style={{ color: "#F54B64" }}>{user?.name}</span>}
              style={{ float: "right" }}
            >
              {user?.access?.includes("CLIENT") && (
                <>
                  <Menu.Item key="9">
                    <Link to={"/admin/profile"}>My Profile</Link>
                  </Menu.Item>
                  <Menu.Item key="10">
                    <Link to={"/admin/teams"}>My Team</Link>
                  </Menu.Item>
                </>
              )}
              <Menu.Item key="9">
                <Button type="ghost" block onClick={() => logout()}>
                  Sign Out
                </Button>
              </Menu.Item>{" "}
            </SubMenu>
          ) : (
            <Menu.Item style={{ float: "right" }}>
              <Link to={"/admin/login"}>
                <LockOutlined /> Sign In
              </Link>
            </Menu.Item>
          )}
        </Menu>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Navigation));
