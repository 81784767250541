import React, { useState, useEffect } from "react";
import { Row, Col, Button, Empty, Typography } from "antd";
import {
  CloseOutlined,
  VerticalAlignTopOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Form } from "formik-antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../Flyer.css";

const Photos = ({ flyer, gallery, setFieldValue }) => {
  const [state, setState] = useState({
    imagesAvlb: [],
    selectedImages: [],
    selectedCount: flyer.photos.length,
  });
  const { imagesAvlb, selectedImages, selectedCount } = state;

  useEffect(() => {
    const imagesAvlb = [];
    const selectedImages = flyer.photos.concat();
    if (gallery.length) {
      gallery.forEach((pic) => {
        imagesAvlb.push({ pic, selected: false, order: null });
      });
    }
    selectedImages.forEach((img) => (img.selected = true));
    setState({ ...state, imagesAvlb, selectedImages });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const orderedArr = arrange(
      selectedImages,
      result.source.index,
      result.destination.index
    );
    orderedArr.map((obj, index) => {
      obj.order = index + 1;
      return obj;
    });
    setState({ ...state, selectedImages: orderedArr });
    setFieldValue("photos", orderedArr);
    //  props.updatedOrder(orderedArr);
  };

  const arrange = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "white",
    display: "flex",
  });

  const selectHandler = (tour) => {
    const images = [...imagesAvlb];
    let cnt = selectedCount;
    images.forEach((img) => {
      if (img.pic === tour.pic) {
        if (!img.selected) {
          if (selectedCount < 4) {
            img.selected = !img.selected;
            cnt += 1;
            img.order = cnt;
          }
        } else {
          img.selected = !img.selected;
          cnt -= 1;
          reorder(img.order);
          img.order = null;
        }
      }
    });
    setState({ ...state, imagesAvlb: images, selectedCount: cnt });
  };

  const reorder = (order) => {
    const images = imagesAvlb.concat();
    images.forEach((img) => {
      if (img.order && img.order > order) {
        img.order -= 1;
      }
    });
  };

  const clickHandler = () => {
    const selected = state.selectedImages.concat();
    imagesAvlb.forEach((img) => {
      if (img.order && img.selected) {
        selected.push({
          order: img.order,
          pic: img.pic,
        });
        img.selected = false;
      }
    });
    selected.sort((a, b) => {
      return a.order - b.order;
    });
    selected.forEach((imgObj, ind) => {
      if (imgObj.order !== ind + 1) {
        imgObj.order = ind + 1;
      }
    });
    setState({ ...state, selectedImages: selected });
    setFieldValue("photos", selected);
  };

  const editSelected = (index) => {
    const editedImgs = selectedImages.concat();
    editedImgs.splice(index, 1);
    editedImgs.forEach((img) => {
      if (img.order > index + 1) {
        img.order -= 1;
      }
    });
    setState({
      ...state,
      selectedImages: editedImgs,
      selectedCount: selectedCount - 1,
    });
    setFieldValue("photos", editedImgs);
  };

  return (
    <Form>
      {gallery.length ? (
        <div>
          <Form.Item name="photos" label="Photos" className="form-group">
            <Row className="thumbnail-list">
              {selectedImages.length ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {selectedImages.map((img, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Row className="image-container" gutter={[8, 8]}>
                                <Col span={24} justify="center" align="middle">
                                  <img
                                    className="thumbnail-image"
                                    src={img.pic}
                                    alt=""
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  />
                                </Col>
                                <Col span={12} justify="center">
                                  <span className="order">{img.order}</span>
                                </Col>
                                <Col span={12} justify="center" align="end">
                                  <Button
                                    type="circle"
                                    ghost
                                    onClick={() => editSelected(index)}
                                    icon={
                                      <CloseOutlined className="remove-icon" />
                                    }
                                  ></Button>
                                </Col>
                              </Row>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Col offset={8} span={8}>
                  <Empty />
                </Col>
              )}
            </Row>
          </Form.Item>
          <Row>
            <Col
              span={24}
              style={{
                fontSize: "1.2rem",
                margin: "10px 0",
                textAlign: "center",
              }}
            >
              <Button
                type="primary"
                disabled={
                  selectedCount === 4 && selectedImages.length !== 4
                    ? false
                    : true
                }
                icon={<VerticalAlignTopOutlined />}
                onClick={clickHandler}
              >
                Move 4 photos up
              </Button>
            </Col>
          </Row>
          <Row className="image-container" gutter={[8, 8]}>
            {imagesAvlb.map((tour) => (
              <Col span={6}>
                <img
                  src={tour.pic}
                  alt=""
                  onClick={() => selectHandler(tour)}
                />
                {tour.selected ? (
                  <Row style={{ height: "40px" }}>
                    <Col span={12}>
                      <span className="order">{tour.order}</span>
                    </Col>
                    <Col align="end" span={12}>
                      <CheckCircleFilled
                        className="remove-icon"
                        style={{ color: "green" }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Col
                    span={24}
                    style={{ width: "100%", height: "40px" }}
                  ></Col>
                )}
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <Empty>
          <Typography.Text>
            Oops !! You dont have tour created to add photos.Kindly create
            Virtual tour and then add photos.
          </Typography.Text>
        </Empty>
      )}
    </Form>
  );
};
export default Photos;
