import React, { useEffect, useState } from "react";
import axios from "axios";
import { Avatar, Card, Row, Col } from "antd";
import { UserOutlined, PhoneTwoTone, MailTwoTone } from "@ant-design/icons";
import { renderIconLine } from "../../../shared/utils/utils";

const ProfileSummary = ({ selectedClient }) => {
  useEffect(() => {
    if (selectedClient) {
      fetchStats();
    }
  }, []);

  const [stats, setStats] = useState(null);

  const fetchStats = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/clients/stats/${selectedClient._id}`
    );

    if (res.status === 200) {
      setStats(res.data);
    }
  };
  return (
    <div>
      <Card>
        <Row justify="space-around" align="middle">
          <Col flex={1}>
            {selectedClient.headshot_url ? (
              <Avatar size={90} src={selectedClient.headshot_url} />
            ) : (
              <Avatar size={90} icon={<UserOutlined />} />
            )}
          </Col>
          <Col flex={4}>
            <span className="client-name">
              {`${selectedClient.first} ${selectedClient.last}`}{" "}
            </span>
            <p
              style={{
                color: "#112350",
                fontSize: "1em",
              }}
            >
              {selectedClient.role}
              <br />
              HSF# {selectedClient.hsf}
            </p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <div className="inner-card mt-2">
              <span>
                {renderIconLine(MailTwoTone, selectedClient.email)}
                {renderIconLine(PhoneTwoTone, selectedClient.phone)}
              </span>
              {selectedClient.referred_by &&
                (<br />)`Referred By: ${selectedClient.referred_by}`}
              <div className="shoot-summary">
                <h4>Summary</h4>
                <div className="shoot-summary-item pt-3 pb-3">
                  <strong>Available Credit</strong>{" "}
                  <span className="float-right text-success">
                    <strong>{`$${
                      stats?.credits?.amount.toFixed(2) || 0
                    }`}</strong>
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Pending Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.pendingShoots}
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Scheduled Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.scheduledShoots}
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Processing Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.processingShoots}
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Completed Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.completedShoots}
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Rescheduled Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.rescheduledShoots}
                  </span>
                </div>
                <div className="shoot-summary-item">
                  Cancelled Shoots{" "}
                  <span className="float-right">
                    {stats?.shoots?.cancelledShoots}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProfileSummary;
