import React, { useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";

import processIcon from "../../../content/process.png";
import { READY } from "../../../redux/action/shoot/shootConstants";

const Dollhouse = ({ dollHouse }) => {
  const [dollhouseId, setDollhouseId] = useState();
  useEffect(() => {
    if (window.location.pathname.includes("/b/")) {
      setDollhouseId(dollHouse.branded);
    }
    if (window.location.pathname.includes("/u/")) {
      setDollhouseId(dollHouse.unbranded);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row>
      <Col span={24}>
        {(dollHouse.status === READY && dollHouse.matterPort_id) ||
        dollHouse.branded ? (
          <iframe
            title="Dollhouse"
            className="dollhouse-wrapper"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
            }}
            src={`https://my.matterport.com/show/?m=${
              dollhouseId ? dollhouseId : dollHouse.matterPort_id
            }&brand=0`}
          ></iframe>
        ) : (
          <Row justify="space-around" align="middle">
            <img src={processIcon} style={{ height: "35vh" }} alt="" />
            <Col span={24} align="middle">
              <Typography.Text type="secondary" style={{ color: "white" }}>
                We are processing your work.
              </Typography.Text>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Dollhouse;
