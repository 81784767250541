import axios from "axios";
import {
  FETCH_CREDITS_SUCCESS,
  FETCH_CREDITS_FAIL,
  FETCH_CREDIT_BY_ID_SUCCESS,
  FETCH_CREDIT_BY_ID_FAIL,
  FETCH_CLIENT_CREDITS_SUCCESS,
  FETCH_CLIENT_CREDITS_FAIL,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_CREDIT_FAIL,
  CREATE_CREDIT_SUCCESS,
  CREATE_CREDIT_FAIL,
  REMOVE_CREDIT_SUCCESS,
  REMOVE_CREDIT_FAIL,
  FETCH_CREDITS_TABLEVIEW_SUCCESS,
  FETCH_CREDITS_TABLEVIEW_FAIL,
} from "./types";

export const fetchCredits = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/credits`);
    dispatch({
      type: FETCH_CREDITS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CREDIT_BY_ID_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const fetchCreditsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/credits/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );

    dispatch({
      type: FETCH_CREDITS_TABLEVIEW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CREDITS_TABLEVIEW_FAIL,
    });
    return {
      data: null,
    };
  }
};

export const fetchClientCredits = (clientId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/credits/client?client=${clientId}`
    );
    dispatch({
      type: FETCH_CLIENT_CREDITS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CLIENT_CREDITS_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const fetchCreditById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/credits/${id}`
    );
    dispatch({
      type: FETCH_CREDIT_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CREDITS_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const createCredit = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/credits`,
      formData
    );
    console.log(res.data);
    dispatch({
      type: CREATE_CREDIT_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.error("Error occured while creating the credit as " + err.message);
    dispatch({
      type: CREATE_CREDIT_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const updateCredit = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/credits/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_CREDIT_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("Error occured while updating the credit as " + err.message);
    dispatch({
      type: UPDATE_CREDIT_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const removeCredit = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/credits/${id}`
    );
    dispatch({
      type: REMOVE_CREDIT_SUCCESS,
      payload: id,
    });
    return res;
  } catch (err) {
    dispatch({
      type: REMOVE_CREDIT_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};
