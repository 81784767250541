import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Switch, Tooltip, Select, Row, Col } from "antd";
import { BgColorsOutlined } from "@ant-design/icons";
import { CompactPicker } from "react-color";
import NumberFormat from "react-number-format";
import { updatePhotographer } from "../../../redux/action/photographer";

const { Option } = Select;

const PhotographerEdit = ({ data, updatePhotographer, handleCancel }) => {
  const [formData, setFormData] = useState({
    _id: data._id,
    photog_id: data.photog_id,
    name: data.name,
    home_address: data.home_address,
    preferred_email: data.preferred_email,
    phone: data.phone,
    color: data.color,
    pay_multiplier: data.pay_multiplier,
    base_pay: data.base_pay,
    sp_cost_per_hour: data.sp_cost_per_hour,
    headshot_url: data.headshot_url,
    skills: data.skills,
    active: data.active,
  });
  // const [photogSkills, setPhotogSkills] = useState({ ...data.skills });

  const {
    _id,
    photog_id,
    name,
    home_address,
    preferred_email,
    phone,
    color,
    pay_multiplier,
    sp_cost_per_hour,
    base_pay,
    headshot_url,
    skills,
    active,
  } = formData;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onShowColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const onColorChange = (color, event) => {
    setFormData({ ...formData, color: color.hex });
    setDisplayColorPicker(!displayColorPicker);
  };

  const onSwitchChange = (checked) => {
    setFormData({ ...formData, active: checked });
  };

  const onSkillsSelectChange = (value) => {
    setFormData({ ...formData, skills: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updatePhotographer(_id, { ...formData });
    handleCancel();
  };

  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Name">
              <Input name="name" value={name} onChange={(e) => onChange(e)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="ID">
              <Input
                name="photog_id"
                value={photog_id}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Address">
              <Input
                name="home_address"
                value={home_address}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email">
              <Input
                name="preferred_email"
                value={preferred_email}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Phone">
              <NumberFormat
                allowEmptyFormatting
                format="+1 (###) ### ####"
                name="phone"
                value={phone}
                onChange={(e) => onChange(e)}
                customInput={Input}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="Pay Multiplier">
              <Input
                type="number"
                name="pay_multiplier"
                value={pay_multiplier}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="Base Pay">
              <Input
                type="number"
                prefix="$"
                name="base_pay"
                value={base_pay}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="Cost per Hour">
              <Input
                type="number"
                prefix="$"
                name="sp_cost_per_hour"
                value={sp_cost_per_hour}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Headshot URL">
              <Input
                name="headshot_url"
                placeholder="http://path-to-photo.com/photo"
                value={headshot_url}
                onChange={(e) => onChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Calendar Color">
              <Tooltip title="Select a Color">
                <Button
                  onClick={() => onShowColorPicker()}
                  shape="circle"
                  icon={<BgColorsOutlined style={{ color: `${color}` }} />}
                />
              </Tooltip>
              <br />
              {displayColorPicker ? (
                <CompactPicker onChangeComplete={onColorChange} />
              ) : null}
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="form-group">
              Active <Switch checked={active} onChange={onSwitchChange} />
            </div>
          </Col>
          <Col span={24}>
            <h3>Skills</h3>
          </Col>
          <Col span={24}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Skills"
              onChange={onSkillsSelectChange}
              defaultValue={skills}
            >
              <Option value="Dollhouse">Dollhouse</Option>
              <Option value="Drone Photos and HD Video">
                Drone Photos and HD Video
              </Option>
              <Option value="Interior/Exterior Photos">
                Interior/Exterior Photos
              </Option>
              <Option value="Twilight">Twilight</Option>
            </Select>
            ,
          </Col>
          <Col span={24}>
            <div className="form-group">
              <Button
                type="primary"
                onClick={(e) => onSubmit(e)}
                style={{ marginTop: "20px" }}
              >
                Save
              </Button>
              <Button
                type="link"
                onClick={handleCancel}
                style={{ marginTop: "20px" }}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default connect(null, { updatePhotographer })(PhotographerEdit);
