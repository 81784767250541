import React, { useState, useEffect } from "react";
import { Card, Button, Progress, Row, Col, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import Pdf from "react-to-pdf";
import "./FlyerPreview.css";

import icon1 from "./img/icon-01.svg";
import location2 from "./img/location-2.svg";
import phone from "./img/phone.svg";

const ref = React.createRef();
const FlyerPreview = ({ flyer, updateDisableStatus }) => {
  const [state, setState] = useState({
    isCompleted: false,
    compPercnt: 0,
    mainImg: null,
  });
  const [img, setImg] = useState({ 1: "", 2: "", 3: "", 4: "" });

  const updateProgress = (val) => {
    if (Object.keys(val).length === 0) return;

    const { property, brokerage, agent, photos } = val;
    let fieldCnt = 19;

    if (!val.headline) {
      fieldCnt -= 1;
    }

    if (property) {
      if (property.features.length === 0) {
        fieldCnt -= 1;
      }
      if (!property.street) {
        fieldCnt -= 1;
      }
      if (!property.description) {
        fieldCnt -= 1;
      }
      if (!property.price) {
        fieldCnt -= 1;
      }
      if (!property.sqft) {
        fieldCnt -= 1;
      }
      if (!property.beds) {
        fieldCnt -= 1;
      }
      if (!property.baths) {
        fieldCnt -= 1;
      }
      if (!property.year) {
        fieldCnt -= 1;
      }
    }

    if (brokerage) {
      if (!brokerage.name) {
        fieldCnt -= 1;
      }
      if (!brokerage.logo_url) {
        fieldCnt -= 1;
      }
      if (!brokerage.address) {
        fieldCnt -= 1;
      }
    }

    if (agent) {
      if (!agent.headshot_url) {
        fieldCnt -= 1;
      }
      if (!agent.name) {
        fieldCnt -= 1;
      }
      if (!agent.phone) {
        fieldCnt -= 1;
      }
      if (photos.length < 4) {
        fieldCnt -= 4 - photos.length;
      }
      const compPercnt = parseFloat(((fieldCnt / 19) * 100).toFixed(2));
      const isCompleted = compPercnt === 100 ? true : false;
      setState({ isCompleted, compPercnt });
      updateDisableStatus(!isCompleted);
    }

    if (photos.length < 4) {
      fieldCnt -= 4 - photos.length;
    }

    const compPercnt = parseFloat(((fieldCnt / 19) * 100).toFixed(2));
    const isCompleted = compPercnt === 100 ? true : false;
    setState({ isCompleted, compPercnt });
  };

  useEffect(() => {
    updateProgress(flyer);
    const getImgBlob = async () => {
      var img = {
        1: await getBlob(flyer.photos[0].pic),
        2: await getBlob(flyer.photos[1].pic),
        3: await getBlob(flyer.photos[2].pic),
        4: await getBlob(flyer.photos[3].pic),
      };
      setImg(img);
    };
    if (flyer && flyer.photos && flyer.photos.length === 4) {
      getImgBlob();
    }
  }, [flyer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBlob = async (url) => {
    const img = await fetch(url, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    const blob = await img.blob();
    return URL.createObjectURL(blob);
  };

  return (
    <div>
      {/* <button onClick={exportPdf}>pdf</button> */}
      {state.isCompleted ? (
        <div style={{ float: "right" }}>
          <Pdf targetRef={ref} filename="Flyer.pdf">
            {({ toPdf }) => (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={toPdf}
              >
                Download PDF
              </Button>
            )}
          </Pdf>
        </div>
      ) : null}
      <h2>Preview</h2>
      {state.isCompleted ? (
        <>
          {/* <Card> */}
          <div className="fl-wrap" ref={ref} id="capture">
            <div className="fl-container">
              <div className="fl-col-bg"></div>
              <div className="fl-main">
                <div className="fl-main__content">
                  <div className="fl-logo">
                    <img src={flyer.brokerage.logo_url} alt="" />
                  </div>
                  <div className="fl-street">
                    <address className="">{flyer.property.street}</address>
                  </div>
                  <div className="fl-headline">{flyer.headline}</div>
                  <ul className="fl-list">
                    <li>{`Priced at $${flyer.property.price.toLocaleString(
                      "en"
                    )}`}</li>
                    <li>{`${flyer.property.sqft.toLocaleString(
                      "en"
                    )} sqft`}</li>
                    <li>{`${flyer.property.beds} Bed`}</li>
                    <li>{`${flyer.property.baths} Bath`}</li>
                    <li>{`Built in ${flyer.property.year}`}</li>
                    {flyer.property.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
                <div
                  className="fl-main__img"
                  style={{
                    backgroundImage: `url(${img[1]})`,
                  }}
                ></div>
              </div>

              <div className="fl-desc">
                <div className="fl-desc__left">
                  {flyer.photos.map((tour, index) =>
                    tour.order > 1 ? (
                      <div className="fl-small-img" key={index}>
                        <img src={img[tour.order]} alt="" />
                      </div>
                    ) : null
                  )}
                </div>
                <div className="fl-desc__right">
                  <div className="fl-person-block">
                    <div className="fl-photo">
                      <img src={flyer.agent.headshot_url} alt="" />
                    </div>
                    <div className="fl-info">
                      <div className="fl-title">{flyer.agent.name}</div>
                      <div className="fl-text">{flyer.brokerage.moto}</div>
                      <ul className="fl-info__list">
                        <li className="fl-info__item">
                          <div className="fl-info__icon">
                            <img src={icon1} alt="" />
                          </div>
                          <span>{flyer.brokerage.name}</span>
                        </li>
                        <li className="fl-info__item">
                          <div className="fl-info__icon">
                            <img src={phone} alt="" />
                          </div>
                          <span>{flyer.agent.phone}</span>
                        </li>
                        <li className="fl-info__item">
                          <div className="fl-info__icon">
                            <img src={location2} alt="" />
                          </div>
                          <address>
                            {flyer.brokerage.address} <br />
                            {`${flyer.brokerage.city}, ${flyer.brokerage.state} ${flyer.brokerage.zip}`}
                          </address>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="fl-text-block">
                    <div className="fl-title">Property Features:</div>
                    <div className="fl-text">{flyer.property.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Card> */}
        </>
      ) : (
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={8} offset={8} align="middle">
              <Progress
                type="circle"
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
                percent={state.compPercnt}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24} align="middle">
              <Typography.Text type="secondary">
                Complete your form to see the Flyer preview here.
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default FlyerPreview;
