import React, { useEffect, useState } from "react";
import {
  Calendar,
  Select,
  Tag,
  Col,
  Row,
  Typography,
  Button,
  message,
  Spin,
  Modal,
  Form,
  DatePicker,
  Radio,
} from "antd";
import { connect } from "react-redux";
import {
  fetchPhotographerByUserId,
  fetchPhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
} from "../../../redux/action/photographerAction";
import {
  convertISODateString,
  convertISOStrToDate,
} from "../../../shared/utils/utils";
const moment = require("moment");
const { CheckableTag } = Tag;

const EditAvailability = ({
  photographer: { currentPhotographer, photographerUnavailableSlotsForDay },
  fetchPhotographerUnavailabilityForDay,
  fetchPhotographerByUserId,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
  auth,
  initialDate,
}) => {
  const [calendarDate, setCalendarDate] = useState();
  const [form] = Form.useForm();

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [formValues, setFormValues] = useState({
    from: moment(),
    to: moment(),
    updateFor: "daily",
  });

  useEffect(() => {
    if (initialDate) {
      setCalendarDate(initialDate);
    } else {
      setCalendarDate(moment());
    }
  }, []);

  const tagsData = [
    { label: "9:00AM - 10:00AM", start: 9, end: 10 },
    { label: "10:00AM - 11:00AM", start: 10, end: 11 },
    { label: "11:00AM - 12:00PM", start: 11, end: 12 },
    { label: "12:00PM - 1:00PM", start: 12, end: 13 },
    { label: "1:00PM - 2:00PM", start: 13, end: 14 },
    { label: "2:00PM - 3:00PM", start: 14, end: 15 },
    { label: "3:00PM - 4:00PM", start: 15, end: 16 },
    { label: "4:00PM - 5:00PM", start: 16, end: 17 },
    { label: "5:00PM - 6:00PM", start: 17, end: 18 },
    { label: "6:00PM - 7:00PM", start: 18, end: 19 },
    { label: "7:00PM - 8:00PM", start: 19, end: 20 },
    { label: "8:00PM - 9:00PM", start: 20, end: 21 },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      await fetchPhotographerUnavailabilityForDay(
        currentPhotographer._id,
        convertISODateString(calendarDate._d)
      );
      setLoader(false);
    }
    if (calendarDate && currentPhotographer?._id) {
      setSelectedSlots([...tagsData]);
      fetchData();
    }
  }, [calendarDate, currentPhotographer]);

  useEffect(() => {
    let tempSelectedSlots = [...tagsData];
    if (photographerUnavailableSlotsForDay?.unAvailableSlots) {
      photographerUnavailableSlotsForDay.unAvailableSlots.forEach((slot) => {
        for (let obj of tagsData) {
          if (
            convertISOStrToDate(slot.start).getHours() === obj.start &&
            convertISOStrToDate(slot.end).getHours() === obj.end
          ) {
            tempSelectedSlots = tempSelectedSlots.filter(
              (slt) => slt.label !== obj.label
            );
            break;
          }
        }
      });
    }
    setSelectedSlots(tempSelectedSlots);
  }, [photographerUnavailableSlotsForDay]);

  const onSelect = (date) => {
    setCalendarDate(date);
  };

  const handleChange = (tag, checked) => {
    const nextSelectedSlots = checked
      ? [...selectedSlots, tag]
      : selectedSlots.filter((t) => t.label !== tag.label);
    setSelectedSlots([...nextSelectedSlots]);
  };

  const modalOpen = () => {
    setFormValues({
      ...formValues,
      from: calendarDate,
      to: calendarDate.clone().add(1, "months"),
    });
    form.setFieldsValue({
      from: calendarDate,
      to: calendarDate.clone().add(1, "months"),
    });
    setIsModalVisible(true);
  };

  const onClose = () => {
    setIsModalVisible(false);
  };

  const save = async () => {
    setLoader(true);
    const res = await savePhotographerUnavailabilityForDay(
      currentPhotographer._id,
      {
        date: convertISODateString(calendarDate._d),
        slots: tagsData.filter(
          (slot) => !selectedSlots.find((slt) => slt.label === slot.label)
        ),
      }
    );
    if (res.data) {
      message.success("Successfully Updated");
    }
    setLoader(false);
  };

  const update = async (values) => {
    setLoader(true);
    const res = await savePhotographerUnavailabilityForCustomDate(
      currentPhotographer._id,
      {
        ...values,
        from: convertISODateString(values.from._d),
        to: convertISODateString(values.to._d),
        slots: tagsData.filter(
          (slot) => !selectedSlots.find((slt) => slt.label === slot.label)
        ),
      }
    );
    if (res.data) {
      message.success("Successfully Updated");
    }
    setIsModalVisible(false);
    setLoader(false);
  };

  const disabledDate = (current, dateType) => {
    const filterDate =
      dateType === "From" ? formValues.to?.toDate() : formValues.from?.toDate();
    if (filterDate) {
      if (dateType === "From") {
        return current && current < moment().subtract(1, "days").endOf("day");
      } else {
        return moment(current.toDate()).isBefore(filterDate, "day");
      }
    }
    return current && current >= moment().endOf("day");
  };

  const toggleSelection = () => {
    if (selectedSlots.length === 12) {
      setSelectedSlots([]);
    } else {
      setSelectedSlots([...tagsData]);
    }
  };

  const onFormChange = (changedValue) => {
    if (changedValue.from) {
      const to = changedValue.from.clone().add(1, "months");
      form.setFieldsValue({ to });
      setFormValues({ ...formValues, ...changedValue, to });
    } else {
      setFormValues({ ...formValues, ...changedValue });
    }
  };

  return (
    <>
      <Row gutter={[32, 32]} className="mt-3">
        <Col span={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Calendar
                value={calendarDate}
                fullscreen={false}
                onSelect={onSelect}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];

                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                  }

                  for (let index = start; index < end; index++) {
                    monthOptions.push(
                      <Select.Option className="month-item" key={`${index}`}>
                        {months[index]}
                      </Select.Option>
                    );
                  }
                  const month = value.month();

                  const year = value.year();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div style={{ padding: 8 }}>
                      <Typography.Title level={4}>Calendar</Typography.Title>
                      <Row gutter={8}>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            value={String(year)}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                              const newValue = value.clone();
                              newValue.month(parseInt(selectedMonth, 10));
                              onChange(newValue);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <div className="slot-header">
                <div>
                  <h3>
                    Availability for {calendarDate?.format("MMMM D, YYYY")}
                  </h3>
                  {(auth?.user?.access.includes("ADMIN") ||
                    auth.user?.access.includes("COMMS")) && (
                    <span>
                      Please select the times that you are <strong>NOT</strong>{" "}
                      available to work on this day
                    </span>
                  )}
                </div>
                <div>
                  {(auth?.user?.access.includes("ADMIN") ||
                    auth.user?.access.includes("COMMS")) && (
                    <Button onClick={toggleSelection}>
                      {selectedSlots.length === 12
                        ? "Deselect All"
                        : "Select All"}
                    </Button>
                  )}
                </div>
              </div>
              <div className="slot-container">
                {!loader ? (
                  <>
                    {calendarDate?.day() !== 0 ? (
                      tagsData.map((tag) => {
                        return (
                          <CheckableTag
                            className={
                              auth?.user?.access.includes("ADMIN") ||
                              auth.user?.access.includes("COMMS")
                                ? "slot"
                                : "slot read-only"
                            }
                            key={tag.label}
                            checked={selectedSlots.find(
                              (slt) => slt.label === tag.label
                            )}
                            onChange={(checked) => handleChange(tag, checked)}
                          >
                            {tag.label}
                          </CheckableTag>
                        );
                      })
                    ) : (
                      <p style={{ fontWeight: "bold" }}>
                        No Slots Available for Sunday
                      </p>
                    )}
                  </>
                ) : (
                  <Spin size="large" />
                )}
              </div>
              <Row justify="center" className="mb-4 ">
                {calendarDate?.isSameOrAfter(moment(), "day") &&
                calendarDate?.day() !== 0 &&
                (auth?.user?.access.includes("ADMIN") ||
                  auth.user?.access.includes("COMMS")) ? (
                  <>
                    <Button
                      type="secondary"
                      onClick={modalOpen}
                      className="mr-2 mb-2"
                    >
                      Apply selected times to multiple days
                    </Button>
                    <Button type="primary" onClick={save}>
                      Submit Availability
                    </Button>
                  </>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Custom Selection"
        visible={isModalVisible}
        footer={null}
        onCancel={onClose}
        destroyOnClose={true}
        style={{ top: 20 }}
      >
        <h4>Unavailable Slots</h4>
        <ul>
          {tagsData
            .filter(
              (slot) => !selectedSlots.find((slt) => slt.label === slot.label)
            )
            .map((slot) => (
              <li key={slot.label}>{slot.label}</li>
            ))}
        </ul>
        <Form
          onValuesChange={onFormChange}
          form={form}
          onFinish={update}
          initialValues={formValues}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="from"
                label="From"
                rules={[
                  {
                    required: true,
                    message: "Select the From date",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => disabledDate(current, "From")}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="to"
                label="To"
                rules={[
                  {
                    required: true,
                    message: "Select the To date",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => disabledDate(current, "To")}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="updateFor"
            label="Update this for"
            rules={[
              {
                required: true,
                message: "Select any one",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="specificDay">
                {`Only for ${formValues.from?.format("dddd")}s`}
              </Radio>
              <Radio value="daily">Daily</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="mr-2">
              Update
            </Button>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({
  photographer: state.photographer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPhotographerByUserId,
  fetchPhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForDay,
  savePhotographerUnavailabilityForCustomDate,
})(EditAvailability);
