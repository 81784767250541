import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Card, Row, Col, Avatar, Tabs, Modal, Timeline, Button } from "antd";

import { renderLabel } from "../../../shared/utils/utils";
import Notes from "../Order/Common/Notes";
import { fetchDeviceById } from "../../../redux/action/deviceAction";
import "./styles.scss";

const { TabPane } = Tabs;

const DeviceDetail = ({
  match,
  fetchDeviceById,
  devices: { selectedDevice },
}) => {
  useEffect(() => {
    fetchDeviceById(match.params.id);
  }, [fetchDeviceById, match.params.id]);

  const [modalVisible, setModalVisible] = useState(false);

  const renderAssignedPhotographer = (assignee) => {
    return (
      <>
        {assignee ? (
          <div>
            <Card
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 7,
              }}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <div className="mb-3">
                    <span className="mr-3">
                      {assignee?.headshot_url ? (
                        <Avatar src={assignee?.headshot_url} />
                      ) : (
                        <Avatar
                          style={{
                            backgroundColor: assignee?.color,
                          }}
                        >
                          {assignee?.name.charAt(0)}
                        </Avatar>
                      )}
                    </span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {" "}
                      {assignee?.name}
                    </span>
                  </div>
                  <div>{assignee?.address}</div>
                  <div>{assignee?.phone}</div>
                  <div>{assignee?.email}</div>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  {renderLabel(
                    `Date ${
                      assignee?.assignee_type === "Photographer"
                        ? "assigned"
                        : "sent"
                    }`,
                    moment(assignee?.date_assigned).format("M/D/YYYY")
                  )}
                  {renderLabel(
                    `Quantity ${
                      assignee?.assignee_type === "Photographer"
                        ? "assigned"
                        : "sent"
                    }`,
                    assignee?.quantity
                  )}

                  {renderLabel(
                    `Condition when ${
                      assignee?.assignee_type === "Photographer"
                        ? "assigned"
                        : "sent"
                    }`,
                    assignee?.condition
                  )}
                  {renderLabel(
                    "Return Date",
                    moment(assignee?.date_returned).format("M/D/YYYY")
                  )}
                  {assignee?.assignee_type === "Repairer" &&
                    renderLabel(
                      "Repair Cost",
                      `$${assignee?.repair_cost.toFixed(2)}`
                    )}
                </Col>
                {assignee?.images.length > 0 && (
                  <Col span={24}>
                    <h4>Condition Photos</h4>
                    <Card>
                      <Row>
                        {assignee?.images.map((image, index) => (
                          <Col span={6} key={index}>
                            <img
                              style={{ width: "100%", padding: 5 }}
                              src={image}
                              alt=""
                            />
                          </Col>
                        ))}
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
            </Card>
          </div>
        ) : (
          <div>Currently not assigned</div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="device-detail">
        <Card
          style={{
            margin: "20px",
          }}
        >
          <Row gutter={[8, 8]} className="promo-wrapper">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="description-header">
                {selectedDevice.thumbnail && (
                  <Avatar
                    src={selectedDevice?.thumbnail}
                    shape="square"
                    size={100}
                  />
                )}
                <div>
                  <div className="title">{selectedDevice?.description}</div>
                  <div className="description">
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {renderLabel(
                          "Serial Number",
                          selectedDevice?.serial_number
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {renderLabel(
                          "Bought From",
                          selectedDevice?.bought_from
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {renderLabel(
                          "Purchase Date",
                          moment(selectedDevice?.purchase_date).format(
                            "MM/DD/YY"
                          )
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {" "}
                        {renderLabel(
                          "Purchase Price",
                          `$${selectedDevice?.price?.toFixed(2)}`
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {renderLabel(
                          "Warranty Info",
                          selectedDevice?.warranty_info
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div>
                <h4>Currently Assigned To:</h4>
                <div>
                  {renderAssignedPhotographer(selectedDevice?.current_assignee)}
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Notes" key="1">
                  <div className="device-notes">
                    <h3>Notes</h3>
                    <Notes entityType="Equipment" data={selectedDevice} />
                  </div>
                </TabPane>
                <TabPane tab="Assigned History" key="2">
                  <h3>Assigned History</h3>
                  <Timeline>
                    {selectedDevice?.assignee_history?.map(
                      (assignee, index) => (
                        <Timeline.Item key={index}>
                          {assignee.name}{" "}
                          <span
                            style={{
                              fontSize: 12,
                              color: "#888",
                              marginLeft: 25,
                            }}
                          >
                            {moment(assignee?.date_assigned).format("M/D/YYYY")}{" "}
                            -{" "}
                            {assignee?.date_returned
                              ? moment(assignee?.date_returned).format(
                                  "MM/D/YYYY"
                                )
                              : "Pending"}
                          </span>{" "}
                          <span
                            style={{
                              fontSize: 12,
                              marginLeft: 25,
                            }}
                          >
                            {`${assignee?.condition} condition`}
                          </span>
                          <Button
                            size="small"
                            className="float-right"
                            onClick={() => setModalVisible(true)}
                          >
                            Details
                          </Button>
                          <div>
                            <Row gutter={6}>
                              {assignee?.images.map((image, index) => (
                                <Col span={4} key={index}>
                                  <img
                                    style={{ width: "100%", padding: 5 }}
                                    src={image}
                                    alt=""
                                  />
                                </Col>
                              ))}
                            </Row>
                          </div>
                          <Modal
                            title={`${assignee?.name}'s Details`}
                            visible={modalVisible}
                            onCancel={() => setModalVisible(false)}
                            footer={null}
                          >
                            {renderAssignedPhotographer(assignee)}
                          </Modal>
                        </Timeline.Item>
                      )
                    )}
                  </Timeline>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  devices: state.devices,
});

export default connect(mapStateToProps, { fetchDeviceById })(DeviceDetail);
