/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import SocialLogin from "../../../shared/Components/SocialLogin";
import { connect } from "react-redux";
import { Row, Col, Form, Input, Button, Spin, Card, Alert } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { loginUser } from "../../../redux/action/authAction";
import "./AdminLogin.scss";

const AdminLogin = ({
  isAuthenticated,
  history,
  loginUser,
  loader,
  errorMsg,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      if (history.location.state && history.location.state.from) {
        history.push(history.location.state.from + history.location.state.search);
      } else {
        history.push("/admin/dashboard");
      }
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const [formData, setformData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  let style = {};

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is invalid!",
      number: "${label} is not a validate number!",
    },
  };

  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    loginUser(formData);
  };

  return (
    <div className="login-container">
      <Row gutter={16} style={style} justify="center" align="middle">
        <Col>
          <Card className="login-card">
            <Row>
              <Col span={24}>
                <Form
                  {...layout}
                  onFinish={onSubmit}
                  validateMessages={validateMessages}
                  hideRequiredMark
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      size="large"
                      name="email"
                      value={email}
                      onChange={(e) => onChange(e)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<UnlockOutlined />}
                      size="large"
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                    />
                  </Form.Item>

                  <Spin spinning={loader}>
                    <Button
                      block
                      className="btn-cta btn-block"
                      style={{
                        marginBottom: "15px",
                        padding: "12px !important",
                      }}
                      //className="appButton"
                      type="primary"
                      htmlType="submit"
                    >
                      Sign In
                    </Button>
                  </Spin>
                </Form>
              </Col>
            </Row>
            <Row>
              <SocialLogin />
            </Row>
            <Row>
              {errorMsg && (
                <Col span={24} align="middle" style={{ padding: "25px" }}>
                  <Alert message={errorMsg} type="error" />
                </Col>
              )}
            </Row>
            <p className="additional-info mt-4 text-center">
              Forgot your password?&nbsp;
              <Link to="/admin/forgot-password">Reset password.</Link>
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loader: state.auth.isLoading,
  errorMsg: state.auth.error,
});

export default withRouter(connect(mapStateToProps, { loginUser })(AdminLogin));
