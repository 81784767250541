import axios from "axios";
import {
  FETCH_ACTIVITY_BY_ENTITY,
  CREATE_ACTIVITY,
  REMOVE_ACTIVITY,
} from "./types";
import setAuthToken from "../../utils/setAuthToken";

let config;

if (localStorage.hstoken) {
  setAuthToken(localStorage.hstoken);
  config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.hstoken,
    },
  };
}

export const fetchEntityActivities = (entityId, access) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/activities/entity/${entityId}?access=${access}`
    );
    dispatch({
      type: FETCH_ACTIVITY_BY_ENTITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ACTIVITY_BY_ENTITY,
      payload: [],
    });
    console.log(err);
  }
};

export const createActivity = (shoot, text) => async (dispatch) => {
  try {
    let txt = text;
    if (txt.includes("scheduled")) {
      const actvs = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/activities/entity/${shoot._id}`
      );
      const schd = actvs.data.filter((acty) => acty.text.includes("scheduled"));
      if (schd.length) {
        txt = txt.replace("scheduled", "re-scheduled");
      }
    }
    const body = {
      user: JSON.parse(localStorage.getItem("authUser"))._id,
      text: txt,
      entity_id: shoot._id,
      entity_type: text.includes("Invoice") ? "Invoice" : "Shoot",
      _created_at: new Date(),
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/activities`,
      body,
      config
    );
    dispatch({
      type: CREATE_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const createRequestActivity =
  (activityData, text) => async (dispatch) => {
    try {
      const body = {
        user: activityData.user,
        text: text,
        entity_id: activityData.entity_id,
        entity_type: "Shoot",
        _created_at: new Date(),
      };
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/activities`,
        body,
        config
      );
      dispatch({
        type: CREATE_ACTIVITY,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const removeActivity = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/activities/${id}`);
    dispatch({
      type: REMOVE_ACTIVITY,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
