import React, { useState } from "react";
import { connect } from "react-redux";
import { Input, Switch, Select, Button } from "antd";
import { createOffering } from "../../../redux/action/offeringAction";
import "./OfferingsForm.css";

const OfferingsForm = ({ createOffering, onOk }) => {
  const { Option, OptGroup } = Select;

  const [formData, setFormData] = useState({
    description: "",
    category: "",
    code: "",
    price: "",
    discount_price: "",
    discount_enabled: false,
    pay: "",
    duration: "",
    example_image: "",
    example_url: "",
    active: false,
    bf_visible: false,
    inv_visible: false,
  });

  const {
    description,
    category,
    code,
    price,
    discount_price,
    discount_enabled,
    pay,
    duration,
    example_image,
    example_url,
    active,
    bf_visible,
    inv_visible,
  } = formData;

  const handleSelectChange = (value) => {
    setFormData({ ...formData, category: value });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createOffering(formData);
    setFormData({});
    onOk();
  };

  return (
    <div>
      <div className="form-group">
        <label>Description</label>
        <Input
          name="description"
          value={description}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        <label>Code</label>
        <Input name="code" value={code} onChange={(e) => onChange(e)} />
      </div>
      <div className="form-group">
        <label>Category </label>
        <br />
        <Select
          name="category"
          defaultValue="Select a Category"
          style={{ width: 300 }}
          onChange={handleSelectChange}
          value={category}
        >
          <OptGroup label="Residential">
            <Option value="Residential - Drone Photos and HD Video">
              Drone Photos and HD Video
            </Option>
            <Option value="Residential - Drone Photos">Drone Photos</Option>
            <Option value="Residential - Fees">Fees</Option>
            <Option value="Residential - Interior/Exterior Photos">
              Interior/Exterior Photos
            </Option>
            <Option value="Residential - Photo Turnaround">
              Photo Turnaround
            </Option>
            <Option value="Residential - Premier Offerings">
              Premier Offerings
            </Option>
            <Option value="Residential - Video Turnaround">
              Video Turnaround
            </Option>
            <Option value="Residential - Virtual Reality Tour">
              Virtual Reality Tour
            </Option>
            <Option value="Residential - Specified Package">
              Specified Package
            </Option>
            <Option value="Residential - Discretionary">Discretionary</Option>
          </OptGroup>
          <OptGroup label="Multi-family">
            <Option value="Multifamily - Drone Photos and HD Video">
              Drone Photos and HD Video
            </Option>
            <Option value="Multifamily - Drone Photos">Drone Photos</Option>
            <Option value="Multifamily - Fees">Fees</Option>
            <Option value="Multifamily - Interior/Exterior Photos">
              Interior/Exterior Photos
            </Option>
            <Option value="Multifamily - Photo Turnaround">
              Photo Turnaround
            </Option>
            <Option value="Multifamily - Premier Offerings">
              Premier Offerings
            </Option>
            <Option value="Multifamily - Video Turnaround">
              Video Turnaround
            </Option>
            <Option value="Multifamily - Virtual Reality Tour">
              Virtual Reality Tour
            </Option>
            <Option value="Multifamily - Specified Package">
              Specified Package
            </Option>
            <Option value="Multifamily - Discretionary">Discretionary</Option>
          </OptGroup>
          <OptGroup label="Commercial">
            <Option value="Commercial - Drone Photos and HD Video">
              Drone Photos and HD Video
            </Option>
            <Option value="Commercial - Drone Photos">Drone Photos</Option>
            <Option value="Commercial - Fees">Fees</Option>
            <Option value="Commercial - Interior/Exterior Photos">
              Interior/Exterior Photos
            </Option>
            <Option value="Commercial - Photo Turnaround">
              Photo Turnaround
            </Option>
            <Option value="Commercial - Premier Offerings">
              Premier Offerings
            </Option>
            <Option value="Commercial - Video Turnaround">
              Video Turnaround
            </Option>
            <Option value="Commercial - Virtual Reality Tour">
              Virtual Reality Tour
            </Option>
            <Option value="Commercial - Specified Package">
              Specified Package
            </Option>
            <Option value="Commercial - Discretionary">Discretionary</Option>
          </OptGroup>
          <OptGroup label="Additional">
            <Option value="Additional - Drone Photos and HD Video">
              Drone Photos and HD Video
            </Option>
            <Option value="Additional - Drone Photos">Drone Photos</Option>
            <Option value="Additional - Fees">Fees</Option>
            <Option value="Additional - Interior/Exterior Photos">
              Interior/Exterior Photos
            </Option>
            <Option value="Additional - Photo Turnaround">
              Photo Turnaround
            </Option>
            <Option value="Additional - Premier Offerings">
              Premier Offerings
            </Option>
            <Option value="Additional - Video Turnaround">
              Video Turnaround
            </Option>
            <Option value="Additional - Virtual Reality Tour">
              Virtual Reality Tour
            </Option>
            <Option value="Additional - Specified Package">
              Specified Package
            </Option>
            <Option value="Additional - Discretionary">Discretionary</Option>
          </OptGroup>
        </Select>
      </div>
      <div className="form-group">
        <label>Price</label>
        <Input
          type="number"
          prefix="$"
          name="price"
          value={price}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        Discount{" "}
        <Switch
          checked={discount_enabled}
          onChange={(checked) =>
            setFormData({ ...formData, discount_enabled: checked })
          }
        />
      </div>
      <div className="form-group">
        <label>Discount Price</label>
        <Input
          type="number"
          prefix="$"
          name="discount_price"
          value={discount_price}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        <label>Base Pay</label>
        <Input
          type="number"
          prefix="$"
          name="pay"
          value={pay}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        <label>Duration (optional)</label>
        <Input
          type="number"
          name="duration"
          value={duration}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        <label>Example Image URL (optional)</label>
        <Input
          name="example_image"
          value={example_image}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        <label>Example Offering URL (optional)</label>
        <Input
          name="example_url"
          value={example_url}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="form-group">
        Active{" "}
        <Switch
          checked={active}
          onChange={(checked) => setFormData({ ...formData, active: checked })}
        />
      </div>
      <div className="form-group">
        Visible on Booking Form{" "}
        <Switch
          checked={bf_visible}
          onChange={(checked) =>
            setFormData({ ...formData, bf_visible: checked })
          }
        />
      </div>
      <div className="form-group">
        Visible on Invoice{" "}
        <Switch
          checked={inv_visible}
          onChange={(checked) =>
            setFormData({ ...formData, inv_visible: checked })
          }
        />
      </div>
      <div className="form-group">
        <Button
          type="primary"
          onClick={(e) => onSubmit(e)}
          style={{ marginTop: "20px" }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default connect(null, { createOffering })(OfferingsForm);
