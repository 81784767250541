import {
  FETCH_ACTIVITY_BY_ENTITY,
  CREATE_ACTIVITY,
  REMOVE_ACTIVITY,
} from "../action/types";

const initialState = { activities: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACTIVITY_BY_ENTITY:
      return {
        ...state,
        isLoading: false,
        activities: payload,
      };
    case CREATE_ACTIVITY:
      return {
        ...state,
        activities: [...state.activities, action.payload],
        isLoading: false,
      };

    case REMOVE_ACTIVITY:
      return {
        ...state,
        activities: state.activities.filter(
          (acty) => acty._id !== action.payload
        ),

        isLoading: false,
      };
    default:
      return state;
  }
}
