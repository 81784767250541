import React from "react";
import {
  Tag,
  Row,
  Col,
  Avatar,
  Button,
  Tooltip,
  Drawer,
  Divider,
  message,
} from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  DollarCircleFilled,
  EyeOutlined,
  LinkOutlined,
  InstagramOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const ShareButtons = ({ shoot }) => {
  const brandedUrl = `${process.env.REACT_APP_DOMAIN_URL}/media/b/${shoot?.hsn}/${shoot?.address?.street_lower_no_spaces}`;

  return (
    <Row>
      <Col>
        <Tooltip placement="top" title="Share on Facebook" color="#4e85f5">
          <FacebookOutlined
            onClick={() =>
              window.open(
                `https://www.facebook.com/sharer.php?u=${brandedUrl}`,
                "_blank"
              )
            }
            className="fb"
            style={{ color: "#4e85f5", fontSize: 40, marginRight: 25 }}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip placement="top" title="Share on Instagram" color="#df5885">
          <InstagramOutlined
            onClick={() =>
              window.open(
                `https://www.instagram.com/?url=${brandedUrl}`,
                "_blank"
              )
            }
            className="insta"
            style={{ color: "#df5885", fontSize: 40, marginRight: 25 }}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip placement="top" title="Share on Twitter" color="#4fb1ee">
          <TwitterOutlined
            onClick={() =>
              window.open(
                `https://twitter.com/intent/tweet?url=${brandedUrl}`,
                "_blank"
              )
            }
            className="tweet"
            style={{ color: "#4fb1ee", fontSize: 40 }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default ShareButtons;
