import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Checkbox, Modal, Card, Badge, message } from "antd";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Spin } from "antd";

import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import OrderDetails from "../Common/OrderDetails.js";
import orderData from "../../../../content/DummyData.json";
import {
  convertISOStrToDate,
  convertISODateString,
  eventDateFormat,
} from "../../../../shared/utils/utils.js";

const CalendarView = (props) => {
  const [state, setState] = useState({
    currDate: new Date(),
    tempShoots: props.shoots, //props.shoots,
    count: 2,
    toHidePhotographer: props.togglePhotographers,
    calenderView: "day" /* month | week | day*/,
    visible: false,
    selectedSchedule: null,
    photoGraphers: props.photographers,
    loader: false,
  });
  const calendarRef = useRef();

  useEffect(() => {
    setState({ ...state, tempShoots: props.shoots });
  }, [props.shoots]); // eslint-disable-line react-hooks/exhaustive-deps

  const setVisible = (value) => {
    setState({
      ...state,
      visible: value,
    });
  };

  const showModal = (task) => {
    props.shoots.forEach((schedule) => {
      if (schedule.id === task.event.id) {
        props.fetchEntityActivities(schedule.id);
        setState({
          ...state,
          selectedSchedule: schedule,
          visible: true,
        });
        props.setSelectedShoot(schedule?.details);
      }
    });
  };

  const handleOk = () => {
    setState({
      ...state,
      selectedSchedule: null,
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setState({
      ...state,
      selectedSchedule: null,
    });
    setVisible(false);
  };

  const onShootCancel = (shootId) => {
    setVisible(false);
    props.removeShootfromCalender(shootId);
  };

  const toggleScheduleView = (id) => {
    let schedules = [...state.tempShoots];
    props.shoots.forEach((schedule) => {
      if (schedule.calendarId === state.toHidePhotographer[id].id) {
        if (state.toHidePhotographer[id]["checked"]) {
          schedules = schedules.filter(
            (schd) => schd.calendarId !== schedule.calendarId
          );
        } else {
          schedules.push(schedule);
        }
      }
    });

    let toHidePhotographer = state.toHidePhotographer;
    toHidePhotographer[id].checked = !toHidePhotographer[id].checked;
    setState({
      ...state,
      tempShoots: schedules,
      toHidePhotographer: toHidePhotographer,
    });
  };

  //Update Schedule Through Drag Drop.

  const updateTask = async (info) => {
    const { event } = info;
    const start = event.start.toISOString();
    let end;
    if (event.end) {
      end = event.end.toISOString();
    } else {
      for (let i = 0; i < props.shoots.length; i++) {
        if (props.shoots[i].id === event.id) {
          const dt = convertISOStrToDate(start);
          const endTime = new Date(
            dt.setMinutes(dt.getMinutes() + props.shoots[i].duration)
          );
          end = convertISODateString(endTime);
          break;
        }
      }
    }
    let duration = null;
    if (event.end) {
      duration = new Date(event.end - event.start).getTime() / (60 * 1000);
    }
    props.shoots.map(async (sc, index) => {
      if (sc.id === event.id) {
        const schedule = {
          events: props.shoots[index]["details"].events,
        };
        schedule["events"]["start"] = start;
        schedule["events"]["end"] = end;
        if (duration) {
          schedule["events"]["duration"] = duration;
        }
        const res = await props.onUpdateShoot(event.id, schedule);
        if (schedule.events.photographer?.name) {
          props.createActivity(
            res.data,
            `Shoots scheduled for ${eventDateFormat(
              schedule.events.start,
              schedule.events.end
            )} with ${schedule.events.photographer.name}`
          );
        }
        if (res.status !== 200) {
          message.error("Oops! failed to update the events.");
        }
        return true;
      }
    });
  };

  return (
    <React.Fragment>
      <Row
        gutter={[5, 5]}
        style={{
          borderRadius: "3px",
          margin: "10px",
          padding: "5px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col
          md={3}
          style={{
            textAlign: "left",
          }}
        >
          <Row gutter={[5, 5]}>
            <Col
              md={24}
              style={{
                textAlign: "center",
              }}
            >
              <h3> Photographer </h3>
            </Col>{" "}
            <Col md={24}>
              {" "}
              {state.photoGraphers &&
                state.photoGraphers.map((pg, indx) =>
                  pg.active ? (
                    <div key={indx}>
                      <Checkbox
                        checked={
                          state.toHidePhotographer &&
                          state.toHidePhotographer[indx].checked
                        }
                        key={indx}
                        onChange={() => {
                          toggleScheduleView(indx);
                        }}
                      >
                        <Badge color={pg.bgColor} text={pg.name} />{" "}
                      </Checkbox>
                    </div>
                  ) : null
                )}{" "}
            </Col>
          </Row>
        </Col>
        <Col span={21} className={"map-view"}>
          <Row gutter={[5, 5]}>
            <Col
              md={24}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "3px",
                marginTop: "10px",
              }}
            >
              <Spin spinning={props.loading}>
                <FullCalendar
                  timeZone="UTC"
                  height={600}
                  defaultView="timeGridDay"
                  allDaySlot={false}
                  editable={true}
                  contentHeight="auto"
                  nowIndicator={false}
                  minTime="07:00:00"
                  maxTime="22:00:00"
                  scrollTime="07:00:00"
                  snapDuration="00:05:00"
                  header={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                  }}
                  titleFormat={{
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                    weekday: "long",
                  }}
                  customButtons={{
                    prev: {
                      text: "<",
                      click: function () {
                        const calendarApi = calendarRef.current.getApi();
                        // calendarApi.gotoDate(newDate);
                        calendarApi.prev();
                        props.changeDateView(
                          moment(
                            calendarApi.view.activeStart.toString()
                          ).format("YYYY-MM-DD"),
                          moment(calendarApi.view.activeEnd.toString()).format(
                            "YYYY-MM-DD"
                          )
                        );
                      },
                    },
                    next: {
                      text: ">",
                      click: () => {
                        const calendarApi = calendarRef.current.getApi();
                        // calendarApi.gotoDate(newDate);
                        calendarApi.next();
                        props.changeDateView(
                          moment(
                            calendarApi.view.activeStart.toString()
                          ).format("YYYY-MM-DD"),
                          moment(calendarApi.view.activeEnd.toString()).format(
                            "YYYY-MM-DD"
                          )
                        );
                      },
                    },
                    today: {
                      text: "today",
                      click: () => {
                        const newDate = new Date();
                        props.changeDateView(
                          moment(newDate.toString()).format("YYYY-MM-DD"),
                          moment(newDate.toString()).format("YYYY-MM-DD")
                        );
                        const calendarApi = calendarRef.current.getApi();
                        calendarApi.gotoDate(newDate);
                      },
                    },
                    timeGridWeek: {
                      text: "Week",
                      click: () => {
                        const calendarApi = calendarRef.current.getApi();
                        calendarApi.changeView("timeGridWeek", {
                          start: moment(state.currDate, "YYYY-MM-DD")
                            .subtract(7, "days")
                            .format("YYYY-MM-DD"),
                          end: moment(state.currDate).format("YYYY-MM-DD"),
                        });
                        props.changeDateView(
                          moment(
                            calendarApi.view.activeStart.toString()
                          ).format("YYYY-MM-DD"),
                          moment(calendarApi.view.activeEnd.toString())
                            .subtract(1, "days")
                            .format("YYYY-MM-DD")
                        );
                      },
                    },
                    timeGridDay: {
                      text: "Day",
                      click: () => {
                        const calendarApi = calendarRef.current.getApi();
                        calendarApi.changeView(
                          "timeGridDay",
                          moment(state.currDate).format("YYYY-MM-DD")
                        );
                        props.changeDateView(
                          moment(
                            calendarApi.view.activeStart.toString()
                          ).format("YYYY-MM-DD"),
                          moment(calendarApi.view.activeEnd.toString())
                            .subtract(1, "days")
                            .format("YYYY-MM-DD")
                        );
                      },
                    },
                    dayGridMonth: {
                      text: "Month",
                      click: () => {
                        const calendarApi = calendarRef.current.getApi();
                        calendarApi.changeView("dayGridMonth", {
                          start: moment(state.currDate, "YYYY-MM-DD").subtract(
                            30,
                            "days"
                          ),
                          end: moment(state.currDate, "YYYY-MM-DD"),
                        });
                        props.changeDateView(
                          moment(
                            calendarApi.view.activeStart.toString()
                          ).format("YYYY-MM-DD"),
                          moment(calendarApi.view.activeEnd.toString())
                            .subtract(1, "days")
                            .format("YYYY-MM-DD")
                        );
                      },
                    },
                  }}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  ref={calendarRef}
                  events={
                    props.shoots.length === state.tempShoots.length
                      ? props.shoots
                      : state.tempShoots
                  }
                  eventClick={showModal}
                  eventDrop={updateTask}
                  eventResize={updateTask}
                />
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Order Details"
        visible={state.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{
          top: 20,
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Card
          type="inner"
          size="small"
          style={{
            marginTop: 5,
          }}
        >
          <OrderDetails
            onShootCancel={onShootCancel}
            shoots={props.shoots}
            order={orderData}
            isMapView={true}
            selectedSchedule={state.selectedSchedule}
            selectedShoot={props.selectedShoot}
            photographers={props.photographers}
            onUpdateShoot={props.onUpdateShoot}
            activities={props.activities}
            createActivity={(shoot, text) => props.createActivity(shoot, text)}
            getSelectedPhotographer={props.getSelectedPhotographer}
            fetchEntityActivities={props.fetchEntityActivities}
            setSelectedPhotographer={(phtg) =>
              props.setSelectedPhotographer(phtg)
            }
          />
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default CalendarView;
