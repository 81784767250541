// middleware/websocketMiddleware.js

const websocketMiddleware = (store) => (next) => (action) => {
  if (action.type === "UPDATE_WEBSOCKET_NOTE") {
    const state = store.getState(); // Get the current Redux state
    const userAccess = state.auth?.user?.access; // Access the auth state slice

    // Add the user's access to the action payload if it exists
    if (userAccess) {
      action.payload.userAccess = userAccess;
    }
  }

  // Pass the action on to the next middleware/reducer
  return next(action);
};

export default websocketMiddleware;
