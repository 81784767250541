import React, { useState } from "react";
import {
  Select,
  Upload,
  message,
  Button,
  Row,
  Col,
  Spin,
  Typography,
  Avatar,
} from "antd";
import NumberFormat from "react-number-format";
import { PlusOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { Input, Form } from "formik-antd";
import { Formik } from "formik";

const ClientForm = ({ mode, initVal, close, brokerages, submitHandler }) => {
  const [state, setState] = useState({
    imgUrl: initVal.headshot_url,
    loader: false,
  });
  const { loader, imgUrl } = state;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (file, setFieldValue) => {
    let imgFile = file.originFileObj;
    if (file.originFileObj instanceof Blob) {
      imgFile = new File([file.originFileObj], file.name, {
        lastModified: new Date().getTime(),
        type: file.originFileObj.type,
      });
    }
    if (mode === "Edit") {
      const clientImage = new FormData();
      clientImage.append("path", `clients/avatar/high/${initVal.hsf}`);
      clientImage.append("file", imgFile);
      setFieldValue("headshot_url", clientImage);
    } else {
      setFieldValue("headshot_url", imgFile);
    }
    getBase64(imgFile, (imageUrl) => {
      setState({
        imgUrl: imageUrl,
        loader: false,
      });
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        const updatedClient = { ...values };
        const res = await submitHandler(updatedClient);
        if (res.status === 404) {
          message.error(res.data);
          setState({ ...state, loader: false });
        } else if (res.status === 413) {
          message.error(
            "Oops! It seems your image size is too large. Please upload image 2MB or less.",
            5
          );
          setState({ ...state, loader: false });
        } else if (res.status === 500) {
          message.error(
            `${
              res.data.includes("duplicate key error")
                ? "Email already exists in the system. Please try again with a different email."
                : res.data
            }`,
            5
          );
          setState({ ...state, loader: false });
        } else {
          message.success("Client saved successfully!");
          setState({ ...state, loader: false });
          close(res.data);
        }
      }}
      render={({ setFieldValue, values, handleSubmit }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <h3>
                    <UserOutlined /> Profile Details
                  </h3>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>First Name </label>
                    <Input name="first" />{" "}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Last Name </label>
                    <Input name="last" />{" "}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Email </label>
                    <Input name="email" />{" "}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Phone </label>
                    <NumberFormat
                      className="ant-input"
                      value={values.phone}
                      onValueChange={(val) =>
                        setFieldValue("phone", val.formattedValue)
                      }
                      allowEmptyFormatting
                      format="+1 (###) ### ####"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Role </label>
                    <Input name="role" />{" "}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Headshot </label>
                    <ImgCrop rotate>
                      <Upload
                        name="headshot_url"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        //onChange={handleChange}
                        onChange={({ file }) =>
                          handleChange(file, setFieldValue)
                        }
                      >
                        {imgUrl ? (
                          <img
                            src={imgUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        position: "relative",
                        right: "106px",
                      }}
                    >
                      {values.headshot_url
                        ? "*Click to change your headshot"
                        : ""}
                    </Typography.Text>
                  </div>
                </Col>
                {values.user?.access?.includes("COMMS" || "ADMIN") && (
                  <Col span={12}>
                    <div className="form-group">
                      <label>Designation </label>
                      <Input name="designation" />{" "}
                    </div>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    label="Brokerage"
                    name="brokerage"
                    className="form-group"
                  >
                    <Select
                      name="brokerage"
                      defaultValue={initVal?.brokerage}
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Select a Brokerage"
                      onChange={(value) => setFieldValue("brokerage", value)}
                    >
                      {brokerages.map((brokerage) => (
                        <Select.Option
                          key={brokerage._id}
                          value={brokerage._id}
                        >
                          {brokerage.logo ? (
                            <Avatar src={brokerage.logo} />
                          ) : (
                            <Avatar
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: "rgb(1, 148, 134)",
                              }}
                            >
                              {brokerage.name.charAt(0)}
                            </Avatar>
                          )}{" "}
                          {brokerage.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {mode === "Add" && (
                  <React.Fragment>
                    <Col span={24}>
                      <h3>
                        <LockOutlined /> Set Password
                      </h3>
                      <span style={{ color: "#777", fontSize: "0.8rem" }}>
                        Client can use password reset to change the password
                      </span>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Password</label>
                        <Input name="password" type="password" />{" "}
                      </div>
                    </Col>
                  </React.Fragment>
                )}

                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default ClientForm;
