import axios from "axios";
import { SEND_APPOINTMENT_CONFIRMATION, SEND_CANCEL_SHOOT } from "./types";

let config;

config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const sendAppointmentConfirmation = (emailData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/appointment`,
      emailData,
      config
    );
    dispatch({
      type: SEND_APPOINTMENT_CONFIRMATION,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const sendCancelShoot = (emailData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/cancel`,
      emailData,
      config
    );
    dispatch({
      type: SEND_CANCEL_SHOOT,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
