import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import SimpleReactLightbox from "simple-react-lightbox";

import ReactGA from "react-ga";

// 3rd
import "./styles/antd.less";
import "./styles/bootstrap/bootstrap.scss";
import "./styles/layout.scss";
import "./styles/theme.scss";
import "./styles/ui.scss";
import "./styles/vendors.scss";
import "intro.js/introjs.css";

import AdminLayout from "./layouts/AdminLayout";
import UserLayout from "./layouts/UserLayout";
import TourLayout from "./layouts/TourLayout";
class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const location = window.location.pathname;
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;

    const unbrandedDomainUrl =
      process.env.REACT_APP_UNBRANDED_DOMAIN_URL.replace(`${protocol}//`, "");
    const domainUrl = process.env.REACT_APP_DOMAIN_URL.replace(
      `${protocol}//`,
      ""
    );

    const getRoute = () => {
      if (hostname) {
        if (unbrandedDomainUrl.startsWith(hostname)) {
          //go to unbranded tour layout;
          return <TourLayout />;
        } else if (domainUrl.startsWith(hostname)) {
          if (location.includes("admin")) {
            //go to admin
            return (
              <SimpleReactLightbox>
                <AdminLayout />
              </SimpleReactLightbox>
            );
          } else if (location.includes("media")) {
            //go to branded tour layout;
            return <TourLayout />;
          } else {
            // go to user
            return (
              <ParallaxProvider>
                <SimpleReactLightbox>
                  <UserLayout />
                </SimpleReactLightbox>
              </ParallaxProvider>
            );
          }
        }
      }
    };
    return getRoute();
  }
}

export default App;
