import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Empty,
  Button,
  message,
  Input,
  Switch,
  Select,
  Form,
  Tooltip,
  Alert,
} from "antd";
import Loading from "../../../shared/loading/Loading.js";
import { PROS, READY } from "../../../redux/action/shoot/shootConstants.js";
import ability from "../../../user/Component/Auth/ability";

const { Option } = Select;

const Dollhouse = ({
  selectedShoot,
  media,
  updateMedia,
  createActivity,
  denyMediaAccess,
}) => {
  const [loader, setLoader] = useState(false);
  const { dollHouse } = media;

  const [mattId, setMattId] = useState();
  const [mattType, setMattType] = useState();
  const [brandedId, setBrandedId] = useState(dollHouse?.branded);
  const [unbrandedId, setUnbrandedId] = useState(dollHouse?.unbranded);

  const changeHandler = async (value) => {
    const updatedStatus = value ? READY : PROS;
    const res = await updateMedia(media._id, {
      dollHouse: { ...media.dollHouse, status: updatedStatus },
    });
    if (!res.error) {
      message.success("Dollhouse updated successfully!");
      if (updatedStatus === READY) {
        createActivity(selectedShoot, `Dollhouse marked as ready.`);

        const emailData = {
          shootId: selectedShoot._id,
          clientEmail: selectedShoot.client_email,
          hsn: selectedShoot.hsn,
          address: selectedShoot.address,
          mediaType: "dollhouse",
          clientFirst: selectedShoot?.client_name_first,
          clientLast: selectedShoot?.client_name_last,
          secondaryContact: selectedShoot?.booking_form?.secondary_contact,
          share: selectedShoot.share,
        };

        const res = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/notifications/photos-ready`,
          emailData
        );
        if (res.status === 200) {
          createActivity(
            selectedShoot,
            "Dollhouse ready email sent to client."
          );
        }
      } else {
        createActivity(selectedShoot, `Dollhouse status changed to not ready.`);
      }
    } else {
      message.error("Oops! Error occured while updating dollhouse");
    }
  };

  const handleSave = async () => {
    const res = await updateMedia(media._id, {
      dollHouse: { ...media.dollHouse, [mattType]: mattId },
    });
    if (!res.error) {
      message.success("Dollhouse updated successfully!");
      if (mattType === "branded") {
        setBrandedId(mattId);
      }
      if (mattType === "unbranded") {
        setUnbrandedId(mattId);
      }
      setMattId(null);
      setMattType(null);
    } else {
      message.error("Oops! Error occured while updating dollhouse");
    }

    setLoader(false);
  };

  const renderDollhouseContent = () => {
    return (
      <>
        {denyMediaAccess && (
          <Alert
            message={
              <div>
                <a
                  href={`/admin/invoices/${selectedShoot.invoice[0]?._id}`}
                  style={{ color: "#1890ff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Please pay your invoice
                </a>{" "}
                to access your media.
              </div>
            }
            style={{ marginBottom: 10 }}
            banner
          />
        )}
        {ability.can("edit", "Dollhouse") && (
          <Row style={{ padding: 10, background: "#f3f7ff", marginBottom: 10 }}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Form layout="inline">
                <Form.Item label="Matterport ID">
                  <Input
                    value={mattId}
                    onChange={(e) => setMattId(e.target.value)}
                  />{" "}
                </Form.Item>
                <Form.Item>
                  <Select
                    placeholder="Select a type"
                    onChange={(value) => setMattType(value)}
                    value={mattType}
                  >
                    <Option value="branded">Branded</Option>
                    <Option value="unbranded">Unbranded</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={handleSave}
                    disabled={!mattType || !mattId}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              align="right"
              className="float-right"
            >
              MARK AS DOLLHOUSE AS READY{" "}
              <Tooltip
                title="NOTE: When you check this an email will be sent to the Client"
                placement="topRight"
              >
                <Switch
                  onChange={changeHandler}
                  checked={media.dollHouse && media.dollHouse.status === READY}
                />
              </Tooltip>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          {" "}
          <Col span={24}>
            <h3>Preview</h3>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div>
              <span className="float-right">
                <strong>View and share:</strong>{" "}
                <Button
                  size="small"
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_DOMAIN_URL}/media/b/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}?tab=dollhouse`,
                      "_blank"
                    );
                  }}
                  disabled={denyMediaAccess}
                >
                  Dollhouse Only
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_DOMAIN_URL}/media/b/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`,
                      "_blank"
                    );
                  }}
                  disabled={denyMediaAccess}
                >
                  All Media
                </Button>
              </span>
              <h4>Branded</h4>
            </div>

            {media.dollHouse.matterPort_id || brandedId ? (
              brandedId ? (
                <div style={{ minHeight: "450px" }}>
                  <iframe
                    title="Dollhouse"
                    src={`https://my.matterport.com/show/?m=${brandedId}&brand=0`}
                    style={{
                      position: "absolute",
                      height: "400px",
                      width: "100%",
                      border: "none",
                    }}
                  ></iframe>
                </div>
              ) : (
                <div style={{ minHeight: "450px" }}>
                  <iframe
                    title="Dollhouse"
                    src={`https://my.matterport.com/show/?m=${media.dollHouse.matterPort_id}&brand=0`}
                    style={{
                      position: "absolute",
                      height: "400px",
                      width: "100%",
                      border: "none",
                    }}
                  ></iframe>
                </div>
              )
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div>
              <span className="float-right">
                <strong>View and share:</strong>{" "}
                <Button
                  size="small"
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}?tab=dollhouse`,
                      "_blank"
                    );
                  }}
                  disabled={denyMediaAccess}
                >
                  Dollhouse Only
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_UNBRANDED_DOMAIN_URL}/media/u/${selectedShoot.hsn}/${selectedShoot.address.street_lower_no_spaces}`,
                      "_blank"
                    );
                  }}
                  disabled={denyMediaAccess}
                >
                  All Media
                </Button>
              </span>
              <h4>Unbranded</h4>
            </div>
            {unbrandedId ? (
              <div style={{ minHeight: "450px" }}>
                <iframe
                  title="Dollhouse"
                  src={`https://my.matterport.com/show/?m=${unbrandedId}`}
                  style={{
                    position: "absolute",
                    height: "400px",
                    width: "100%",
                    border: "none",
                  }}
                ></iframe>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {loader && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {!loader && renderDollhouseContent()}
    </div>
  );
};

export default Dollhouse;
