import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Card, Table, Button, Modal, Row, Col, Input, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../shared/loading/Loading.js";
import {
  fetchCredits,
  createCredit,
  updateCredit,
  removeCredit,
  fetchCreditsTableView,
} from "../../redux/action/creditAction";
import { fetchClients } from "../../redux/action/clientAction";
import CreditForm from "./Credit/CreditForm";
import Can from "../../user/Component/Auth/Can";
import NumberFormat from "react-number-format";
import queryString from "query-string";

const { Text } = Typography;

const Credits = ({
  handleCurrentPath,
  fetchCredits,
  fetchClients,
  createCredit,
  updateCredit,
  removeCredit,
  fetchCreditsTableView,
  credit: { credits, count, isLoading },
  client: { clients },
  auth,
  location,
}) => {
  const [state, setState] = useState({
    title: "Add Credit",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });

  const { mode, modalVisiblity, initVal, title } = state;
  const { confirm } = Modal;

  const formInitValue = {
    client: { _id: "", first: "", last: "", hsf: "", full: "" },
    credit_type: "",
    code: "",
    amount: 0,
    redeemed: false,
    _redeemed_at: null,
  };
  const columns = [
    {
      title: "Client",
      dataIndex: "client",
      render: (client, record) => (
        <Link to={`/admin/clients/${record.client?._id}`}>
          {`${record.client?.first} ${record.client?.last}`}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "credit_type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => <Text ellipsis={true}>{text}</Text>,
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Amount",
      key: "amount",
      render: (amount, record) => (
        <span>
          <NumberFormat
            value={record.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      ),
    },
    {
      title: "Issued",
      key: "_created_at",
      render: (_created_at, record) =>
        moment(record._created_at).format("MM/D/YY"),
    },
    {
      title: "Redeemed",
      key: "redeemed",
      render: (redeemed, record) => (
        <span>{record.redeemed ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          <Can do="edit" on="Credits">
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Credits">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
              disabled={record.redeemed}
            />
          </Can>
        </span>
      ),
    },
  ];
  const history = useHistory();
  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Credit",
      modalVisiblity: true,
    });
  };

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const { search, skip, limit, client } = tableOpt;

  useEffect(() => {
    handleCurrentPath("Credits", "10");
    fetchCreditsTableView({ search: "", skip, limit: 10 });
    if (!clients || clients.length === 0) {
      fetchClients();
    }
  }, [removeCredit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchCreditsTableView();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      limit: 10,
      search: target.value,
    });
    fetchCreditsTableView({
      client,
      search: target.value,
      skip: 1,
      limit: 10,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchCreditsTableView({
      client,
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const onConfirmRemove = (credit) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete this ${credit.credit_type} credit.`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeCredit(credit._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createCredit(body);
      return res;
    } else {
      const res = await updateCredit(body._id, body);
      return res;
    }
  };
  // const handleOk = (e) => {
  //   setVisible(false);
  // };

  return (
    <React.Fragment>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && credits ? (
          <div>
            <Row>
              <Col span={16}>
                <h2>Credits</h2>
              </Col>
              <Can do="create" on="Clients">
                <Col span={4}>
                  <Input.Search
                    placeholder="Search by HSF, name or email"
                    onChange={searchHandler}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={() => clickHandler("Add", formInitValue)}
                  >
                    <PlusOutlined /> Add a Credit
                  </Button>
                </Col>
              </Can>
            </Row>
            <Table
              columns={columns}
              dataSource={credits}
              rowKey={(record) => record._id}
              pagination={{
                current: skip,
                pageSize: limit,
                total: count,
              }}
              onChange={handleTableChange}
            />
          </div>
        ) : null}
      </Card>
      <Modal
        title={<span style={{ color: "white" }}>{title}</span>}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        destroyOnClose={true}
      >
        <CreditForm
          initVal={initVal}
          close={handleCancel}
          clients={clients}
          submitHandler={(values) => submitHandler(mode, values)}
          auth={auth}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  credit: state.credit.credits,
  client: state.clients,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchCredits,
  fetchClients,
  createCredit,
  updateCredit,
  removeCredit,
  fetchCreditsTableView,
})(Credits);
