import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { message, Button, Tooltip, Modal, Select } from "antd";
import { createShoot } from "../../../../redux/action/shootAction";
import { createMedia } from "../../../../redux/action/mediaAction";
import {
  createDuplicateNotes,
  fetchEntityNotes,
} from "../../../../redux/action/note";
import { CopyOutlined } from "@ant-design/icons";

const { Option } = Select;

const CloneButton = ({
  originalShoot,
  createMedia,
  createShoot,
  createDuplicateNotes,
  fetchEntityNotes,
  auth: { user },
  note: { notes },
}) => {
  let history = useHistory();

  const [visible, setVisible] = useState(false);
  const [shootType, setShootType] = useState("Additional");

  useEffect(() => {
    fetchEntityNotes(originalShoot._id);
  }, []);

  const handleSubmit = async () => {
    // Create Media object
    const medReq = {
      photos: {
        status: "Processing",
        gallery: [],
      },
      video: {
        status: "Not Available",
        link: null,
      },
      dollHouse: {
        status: "Not Available",
        matterPort_id: null,
      },
      property_info: {
        street: originalShoot.address?.street,
        city: originalShoot.address?.city,
        state: originalShoot.address?.state,
        zip: originalShoot.address?.zip,
      },
      branding: {
        agent_info: {
          name:
            originalShoot.client_name_first +
            " " +
            originalShoot.client_name_last,
          email: originalShoot.client_email,
          phone: originalShoot.client_phone,
          headshot: originalShoot.client_headshot_url,
        },
        brokerage_info: {
          name: originalShoot.brokerage?.name,
          logo: originalShoot.brokerage?.logo,
        },
      },
    };

    const mediaRes = await createMedia(medReq);

    if (!mediaRes.error) {
      const newShoot = {
        ...originalShoot,
        booking_form: {
          ...originalShoot.booking_form,
          shoot_type: shootType,
        },
        reqs: [],
        media: mediaRes.data._id,
        invoice: {
          subtotal: 0.0,
          sent: false,
          paid: false,
          total: 0.0,
          balance: 0.0,
          tax_exempt: false,
          products: [],
        },
      };
      delete newShoot._id;
      delete newShoot.hsn;
      delete newShoot._created_at;
      delete newShoot._updated_at;

      const res = await createShoot(newShoot);
      const body = res.data;
      if (res.status === 200) {
        // Create Activity
        const newActivity = {
          user: user._id,
          text: `Shoot requested for ${body?.address?.full}. Original HS#: ${originalShoot.hsn}.`,
          entity_id: body?._id,
          entity_type: "Shoot",
          _created_at: new Date(),
        };

        await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/activities`,
          newActivity
        );

        // Create Duplicate Notes on the new shoots
        if (notes && notes.length > 0) {
          await createDuplicateNotes(body?._id, notes);
        }

        // Route to shoots page
        history.push(`/admin/shoots/`);
        setVisible(false);

        // Success message
        message.success({
          content: "Duplicate shoot created successfully",
          style: {
            marginTop: "10vh",
          },
        });
      } else {
        message.error("Oops! Failed to create shoot");
      }
    } else {
      console.log("Unable to create media");
    }
  };

  return (
    <span className="ml-5 mr-2">
      <Modal
        visible={visible}
        onOk={() => handleSubmit()}
        okText="Duplicate"
        onCancel={() => setVisible(false)}
      >
        <div className="form-group mt-3 mb-3">
          <h3 className="mb-2">What type of shoot is this?</h3>
          <div className="form-group mb-4">
            <br />
            <label>Select one below:</label>
            <Select
              defaultValue="Additional"
              style={{ width: 200 }}
              onChange={(value) => setShootType(value)}
            >
              <Option value="Residential">Residential</Option>
              <Option value="Multi-family">Multi-family</Option>
              <Option value="Commercial">Commercial</Option>
              <Option value="Additional">Additional Shots</Option>
            </Select>
          </div>
        </div>
      </Modal>
      <Tooltip
        title="Duplicates shoot and creates a blank invoice."
        placement="bottom"
      >
        <Button onClick={() => setVisible(true)}>
          <CopyOutlined /> Duplicate
        </Button>
      </Tooltip>
    </span>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  note: state.note,
});

export default connect(mapStateToProps, {
  createMedia,
  createShoot,
  createDuplicateNotes,
  fetchEntityNotes,
})(CloneButton);
