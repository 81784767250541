import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Statistic, Row, Col, message } from "antd";
import { CalculatorOutlined } from "@ant-design/icons";
import { calculateTax } from "../../shared/utils/utils";

const TripChargeCalc = ({
  shoot,
  hasAccess,
  invoice,
  updateInvoice,
  createActivity,
  updateShoot,
  manualFetch,
}) => {
  const [tripCharge, setTripCharge] = useState(0);
  const [driveTime, setDriveTime] = useState(0);
  const [photogDriveTime, setPhotogDriveTime] = useState(0);
  const [photogTcPay, setPhotogTcPay] = useState(0);
  const [payMultiplier, setPayMultiplier] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [noTripChargeMsg, setNoTripChargeMsg] = useState(null);
  const [noPhotogTripChargeMsg, setNoPhotogTripChargeMsg] = useState(null);
  const [disableAddToInvoice, setDisableAddToInvoice] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const clearInputs = () => {
    setDriveTime(0);
    setTripCharge(0);
    setPhotogTcPay(0);
    setPhotogDriveTime(0);
    setPayMultiplier(1);
    setNoTripChargeMsg(null);
    setNoPhotogTripChargeMsg(null);
  };

  useEffect(() => {
    populate();
  }, [shoot]);

  const populate = () => {
    setTripCharge(shoot?.trip_charge);
    setDriveTime(shoot?.address?.drive_time);
    setPhotogDriveTime(shoot?.photographer_drive_time);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleChange = (e) => {
    setDriveTime(e.target.value);
  };
  const handlePhotogDtChange = (e) => {
    setNoPhotogTripChargeMsg(null);
    setPhotogDriveTime(e.target.value);
  };
  const handlePmChange = (e) => {
    setPayMultiplier(e.target.value);
  };
  const handleClear = () => {
    clearInputs();
    setDisableAddToInvoice(true);
  };

  const handleCalc = async (dt) => {
    let final;
    if (dt > 49) {
      const calc = (dt - 50) / 5;
      const rounded = Math.floor(calc);
      final = rounded * 10 + 10;
      await handlePhotogTcCalc(final);
      setTripCharge(final);
      setNoTripChargeMsg(null);
    } else {
      setNoTripChargeMsg("No trip charge 😎");
      setTripCharge(0);
      await handlePhotogTcCalc(final);
    }
    setDisableAddToInvoice(false);
  };

  const handlePhotogTcCalc = (tripC) => {
    const tripCharge = parseFloat(tripC);
    if (tripCharge > 50) {
      // Pay the photographer 75% of the trip charge
      const photographer_tc_pay = (tripCharge / 10) * 7.5;
      const result = photographer_tc_pay.toFixed(2);
      setPhotogTcPay(result);
      return parseFloat(result);

      // Pay the photographer 50% of the
    } else {
      return 0;
    }
    // if (parseFloat(photogDriveTime) > 49) {
    //   const tc = tripC ? parseFloat(tripC) : 0;
    //   const driveTimeValue = parseFloat(photogDriveTime);
    //   const photographer_trip_charge =
    //     Math.ceil((driveTimeValue - 49) / 5) * 10;
    //   const new_trip_charge = parseFloat(photographer_trip_charge + tc) / 2;
    //   const calculated_trip_charge = new_trip_charge * payMultiplier;
    //   const photographer_tc_pay =
    //     calculated_trip_charge > 0 ? (calculated_trip_charge / 10) * 10 : 0;
    //   const finalPhotogPay = parseFloat(photographer_tc_pay.toFixed(2));
    //   setPhotogTcPay(finalPhotogPay);
    // } else {
    //   setPhotogTcPay(0);
    //   setNoPhotogTripChargeMsg("No photgrapher trip charge pay 😎");
    // }
  };

  const handleAddToInvoice = async () => {
    const tripChargeFloat = parseFloat(tripCharge);
    const dataToAdd = {
      photographer_pay: parseFloat(photogTcPay),
      _id: "61a515849ecf4717739c1050",
      price: tripChargeFloat,
      category: `${shoot?.booking_form?.shoot_type} - Fees`,
      description: "Trip Charge",
      code: "TC",
      inv_visible: tripChargeFloat > 0 ? true : false,
    };

    //Recalculate invoice
    const updatedSubtotal = parseFloat(invoice?.subtotal) + tripChargeFloat;
    const updatedTax = calculateTax(updatedSubtotal);
    const updatedTotal = parseFloat(updatedSubtotal) + parseFloat(updatedTax);
    const updatedInvoice = {
      ...invoice,
      photographer_total_pay: invoice.photographer_total_pay
        ? invoice.photographer_total_pay + parseFloat(photogTcPay)
        : parseFloat(photogTcPay),
      products: [...invoice?.products, dataToAdd],
      subtotal: updatedSubtotal.toFixed(2),
      tax: updatedTax.toFixed(2),
      total: updatedTotal.toFixed(2),
      balance: invoice?.paid ? invoice?.balance : updatedTotal.toFixed(2),
    };

    const res = await updateInvoice(invoice._id, updatedInvoice);

    if (!res.error) {
      setIsModalVisible(false);
      message.success("Trip Charge added to invoice");
      // Update shoot
      const updatedShoot = {
        ...shoot,
        trip_charge: tripChargeFloat.toString(),
        reqs: [...shoot?.reqs, "TC"],
      };

      await updateShoot(shoot._id, updatedShoot);

      // Create activity
      if (tripCharge > 0) {
        createActivity(
          shoot,
          `A trip charge of $${parseFloat(tripCharge).toFixed(
            2
          )} was added to the invoice`
        );
      }
      manualFetch();
      setDisableAddToInvoice(true);
    } else {
      message.error(res.error);
    }
  };

  return (
    <>
      <Button onClick={showModal} size="small">
        <CalculatorOutlined /> Trip Charge Calc
      </Button>
      <Modal
        title="Trip Charge Calculator"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        style={{ top: 20 }}
      >
        <Row gutter={12}>
          <Col flex="auto">
            <span style={{ color: "#333", fontSize: 18 }}>
              Total drive time (in minutes)
            </span>
          </Col>
          <Col flex="auto">
            <Input
              size="large"
              placeholder="Drive time in minutes"
              onChange={handleChange}
              value={driveTime}
            />
          </Col>
        </Row>
        <Row gutter={12} className="mt-4">
          <Col span={24}>
            {noTripChargeMsg ? (
              <span style={{ color: "tomato", fontSize: 16 }}>
                {noTripChargeMsg}
              </span>
            ) : null}
          </Col>
          <Col flex="auto">
            <Statistic
              title="Drive Time"
              value={driveTime || 0}
              valueStyle={{ fontSize: 40 }}
            />
          </Col>
          <Col flex="auto">
            <Statistic
              title="Trip Charge"
              valueStyle={{ color: "#3f8600", fontSize: 40 }}
              prefix="$"
              value={tripCharge}
              precision={2}
            />
          </Col>
        </Row>
        {hasAccess && (
          <div className="inner-card">
            <Row gutter={12}>
              <Col span={24}>
                <h4>{`${
                  shoot.events?.photographer
                    ? shoot?.events?.photographer?.name
                    : "Photographer"
                }'s Pay`}</h4>
              </Col>
              <Col flex="auto">
                <Statistic
                  valueStyle={{ color: "#1890ff", fontSize: 30 }}
                  prefix="$"
                  value={photogTcPay}
                  precision={2}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col flex="auto" className="mt-2">
            <span>
              <Button
                type="primary"
                size="large"
                className="mr-1"
                onClick={() => handleCalc(driveTime)}
              >
                Calculate
              </Button>
              <Button size="large" onClick={handleClear}>
                Clear
              </Button>
            </span>
            <span className="float-right">
              <Button
                type="secondary"
                size="large"
                className="mr-1"
                disabled={disableAddToInvoice || driveTime === 0}
                onClick={() => handleAddToInvoice()}
              >
                + Add to Invoice
              </Button>
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default TripChargeCalc;
