import axios from "axios";
import {
  CREATE_MEDIA,
  UPDATE_MEDIA,
  REMOVE_MEDIA,
  UPDATE_SHOOT_MEDIA,
} from "./types";

export const createMedia = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/media`,
      formData,
      config
    );
    dispatch({
      type: CREATE_MEDIA,
      payload: res.data,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.error(err);
    return {
      data: null,
      error: err,
    };
  }
};

export const updateMedia = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/media/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_MEDIA,
      payload: response.data,
    });
    dispatch({
      type: UPDATE_SHOOT_MEDIA,
      payload: response.data,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (err) {
    console.log(err);
    return {
      data: null,
      error: err,
    };
  }
};

export const removeMedia = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/media/${id}`
    );
    dispatch({
      type: REMOVE_MEDIA,
      payload: id,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
