import React from "react";
import { Route, Switch } from "react-router";
import StatusNotFound from "../admin/Components/StatusNotFound";
import Tour from "../tour/Tour";

const TourRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/media/:brandstatus/:hsn/:address" exact>
          <Tour />
        </Route>
        <Route>
          <StatusNotFound status={{ is404: true, isPermitted: null }} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default TourRoutes;
