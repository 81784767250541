import React from "react";
import { Button } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./DragDrop.css";

const DragDrop = (props) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const orderedArr = reorder(
      props.photos,
      result.source.index,
      result.destination.index
    );

    props.updatedOrder(orderedArr);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    padding: "15px",
    // margin: `0 ${grid}px 0 0`,
    //opacity: "0.8",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
    // float: "left",
    // width: "250px",
    // height: "100%",
    //  margin: "0 8px 8px 0"
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "white",
    display: "flex",
    // padding: grid,
    overflow: "auto",
  });

  return (
    <div>
      <p>Drag the photos to change the order of appearance</p>
      <div className="thumbnail-container">
        <ul className="thumbnail-list">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {props.photos.map((photo, index) => (
                    <li>
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <h3 className="dot-container">{photo.order}</h3>
                            <span>
                              <img
                                className="thumbnail-image"
                                src={photo.url}
                                alt={`${photo._id}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              />
                              {photo?.filename}
                            </span>
                          </>
                        )}
                      </Draggable>
                    </li>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </div>
      <div className="footor">
        <Button type="primary" onClick={props.saveHandler}>
          Save and Close
        </Button>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DragDrop;
