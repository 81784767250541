import {
  FETCH_BROKERAGES,
  FETCH_BROKERAGE,
  FETCH_BROKERAGE_CLIENTS,
  CREATE_BROKERAGE,
  UPDATE_BROKERAGE,
  REMOVE_BROKERAGE,
  FETCH_BROKERAGES_TABLEVIEW,
} from "../action/types";

const initialState = { brokerages: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BROKERAGES:
      return {
        ...state,
        isLoading: false,
        brokerages: payload,
      };
    case FETCH_BROKERAGE:
      return {
        ...state,
        isLoading: false,
        selectedBrokerage: payload,
      };
    case CREATE_BROKERAGE:
      return {
        ...state,
        isLoading: false,
        brokerages: [...state.brokerages, payload],
        selectedBrokerage: payload,
      };
    case UPDATE_BROKERAGE:
      const updatedBrokerages = state.brokerages.concat();
      updatedBrokerages.map((brok, index) => {
        if (brok._id === payload._id) {
          updatedBrokerages[index] = payload;
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        brokerages: updatedBrokerages,
        selectedBrokerage: payload,
      };
    case REMOVE_BROKERAGE:
      return {
        ...state,
        isLoading: false,
        brokerages: state.brokerages.filter((brok) => brok._id !== payload),
      };
    case FETCH_BROKERAGES_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        brokerages: payload.newBrokerages,
        totalCount: payload.count,
      };
    case FETCH_BROKERAGE_CLIENTS:
      return {
        ...state,
        isLoading: false,
        clients: payload,
      };
    default:
      return state;
  }
}
