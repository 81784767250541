import React from "react";
import { Col, Select, DatePicker, Form, Input } from "antd";

const { Option } = Select;

const PhotogForm = (props) => {
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item hidden={true} name={["current_assignee", "name"]} />
        <Form.Item hidden={true} name={["current_assignee", "address"]} />
        <Form.Item hidden={true} name={["current_assignee", "phone"]} />
        <Form.Item hidden={true} name={["current_assignee", "email"]} />
        <Form.Item
          label="Date Assigned"
          name={["current_assignee", "date_assigned"]}
          className="form-group"
        >
          <DatePicker />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form.Item
          label="Quantity"
          name={["current_assignee", "quantity"]}
          className="form-group"
        >
          <Input type="number" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Condition"
          name={["current_assignee", "condition"]}
          className="form-group"
        >
          <Select
            style={{
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Option value="New">New</Option>
            <Option value="Good">Good</Option>
            <Option value="Fair">Fair</Option>
            <Option value="Poor">Poor</Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default PhotogForm;
