import React from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { logoutUser } from "../redux/action/authAction";
import "../App.scss";
import "./user.scss";

const { SubMenu } = Menu;

const Navigator = ({ auth }) => {
  const history = useHistory();
  return (
    <Menu mode="horizontal" className="user">
      <Menu.Item key="1">
        <NavLink to={"/"} className="nav">
          Home
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={"/about-us"} className="nav">
          About Us
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={"/faq"} className="nav">
          FAQ
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={"/portfolio"} className="nav">
          Portfolio
        </NavLink>
      </Menu.Item>
      <SubMenu title="Services" onTitleClick={() => history.push("/services")}>
        <Menu.Item key="real-estate-photography">
          <NavLink to={"/real-estate-photography"} className="nav">
            Real Estate Photography
          </NavLink>
        </Menu.Item>
        <Menu.Item key="real-estate-videography">
          <NavLink to={"/real-estate-videography"} className="nav">
            Real Estate Videography
          </NavLink>
        </Menu.Item>
        <Menu.Item key="real-estate-drone-photography">
          <NavLink to={"/real-estate-drone-photography"} className="nav">
            Real Estate Drone Photography
          </NavLink>
        </Menu.Item>
        <Menu.Item key="real-estate-virtual-tour">
          <NavLink to={"/real-estate-virtual-tour"} className="nav">
            Real Estate Virtual Tour
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="pricing">
        <NavLink to={"/pricing"} className="nav">
          Pricing
        </NavLink>
      </Menu.Item>
      <Menu.Item key="9">
        <NavLink to={"/blog"} className="nav">
          Blog
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="6">
        {!auth?.isAuthenticated ? (
          <Link to={"/admin/login"} target="_blank">
            <Button danger size="large">
              Log In
            </Button>
          </Link>
        ) : (
          <Link to={"/admin/dashboard"} target="_blank">
            <Button danger size="large">
              My Dashboard
            </Button>
          </Link>
        )}
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/lets-book-a-shoot">
          <Button type="primary" size="large">
            Book a Shoot
          </Button>
        </Link>
      </Menu.Item> */}
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser }, null, { pure: false })(
  Navigator
);
