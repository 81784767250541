import React, { useEffect } from "react";
import { withRouter } from "react-router";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from "react-redux";
import { Row, Col, Button, message } from "antd";
import { GoogleSquareFilled, FacebookFilled } from "@ant-design/icons";
import { socialLogin } from "../../redux/action/authAction";

const SocialLogin = ({
  isAuthenticated,
  authError,
  authUser,
  socialLogin,
  onSuccess,
  onFailure,
  islegacyClient,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      if (onSuccess !== undefined) onSuccess(authUser);
    }

    if (authError) {
      if (onFailure !== undefined) onFailure(authError);
    }
  }, [isAuthenticated, authError]); // eslint-disable-line react-hooks/exhaustive-deps

  const btnStyles = {
    marginBottom: 20,
    border: "none",
    background: " #FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
  };

  const responseGoogle = async (response) => {
    if (response.error === "idpiframe_initialization_failed") {
      message.warning(
        "Your third party cookies are disabled please enable to login via google"
      );
      return;
    }
    if (!response.accessToken) {
      message.error("Error ocurred while login with Google");
      return;
    }

    const profile = response.profileObj;
    const accessToken = response.accessToken;

    const user = {
      provider: "google",
      sId: profile.googleId,
      email: profile.email,
      name: profile.name,
      firstName: profile.givenName,
      lastName: profile.familyName,
    };

    const res = socialLogin({
      user: user,
      token: accessToken,
      authType: "GOOGLE",
    });
    if (res.data?.islegacyClient) {
      islegacyClient();
    }
  };

  const responseFacebook = async (response) => {
    if (!response.accessToken) {
      message.error("Error ocurred while login with FaceBook");
      return;
    }

    const accessToken = response.accessToken;

    let firstName = "";
    let lastName = "";
    const names = response.name.split(" ");
    if (names.length > 1) {
      firstName = names[0];
      lastName = names[names.length - 1];
    }

    const user = {
      provider: "facebook",
      sId: response.id,
      email: response.email,
      name: response.name,
      firstName: firstName,
      lastName: lastName,
    };

    const res = socialLogin({
      user: user,
      token: accessToken,
      authType: "FACEBOOK",
    });
    if (res.data?.islegacyClient) {
      islegacyClient();
    }
  };

  return (
    <Row className="p-4">
      <Col span={24}>
        <p className="text-muted text-center">or sign in with</p>
      </Col>
      <Col span={24}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <Button
              style={btnStyles}
              size="large"
              block
              onClick={renderProps.onClick}
            >
              <GoogleSquareFilled
                style={{ color: "#DD4B39", fontSize: "20px" }}
              />
              Google
            </Button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      </Col>
      <Col span={24}>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="name,email,picture"
          render={(renderProps) => (
            <Button
              style={btnStyles}
              size="large"
              block
              onClick={renderProps.onClick}
            >
              <FacebookFilled style={{ color: "#3b5998", fontSize: "20px" }} />{" "}
              Facebook
            </Button>
          )}
          callback={responseFacebook}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authUser: state.auth.user,
  authError: state.auth.error,
});

export default withRouter(
  connect(mapStateToProps, { socialLogin })(SocialLogin)
);
