import axios from "axios";

export const upload = async (file) => {
  const upload = await axios.post(
    `${process.env.REACT_APP_ROOT_URL}/upload`,
    file
  );
  if (upload && upload.status === 200) {
    return upload.data.urls;
  }
};
