import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Table, Input, Button, Modal, Avatar, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../shared/loading/Loading.js";
import queryString from "query-string";
import {
  fetchBrokerages,
  createBrokerage,
  updateBrokerage,
  removeBrokerage,
  fetchBrokeragesTableView,
} from "../../redux/action/brokerageAction";
import BrokerageForm from "./Brokerage/BrokerageForm";
import Can from "../../user/Component/Auth/Can";
import { fetchClients } from "../../redux/action/clientAction";

const Brokerages = ({
  handleCurrentPath,
  fetchBrokerages,
  fetchClients,
  createBrokerage,
  updateBrokerage,
  removeBrokerage,
  fetchBrokeragesTableView,
  brokerage: { selectedBrokerage, brokerages, isLoading, totalCount },
  client: { clients },
}) => {
  useEffect(() => {
    handleCurrentPath("Brokerages", "5");
    fetchBrokeragesTableView({ search: "", skip, limit: 10 });
    if (!clients || clients.length === 0) {
      fetchClients();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [state, setState] = useState({
    title: "Add Brokerage",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });
  const { mode, modalVisiblity, initVal, title } = state;
  const { confirm } = Modal;

  const formInitValue = {
    name: "",
    website: "",
    street: "",
    city: "",
    state: "",
    zip: null,
    logo: "",
    team: [],
  };
  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });
  const history = useHistory();
  const { search, skip, limit } = tableOpt;
  const columns = [
    {
      title: "",
      key: "logo",
      render: (record) =>
        record.logo ? (
          <Avatar src={record.logo} />
        ) : (
          <Avatar
            style={{ color: "#FFFFFF", backgroundColor: "rgb(1, 148, 134)" }}
          >
            {record.name.charAt(0)}
          </Avatar>
        ),
    },
    {
      title: "HSB#",
      dataIndex: "hsb",
      key: "hsb",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "hsb",
      width: 300,
      render: (name, record) => (
        <Link to={`/admin/brokerages/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      responsive: ["md"],
    },
    {
      title: "Address",
      key: "city",
      responsive: ["md"],
      render: (record) => (
        <span>
          {record.street +
            ", " +
            (record.room ? "#" + record.room + ", " : "") +
            " " +
            (record.city || "") +
            ", " +
            (record.state || "") +
            " " +
            record.zip}
        </span>
      ),
    },
    {
      title: "Team",
      key: "team",
      render: (record) => <span>{record.team.length}</span>,
      responsive: ["md"],
    },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          <Can do="edit" on="Brokerages">
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          </Can>
          <Can do="delete" on="Brokerages">
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
            />
          </Can>
        </span>
      ),
    },
  ];

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchBrokeragesTableView();
    };
  }, []);

  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Brokerage",
      modalVisiblity: true,
    });
  };

  const onConfirmRemove = (brokerage) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${brokerage.name}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeBrokerage(brokerage._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };
  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchBrokeragesTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
    fetchBrokeragesTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };
  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createBrokerage(body);
      return res;
    } else {
      const res = await updateBrokerage(body._id, body);
      return res;
    }
  };
  // const handleOk = (e) => {
  //   setVisible(false);
  // };

  return (
    <React.Fragment>
      <Card
        style={{
          margin: "20px",
        }}
      >
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && brokerages && (
          <div>
            <Row>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <h2>Brokerages</h2>
              </Col>
              <Can do="create" on="Offerings">
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <Input.Search
                    placeholder="Search name"
                    onChange={searchHandler}
                    style={{ width: "60%", marginBottom: "5px" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={() => clickHandler("Add", formInitValue)}
                  >
                    <PlusOutlined /> Add a Brokerage
                  </Button>
                </Col>
              </Can>
            </Row>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={brokerages}
              rowKey={(record) => record._id}
              scroll={{ x: 500 }}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
                showSizeChanger: false,
              }}
              onChange={handleTableChange}
            />
          </div>
        )}
      </Card>
      <Modal
        title={title}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        destroyOnClose={true}
      >
        <BrokerageForm
          initVal={initVal}
          close={handleCancel}
          clients={clients}
          submitHandler={(values) => submitHandler(mode, values)}
        />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  brokerage: state.brokerages,
  client: state.clients,
});

export default connect(mapStateToProps, {
  fetchBrokerages,
  fetchClients,
  createBrokerage,
  updateBrokerage,
  removeBrokerage,
  fetchBrokeragesTableView,
})(Brokerages);
