import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchShoots, removeShoot } from "../../redux/action/shootAction";
import { fetchClientByUserId } from "../../redux/action/clientAction";
import Loading from "../../shared/loading/Loading.js";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  Tag,
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Modal,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  formatUsAddress,
  statusTagColor,
  verifyAddress,
} from "../../shared/utils/utils";
import { filtersObj } from "../../shared/constants/constants";
import EditorList from "./Shoots/EditorList";
import PhotogList from "./Shoots/PhotographerList";
import { fetchPhotographerByUserId } from "../../redux/action/photographerAction";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ability from "../../user/Component/Auth/ability";
import queryString from "query-string";
import ShootTypeBadge from "../../shared/Components/ShootTypeBadge";
import DownloadButton from "../../shared/Components/DowloadButton";

const Shoots = ({
  fetchShoots,
  removeShoot,
  fetchClientByUserId,
  fetchPhotographerByUserId,
  shoot: { shoots, totalCount },
  client: { clientShoots },
  auth: { user },
  handleCurrentPath,
}) => {
  const [loading, setLoading] = useState(true);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [tableOpt, setOperation] = useState({
    photographer: null,
    client: null,
    date: null,
    share: null,
    search: "",
    reqs: "",
    filter: user.access.includes("EDITOR")
      ? "Processing,Tentative,Scheduled,Completed,Reschedule"
      : "",
    sortOrder: "descend",
    sortField:
      user.access.includes("EDITOR") || user.access.includes("PHOTOGRAPHER")
        ? "events.start"
        : "_created_at",
    limit: 10,
    skip: 1,
  });

  const {
    photographer,
    date,
    search,
    filter,
    serviceFilters,
    sortOrder,
    sortField,
    client,
    skip,
    share,
    limit,
    reqs,
  } = tableOpt;
  const history = useHistory();

  useEffect(() => {
    handleCurrentPath("Shoots", "2");
    fetchMyShoots();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteFlag) {
      setDeleteFlag(false);
      fetchMyShoots();
    }
  }, [deleteFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onpopstate = (e) => {
      fetchMyShoots();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMyShoots = async () => {
    let skip = 1;
    const value = queryString.parse(history.location.search);
    let newPageValue = value.page;
    if (newPageValue) {
      if (!shoots.length && !loading) {
        newPageValue = newPageValue - 1;
        const params = new URLSearchParams();
        params.append("page", newPageValue);
        history.push({ search: params.toString() });
      }
      skip = parseInt(newPageValue);
      setOperation({ ...tableOpt, skip });
    } else {
      setOperation({ ...tableOpt, skip });
    }
    if (
      (user &&
        (user.access.includes("ADMIN") || user.access.includes("COMMS"))) ||
      user.access.includes("EDITOR")
    ) {
      fetchShoots({
        date,
        reqs,
        filter,
        serviceFilters,
        search,
        sortField: sortField ? sortField : "hsn",
        sortOrder: sortOrder ? sortOrder : "-1",
        share,
        skip,
        limit,
        access: user.access,
      });
      setLoading(false);
    } else if (user.access.includes("CLIENT")) {
      const client = await fetchClientByUserId(user._id);
      setLoading(false);
      setOperation({
        ...tableOpt,
        client: client.data._id,
        share: [client.data._id],
        skip,
      });
      fetchShoots({
        photographer,
        reqs,
        client: client.data._id,
        date,
        filter,
        serviceFilters,
        search,
        sortOrder,
        sortField,
        share: [client.data._id],
        skip,
        limit,
        access: user.access,
      });
    } else if (user.access.includes("PHOTOGRAPHER")) {
      const res = await fetchPhotographerByUserId(user._id);
      if (res.data) {
        const photographer = res.data;
        setOperation({ ...tableOpt, photographer: photographer._id, skip });
        fetchShoots({
          reqs,
          photographer: photographer._id,
          date,
          filter,
          serviceFilters,
          search,
          sortOrder,
          sortField,
          share,
          skip,
          limit,
          access: user.access,
        });
      }
      setLoading(false);
    }
  };

  const removeShootById = async (id) => {
    await removeShoot(id);
    setDeleteFlag(true);
  };

  const columns = [
    {
      title: "HS#",
      dataIndex: "hsn",
      sorter: true,
      width: 150,
      render: (hsn, record) => (
        <React.Fragment>
          <>
            <span className="mr-2">
              {record?.booking_form?.shoot_type === "Residential" && (
                <ShootTypeBadge shootType="Residential" bgColor="#a155b9" />
              )}
              {record?.booking_form?.shoot_type === "Multi-family" && (
                <ShootTypeBadge shootType="Multi-Family" bgColor="#f765a3" />
              )}
              {record?.booking_form?.shoot_type === "Commercial" && (
                <ShootTypeBadge shootType="Commercial" bgColor="#ffa4b6" />
              )}
              {record?.booking_form?.shoot_type === "Additional" && (
                <ShootTypeBadge shootType="Additional" bgColor="#165baa" />
              )}
            </span>
            <Link to={`/admin/shoots/${record._id}?tab=details`}>{hsn}</Link>
            {""}
            <span className="ml-2">
              {record.original_shoot ? renderTooltip(record) : null}
            </span>
          </>
          {/* {record.status_change_request &&
            !record.status_change_request.resolved && (
              <Tooltip placement="top" title="Pending Request">
                <span className="ml-2" style={{ color: "orange" }}>
                  <ExclamationCircleFilled />
                </span>
              </Tooltip>
            )}
          {record.status_change_request &&
            record.status_change_request.resolved &&
            !record.status_change_request.admin_approved && (
              <Tooltip placement="top" title="Pending Request">
                <span className="ml-2" style={{ color: "red" }}>
                  <ExclamationCircleFilled />
                </span>
              </Tooltip>
            )} */}
        </React.Fragment>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      width: 320,

      render: (address, record) => (
        <Row wrap={false}>
          {ability.can("confirm", "Address") && (
            <Col flex="30px">
              {verifyAddress(record) && (
                <Link to={`/admin/shoots/${record._id}?tab=details`}>
                  <Tooltip placement="topRight" title="Confirm address">
                    <ExclamationCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </Link>
              )}
            </Col>
          )}
          <Col flex="auto">
            {formatUsAddress(
              record.address.full,
              record.address.street,
              record.address.apt,
              record.address.city,
              record.address.state,
              record.address.zip
            )}
          </Col>
        </Row>
      ),
      ellipsis: true,
    },
    {
      title: "Client",
      dataIndex: "client_name_first",
      width: 180,
      responsive: ["md"],
      render: (name, record) => (
        <span>
          {user.access.includes("ADMIN") || user.access.includes("COMMS") ? (
            <Link to={`/admin/clients/${record.client_id}`}>
              {record.client_name_first} {record.client_name_last}
            </Link>
          ) : (
            `${record.client_name_first} ${record.client_name_last}`
          )}
        </span>
      ),
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Photographer",
      dataIndex: "events.photographer",
      width: 130,
      responsive: ["md", "lg", "xl"],
      render: (photographer, record) => (
        <span>
          {record.events?.photographer
            ? record.events?.photographer?.name
            : "---"}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Time",
      dataIndex: "events.start",
      width: 170,
      render: (start, record) => (
        <span>
          {record.events.start &&
            !record.flexible &&
            moment.utc(record.events.start).format("MM/DD/YY LT")}
          {!record.events.start && "---"}
          {record.events.start &&
            record.flexible &&
            moment.utc(record.events.start).format("MM/DD/YY")}
          {record.flexible && ability.can("confirm", "Address") && (
            <Tag className="ml-2" color="magenta">
              Flex
            </Tag>
          )}
        </span>
      ),
      sorter: true,
      ellipsis: true,
      responsive: ["lg", "md"],
    },
    {
      title: "Services",
      dataIndex: "reqs",
      responsive: ["xl", "lg", "md"],
      width: 120,
      filterMode: "tree",
      filters: filtersObj,
      render: (title, record) => (
        <span>
          {record.reqs
            .toString()
            .replace(/RSC/g, "")
            .replace(/FREE,/g, "")
            .replace(/,/g, ", ")}
        </span>
      ),
    },
    {
      title: "",
      responsive: ["xl", "lg", "md"],
      width: 30,
      render: (record) =>
        record?.media?.photos && record?.media?.photos.gallery.length > 0 ? (
          <Tooltip placement="top" title={`Download High Res Photos`}>
            <div style={{ textAlign: "center", marginTop: "-8px" }}>
              <DownloadButton
                media={record?.media}
                selectedShoot={record}
                type="high"
                label=""
                mediaType="photos"
              />
            </div>
          </Tooltip>
        ) : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Scheduled",
          value: "Scheduled",
        },
        {
          text: "Tentative",
          value: "Tentative",
        },
        {
          text: "Processing",
          value: "Processing",
        },
        {
          text: "Reschedule",
          value: "Reschedule",
        },
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Cancelled",
          value: "Cancelled",
        },
        {
          text: "Postpone",
          value: "Postpone",
        },
      ],
      render: (status, record) => (
        <Tag color={statusTagColor(record.status)}>{status}</Tag>
      ),
    },
  ];
  if (user.access.includes("ADMIN"))
    columns.push({
      title: "",
      key: "_id",
      width: "5%",
      render: (record) => (
        <span>
          <Button
            onClick={() => onConfirmRemove(record)}
            danger
            icon={<DeleteOutlined />}
          />
        </span>
      ),
    });

  const { confirm } = Modal;
  const onConfirmRemove = (Shoot) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete shoot HS# ${Shoot.hsn}?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        await removeShootById(Shoot._id);
      },
      onCancel() {},
    });
  };

  // const handleDownloadHighRes = async (record) => {}

  const renderTooltip = (shoot) => {
    return (
      <Tooltip
        title={
          <a href={`/admin/shoots/${shoot.original_shoot}?tab=details`}>
            Reschedule of {shoot?.original_hsn}
          </a>
        }
      >
        <HistoryOutlined style={{ color: "#ff7875" }} />
      </Tooltip>
    );
  };

  const renderTableView = () => {
    return (
      <Table
        dataSource={shoots}
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{
          current: skip,
          pageSize: limit,
          total: totalCount,
        }}
        onChange={handleTableChange}
        scroll={{ x: 500 }}
      />
    );
  };

  const editorsChangeHandler = (change) => {
    const params = new URLSearchParams();
    params.append("page", change.skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      photographer: change.photographer,
      skip: change.skip,
      limit: change.limit,
      reqs: change.reqs,
    });
    fetchShoots({
      client,
      filter,
      sortField,
      sortOrder,
      search,
      share,
      date,
      photographer: change.photographer,
      skip: change.skip,
      limit: change.limit,
      reqs: change.reqs,
      access: user.access,
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      reqs,
      filter: filters.status ? filters.status.toString() : "",
      serviceFilters: filters.reqs ? filters.reqs.toString() : "",
      sortField: sorter.field ? sorter.field : "",
      sortOrder: sorter.order ? sorter.order : "",
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchShoots({
      photographer,
      reqs,
      client,
      filter: filters.status ? filters.status.toString() : "",
      serviceFilters: filters.reqs ? filters.reqs.toString() : "",
      sortField: sorter.field ? sorter.field : "hsn",
      sortOrder: sorter.order ? sorter.order : "-1",
      search,
      share,
      date,
      skip: pagination.current,
      limit: pagination.pageSize,
      access: user.access,
    });
  };

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      search: target.value,
      skip: 1,
    });
    fetchShoots({
      date,
      reqs,
      photographer,
      client,
      filter,
      serviceFilters,
      sortField,
      sortOrder,
      share,
      search: target.value,
      skip: 1,
      limit,
      access: user.access,
    });
  };

  const dateHandler = (date, dateString) => {
    const params = new URLSearchParams();
    params.append("page", 1);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      date: dateString,
      skip: 1,
    });
    fetchShoots({
      photographer,
      reqs,
      client,
      date: dateString,
      filter,
      serviceFilters,
      sortField,
      sortOrder,
      search,
      share,
      skip: 1,
      limit,
      access: user.access,
    });
  };
  return (
    <React.Fragment>
      <Card
        style={{
          margin: "20px",
        }}
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <h2>Shoots</h2>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <Row justify="start" gutter={12}>
              <Col className="mb-1">
                <DatePicker onChange={dateHandler} />
              </Col>
              <Col className="mb-1">
                <Input.Search
                  placeholder="Search by HSN, address or client"
                  onChange={searchHandler}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
            {(user.access?.includes("ADMIN") ||
              user.access?.includes("COMMS")) && (
              <span className="ml-5">
                <a href="/admin/shoots/new">
                  <Button type="primary">
                    <PlusOutlined /> Add a Shoot
                  </Button>
                </a>
              </span>
            )}
          </Col>
        </Row>
        {loading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {user && user.access.includes("EDITOR") && !loading ? (
          <>
            <EditorList
              search={search}
              date={date}
              shoots={shoots}
              loading={loading}
              paginationHandler={handleTableChange}
              changeHandler={editorsChangeHandler}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
              }}
            />
          </>
        ) : user && user.access.includes("PHOTOGRAPHER") && !loading ? (
          <>
            <PhotogList
              photographer={photographer}
              search={search}
              date={date}
              shoots={shoots}
              loading={loading}
              pagination={{
                current: skip,
                pageSize: limit,
                total: totalCount,
              }}
              photogId={user?._id}
            />
          </>
        ) : !loading ? (
          renderTableView()
        ) : null}
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  shoot: state.shoots,
  client: state.clients,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchShoots,
  removeShoot,
  fetchClientByUserId,
  fetchPhotographerByUserId,
})(Shoots);
