import React from "react";
import { List, Avatar } from "antd";
import { TrophyOutlined } from "@ant-design/icons";

const Leaderboard = ({ upsellLeaderboard }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={upsellLeaderboard}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                style={{ backgroundColor: index === 0 ? "#f56a00" : "#d6a885" }}
              >
                {index === 0 ? <TrophyOutlined /> : index + 1}
              </Avatar>
            }
            title={
              <>
                {item.photographer_name}
                {index === 0 && (
                  <TrophyOutlined
                    style={{ color: "gold", marginLeft: "8px" }}
                  />
                )}
              </>
            }
            description={`Total Earnings: $${item.total_earned.toFixed(2)}`}
          />
        </List.Item>
      )}
    />
  );
};

export default Leaderboard;
