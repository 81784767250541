import React from "react";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";
import { Spin, Row, Col, Upload, Typography, Button, message } from "antd";
import { PlusOutlined, UserOutlined, HomeOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { useState } from "react";

const VirtualTourInfo = ({ submitHandler, brokerages, close, initVal }) => {
  const [state, setState] = useState({
    brokerage: initVal.brokerage,
    headshot_url: initVal.headshot_url,
    loader: false,
  });
  const { loader, brokerage, headshot_url } = state;

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const checkUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (file, key, setFieldValue) => {
    // setFieldValue("file", file.originFileObj);
    const check = checkUpload(file);
    if (check) {
      const clientImage = new FormData();
      clientImage.append(
        "path",
        `${key === "brokerage" ? "brokerage" : "clients"}/logo/high/${
          initVal.hsf
        }`
      );
      clientImage.append("file", file.originFileObj);
      setFieldValue(key, clientImage);
      getBase64(file.originFileObj, (imageUrl) => {
        setState({
          ...state,
          [key]: imageUrl,
          loader: false,
        });
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        const updatedMedia = { ...values };
        const res = await submitHandler(updatedMedia);
        if (res.error && res.error.status === 500) {
          message.error("Oops! Error occured while updating media.");
          setState({ ...state, loader: false });
        } else if (res.error && res.error.status === 413) {
          message.error(
            "Oops! It seems your image size is too large. Please upload image 2MB or less.",
            15
          );
          setState({ ...state, loader: false });
        } else {
          message.success("Tour updated successfully!");
          setState({ ...state, loader: false });
        }
        close(res.data);
      }}
      render={({ setFieldValue, values, handleSubmit }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <h3>
                    <HomeOutlined />
                    &nbsp; Property Info
                  </h3>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Street </label>
                    <Input name="street" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>City </label>
                    <Input name="city" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>State </label>
                    <Input name="state" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Zip </label>
                    <Input name="zip" />
                  </div>
                </Col>
                <Col span={24}>
                  <h3>
                    <UserOutlined /> Branding
                  </h3>
                </Col>
                <Col span={24}>
                  <div className="form-group">
                    <label>Name </label>
                    <Input name="name" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Email </label>
                    <Input name="email" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Phone </label>
                    <Input name="phone" />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Headshot </label>
                    <ImgCrop rotate>
                      <Upload
                        name="headshot"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        onChange={async ({ file }) => {
                          handleChange(file, "headshot_url", setFieldValue);
                        }}
                        // onChange={({ file }) =>
                        //   handleChange(file, setFieldValue)
                        // }
                      >
                        {headshot_url ? (
                          <img
                            src={headshot_url}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {values.headshot ? "*Click to change your headshot" : ""}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="form-group">
                    <label>Logo </label>
                    <ImgCrop rotate>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        onChange={({ file }) =>
                          handleChange(file, "brokerage", setFieldValue)
                        }
                      >
                        {brokerage ? (
                          <img
                            src={brokerage}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {values.brokerage ? "*Click to change your logo" : ""}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default VirtualTourInfo;
