import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { Steps, Hints } from "intro.js-react";
import {
  fetchClientByUserId,
  updateClient,
} from "../../../../redux/action/clientAction";
import {
  Row,
  Col,
  Input,
  Alert,
  Card,
  Button,
  Calendar,
  Spin,
  Select,
  Tag,
  Drawer,
  Radio,
  Divider,
  Tooltip,
} from "antd";
import {
  AppstoreOutlined,
  ArrowRightOutlined,
  BellOutlined,
  DollarCircleOutlined,
  LikeOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { updateMedia } from "../../../../redux/action/mediaAction";
import { fetchClientInvoices } from "../../../../redux/action/invoiceAction";
import Loading from "../../../../shared/loading/Loading.js";
import CardItem from "./CardItem";
import "./styles.scss";
import Branding from "./CardItem/Branding";
import { READY } from "../../../../redux/action/shoot/shootConstants";
import ViewMediaItem from "./CardItem/ViewMediaItem";
import PhotosIcon from "../../../../content/icons/Photos.svg";
import WalkthroughIcon from "../../../../content/icons/AerialVideo.svg";
import DollhouseIcon from "../../../../content/icons/3D.svg";
import YoutubeIcon from "../../../../content/icons/Youtube.svg";
import DownloadButton from "../../../../shared/Components/DowloadButton";
import ReferralCard from "./ReferralCard";
import {
  hasUnpaidInvoice,
  hasDeferredTerms,
} from "../../../../shared/utils/utils";
import ShareButtons from "./CardItem/ShareButtons";
import useWindowSize from "../../../../hooks/useWindowSize";

const { Search } = Input;
const { Option } = Select;

const ClientDashboard = ({
  fetchClientByUserId,
  fetchClientInvoices,
  updateMedia,
  auth: { user },
  invoice: { invoices },
  client: { clientProfile },
  updateClient,
}) => {
  const windowSize = useWindowSize();

  const [displayTour, setDisplayTour] = useState(null);

  useEffect(() => {
    fetchFilteredShoots();
    setDisplayTour(JSON.parse(localStorage.getItem("show-tour")));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [reqShoots, setReqShoots] = useState([]);
  const [pendingShoots, setPendingReqShoots] = useState([]);
  const [schedShoots, setSchedShoots] = useState([]);
  const [processingShoots, setProcessingShoots] = useState([]);
  const [completeShoots, setCompleteShoots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [state, setState] = useState([]);
  const [tab, setTab] = useState("allShoots");
  const [date, setDate] = useState();
  const [showFilter, setShowFilter] = useState();
  const [showFilterScheduled, setShowFilterScheduled] = useState();
  const [showFilterPending, setShowFilterPending] = useState();
  const [showFilterProcessing, setShowFilterProcessing] = useState();
  const [showFilterCompleted, setShowFilterCompleted] = useState();
  const [total, setTotal] = useState();
  const [button, setButton] = useState(false);
  const [layout, setLayout] = useState("grid");
  const [selectedShoot, setSelectedShoot] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [photoCount, setPhotoCount] = useState(0);
  const [referralCode, setReferralCode] = useState(null);

  const [guidedTour, setGuidedTour] = useState({
    stepsEnabled: false,
    initialStep: 0,

    steps: [
      {
        element: ".step1",
        intro:
          "Use this dropdown to filter your shoots by their current status.",
        tooltipClass: "step-tooltip",
        highlightClass: "step-highlight",
      },
      {
        element: ".step2",
        intro: "Search your shoots using any part of the address.",
        tooltipClass: "step-tooltip",
        highlightClass: "step-highlight",
      },
      {
        element: ".step3",
        intro: "Select a date to see all shoots scheduled for that day.",
        tooltipClass: "step-tooltip",
        highlightClass: "step-highlight",
      },
      {
        element: ".step4",
        intro: `Here you have all your shoot info. Once your media is ready, the "Quick Download" button gives you fast access to your photos and video. Click the "Details" button to edit or see more.`,
        position: "right",
        tooltipClass: "step-tooltip",
        highlightClass: "step-highlight",
      },
    ],
  });

  const stepsOptions = { overlayOpacity: 0.6, exitOnOverlayClick: false };

  useEffect(() => {
    if (selectedShoot) {
      setPhotoCount(selectedShoot?.media?.photos?.gallery?.length);
    }
  }, [selectedShoot]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientProfile?.referral_code) {
      setReferralCode(clientProfile?.referral_code);
    }
  }, [clientProfile]);

  const fetchFilteredShoots = async () => {
    setLoading(true);
    const client = await fetchClientByUserId(user._id);
    const clientId = client.data._id;
    const allShoots = await axios.get(
      `${
        process.env.REACT_APP_ROOT_URL
      }/shoots?photographer=null&client=${clientId}&share=${clientId}&isDashboard=${true}&access=${
        user?.access
      }`
    );
    setState(allShoots.data.shoots);
    filterReqShoots(allShoots.data.shoots);
    filterPendingShoots(allShoots.data.shoots);
    filterSchedShoots(allShoots.data.shoots);
    filterProcessingShoots(allShoots.data.shoots);
    filterCompleteShoots(allShoots.data.shoots);
    fetchClientInvoices(clientId);
    setLoading(false);
  };

  const unpaidInvoices = invoices.filter(
    (inv) =>
      !inv.paid &&
      !inv.canceled &&
      inv.balance > 0 &&
      moment(inv?.shoot?.events?.start).isBefore()
  );

  const updateShoot = (updatedShoot) => {
    const updatedShoots = state.map((shoot) => {
      if (shoot._id === updatedShoot._id) {
        return updatedShoot;
      } else {
        return shoot;
      }
    });
    setState(updatedShoots);
    const updatedReqShoots = reqShoots.map((shoot) => {
      if (shoot._id === updatedShoot._id) {
        return updatedShoot;
      } else {
        return shoot;
      }
    });
    setReqShoots(updatedReqShoots);
    const updatedFilterShoots = showFilter.map((shoot) => {
      if (shoot._id === updatedShoot._id) {
        return updatedShoot;
      } else {
        return shoot;
      }
    });
    setShowFilter(updatedFilterShoots);
    setSelectedShoot(updatedShoot);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const onFullRender = (date) => {
    const day = date.date();
    let highlightStyle;
    state.map((shoot) => {
      if (
        moment(shoot.events.start).format("YYYY-MM-DD") ===
        date.format("YYYY-MM-DD")
      ) {
        highlightStyle = { border: "1px solid red" };
        return;
      }
    });
    return (
      <div
        className="ant-picker-cell-inner ant-picker-calendar-date"
        style={highlightStyle}
      >
        <div className="ant-picker-calendar-date-value">{day}</div>
        <div className="ant-picker-calendar-date-content"></div>
      </div>
    );
  };

  const drawerStyles = {
    h4: { fontSize: 14, fontWeight: 600, color: "#777" },
    innerCard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#f1f6ff",
      borderRadius: 5,
      margin: 10,
      padding: 5,
    },
    spacing: { margin: "0 auto" },
  };

  const filterReqShoots = (shoots) => {
    const requestedShoots = shoots.filter(
      (shoot) =>
        shoot?.status === "Pending" ||
        "Scheduled" ||
        "Tentative" ||
        "Postpone" ||
        "Processing" ||
        "Cancelled" ||
        "Reschedule" ||
        "Completed"
    );
    setReqShoots(requestedShoots);
    setShowFilter(requestedShoots.slice(0, 10));
    setTotal(requestedShoots?.length < 10 ? requestedShoots?.length : 10);
    return showFilterCompleted?.length === requestedShoots?.length
      ? setButton(true)
      : setButton(false);
  };
  const filterPendingShoots = (shoots) => {
    const pendingShoots = shoots.filter((shoot) => shoot?.status === "Pending");
    setPendingReqShoots(pendingShoots);
    setShowFilterPending(pendingShoots.slice(0, 10));
  };

  const filterSchedShoots = (shoots) => {
    const scheduledShoots = shoots.filter(
      (shoot) => shoot?.status === "Scheduled"
    );
    setSchedShoots(scheduledShoots);
    setShowFilterScheduled(scheduledShoots.slice(0, 10));
  };

  const filterProcessingShoots = (shoots) => {
    const processingShoots = shoots.filter(
      (shoot) => shoot?.status === "Processing"
    );
    setProcessingShoots(processingShoots);
    setShowFilterProcessing(processingShoots.slice(0, 10));
  };

  const filterCompleteShoots = (shoots) => {
    const completeShoots = shoots.filter(
      (shoot) => shoot?.media?.photos?.status === "Ready"
    );
    setCompleteShoots(completeShoots);
    setShowFilterCompleted(completeShoots.slice(0, 10));
  };

  const allShoots = `All Shoots(${reqShoots?.length})`;

  const onSearch = (value) => {
    if (tab === "completed") {
      setButton(false);
      setSearch(value);
      setTotal(completeShoots?.length < 10 ? completeShoots?.length : 10);
      return showFilterCompleted?.length === completeShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (tab === "pending") {
      setSearch(value);
      setTotal(pendingShoots?.length < 10 ? pendingShoots?.length : 10);
      return showFilterPending?.length === pendingShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (tab === "processing") {
      setButton(false);
      setSearch(value);
      setTotal(processingShoots?.length < 10 ? processingShoots?.length : 10);
      return showFilterProcessing?.length === processingShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (tab === "scheduled") {
      setButton(false);
      setSearch(value);
      setTotal(schedShoots?.length < 10 ? schedShoots?.length : 10);
      return showFilterScheduled?.length === schedShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (tab === "allShoots") {
      setButton(false);
      setSearch(value);
      setTotal(state?.length < 10 ? state?.length : 10);
      return showFilter?.length === state?.length
        ? setButton(true)
        : setButton(false);
    }
  };

  const handleSelectChange = (value) => {
    if (value === "completed") {
      setButton(false);
      setTab(value);
      setTotal(completeShoots?.length < 10 ? completeShoots?.length : 10);
      return showFilterCompleted?.length === completeShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (value === "pending") {
      setButton(false);
      setTab(value);
      setTotal(pendingShoots?.length < 10 ? pendingShoots?.length : 10);
      return showFilterPending?.length === pendingShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (value === "processing") {
      setButton(false);
      setTab(value);
      setTotal(processingShoots?.length < 10 ? processingShoots?.length : 10);
      return showFilterProcessing?.length === processingShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (value === "scheduled") {
      setButton(false);
      setTab(value);
      setTotal(schedShoots?.length < 10 ? schedShoots?.length : 10);
      return showFilterScheduled?.length === schedShoots?.length
        ? setButton(true)
        : setButton(false);
    } else if (value === undefined) {
      setButton(false);
      setTab("allShoots");
      setTotal(state?.length < 10 ? state?.length : 10);
      return showFilter?.length === state?.length
        ? setButton(true)
        : setButton(false);
    }
  };

  const dateHandler = (date) => {
    if (date === null) {
      setDate("");
    } else {
      setDate(moment(date?._d).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (tab === "completed") {
      if (search !== "" || date !== undefined) {
        const completeShoots = state.filter((shoot) =>
          date
            ? shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              moment(shoot.events.start).format("YYYY-MM-DD") === date &&
              shoot?.media?.photos?.status === "Ready"
            : shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              shoot?.media?.photos?.status === "Ready"
        );
        setReqShoots(completeShoots);
        setShowFilter(completeShoots.slice(0, 10));
        setTotal(completeShoots?.length < 10 ? completeShoots?.length : 10);
        return completeShoots?.length < 10 ? setButton(true) : setButton(false);
      } else {
        const completeShoots = state.filter(
          (shoot) => shoot?.media?.photos?.status === "Ready"
        );
        setReqShoots(completeShoots);
        setShowFilter(completeShoots.slice(0, 10));
        return completeShoots?.length < 10 ? setButton(true) : setButton(false);
      }
    } else if (tab === "pending") {
      if (search !== "" || date !== undefined) {
        const pendingShoots = state.filter((shoot) =>
          date
            ? shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              moment(shoot.events.start).format("YYYY-MM-DD") === date &&
              shoot?.status === "Pending"
            : shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) && shoot?.status === "Pending"
        );

        setReqShoots(pendingShoots);
        setShowFilter(pendingShoots.slice(0, 10));
        setTotal(pendingShoots?.length < 10 ? pendingShoots?.length : 10);
        return pendingShoots?.length < 10 ? setButton(true) : setButton(false);
      } else {
        const pendingShoots = state.filter(
          (shoot) => shoot?.status === "Pending"
        );
        setReqShoots(pendingShoots);
        setShowFilter(pendingShoots.slice(0, 10));
        return pendingShoots?.length < 10 ? setButton(true) : setButton(false);
      }
    } else if (tab === "scheduled") {
      if (search !== "" || date !== undefined) {
        const scheduledShoots = state.filter((shoot) =>
          date
            ? shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              moment(shoot.events.start).format("YYYY-MM-DD") === date &&
              shoot?.status === "Scheduled"
            : shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              shoot?.status === "Scheduled"
        );
        setReqShoots(scheduledShoots);
        setShowFilter(scheduledShoots.slice(0, 10));
        setTotal(scheduledShoots?.length < 10 ? scheduledShoots?.length : 10);
        return scheduledShoots?.length < 10
          ? setButton(true)
          : setButton(false);
      } else {
        const scheduledShoots = state.filter(
          (shoot) => shoot?.status === "Scheduled"
        );
        setReqShoots(scheduledShoots);
        setShowFilter(scheduledShoots.slice(0, 10));
        return scheduledShoots?.length < 10
          ? setButton(true)
          : setButton(false);
      }
    } else if (tab === "processing") {
      if (search !== "" || date !== undefined) {
        const processingShoots = state.filter((shoot) =>
          date
            ? shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              moment(shoot.events.start).format("YYYY-MM-DD") === date &&
              shoot?.status === "Processing"
            : shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              shoot?.status === "Processing"
        );
        setReqShoots(processingShoots);
        setShowFilter(processingShoots.slice(0, 10));
        setTotal(processingShoots?.length < 10 ? processingShoots?.length : 10);
        return processingShoots?.length < 10
          ? setButton(true)
          : setButton(false);
      } else {
        const processingShoots = state.filter(
          (shoot) => shoot?.status === "Processing"
        );
        setReqShoots(processingShoots);
        setShowFilter(processingShoots.slice(0, 10));
        return processingShoots?.length < 10
          ? setButton(true)
          : setButton(false);
      }
    } else if (tab === "allShoots") {
      if (search !== "" || date !== undefined) {
        const allShoots = state.filter((shoot) =>
          date
            ? shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              moment(shoot.events.start).format("YYYY-MM-DD") === date &&
              (shoot?.status === "Pending" ||
                "Scheduled" ||
                "Tentative" ||
                "Postpone" ||
                "Processing" ||
                "Cancelled" ||
                "Reschedule" ||
                "Completed")
            : shoot.address.full
                .toLowerCase()
                .includes(search?.toLowerCase()) &&
              (shoot?.status === "Pending" ||
                "Scheduled" ||
                "Tentative" ||
                "Postpone" ||
                "Processing" ||
                "Cancelled" ||
                "Reschedule" ||
                "Completed")
        );
        setReqShoots(allShoots);
        setShowFilter(allShoots.slice(0, 10));
        setTotal(allShoots?.length < 10 ? allShoots?.length : 10);
        return allShoots?.length < 10 ? setButton(true) : setButton(false);
      } else {
        const allShoots = state.filter(
          (shoot) =>
            shoot?.status === "Pending" ||
            "Scheduled" ||
            "Tentative" ||
            "Postpone" ||
            "Processing" ||
            "Cancelled" ||
            "Reschedule" ||
            "Completed"
        );
        setReqShoots(allShoots);
        setShowFilter(allShoots.slice(0, 10));
        setTotal(allShoots?.length < 10 ? allShoots?.length : 10);
        return allShoots?.length < 10 ? setButton(true) : setButton(false);
      }
    }
  }, [search, tab, date]); // eslint-disable-line react-hooks/exhaustive-deps
  const renderCardTitle = (title, icon) => {
    return (
      <span className="reminder-card-title">
        {" "}
        {icon} {title}
      </span>
    );
  };
  const showShootOnboard = [];
  const showShootOnboardScheduled = [];
  const showShootOnboardPending = [];
  const showShootOnboardProcessing = [];
  const showShootOnboardCompleted = [];
  let count = total;

  const onLoadMore = () => {
    if (tab === "scheduled") {
      for (let i = count; i < count + 10; i++) {
        if (reqShoots[i]) {
          showShootOnboardScheduled.push(reqShoots[i]);
        } else {
          break;
        }
      }
      setShowFilter(showFilter.concat(showShootOnboardScheduled));
      setTotal(count + 10);
      return reqShoots?.length === showFilter?.length
        ? setButton(true)
        : setButton(false);
    }
    if (tab === "pending") {
      for (let i = count; i < count + 10; i++) {
        if (reqShoots[i]) {
          showShootOnboardPending.push(reqShoots[i]);
        } else {
          break;
        }
      }
      setShowFilter(showFilter.concat(showShootOnboardPending));
      setTotal(count + 10);
      return reqShoots?.length === showFilter?.length
        ? setButton(true)
        : setButton(false);
    }
    if (tab === "processing") {
      for (let i = count; i < count + 10; i++) {
        if (reqShoots[i]) {
          showShootOnboardProcessing.push(reqShoots[i]);
        } else {
          break;
        }
      }
      setShowFilter(showFilter.concat(showShootOnboardProcessing));
      setTotal(count + 10);
      return reqShoots?.length === showFilter?.length
        ? setButton(true)
        : setButton(false);
    }
    if (tab === "completed") {
      for (let i = count; i < count + 10; i++) {
        if (reqShoots[i]) {
          showShootOnboardCompleted.push(reqShoots[i]);
        } else {
          break;
        }
      }
      setShowFilter(showFilter.concat(showShootOnboardCompleted));
      setTotal(count + 10);
      return reqShoots?.length === showFilter?.length
        ? setButton(true)
        : setButton(false);
    }
    if (tab === undefined || tab === "allShoots") {
      for (let i = count; i < count + 10; i++) {
        if (reqShoots[i]) {
          showShootOnboard.push(reqShoots[i]);
        } else {
          break;
        }
      }
      setShowFilter(
        showFilter ? showFilter.concat(showShootOnboard) : showShootOnboard
      );
      setTotal(count + 10);
      return reqShoots?.length === showFilter?.length
        ? setButton(true)
        : setButton(false);
    }
  };

  const handleLayout = (e) => {
    setLayout(e.target.value);
  };

  const preventDefault = () => {
    setDate("");
  };

  const renderAlertMessage = () => {
    return (
      <div>
        <strong>Welcome to the new Dashboard!</strong> Take a moment to look
        around.
      </div>
    );
  };

  const handleHideTour = () => {
    localStorage.setItem("show-tour", false);
    setDisplayTour(false);
  };

  return (
    <div className="client-dashboard">
      <Steps
        enabled={guidedTour.stepsEnabled}
        steps={guidedTour.steps}
        initialStep={guidedTour.initialStep}
        options={stepsOptions}
        onExit={() => setGuidedTour({ ...guidedTour, stepsEnabled: false })}
      />
      <Row>
        <Col span={22} offset={1} className="pr-3">
          <Spin spinning={loading}>
            <Row justify="space-between">
              {(displayTour || displayTour === null) && (
                <Col span={24} className="mb-4">
                  <Alert
                    message={renderAlertMessage()}
                    action={
                      <>
                        <Button
                          size="small"
                          type="primary"
                          className="mr-2"
                          onClick={() =>
                            setGuidedTour({
                              ...guidedTour,
                              stepsEnabled: true,
                            })
                          }
                        >
                          Start the Tour
                        </Button>
                        <Button onClick={() => handleHideTour()} size="small">
                          Dismiss
                        </Button>
                      </>
                    }
                  />
                </Col>
              )}
              <Col flex="400px">
                <h1>{`My Shoots (${reqShoots?.length})`}</h1>
              </Col>
              <Col flex="auto" className="mb-2">
                <div className="float-right">
                  <Select
                    size="large"
                    allowClear
                    placeholder="Filter by Status"
                    onChange={handleSelectChange}
                    style={{
                      minWidth: 200,
                      marginRight: 15,
                      marginBottom: 10,
                      background: "#FFF",
                    }}
                    className="step1"
                  >
                    <Option value="pending">Pending</Option>
                    <Option value="scheduled">Scheduled</Option>
                    <Option value="processing">Processing</Option>
                    <Option value="completed">Completed</Option>
                  </Select>
                  <Search
                    placeholder="Search by address"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                    style={{ width: 300 }}
                    className="step2"
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                {/* <Tabs defaultActiveKey="1" size="large">
                  <TabPane tab={allShoots} value="all"> */}
                <Card bordered={false}>
                  <Row style={{ marginBottom: 15 }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                      <Link
                        to="/lets-book-a-shoot"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          type="primary"
                          size="large"
                          className="float-left"
                        >
                          Let's Book a Shoot <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                      <Radio.Group value={layout} onChange={handleLayout}>
                        <Radio.Button value="grid" type="primary" size="small">
                          <AppstoreOutlined />
                        </Radio.Button>
                        <Radio.Button value="list" type="default" size="small">
                          <UnorderedListOutlined />
                        </Radio.Button>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row gutter={16} className="step4">
                    {!showFilter && <Loading />}
                    {showFilter &&
                      showFilter?.map((shoot) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={layout === "grid" ? 12 : 24}
                          lg={layout === "grid" ? 12 : 24}
                          xl={layout === "grid" ? 12 : 24}
                          key={shoot._id}
                        >
                          <CardItem
                            setDrawerVisible={setDrawerVisible}
                            setSelectedShoot={setSelectedShoot}
                            shoot={shoot}
                            updateMedia={updateMedia}
                            updateShoot={updateShoot}
                            clientProfile={clientProfile}
                          />
                        </Col>
                      ))}
                  </Row>
                </Card>
                {/* </TabPane>
                </Tabs> */}
                {button === false && (
                  <div className="mt-4 text-center">
                    <Button size="large" type="dashed" onClick={onLoadMore}>
                      Load More
                    </Button>
                  </div>
                )}
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <ReferralCard
                  clientProfile={clientProfile}
                  referralCode={referralCode}
                  setReferralCode={setReferralCode}
                  user={user}
                  updateClient={updateClient}
                  renderCardTitle={renderCardTitle(
                    "Referrals",
                    <LikeOutlined />
                  )}
                />
                {windowSize !== "sm" && (
                  <div
                    className="calendar-card step3"
                    style={{ maxWidth: 330 }}
                  >
                    {date !== "" && date !== undefined && (
                      <div
                        className="city"
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          background: "#FFF",
                        }}
                      >
                        Filter by Date:{" "}
                        <Tag
                          color="cyan"
                          closable
                          visible={true}
                          onClose={preventDefault}
                          style={{ marginLeft: 10 }}
                        >
                          {date}
                        </Tag>
                      </div>
                    )}
                    <Calendar
                      fullscreen={false}
                      onChange={dateHandler}
                      dateFullCellRender={onFullRender}
                    />
                  </div>
                )}

                {unpaidInvoices?.length > 0 && (
                  <Card
                    title={renderCardTitle("Action Needed", <BellOutlined />)}
                    bordered={false}
                    style={{ maxWidth: 330, marginTop: 20 }}
                  >
                    {unpaidInvoices?.map((inv) => (
                      <div key={inv._id}>
                        <Row justify="space-between" className="mb-3">
                          <Col flex="auto">
                            <h5
                              className="text-danger"
                              style={{ fontSize: 14 }}
                            >
                              <DollarCircleOutlined
                                style={{ marginRight: 5 }}
                              />
                              Past Due{" "}
                              <span
                                style={{
                                  color: "#112350",
                                  background: "#f1f6ff",
                                  padding: 3,
                                  borderRadius: 5,
                                  fontSize: 14,
                                  marginLeft: 10,
                                }}
                              >{`$${inv?.balance.toFixed(2)}`}</span>
                            </h5>
                            <div className="city">
                              {(inv?.shoot?.address?.street).replace(
                                "null",
                                ""
                              )}
                            </div>
                          </Col>
                          <Col flex="auto">
                            <div className="float-right">
                              <Button
                                style={{
                                  color: "green",
                                  border: "solid 1px green",
                                }}
                                size="small"
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_DOMAIN_URL}/admin/invoices/${inv?._id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                Pay
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    ))}
                  </Card>
                )}
              </Col>
            </Row>
          </Spin>
        </Col>
        <Drawer
          width={640}
          placement="right"
          closable={false}
          onClose={handleCloseDrawer}
          visible={drawerVisible}
          bodyStyle={{ background: "white" }}
        >
          <div className="media-drawer">
            <h4>Branding</h4>
            <Branding
              media={selectedShoot?.media}
              property_info={selectedShoot?.media?.property_info}
              hsf={selectedShoot?.client_hsf}
              updateMedia={updateMedia}
              shoot={selectedShoot}
              updateShoot={updateShoot}
            />
            <Divider />

            <h4>View</h4>
            {selectedShoot?.media?.photos?.status === READY &&
              photoCount > 0 && (
                <ViewMediaItem
                  icon={selectedShoot?.media?.photos?.gallery[0] || PhotosIcon}
                  mediaType="gallery"
                  shoot={selectedShoot}
                  drawerStyles={drawerStyles}
                />
              )}

            {selectedShoot?.media?.video?.status === READY && (
              <ViewMediaItem
                icon={WalkthroughIcon}
                mediaType="video"
                shoot={selectedShoot}
                drawerStyles={drawerStyles}
              />
            )}

            {selectedShoot?.media?.dollHouse?.status === READY && (
              <ViewMediaItem
                icon={DollhouseIcon}
                mediaType="dollhouse"
                shoot={selectedShoot}
                drawerStyles={drawerStyles}
              />
            )}

            {selectedShoot?.media?.slideShow?.status === READY && (
              <ViewMediaItem
                icon={YoutubeIcon}
                mediaType="slideShow"
                shoot={selectedShoot}
                drawerStyles={drawerStyles}
              />
            )}

            <Divider />
            <h4>Download</h4>
            <Row style={drawerStyles.innerCard}>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={drawerStyles.spacing}
              >
                <Tooltip
                  placement="top"
                  title={`Suitable for print flyers/brochures`}
                >
                  <DownloadButton
                    media={selectedShoot?.media}
                    selectedShoot={selectedShoot}
                    type="high"
                    label=" High Resolution"
                    mediaType="photos"
                    isDisabled={
                      (selectedShoot?.media?.photos &&
                        selectedShoot?.media?.photos.gallery.length === 0) ||
                      (hasUnpaidInvoice(selectedShoot?.invoice) &&
                        !hasDeferredTerms(
                          clientProfile?.preferences?.billing?.terms
                        ))
                    }
                  />
                </Tooltip>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                style={drawerStyles.spacing}
              >
                <Tooltip placement="top" title={`Suitable for Web and MLS`}>
                  <DownloadButton
                    media={selectedShoot?.media}
                    selectedShoot={selectedShoot}
                    type="low"
                    label=" Low Resolution"
                    mediaType="photos"
                    isDisabled={
                      (selectedShoot?.media?.photos &&
                        selectedShoot?.media?.photos.gallery.length === 0) ||
                      (hasUnpaidInvoice(selectedShoot?.invoice) &&
                        !hasDeferredTerms(
                          clientProfile?.preferences?.billing?.terms
                        ))
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
            <Divider />
            <h4>Share</h4>
            <ShareButtons shoot={selectedShoot} />
          </div>
        </Drawer>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  client: state.clients,
  invoice: state.invoice,
  auth: state.auth,
  media: state.media.media ? state.media.media : {},
});

export default connect(mapStateToProps, {
  fetchClientByUserId,
  fetchClientInvoices,
  updateMedia,
  updateClient,
})(ClientDashboard);
