import axios from "axios";
import {
  FETCH_BLOG_POSTS,
  FETCH_BLOG_POSTS_TABLEVIEW,
  FETCH_BLOG_POST,
  CREATE_BLOG_POST,
  UPDATE_BLOG_POST,
  REMOVE_BLOG_POST,
} from "./types";

export const fetchBlogPosts = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/posts?published=true`
    );
    dispatch({
      type: FETCH_BLOG_POSTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchBlogPostsTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/posts/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );

    dispatch({
      type: FETCH_BLOG_POSTS_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchBlogPostBySlug = (slug) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/posts/${slug}`
    );
    dispatch({
      type: FETCH_BLOG_POST,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createBlogPost = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/posts`,
      formData,
      config
    );
    dispatch({
      type: CREATE_BLOG_POST,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err);
  }
};

export const updateBlogPost = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/posts/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_BLOG_POST,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const removeBlogPost = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/posts/${id}`);
    dispatch({
      type: REMOVE_BLOG_POST,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
