import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  Table,
  Button,
  Modal,
  Avatar,
  Tooltip,
  message,
  Badge,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CrownFilled,
} from "@ant-design/icons";
import Can from "../../user/Component/Auth/Can";
import TeamEditForm from "../Components/Teams/TeamEditForm";
import {
  fetchTeam,
  removeTeam,
  fetchTeamByClientId,
} from "../../redux/action/teamAction";
import { fetchClientByUserId } from "../../redux/action/clientAction";

const Teams = ({
  handleCurrentPath,
  fetchTeam,
  removeTeam,
  teams,
  fetchClientByUserId,
  fetchTeamByClientId,
  auth: { user },
  clients,
}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const fetchForClients = async () => {
      const clientTeam = await fetchClientByUserId(user._id);
      if (clientTeam.error) {
        return "The given ID is not found";
      } else {
        fetchTeamByClientId(clientTeam.data._id);
      }
    };
    handleCurrentPath("Team", "8");
    user.access?.includes("CLIENT") &&
    !(user.access?.includes("ADMIN") || user.access?.includes("COMMS"))
      ? fetchForClients()
      : fetchTeam();
  }, [teams]); // eslint-disable-line react-hooks/exhaustive-deps

  const [data, setData] = useState({
    _id: "",
    name: "",
    owner: "",
    members: "",
  });
  const { confirm } = Modal;

  const colors = [
    "#528FC4",
    "#3C7369",
    "#D6A334",
    "#C88564",
    "#AF5656",
    "#808080",
    "#7378AD",
    "#058A8A",
    "#800000",
    "#76802B",
    "#A0A04C",
    "#82C291",
    "#5E925E",
    "#2D9393",
    "#419C9C",
    "#828EC2",
    "#7575D5",
    "#DC85DE",
    "#C765C7",
  ];
  const MemberAvatar = (member, index, i, owner) => {
    return (
      <>
        {member?.headshot_url ? (
          <Tooltip title={member?.full}>
            <Badge
              count={
                owner?._id === member?._id ? (
                  <CrownFilled style={{ color: "#FFB404" }} />
                ) : (
                  ""
                )
              }
              style={{ paddingRight: "5px" }}
            >
              <Avatar
                style={{ border: "0.5px solid #EEEEEE" }}
                src={member?.headshot_url}
              />
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip title={member?.full}>
            <Badge
              count={
                owner?._id === member?._id ? (
                  <CrownFilled style={{ color: "#FFB404" }} />
                ) : (
                  ""
                )
              }
              style={{ paddingRight: "5px" }}
            >
              <Avatar
                style={{
                  color: "#FFFFFF",
                  fontSize: "30px",
                  backgroundColor:
                    colors[((index + 1) * (i + 1) * member?.full?.length) % 19],
                  key: i,
                }}
                // icon={<UserOutlined />}
                // alt={content}
              >
                {member?.full?.trim().charAt(0)}
              </Avatar>
            </Badge>
          </Tooltip>
        )}
      </>
    );
  };
  const columns = [
    // {
    //   title: "",
    //   dataIndex: "avatar",
    //   key: "avatar",
    // },
    // {
    //   title: "HST",
    //   dataIndex: "hst",
    //   key: "hst",
    //   render: (hst, record) => <span>{hst}</span>,
    // },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, record) => <span>{`${record.name}`}</span>,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 200,
      render: (owner, record) => (
        <span>{`${record.owner?.first}  ${record.owner?.last}`}</span>
      ),
    },
    {
      title: "Members",
      dataIndex: "full",
      key: "full",
      render: (full, record, index) => (
        <span>
          {/* {MemberAvatar(record.owner, index, 0)} */}
          {record.members.map((member, i) => (
            <span key={i}>
              {MemberAvatar(member.client, index, i, record.owner)}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) =>
        user.access?.includes("CLIENT") ? (
          record.owner?._id === clients.clientProfile._id && (
            <span>
              <Can do="edit" on="Team">
                <Button
                  onClick={() => showModal(record, true)}
                  style={{ margin: "0 5px 5px 0" }}
                  icon={<EditOutlined />}
                />
              </Can>
              <Can do="delete" on="Team">
                <Button
                  onClick={() => onConfirmRemove(record)}
                  danger
                  icon={<DeleteOutlined />}
                />
              </Can>
            </span>
          )
        ) : (
          <span>
            <Can do="edit" on="Team">
              <Button
                onClick={() => showModal(record, true)}
                style={{ margin: "0 5px 5px 0" }}
                icon={<EditOutlined />}
              />
            </Can>
            <Can do="delete" on="Team">
              <Button
                onClick={() => onConfirmRemove(record)}
                danger
                icon={<DeleteOutlined />}
              />
            </Can>
          </span>
        ),
    },
  ];
  const showModal = (item, edit) => {
    setData({ ...item, editMode: edit });
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onConfirmRemove = (team) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to remove ${team.name}`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        const res = await removeTeam(team._id);
        if (res) {
          message.success("Team deleted successfully.");
        } else {
          message.error("Oops! Something went wrong");
        }
      },
      onCancel() {
        handleOk();
      },
    });
  };

  const handleOk = (e) => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Card style={{ margin: "10px" }}>
        <div>
          <h2>Teams</h2>
          <Button
            style={{ float: "right", marginBottom: 10 }}
            type="primary"
            onClick={() => showModal(null, false)}
          >
            <PlusOutlined /> Add a Team
          </Button>
          <Table
            columns={columns}
            dataSource={teams}
            rowKey={(record) => record._id}
            pagination={true}
          />
        </div>
        {/* )} */}
      </Card>
      <Modal
        width={1200}
        title={data.editMode ? "Edit a Team" : "Add a Team"}
        onCancel={handleCancel}
        visible={visible}
        footer={false}
        mask={true}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div>
          {data.editMode ? (
            <TeamEditForm mode="EDIT" handleCancel={handleCancel} data={data} />
          ) : (
            <TeamEditForm
              mode="ADD"
              handleCancel={handleCancel}
              isClient={
                user.access?.includes("CLIENT") &&
                !(
                  user.access?.includes("ADMIN") ||
                  user.access?.includes("COMMS")
                )
              }
            />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  teams: state.team.teams,
  auth: state.auth,
  clients: state.clients,
  credit: state.credit.credits,
});

export default connect(mapStateToProps, {
  fetchTeam,
  removeTeam,
  fetchClientByUserId,
  fetchTeamByClientId,
})(Teams);
