import React from "react";
import { Col, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "../styles.scss";

const CardAvatar = ({ name, title }) => {
  return (
    <>
      <Col flex="45px" className="mr-2">
        <Avatar size={40} icon={<UserOutlined />} />
      </Col>
      <Col flex="auto">
        <div className="avatar-title">{name}</div>
        <div className="avatar-desc">{title}</div>
      </Col>
    </>
  );
};

export default CardAvatar;
