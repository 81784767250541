import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import Loading from "../shared/loading/Loading";

const BookShoot = lazy(() => import("../user/Component/BookShoot"));
const Home = lazy(() => import("../user/Component/Home"));
const AboutUs = lazy(() => import("../user/Component/AboutUs"));
const Faq = lazy(() => import("../user/Component/Faq"));
const FlyerOrderForm = lazy(() => import("../user/Component/FlyerOrderForm"));
const BlogPosts = lazy(() => import("../user/Component/Blog/BlogPosts"));
const BlogPost = lazy(() => import("../user/Component/Blog/BlogPost"));
const Services = lazy(() => import("../user/Component/Sevices/"));
const Pricing = lazy(() => import("../user/Component/Pricing/"));
const RealEstatePhotography = lazy(() =>
  import("../user/Component/Sevices/RealEstatePhotography")
);
const RealEstateVideography = lazy(() =>
  import("../user/Component/Sevices/RealEstateVideography")
);
const RealEstateDrone = lazy(() =>
  import("../user/Component/Sevices/RealEstateDrone")
);
const RealEstateVirtualTour = lazy(() =>
  import("../user/Component/Sevices/RealEstateVirtualTour")
);
const TermsOfService = lazy(() => import("../user/Component/TermsOfService"));
const Portfolio = lazy(() => import("../user/Component/Portfolio"));
const RegisterInvite = lazy(() =>
  import("../user/Component/Auth/RegisterInvite")
);
const Login = lazy(() => import("../user/Component/Auth/Login"));
const Team = lazy(() => import("../user/Component/Auth/InvitationPage"));
const ReferralsLandingPage = lazy(() =>
  import("../user/Component/LandingPage/Referrals")
);

class UserRoutes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Suspense fallback={<Loading />}>
            <Route path="/lets-book-a-shoot">
              <BookShoot handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/about-us">
              <AboutUs handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/faq">
              <Faq handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/portfolio">
              <Portfolio handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/services">
              <Services handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/real-estate-photography">
              <RealEstatePhotography
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/real-estate-videography">
              <RealEstateVideography
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/real-estate-drone-photography">
              <RealEstateDrone
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/real-estate-virtual-tour">
              <RealEstateVirtualTour
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/terms-of-service">
              <TermsOfService
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/register">
              <RegisterInvite
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/accept">
              <Team handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/login">
              <Login handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route exact path="/blog">
              <BlogPosts handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route exact path="/blog/:slug">
              <BlogPost handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/kpauley">
              <Home handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route path="/referrals/:prefix/:code">
              <ReferralsLandingPage
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/flyer-form-template">
              <FlyerOrderForm
                handleCurrentPath={this.props.handleCurrentPath}
              />
            </Route>
            <Route path="/pricing">
              <Pricing handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
            <Route exact path="/">
              <Home handleCurrentPath={this.props.handleCurrentPath} />
            </Route>
          </Suspense>
        </Switch>
      </React.Fragment>
    );
  }
}
export default UserRoutes;
