import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Button, List, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const Pay = ({ startDate, endDate }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [pay, setPay] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchPhotographersWeeklyPay();
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPhotographersWeeklyPay = async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/photographers/weekly-pay?startDate=${startDate}`
    );
    setPay(res.data);
    setLoading(false);
  };

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>
        All Photographers <MoreOutlined />
      </Button>
      <Modal
        title={`Photographers Weekly Pay for ${startDate} to ${endDate}`}
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={pay}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a
                  key="view-details"
                  href={`${process.env.REACT_APP_DOMAIN_URL}/admin/photographers/${item?._id}?date=${startDate}`}
                >
                  View Detail
                </a>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  item?.headshot_url ? (
                    <Avatar src={item?.headshot_url} />
                  ) : (
                    <Avatar
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: `${item?.color}`,
                      }}
                    >
                      {item?.name.charAt(0)}
                    </Avatar>
                  )
                }
                title={item?.name}
              />
              <div className="float-right">{`$${
                item?.current_week?.weekTotal.toFixed(2) || 0.0
              }`}</div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default Pay;
