import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
const getIcon = (type, color) => {
  if (type === "home") {
    return (
      "<svg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='48px' height='48px'><path fill='" +
      color +
      "' d='M12,2C8.134,2,5,5.134,5,9c0,5,7,13,7,13s7-8,7-13C19,5.134,15.866,2,12,2z M16,10h-1v3h-2v-3h-2v3H9v-3H8V9l4-3l4,3V10z'/></svg>"
    );
  } else {
    return (
      "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48px' height='48px'><path fill='" +
      color +
      "' d='M39,18c0,8.284-15,27-15,27S9,26.284,9,18S15.716,3,24,3S39,9.716,39,18z' /> <path fill='#FFFFFF' d='M31,25H17c-1.159,0-2-0.826-2-2v-9.875C15,11.951,15.94,11,17.1,11H30.9c1.159,0,2.1,0.951,2.1,2.125V23C33,24.174,32.159,25,31,25' /> <path fill='#000' d='M17 13H19V15H17z' /> <path fill='#000' d='M20 18c0-2.761 2.24-5 5-5 2.761 0 5 2.239 5 5s-2.239 5-5 5C22.24 23 20 20.761 20 18M28 11l-.981-1.531C26.83 9.176 26.514 9 26.176 9H21.31c-.338 0-.654.176-.842.469L19.486 11H28z' /> <path fill='#B388FF' d='M22,18c0-1.656,1.344-3,3-3s3,1.344,3,3s-1.344,3-3,3S22,19.656,22,18' /> <path fill='#D2BDFF' d='M25.707,18.293c-0.257,0-0.513-0.098-0.708-0.294c-0.391,0.391-1.023,0.39-1.414,0.001c-0.391-0.391-0.39-1.023,0.001-1.414C23.964,16.208,24.466,16,25,16c0.001,0,0.001,0,0.002,0c0.534,0,1.036,0.209,1.413,0.587c0.39,0.391,0.39,1.024-0.002,1.414C26.218,18.195,25.963,18.293,25.707,18.293z'/></svg>"
    );
  }
};

const darkMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];

export const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultOptions={{
        styles: darkMapStyles,
      }}
      defaultZoom={9}
      MapTypeIds={["roadmap", "satellite", "hybrid", "terrain"]}
      defaultCenter={{ lat: 32.80978545, lng: -96.8664845 }}
      yesIWantToUseGoogleMapApiInternals={true}
      st
    >
      {props.shoots.map((sht, ind) => (
        <Marker
          icon={{
            url:
              "data:image/svg+xml;charset=UTF-8;base64," +
              btoa(getIcon("home", sht.backgroundColor)),
          }}
          defaultOpacity={1}
          position={{
            lat: sht.details.address.geometry.coordinates[1],
            lng: sht.details.address.geometry.coordinates[0],
          }}
          key={sht.details.address.geometry.coordinates[1]}
          onClick={() =>
            props.toggleInfoWindow({
              type: "shoot",
              arr: props.shoots,
              ind,
              status: true,
            })
          }
        >
          {sht.showInfoWindow && (
            <InfoWindow
              onCloseClick={() =>
                props.toggleInfoWindow({
                  type: "shoot",
                  arr: props.shoots,
                  ind,
                  status: false,
                })
              }
            >
              <span>{sht.details.events.title}</span>
            </InfoWindow>
          )}
        </Marker>
      ))}
      {props.photographers.map((pht, ind) =>
        pht.geometry ? (
          <Marker
            key={ind}
            defaultOpacity={pht.opacity}
            icon={{
              url:
                "data:image/svg+xml;charset=UTF-8;base64," +
                btoa(getIcon("phtg", pht.bgColor)),
            }}
            position={{
              lat: pht.geometry.coordinates[1],
              lng: pht.geometry.coordinates[0],
            }}
            onClick={() =>
              props.toggleInfoWindow({
                type: "Photographer",
                arr: props.photographers,
                ind,
                status: true,
              })
            }
          >
            {pht.showInfoWindow ? (
              <InfoWindow
                onCloseClick={() =>
                  props.toggleInfoWindow({
                    type: "Photographer",
                    arr: props.photographers,
                    ind,
                    status: false,
                  })
                }
              >
                <span style={{ fontSize: "10px", fontWeight: 700 }}>
                  {pht.name}
                </span>
              </InfoWindow>
            ) : null}
          </Marker>
        ) : null
      )}
    </GoogleMap>
  ))
);
