import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchInvoiceById,
  updateInvoice,
} from "../../../redux/action/invoiceAction";
import { FETCH_INVOICE_BY_ID } from "../../../redux/action/types";
import { Row, Col, message, Spin } from "antd";
import InvoiceDetails from "./InvoiceDetails";
import Loading from "../../../shared/loading/Loading.js";
import {
  updateCredit,
  fetchCreditById,
  createCredit,
} from "../../../redux/action/creditAction";
import { createActivity } from "../../../redux/action/activityAction";
import { fetchOfferings } from "../../../redux/action/offeringAction";
import {
  calculateTax,
  convertISODateString,
} from "../../../shared/utils/utils";

const Invoice = ({
  match,
  id,
  fetchInvoiceById,
  updateInvoice,
  updateCredit,
  createCredit,
  fetchOfferings,
  invoice: { selectedInvoice, isLoading },
  offering: { offerings },
  removeSelectedInvoice,
  manualFetch,
  openmodal,
  createActivity,
}) => {
  const [discountAmount, setDiscountAmount] = useState(null);
  const [creditArray, setCreditArray] = useState(); // eslint-disable-next-line
  const [availableCredit, setAvailableCredit] = useState(0);
  useEffect(() => {
    const fetch = async () => {
      let invoiceId;
      if (match?.params?.id) {
        invoiceId = match.params.id;
      } else {
        invoiceId = selectedInvoice?._id;
      }
      const invoice = await fetchInvoiceById(invoiceId);
      if (invoice.data && invoice.data.client?.credit) {
        setCreditArray(invoice.data.client?.credit);
      }
    };
    fetch();
  }, [fetchInvoiceById, setCreditArray, availableCredit, openmodal]); // eslint-disable-line react-hooks/exhaustive-deps

  let bal;
  useEffect(() => {
    let availableTotal = 0;
    bal = selectedInvoice?.client?.credit
      ?.filter((credit) => credit.redeemed === false)
      .map((cre) => {
        availableTotal = availableTotal + cre?.amount;
        setAvailableCredit(availableTotal?.toFixed(2));
      });
  }, [selectedInvoice, manualFetch]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!id) {
      return () => {
        removeSelectedInvoice();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!offerings.length) {
      fetchOfferings();
      return;
    }
    const price = offerings.filter((off) => off.code === "REFERRAL")[0][
      "price"
    ];
    setDiscountAmount(price);
  }, [offerings]); // eslint-disable-line react-hooks/exhaustive-deps

  const creditRedeem = async (invoice, discountAmount, selected) => {
    let creditUsed;
    if (selected.length === 1) {
      if (discountAmount > invoice.balance) {
        const amount = Number((discountAmount - invoice.balance).toFixed(2));
        creditUsed = Number(invoice.balance.toFixed(2));
        let totalCreditsUsed = invoice.credit_used
          ? invoice.credit_used + creditUsed
          : creditUsed;
        const [inv, cre, addCred] = await Promise.all([
          updateInvoice(invoice._id, {
            credit_used: totalCreditsUsed,
            balance: 0,
            paid: true,
            _updated_at: convertISODateString(new Date()),
          }),
          updateCredit(selected[0]?._id, { redeemed: true }),
          createCredit({
            valid: true,
            redeemed: false,
            client: invoice.client._id, // Add client
            amount: amount,
            reason: `Balance of Credit used on Invoice #${invoice.hsn} - $${amount}.`, // Add the value of balance amount
            code: "CREDIT BALANCE",
            credit_type: "Refund",
          }),
        ]);
        if (inv.data && cre.data && addCred.data) {
          message.success("You have redeemed the offer successfully");
        }
      } else if (discountAmount === invoice.balance) {
        const balance = Number((invoice.balance - discountAmount).toFixed(2));
        creditUsed = Number(discountAmount.toFixed(2));
        let totalCreditsUsed = invoice.credit_used
          ? invoice.credit_used + creditUsed
          : creditUsed;
        const [inv, cre] = await Promise.all([
          updateInvoice(invoice._id, {
            credit_used: totalCreditsUsed,
            balance: balance < 0 ? 0 : balance,
            paid: true,
            _updated_at: convertISODateString(new Date()),
          }),
          updateCredit(selected[0]?._id, { redeemed: true }),
        ]);
        if (inv.data && cre.data) {
          message.success("You have redeemed the offer successfully");
        }
      } else {
        const balance = Number((invoice.balance - discountAmount).toFixed(2));
        creditUsed = Number(discountAmount.toFixed(2));
        let totalCreditsUsed = invoice.credit_used
          ? invoice.credit_used + creditUsed
          : creditUsed;
        const [inv, cre] = await Promise.all([
          updateInvoice(invoice._id, {
            credit_used: totalCreditsUsed,
            balance: balance < 0 ? 0 : balance,
            paid: false,
            _updated_at: convertISODateString(new Date()),
          }),
          updateCredit(selected[0]?._id, { redeemed: true }),
        ]);
        if (inv.data && cre.data) {
          message.success("You have redeemed the offer successfully");
        }
      }
    } else {
      let invData;
      let creData;
      let addCred;
      for (let i = 0; i < selected.length; i++) {
        if (i === 0) {
          if (discountAmount > invoice.balance) {
            const amount = Number(
              (discountAmount - invoice.balance).toFixed(2)
            );
            creditUsed = Number(invoice.balance.toFixed(2));
            let totalCreditsUsed = invoice.credit_used
              ? invoice.credit_used + creditUsed
              : creditUsed;
            const [inv, cre, addCre] = await Promise.all([
              updateInvoice(invoice._id, {
                credit_used: totalCreditsUsed,
                balance: 0,
                paid: true,
                _updated_at: convertISODateString(new Date()),
              }),
              updateCredit(selected[i]._id, { redeemed: true }),
              createCredit({
                valid: true,
                redeemed: false,
                client: invoice.client._id, // Add client
                amount: amount,
                reason: `Balance of Credit used on Invoice #${invoice.hsn} - $${amount}.`, // Add the value of balance amount
                code: "CREDIT BALANCE",
                credit_type: "Refund",
              }),
            ]);
            invData = inv;
            creData = cre;
            addCred = addCre;
          } else {
            const balance = Number(
              (invoice.balance - discountAmount).toFixed(2)
            );
            creditUsed = Number(discountAmount.toFixed(2));
            let totalCreditsUsed = invoice.credit_used
              ? invoice.credit_used + creditUsed
              : creditUsed;
            const [inv, cre] = await Promise.all([
              updateInvoice(invoice?._id, {
                credit_used: totalCreditsUsed,
                balance: balance < 0 ? 0 : balance,
                paid: false,
                _updated_at: convertISODateString(new Date()),
              }),
              updateCredit(selected[i]?._id, { redeemed: true }),
            ]);
            invData = inv;
            creData = cre;
          }
        } else {
          creData = await updateCredit(selected[i]?._id, { redeemed: true });
        }
        if (i === selected.length - 1)
          if ((invData.data && creData.data) || addCred.data) {
            message.success("You have redeemed the offer successfully");
          }
      }
    }
    if (creditUsed) {
      createActivity(
        invoice.shoot,
        `Credit of $${creditUsed} used on Invoice #${invoice.hsn}`
      );
    }
  };
  return (
    <div>
      {isLoading && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {!isLoading && selectedInvoice ? (
        <>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={id ? { span: 24 } : { span: 14, offset: 5 }}
              lg={id ? { span: 24 } : { span: 14, offset: 5 }}
            >
              <div className="invoice-card">
                {selectedInvoice._id &&
                  selectedInvoice.client?.credit !== null && (
                    <InvoiceDetails
                      data={selectedInvoice}
                      creditRedeem={creditRedeem}
                      calculateTax={calculateTax}
                      discount={discountAmount}
                      updateInvoice={updateInvoice}
                      manualFetch={() => manualFetch()}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  credit: state.credit,
  auth: state.auth,
  offering: state.offering,
});

export default connect(mapStateToProps, {
  fetchInvoiceById,
  fetchCreditById,
  fetchOfferings,
  updateInvoice,
  updateCredit,
  createCredit,
  createActivity,
  removeSelectedInvoice: () => {
    return { type: FETCH_INVOICE_BY_ID, payload: {} };
  },
})(Invoice);
