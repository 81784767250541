import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { hideModal } from "../../../redux/action/modal";
import { updateInvoice } from "../../../redux/action/invoiceAction";
import {
  savePaymentMethod,
  fetchPaymentMethods,
  removePaymentMethod,
} from "../../../redux/action/clientAction";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col, Alert, Spin, Button, message, Checkbox, Input } from "antd";
import NumberFormat from "react-number-format";
import Loading from "../../../shared/loading/Loading.js";
import "./Invoice.scss";
import mastercardIcon from "../../../content/mastercard-icon.svg";
import visaIcon from "../../../content/visa-icon.svg";
import amexIcon from "../../../content/amex-icon.svg";
import discoverIcon from "../../../content/discover-icon.svg";
import { createActivity } from "../../../redux/action/activityAction";
import { updateShoot } from "../../../redux/action/shootAction";
import CardList from "../Clients/PaymentMethods/CardList";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CheckoutForm = ({
  data,
  hideModal,
  updateInvoice,
  updateShoot,
  createActivity,
  user,
  emailPaymentSuccess,
  savePaymentMethod,
  removePaymentMethod,
  fetchPaymentMethods,
  cards,
  createPayment,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [errorVisible, setErrorVisible] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const patBtnRef = useRef();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FFFFFF" }} spin />
  );

  const [formData] = useState({
    amount: (data.balance * 100).toFixed(),
    currency: "USD",
    description: `HS# ${data?.shoot?.hsn} - ${data?.shoot?.address?.street}`,
    payment_method: "",
    email: data?.client?.email,
    phone: data?.client?.phone,
    hsf: data?.client?.hsf,
    stripe_id: data?.client?.stripe_id,
    name: `${data?.client?.first} ${data?.client?.last}`,
    confirm: true,
    clientId: data?.client?._id,
    invoice: data?._id,
    hsn: data.shoot.hsn,
    paid: true,
  });

  useEffect(() => {}, [
    isSubmitting,
    paymentSuccess,
    errorText,
    errorVisible,
    formData,
  ]);

  useEffect(() => {
    fetchPaymentMethods(data?.client?._id);
  }, [data]);

  const renderAlert = (msg, errorType) => {
    return (
      <div>
        <Alert message={msg} type={errorType} showIcon />
      </div>
    );
  };

  const saveCardOnFile = async (clientInfo, card) => {
    const tokenResp = await stripe.createToken(card, { name: cardholderName });

    const { token } = tokenResp;

    const { _id, email, phone, first, last, hsf, stripe_id } = clientInfo;
    const res = await savePaymentMethod({
      customerId: stripe_id,
      token: token.id,
      email,
      phone,
      hsf,
      fullName: `${first} ${last}`,
      clientId: _id,
    });
    if (!res.error) {
      message.success("Card saved successfully");
    } else {
      message.error(
        "Oops. Unable to save card info. Visit client profile to try again."
      );
    }
  };

  const handleChargeCard = async (event, card) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const { amount, description, currency } = formData;
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/payments/charge-card`,
        {
          amount,
          customer: card?.customer,
          source: card?.id,
          description,
          currency,
        }
      );

      if (res.data.status === "succeeded") {
        setIsSubmitting(false);
        setPaymentSuccess(true);
        setErrorVisible(false);

        const paymentData = {
          client: data?.client?._id,
          invoice: data?._id,
          hsn: data?.shoot?.hsn,
          amount: res.data.amount / 100,
          fee: "",
          gateway: "stripe",
          payment_method: "Stripe",
          payment_id: res.data.id,
          gateway_payer_id: res.data.customer,
          gateway_email: data?.client?.email,
          gateway_token: res.data.balance_transaction,
          paid: res.data.status === "succeeded",
          date_paid: new Date(),
        };

        await createPayment(paymentData);

        updateInvoice(data?._id, {
          ...data,
          date_paid: new Date(),
          balance: 0,
          paid: true,
        });
        createActivity(
          { ...data.shoot, user: user?._id },
          `Invoice #${data.shoot.hsn} paid using card ending ${card?.last4}.`
        );
        updateShoot(data?.shoot?._id, {
          checklist: { invoice_paid: true },
        });
        emailPaymentSuccess(false);
      } else {
        setErrorText(data.message);
        setErrorVisible(true);
        setIsSubmitting(false);
      }
    } catch (err) {
      console.log(err);
      // setErrorText(
      //   "Oops! We were unable to charge the crad on file. Please try another card."
      // );
      // setErrorVisible(true);
      // setIsSubmitting(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (paymentMethodReq.error) {
      setErrorText(paymentMethodReq.error.message);
      setErrorVisible(true);
      setIsSubmitting(false);
      return;
    }
    const { id } = paymentMethodReq.paymentMethod;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/invoices/payment`,
        { ...formData, payment_method: id }
      );

      if (res.data.success) {
        if (saveCard) {
          saveCardOnFile(data?.client, card);
        }
        setIsSubmitting(false);
        setPaymentSuccess(true);
        setErrorVisible(false);
        updateInvoice(data?._id, {
          ...data,
          date_paid: new Date(),
          balance: 0,
          paid: true,
        });
        createActivity(
          { ...data.shoot, user: user?._id },
          `Invoice #${data.shoot.hsn} marked as paid.`
        );
        updateShoot(data?.shoot?._id, {
          checklist: { invoice_paid: true },
        });
        emailPaymentSuccess(false);
      } else {
        setErrorText(res.data.message);
        setErrorVisible(true);
        setIsSubmitting(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = ({ error }) => {
    if (error) {
      setErrorText(error.message);
      setErrorVisible(true);
      setIsSubmitting(false);
    } else {
      setErrorVisible(false);
    }
  };

  const handleCheckbox = (e) => {
    setSaveCard(e.target.checked);
  };

  const removePaymentMethodForShoot = async (invoiceId, shootId) => {
    const res = await updateInvoice(invoiceId, {
      selectedCard: null,
    });

    if (!res.error) {
      message.success("Payment method removed successfully");
      await updateShoot(shootId, {
        selectedCard: null,
      });
    } else {
      message.error("Oops. Failed to remove payment method");
    }
  };

  return (
    <div className="payment-container">
      {!stripe && (
        <div className="loading-container">
          <Loading />
        </div>
      )}

      {stripe && (
        <form onSubmit={handleSubmit}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              We accept: <img src={visaIcon} alt="Visa" />{" "}
              <img src={mastercardIcon} alt="Master Card" />{" "}
              <img src={discoverIcon} alt="Discover" />{" "}
              <img src={amexIcon} alt="American Express" />{" "}
            </Col>
            {errorVisible && (
              <Col span={24}>{renderAlert(errorText, "error")}</Col>
            )}
            {paymentSuccess && (
              <Col span={24}>
                {renderAlert(
                  "Thank you! Your payment was successful.",
                  "success"
                )}
              </Col>
            )}

            <Col span={24}>
              <Input
                onChange={(e) => setCardholderName(e.target.value)}
                value={cardholderName}
                placeholder="Name on the Card"
              />
            </Col>
            <Col span={24}>
              <CardElement
                onChange={handleChange}
                options={{
                  classes: { base: "ant-input" },
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      padding: "5px",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </Col>
            {user && (
              <Col span={24}>
                <div
                  className="inner-card mt-3"
                  style={{ fontSize: 18, color: "#676767" }}
                >
                  <Checkbox onChange={handleCheckbox}>
                    <strong>Save this card on file</strong>
                  </Checkbox>
                </div>
              </Col>
            )}

            {user &&
              data?.client?.stripe_id &&
              (user?.access?.includes("ADMIN") ||
                user?.access?.includes("COMMS") ||
                user?.access?.includes("CLIENT")) && (
                <Col span={24}>
                  Would you like to charge a card on file?
                  <CardList
                    selectedClient={data?.client}
                    selectedCard={data?.selectedCard}
                    cards={cards}
                    removePaymentMethod={removePaymentMethod}
                    allowPay={true}
                    allowDelete={false}
                    handleChargeCard={handleChargeCard}
                    paymentSuccess={paymentSuccess}
                    allowRemovePaymentMethodForShoot={true}
                    removePaymentMethodForShoot={() =>
                      removePaymentMethodForShoot(data?._id, data?.shoot?._id)
                    }
                  />
                </Col>
              )}
            <Col span={24}>
              <h3 style={{ marginTop: "20px" }}>
                Total Due:{" "}
                <NumberFormat
                  value={data.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </h3>
            </Col>
            <Col span={24}>
              <button
                ref={patBtnRef}
                className="pay-button"
                type="submit"
                disabled={isSubmitting || paymentSuccess}
                style={{ marginTop: "10px" }}
              >
                {isSubmitting ? <Spin indicator={antIcon} /> : "Pay"}
              </button>
              <Button onClick={() => hideModal()}>Close</Button>
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
};

const StripePayment = ({
  data,
  hideModal,
  updateInvoice,
  updateShoot,
  createActivity,
  auth: { user },
  client: { paymentMethods },
  emailPaymentSuccess,
  savePaymentMethod,
  fetchPaymentMethods,
  removePaymentMethod,
  createPayment,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        data={data}
        hideModal={hideModal}
        updateInvoice={updateInvoice}
        updateShoot={updateShoot}
        user={user}
        createActivity={createActivity}
        emailPaymentSuccess={emailPaymentSuccess}
        savePaymentMethod={savePaymentMethod}
        removePaymentMethod={removePaymentMethod}
        fetchPaymentMethods={fetchPaymentMethods}
        cards={paymentMethods}
        createPayment={createPayment}
      />
    </Elements>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    client: state.clients,
  };
};

export default connect(mapStateToProps, {
  hideModal,
  updateInvoice,
  updateShoot,
  createActivity,
  savePaymentMethod,
  removePaymentMethod,
  fetchPaymentMethods,
})(StripePayment);
