import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_TABLEVIEW,
  FETCH_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
} from "../action/types";

const initialState = { templates: [], selectedTemplate: {}, isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TEMPLATES:
      return {
        ...state,
        isLoading: false,
        templates: payload,
      };
    case FETCH_TEMPLATES_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        templates: payload.templates || [],
        totalCount: payload.count,
      };
    case FETCH_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        selectedTemplate: payload,
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        templates: [...state.templates, payload],
        selectedTemplate: payload,
      };
    case UPDATE_TEMPLATE:
      const updatedTemplates = state.templates.concat();
      updatedTemplates.map((template, index) => {
        if (template._id === payload._id) {
          updatedTemplates[index] = payload;
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        templates: updatedTemplates,
        selectedTemplate: payload,
      };
    case REMOVE_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        templates: state.templates.filter(
          (template) => template._id !== payload
        ),
      };
    default:
      return state;
  }
}
