import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ability from "../../../user/Component/Auth/ability";
import { fetchSubdivisionById } from "../../../redux/action/subdivisionAction";
import {
  fetchAmenityBySubdivisionId,
  fetchAmenityById,
} from "../../../redux/action/amenityAction";
import { useHistory } from "react-router-dom";
import {
  createAmenity,
  updateAmenity,
} from "../../../redux/action/amenityAction";
import {
  Row,
  Col,
  Button,
  Modal,
  Input,
  message,
  Card,
  Typography,
  Upload,
  Popconfirm,
} from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { SRLWrapper } from "simple-react-lightbox";
import Axios from "axios";
import { AddressModal } from "../../../user/Component/ShootForm/Address/AddressModal";
import Paragraph from "antd/lib/skeleton/Paragraph";
import CopyToClipboard from "react-copy-to-clipboard";

const AddEditAmenity = ({
  createAmenity,
  match,
  updateAmenity,
  fetchAmenityById,
  fetchSubdivisionById,
  selectedSubdivision,
  location,
  amenity,
}) => {
  const [validated, setValidated] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [state, setState] = useState({
    existingPhotos: [],
    newPhotos: [],
    pictureWallLoader: false,
    visible: false,
  });
  const history = useHistory();
  const { existingPhotos, newPhotos } = state;
  const { Dragger } = Upload;
  const [initialCoordinate, setInitialCoordinate] = useState({});
  const [googleAddress, setGoogleAddress] = useState({
    address: {
      street: "",
      city: "",
      zipcode: "",
      apt: "",
    },
    uniqueAddressId: null,
    addressData: {},
    addressFetched: false,
    googleAddress: null,
    isGoogleAddressValidated: false,
    contentType: "paragraph",
    messageText:
      'If the address we found is correct, click "Confirm" below. Otherwise you can edit your entry with the "Edit" button, and then drag the pin to the correct location on the map.',
    lat: undefined,
    lng: undefined,
  });
  const [formData, setFormData] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    lat: "",
    lng: "",
  });
  const draggerProps = {
    name: "file",
    multiple: true,
    onChange(info) {
      handleAmenityChange(info);
    },
    showUploadList: false,
    fileList: selectedFiles,
  };

  useEffect(() => {
    const fetch = async () => {
      let divisionID;
      if (match?.params?.subdivisionId) {
        divisionID = match.params.subdivisionId;
        fetchSubdivisionById(divisionID);
      }
    };
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const arr = [];
    if (amenity?.photos?.images.length > 0) {
      amenity.photos.images.forEach((url, index) => {
        if (url) {
          const altFileName = url.replace(/^(?:[^_]*_){3}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url?.split(".").pop();
          let filename = url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          const obj = {};
          const len = url?.split("/").length;
          obj.name = url?.split("/")[len - 1].split("_")[1];
          obj.url = url;
          obj.thumbUrl = url.replace("/high/", "/thumb/");
          obj.uid = index;
          obj._id = index + 1;
          obj.order = index + 1;
          obj.filename = filename;
          arr.push(obj);
        }
      });
    }
    setState({ ...state, existingPhotos: arr });
    // if (amenityLoader) {
    //   setAmenityLoader(false);
    // }
  }, [amenity]); // eslint-disable-line react-hooks/exhaustive-deps

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleAmenityChange = async ({ fileList }) => {
    const photos = [];
    setSelectedFiles([...fileList]);
    for (let index = 0; index < fileList.length; index++) {
      const base64Pic = await getBase64(fileList[index].originFileObj);
      photos.push({
        filename: fileList[index].name,
        name: "",
        order: existingPhotos.length + index + 1,
        uid: existingPhotos.length + index + 1,
        url: base64Pic,
        thumbUrl: base64Pic,
        _id: existingPhotos.length + index + 1,
      });
    }
    setState({ ...state, newPhotos: photos });
  };

  useEffect(() => {
    const amenityId = new URLSearchParams(location.search).get("amenityId");
    if (amenityId && amenityId != "new") {
      fetchAmenityById(amenityId);
      setMode("edit");
    }
    if (amenityId === "new") {
      setMode("create");
    }
  }, []);

  useEffect(() => {
    const fetchInitialCoordinates = async () => {
      if (selectedSubdivision) {
        Axios.get(
          `${process.env.REACT_APP_ROOT_URL}/shoots/address/${selectedSubdivision.city}-${selectedSubdivision.state}-${selectedSubdivision.zip}`
        )
          .then((res) => {
            setInitialCoordinate({
              lat: res.data.latitude,
              lng: res.data.longitude,
            });
          })
          .catch((err) => console.log("error at fetching the Coordinates"));
      }
    };
    fetchInitialCoordinates();
  }, [selectedSubdivision]);

  useEffect(() => {
    if (amenity) {
      setFormData({
        ...formData,
        name: amenity.name,
        street: amenity.street,
        city: amenity.city,
        state: amenity.state,
        zip: amenity.zip,
        lat: amenity.geometry.coordinates[0],
        lng: amenity.geometry.coordinates[1],
      });
      setGoogleAddress({
        ...googleAddress,
        address: {
          ...formData,
          name: amenity?.name,
          street: amenity?.street,
          city: amenity?.city,
          state: amenity?.state,
          zip: amenity?.zip,
        },
        lat: amenity?.geometry?.coordinates[0] || initialCoordinate?.lat,
        lng: amenity?.geometry?.coordinates[1] || initialCoordinate?.lng,
      });
    }
  }, [amenity]);

  useEffect(() => {
    if (initialCoordinate.lat) {
      setGoogleAddress({
        ...googleAddress,
        lat: amenity?.geometry?.coordinates[0] || initialCoordinate?.lat,
        lng: amenity?.geometry?.coordinates[1] || initialCoordinate?.lng,
      });
    }
  }, [initialCoordinate]);
  const [mode, setMode] = useState();
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const geoSubmitHandler = () => {
    if (
      formData.name &&
      formData.street &&
      formData.city &&
      formData.state &&
      formData.zip &&
      (validated || mode === "edit")
    ) {
      Modal.confirm({
        getContainer: () => document.querySelector("#root"),
        title: "Confirm Address",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to proceed with the address?",
        okText: "Proceed",
        cancelText: "Cancel",
        onOk: async () => {
          await onSubmit();
        },
      });
    } else {
      if (!formData.name) {
        message.error("Please enter amenity name to proceed");
      }
      if (!formData.street) {
        message.error("Please enter street to proceed");
      }
      if (!formData.city) {
        message.error("Please enter city to proceed");
      }
      if (!formData.state) {
        message.error("Please enter state to proceed");
      }
      if (!formData.zip) {
        message.error("Please enter zipcode to proceed");
      }
      if (!validated) {
        message.error("Please mark the location in map");
      }
    }
  };

  const confirmationText =
    "Please confirm and re-enter the address details and then drag the red pin on the map to the correct location of the property.";

  const options = {
    settings: {
      overlayColor: "#333333",
      autoplaySpeed: 3000,
      transitionSpeed: 1200,
    },
    buttons: {
      backgroundColor: "#2f80ed",
      iconColor: "#FFFFFF",
      showDownloadButton: false,
    },
  };

  const onSubmit = async () => {
    if (
      formData.name &&
      formData.street &&
      formData.city &&
      formData.state &&
      formData.zip
    ) {
      let photosArr = [];
      photosArr = existingPhotos.map((image) => image.url);
      try {
        if (newPhotos.length) {
          let data = new FormData();
          data.append("path", `amenities/photos/high/amentity_`);
          for (var x = 0; x < selectedFiles.length; x++) {
            const file = selectedFiles[x].originFileObj;
            data.append("file", file);
          }
          const upload = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/upload`,
            data
          );
          if (upload.status === 200) {
            setSelectedFiles([]);
            setState({ ...state, newPhotos: [] });
            photosArr = photosArr.concat(upload.data.urls);
          } else {
            throw new Error("Error while uploading the photos");
          }
        }

        const body = {
          name: formData.name,
          street: formData.street,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          photos: {
            images: photosArr,
          },
          subdivision: selectedSubdivision._id,
          geometry: {
            type: "Point",
            coordinates: [googleAddress.lat, googleAddress.lng],
          },
        };
        let res;
        if (mode === "create") {
          res = await createAmenity(body);

          if (res) {
            message.success("Amenity created Successfully!");
            setMode("edit");
            history.replace({
              pathname: `/admin/amenity/${selectedSubdivision._id}`,
              search: `?amenityId=${res?.data?._id}`,
            });
          }
        } else {
          res = await updateAmenity(amenity._id, body);
          if (res) {
            message.success("Amenity updated Successfully!");
          }
        }

        history.push(`/admin/division/${selectedSubdivision._id}`);
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  const handleChangeDelete = async ({ url }) => {
    try {
      const updatedAmenity = existingPhotos.filter((obj) => obj.url !== url);
      setState({ ...state, existingPhotos: updatedAmenity });
    } catch (err) {
      message.error(err);
    }
  };

  const handleNewPhotosDelete = async (image) => {
    const filteredData = selectedFiles.filter(
      (file) => file.uid !== image.originFileObj.uid
    );
    setSelectedFiles(filteredData);
    try {
      const updatedAmenity = newPhotos.filter((obj) => obj.url !== image.url);
      setState({ ...state, newPhotos: updatedAmenity });
    } catch (err) {
      message.error(err);
    }
  };

  const fetchLatAndLan = () => {
    let fullAddress;
    fullAddress = formData.street + " " + formData.city + " " + formData.zip;
    const add = encodeURI(fullAddress);
    let geocode;
    Axios.get(`${process.env.REACT_APP_ROOT_URL}/shoots/address/${add}`)
      .then((res) => {
        let isGoogleAddressValidated = googleAddress.isGoogleAddressValidated;
        let messageText = googleAddress.messageText;
        geocode = res.data;
        const foundStreet = `${geocode?.streetNumber} ${geocode?.streetName}`;
        const foundState = geocode?.stateCode ? geocode?.stateCode : "TX";
        const addressId = geocode?.address_id;

        const address = {
          drive_time: geocode?.drive_time,
          street: foundStreet,
          street_lower_no_spaces: addressId
            ? addressId
            : foundStreet?.toLowerCase().replace(/ /g, ""),
          full: geocode?.formattedAddress,
          city_state_zip: `${geocode.city}, ${foundState} ${geocode.zipcode}`,
          city: geocode?.city,
          state: foundState, // TODO: Remove when we move outside of TX
          zip: geocode?.zipcode,
          geometry: {
            type: "Point",
            coordinates: [geocode.longitude, geocode.latitude],
          },
        };
        if (!address.street || !address.city) {
          isGoogleAddressValidated = false;
          messageText =
            'Oops! we found some problem with the address. Kindly edit it with "Edit" button';
        } else {
          isGoogleAddressValidated = true;
          messageText =
            'If the address we found is correct, click "Confirm" below. Otherwise you can edit your entry with the "Edit" button, and then drag the pin to the correct location on the map.';
        }
        setGoogleAddress({
          ...googleAddress,
          lat: res.data.latitude,
          lng: res.data.longitude,
          uniqueAddressId: geocode._id,
          googleAddress: geocode.formattedAddress,
          isGoogleAddressValidated,
          messageText,
        });
      })
      .catch((err) => console.log("error at fetching the Coordinates"));
  };

  const googleFetch = async (pos) => {
    setValidated(true);
    let address = {};
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_ROOT_URL}/shoots/address/reversegeocode?lat=${pos.lat}&lng=${pos.lng}`
      ).then(({ data: geocode }) => {
        setGoogleAddress({
          ...googleAddress,
          lat: pos.lat,
          lng: pos.lng,
        });
        const foundState = geocode[0]?.stateCode ? geocode[0]?.stateCode : "TX";
        address = {
          name: formData.name,
          street: geocode[0]?.streetName,
          city: geocode[0]?.city,
          state: foundState, // TODO: Remove when we move outside of TX
          zip: geocode[0]?.zipcode,
          lat: geocode[0]?.latitude,
          lng: geocode[0]?.longitude,
        };
        setFormData({ ...address });
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Card>
        <Row>
          <Col span={24} style={{ padding: "20px" }}>
            <h3>{mode === "create" ? "Add Amenity" : "Edit Amenity"}</h3>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                {googleAddress.lat && googleAddress.lng && (
                  <AddressModal
                    lat={googleAddress.lat}
                    lng={googleAddress.lng}
                    defaultZoom={16}
                    defaultMapId={"hybrid"}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div style={{ height: `500px`, width: `100%` }} />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                    draggable={true}
                    updateLocation={(pos) => {
                      googleFetch(pos);
                    }}
                  />
                )}
                <p className="text-muted">
                  Drag the marker to the location of the shoot
                </p>
              </Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="form-group">
                      <label>Amenity Name</label>
                      <Input
                        value={formData.name}
                        name="name"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="form-group">
                      <label>Street</label>
                      <Input
                        value={formData.street}
                        name="street"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="form-group">
                      <label>City</label>
                      <Input
                        value={formData.city}
                        name="city"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="form-group">
                      <label>State </label>
                      <Input
                        value={formData.state}
                        name="state"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="form-group">
                      <label>Zip code </label>
                      <Input
                        onBlur={fetchLatAndLan}
                        value={formData.zip}
                        name="zip"
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="form-group">
                      <label>Latitude</label>
                      <Input
                        value={formData.lat}
                        name="lat"
                        onChange={(e) => onChange(e)}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="form-group">
                      <label>Longitude</label>
                      <Input
                        value={formData.lng}
                        name="lng"
                        onChange={(e) => onChange(e)}
                        disabled={true}
                      />
                    </div>
                  </Col>
                  <Col span={4}>
                    <CopyToClipboard
                      text={`lat: ${formData.lat}, lng: ${formData.lng}`}
                    >
                      <Button
                        style={{ marginTop: 33 }}
                        onClick={() => {
                          message.success("Copied to clipboard");
                        }}
                      >
                        <CopyOutlined />
                      </Button>
                    </CopyToClipboard>
                  </Col>
                  <Col span={24} className="addressCnfMsg">
                    <Paragraph ellipsis={{ rows: 5, expandable: true }}>
                      {confirmationText}
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Card
          title={<Typography.Title level={4}>Amenity Photos</Typography.Title>}
        >
          <SRLWrapper options={options}>
            <Row gutter={[8, 8]} className="p-2">
              {existingPhotos &&
                existingPhotos.map((image) => (
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} key={image.url}>
                    <div className="showImage">
                      <a href={image.url} data-attribute="SRL">
                        <img
                          src={image.url}
                          width="100%"
                          alt={image.filename}
                        />
                      </a>
                    </div>
                    <div>
                      {ability.can("delete", "Photos") && (
                        <>
                          <span>{image.filename}</span>
                          <span className="close-btn">
                            <Popconfirm
                              title={`Are you sure you want to delete this image`}
                              onConfirm={() => handleChangeDelete(image)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </span>
                        </>
                      )}
                    </div>
                  </Col>
                ))}

              {newPhotos &&
                newPhotos.map((image) => (
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} key={image.url}>
                    <div className="showImage">
                      <a href={image.url} data-attribute="SRL">
                        <img
                          src={image.thumbUrl}
                          width="100%"
                          alt={image.filename}
                        />
                      </a>
                    </div>
                    <div>
                      {ability.can("delete", "Photos") && (
                        <>
                          <span>{image.filename}</span>
                          <span className="close-btn">
                            <Popconfirm
                              title={`Are you sure you want to delete this image`}
                              onConfirm={() => handleNewPhotosDelete(image)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </span>
                        </>
                      )}
                    </div>
                  </Col>
                ))}
            </Row>
          </SRLWrapper>
          <Row justify="center">
            <Col span={9} style={{ padding: "20px" }}>
              <Dragger {...draggerProps} className="dragUpload">
                <p
                  style={{ textAlign: "center" }}
                  className="ant-upload-drag-icon"
                >
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </Col>
          </Row>
        </Card>
        <Col span={8} offset={8}>
          <Button type="primary" block onClick={geoSubmitHandler}>
            Save
          </Button>
        </Col>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Subdivision: state.subdivision,
    amenities: state.amenities.amenities,
    amenity: state.amenities.selectedAmenity,
    selectedSubdivision: state.subdivision.selectedSubdivision,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  createAmenity,
  updateAmenity,
  fetchSubdivisionById,
  fetchAmenityById,
  fetchAmenityBySubdivisionId,
})(AddEditAmenity);
