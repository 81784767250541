import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Statistic,
  Button,
  Select,
  message,
  Switch,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import Chart from "./Chart";

const { Option } = Select;

const Overview = ({
  updateClient,
  stats,
  selectedClient,
  updatedClient,
  setUpdatedClient,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [updatedTerms, setUpdatedTerms] = useState(
    selectedClient?.preferences?.billing?.terms
  );
  const [hasFullAccess, setHasFullAccess] = useState(
    selectedClient?.preferences?.media?.full_access
  );

  const [showNameOnInvoice, setShowNameOnInvoice] = useState(
    selectedClient?.preferences?.billing?.show_name_on_invoice
  );

  useEffect(() => {
    setShowNameOnInvoice(
      selectedClient?.preferences?.billing?.show_name_on_invoice
    );
    setHasFullAccess(selectedClient?.preferences?.media?.full_access);
    setUpdatedTerms(selectedClient?.preferences?.billing?.terms);
  }, [selectedClient]);

  const handleSwitchChange = async (checked) => {
    setHasFullAccess(checked);
    const clientId = updatedClient ? updatedClient._id : selectedClient?._id;
    const updatedData = {
      ...selectedClient,
      preferences: { media: { full_access: checked } },
    };
    const res = await updateClient(clientId, updatedData);
    if (!res.error) {
      message.success("Client updated successfully");
      console.log(res.data?.client?.preferences?.media?.full_access);
      setUpdatedTerms(res.data?.client?.preferences?.media?.full_access);
      setUpdatedClient(res.data?.client);
      setEditMode(false);
    } else {
      message.error("Something went wrong");
      setEditMode(false);
    }
  };

  const handleShowNameOnInvoice = async (checked) => {
    setShowNameOnInvoice(checked);
    const clientId = updatedClient ? updatedClient._id : selectedClient?._id;
    const updatedData = {
      ...selectedClient,
      preferences: { billing: { show_name_on_invoice: checked } },
    };
    const res = await updateClient(clientId, updatedData);
    if (!res.error) {
      message.success("Client updated successfully");
      console.log(res.data?.client?.preferences?.billing?.show_name_on_invoice);
      setUpdatedTerms(
        res.data?.client?.preferences?.billing?.show_name_on_invoice
      );
      setUpdatedClient(res.data?.client);
      setEditMode(false);
    } else {
      message.error("Something went wrong");
      setEditMode(false);
    }
  };

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    const clientId = updatedClient ? updatedClient._id : selectedClient?._id;
    const updatedData = {
      ...selectedClient,
      preferences: { billing: { terms: updatedTerms } },
    };
    const res = await updateClient(clientId, updatedData);
    if (!res.error) {
      message.success("Client updated successfully");
      console.log(res.data?.client?.preferences?.billing?.terms);
      setUpdatedTerms(res.data?.client?.preferences?.billing?.terms);
      setUpdatedClient(res.data?.client);
      setEditMode(false);
    } else {
      message.error("Something went wrong");
      setEditMode(false);
    }
  };

  return (
    <div>
      <Card>
        <Row>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Statistic
              title="Available Credit"
              prefix="$"
              value={stats?.credits?.amount.toFixed(2)}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Statistic
              title="Unpaid Invoices"
              value={stats?.invoices?.unpaidCount}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Statistic
              title="Lifetime Spend"
              prefix="$"
              value={stats?.payments?.amount.toFixed(2)}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Statistic
              title="Avg. Spend per Shoot"
              prefix="$"
              value={
                stats?.invoices?.avgSpend !== "NaN"
                  ? stats?.invoices?.avgSpend
                  : 0
              }
            />
          </Col>
        </Row>
      </Card>
      <Card className="mt-2">
        <Row>
          <Col span={12}>
            <h4>
              Payment Terms:{" "}
              <span style={{ fontWeight: "normal" }}>
                {!editMode ? (
                  updatedTerms
                ) : (
                  <Select
                    defaultValue={updatedTerms}
                    style={{ width: 120 }}
                    onChange={(value) => setUpdatedTerms(value)}
                  >
                    <Option value="Standard">Standard</Option>
                    <Option value="Deferred">Deferred</Option>
                  </Select>
                )}
              </span>
              <span className="ml-2">
                {!editMode ? (
                  <Button type="link" size="small" onClick={handleEdit}>
                    <EditOutlined /> Edit
                  </Button>
                ) : (
                  <Button type="primary" size="small" onClick={handleSave}>
                    Save
                  </Button>
                )}
              </span>
            </h4>
          </Col>
          <Col span={12}>
            <h4>
              Allow access past 2 years:{" "}
              <span style={{ fontWeight: "normal", marginRight: 10 }}>
                {hasFullAccess ? "Yes" : "No"}
              </span>
              <Switch
                checked={hasFullAccess}
                onChange={(checked) => handleSwitchChange(checked)}
              />
            </h4>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <h4>
              Show name on invoice:{" "}
              <span style={{ fontWeight: "normal", marginRight: 10 }}>
                {showNameOnInvoice ? "Yes" : "No"}
              </span>
              <Switch
                checked={showNameOnInvoice}
                onChange={(checked) => handleShowNameOnInvoice(checked)}
              />
            </h4>
          </Col>
        </Row>
      </Card>
      <Card className="mt-2">
        <Row>
          <Col span={24}>
            <h4 className="text-center">{`${
              updatedClient ? updatedClient?.first : selectedClient?.first
            }'s Services`}</h4>
          </Col>
          <Col span={24}>
            <Chart chartData={stats?.services?.chartData} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Overview;
