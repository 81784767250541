import React, { useState, useRef } from "react";
import moment from "moment";
import {
  Collapse,
  Row,
  Col,
  Tag,
  List,
  Modal,
  message,
  Button,
  Popover,
  Typography,
  Input,
  Alert,
} from "antd";
import {
  CheckOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import InvoiceModal from "../../Invoices/InvoiceModal";
import { diff } from "deep-object-diff";
import {
  photographersPayCalc,
  photoCodes,
} from "../../../../shared/payBreakdown/photographersPayCalc";
import { calculateTax } from "../../../../shared/utils/utils";
import ability from "../../../../user/Component/Auth/ability";
import { fetchAmenityByIds } from "../../../../redux/action/amenityAction";
import { PreviewMap } from "../../Order/Common/PreviewMap";

const { Panel } = Collapse;
const InvoiceList = ({
  auth,
  openModal,
  invoices,
  selectedShoot,
  invoice,
  offerings,
  updateInvoice,
  createCredit,
  updateShoot,
  createInvoice,
  createActivity,
  photographer,
  manualFetch,
  fetchInvoiceById,
}) => {
  const myRef = useRef();
  const [state, setState] = useState({
    visible: false,
    mode: "add",
    creditModal: false,
    modalTitle: null,
  });
  const [modalVisible, setModalVisible] = useState(false);
  // const [data, setData] = useState({});
  const handleEdit = async (invoice) => {
    await fetchInvoiceById(invoice._id);
    setModalVisible(true);
  };
  const handleView = async (invoice) => {
    await fetchInvoiceById(invoice._id);
    openModal();
  };
  const [updAmenity, setUpdAmenity] = useState();
  const [updSubdivision, setUpdSubdivision] = useState();
  const { creditModal } = state;
  let diffAmount = 0;
  const [creditReason, setCreditReason] = useState("");
  const [showReasonAlert, setShowReasonAlert] = useState(false);
  const [invoiceAmenityDetails, setInvoiceAmenityDetails] = useState([]);
  const [invoiceAmenityVisible, setInvoiceAmenityVisible] = useState(false);
  const updAmen = (value) => {
    setUpdAmenity(value);
  };
  const updSub = (value) => {
    setUpdSubdivision(value);
  };
  const handleOk = async () => {
    var updatedInvoice = {};
    var total_pay = 0.0;
    const modalState = myRef.current.getModalState();
    const { currentOfferings } = modalState;
    var checkForChanges = false;
    let text = "Invoice Edit. ";
    //activity
    const prevOfferings = invoice.products;
    const deletedOff = [];
    if (prevOfferings.length != currentOfferings.length) {
      prevOfferings.forEach((prevOff) => {
        const deleted = currentOfferings.find(
          (prodOff) => prodOff._id === prevOff._id
        );
        if (deleted === undefined) {
          deletedOff.push({ ...prevOff });
        }
      });
    }

    const newOff = [];
    const { updatedOfferings, newOfferingsIds } = modalState;

    updatedOfferings.map((off) => {
      newOfferingsIds.map((offId) => {
        if (off._id === offId) {
          newOff.push({ ...off });
        }
      });
    });
    let deletedOffText = "These services: ";
    deletedOff.map((off, index) => {
      deletedOffText += off.description;
      if (deletedOff.length - 1 !== index) {
        deletedOffText += ", ";
      }
    });

    let newOffText = "These services: ";
    newOff.map((off, index) => {
      newOffText += off.description;
      if (newOff.length - 1 !== index) {
        newOffText += ", ";
      }
    });

    if (deletedOff.length) {
      text += deletedOffText + " were deleted. ";
    }

    if (newOff.length) {
      text += newOffText + " were added.";
    }

    if (deletedOff.length === 0 && newOff.length === 0) {
      text = null;
    }

    if (modalState.newOfferingsIds) {
      for (let i = 0; i < modalState.newOfferingsIds.length; i++) {
        for (let j = 0; j < modalState.updatedOfferings.length; j++) {
          if (
            modalState.updatedOfferings[j]._id === modalState.newOfferingsIds[i]
          ) {
            currentOfferings.push(modalState.updatedOfferings[j]);
            break;
          }
        }
      }
    }
    currentOfferings.forEach((prod) => {
      if (prod.code === "SP" || prod.code === "MSP" || prod.code === "CSP") {
        if (updAmenity) {
          prod.amenities = [...updAmenity];
        }
      }
      if (photoCodes?.includes(prod.code) && photographer?.pay_multiplier) {
        const pay = photographersPayCalc(
          prod?.code,
          photographer?.pay_multiplier,
          selectedShoot?.booking_form?.sqft,
          selectedShoot?.trip_charge,
          selectedShoot?.events?.start,
          selectedShoot?.events?.end,
          selectedShoot?.photographer_drive_time,
          prod.amenities
        );
        total_pay += pay;
        prod.photographer_pay = pay;
      }
    });

    currentOfferings.forEach((currOff) => {
      const prevOff = invoice[0]
        ? invoice[0].products.filter((prodOff) => prodOff._id === currOff._id)
        : invoice.products.filter((prodOff) => prodOff._id === currOff._id);
      const res = diff(prevOff, currOff);
      if (Object.keys(res).length) {
        checkForChanges = true;
      }
    });
    var res;
    if (checkForChanges) {
      const tax = calculateTax(modalState.subtotal);
      const subtotalDiff = modalState.subtotal - invoice.subtotal;
      const newTax = calculateTax(subtotalDiff);
      const total =
        invoice.balance === invoice.total
          ? invoice.subtotal + subtotalDiff + tax
          : invoice.total + subtotalDiff + newTax;
      let balance = parseFloat(
        (invoice.balance + (total - invoice.total)).toFixed(2)
      );
      if (balance < 0.0) {
        diffAmount = balance * -1;
        balance = 0;
      }
      if (diffAmount > 0 && !creditModal) {
        setState({ ...state, creditModal: true });
        return;
      }
      updatedInvoice = { ...invoice };
      updatedInvoice.sent = false;
      updatedInvoice.products = currentOfferings;
      updatedInvoice.subtotal = modalState.subtotal;
      updatedInvoice.total = total.toFixed(2);
      updatedInvoice.balance = balance.toFixed(2);
      updatedInvoice.tax = tax;
      updatedInvoice.paid = balance > 0 ? false : true;
      updatedInvoice.photographer_total_pay = total_pay;
      updatedInvoice.shoot.photos.turnaround = "";
      updatedInvoice.shoot.photos.ie_package = "";
      updatedInvoice.shoot.video.package = "";
      updatedInvoice.shoot.video.turnaround = "";
      // updatedInvoice.shoot.amenities = updAmenity
      //   ? updAmenity
      //   : updatedInvoice.shoot.amenities;
      updatedInvoice.shoot.subdivision = updSubdivision
        ? updSubdivision
        : updatedInvoice.shoot.subdivision;
      updatedInvoice.shoot.reqs = updatedInvoice.products.map(
        (off) => off.code
      );
      var currDuration = 0;
      var newPhtoTnd = " : ";
      var newVdTnd = " : ";
      var newReqArr = [];
      var newReqs = "";
      let isDeluxe = false;

      currentOfferings.forEach((off) => {
        if (off.code === "DLX" || off.code === "ADLX") {
          isDeluxe = true;
        }
        updatedInvoice.shoot.booking_form.is_deluxe = isDeluxe;

        currDuration = currDuration + (!off.duration ? 0 : off.duration);
        if (off.category.includes("Photo Turnaround")) {
          newPhtoTnd = newPhtoTnd + off.description + " ";
          updatedInvoice.shoot.photos.turnaround = off.description;
        }
        if (off.category.includes("Video Turnaround")) {
          newVdTnd = newVdTnd + off.description + " ";
          updatedInvoice.shoot.video.turnaround = off.description;
        }
        if (off.category.includes("Drone Photos and HD Video")) {
          updatedInvoice.shoot.video.package = off.description;
        }
        if (off.category.includes("Interior/Exterior Photos")) {
          updatedInvoice.shoot.photos.ie_package = off.description;
        }
        newReqArr.push(off.description);
      });
      if (newReqArr.length > 1) {
        newReqs = " : " + newReqArr.toString().replace(/,/g, ", ") + " ";
      } else {
        newReqs = " : " + newReqArr.toString() + " ";
      }
      updatedInvoice.shoot.events.duration = currDuration;
      updatedInvoice.shoot.invoice = {
        _id: updatedInvoice._id,
        products: [...currentOfferings],
        duration: currDuration,
      };
      let currDescrption = updatedInvoice?.shoot?.events?.description;
      const desc1 = currDescrption?.replace(
        currDescrption
          ?.split("Photo Turnaround")[1]
          ?.split("Video Turnaround")[0],
        newPhtoTnd
      );
      const desc2 = desc1?.replace(
        currDescrption.split("Video Turnaround")[1]?.split("Requirements")[0],
        newVdTnd
      );
      const newDescription = desc2?.replace(
        currDescrption.split("Requirements")[1]?.split("Focus on")[0],
        newReqs
      );
      updatedInvoice.shoot.events.duration = currDuration;
      updatedInvoice.shoot.events.description = newDescription;
      const updatedShoot = { ...updatedInvoice.shoot };
      updatedInvoice.shoot = updatedShoot._id;
      const id = updatedShoot["_id"];
      delete updatedShoot["_id"];
      if (diffAmount > 0 && creditModal) {
        const [invoiceRes, shootRes, creditRes] = await Promise.all([
          updateInvoice(updatedInvoice._id, updatedInvoice),
          updateShoot(id, updatedShoot),
          createCredit({
            valid: true,
            redeemed: false,
            client: updatedInvoice.client, // Add client
            amount: diffAmount,
            reason: creditReason, // Add the value of referred_by
            code: "REFUND",
            credit_type: "Refund Credit",
          }),
        ]);
        if (creditRes.data) {
          message.success("The balance has been transferred to a credit.");
        } else {
          message.error("Oops! Balance transfer failed");
        }
        if ((invoiceRes?.data && shootRes?.data) || invoiceRes?.data) {
          res = invoiceRes;
          if (text !== null) {
            createActivity({ _id: id }, text);
          }
          message.success("Package updated successfully!");
          manualFetch();
        } else {
          message.error("Oops! Updating process failed!");
        }
        setState({ ...state, creditModal: false });
      } else {
        const [invoiceRes, shootRes] = await Promise.all([
          updateInvoice(updatedInvoice._id, updatedInvoice),
          updateShoot(id, updatedShoot),
        ]);
        if (
          (invoiceRes?.data && shootRes?.data) ||
          invoiceRes?.data ||
          shootRes?.data
        ) {
          res = shootRes;
          if (text !== null) {
            createActivity({ _id: id }, text);
          }
          message.success("Package updated successfully!");
          manualFetch();
        } else {
          message.error("Oops! Updating process failed!");
        }
      }
    } else {
      if (!modalState.shootId) {
        message.error("Please select Shoot to create invoice.");
        return;
      } else if (!modalState.newOfferingsIds.length) {
        message.error("No content to create invoice.");
        return;
      }
      res = await createInvoice(updatedInvoice);
      if (res.data) {
        updateShoot(modalState.shootId, {
          invoice: { _id: res.data._id },
        });
        message.success("Package updated successfully!");
        manualFetch();
      } else {
        message.error("Oops! Updating process failed!");
      }
    }
    setState({ ...state, visible: false, creditModal: false });
    setModalVisible(false);
  };
  const handleCreditCancel = (e) => {
    setState({ ...state, creditModal: false });
  };

  const handleCancel = (e) => {
    setModalVisible(false);
    setUpdAmenity(undefined);
    setUpdSubdivision(undefined);
    setState({ ...state, invoiceModal: false });
  };

  const viewAmenityDetails = async (item) => {
    if (item.amenities.length) {
      const amenities = await fetchAmenityByIds(item.amenities);
      setInvoiceAmenityDetails([...amenities.data]);
    } else {
      setInvoiceAmenityDetails([]);
    }
    setInvoiceAmenityVisible(true);
  };

  const renderHeader = (inv) => {
    return (
      <>
        <Row justify="space-between" style={{ fontSize: "14px" }}>
          <Col>{`$${inv.balance.toFixed(2)}`}</Col>
          <Col>
            {inv.reschedule && (
              <Tag color="pink" className="float-right">
                Rescheduled
              </Tag>
            )}{" "}
            {inv?.canceled ? (
              <Tag className="float-right">Cancelled</Tag>
            ) : (
              <Tag color={inv.paid ? "green" : "red"} className="float-right">
                {inv.paid ? "Paid" : "Unpaid"}
              </Tag>
            )}
            {inv?.refunded && <Tag color="purple">Refunded</Tag>}
          </Col>
          <Col>
            <Button
              onClick={() => {
                handleView(inv);
              }}
              className="float-right"
              style={{ color: "#1890ff" }}
              size="small"
            >
              View Invoice
            </Button>
          </Col>
          {ability.can("edit", "Invoices") && (
            <Col>
              <Button
                loading={inv.loading}
                type="link"
                size="small"
                onClick={() => {
                  handleEdit(inv);
                }}
              >
                <EditOutlined /> Edit
              </Button>
            </Col>
          )}
        </Row>
      </>
    );
  };
  return (
    <>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          style={{ top: 20 }}
        >
          <InvoiceModal
            auth={auth}
            mode="edit"
            ref={myRef}
            invoice={invoice}
            offerings={offerings}
            updAmenity={updAmenity}
            updSubdivision={updSubdivision}
            updShoot={updateShoot}
            updAmen={updAmen}
            updSub={updSub}
          />
        </Modal>
      )}
      {invoiceAmenityVisible && (
        <Modal
          title="Amenity Details"
          visible={invoiceAmenityVisible}
          onOk={() => setInvoiceAmenityVisible(false)}
          onCancel={() => setInvoiceAmenityVisible(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          destroyOnClose={true}
          width={800}
          style={{ top: 20 }}
        >
          {invoiceAmenityDetails.length > 0 ? (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <h4>
                    Subdivision:{" "}
                    <a
                      href={`${process.env.REACT_APP_DOMAIN_URL}/admin/division/${selectedShoot?.subdivision?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedShoot?.subdivision?.name}
                    </a>
                  </h4>
                </Col>
                <Col span={8}>
                  <span style={{ fontWeight: "bold" }}>Name</span>
                </Col>
                <Col span={12}>
                  <span style={{ fontWeight: "bold" }}>Address</span>
                </Col>
                <Col span={4}></Col>
              </Row>
              {invoiceAmenityDetails.map((amenity) => (
                <Row gutter={16}>
                  <Col span={8}>
                    {amenity.name}
                    <small className="ml-3 text-muted">
                      <PictureOutlined /> {amenity?.photos?.images?.length}
                    </small>
                  </Col>
                  <Col span={12}>
                    {`${amenity.street}, ${amenity.city} ${amenity.state} ${amenity.zip}`}
                    <Popover
                      placement="right"
                      content={
                        <PreviewMap
                          isMarkerShown
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                          loadingElement={
                            <div
                              style={{
                                height: "100%",
                              }}
                            />
                          }
                          containerElement={
                            <div
                              style={{
                                height: "300px",
                                width: "400px",
                              }}
                            />
                          }
                          mapElement={
                            <div
                              style={{
                                height: "300px",
                              }}
                            />
                          }
                          centerLat={amenity?.geometry?.coordinates[0]}
                          centerLng={amenity?.geometry?.coordinates[1]}
                        />
                      }
                      title={selectedShoot.address.full}
                      trigger="click"
                    >
                      <Button
                        size="small"
                        type="link"
                        className="ml-2 mr-2 mb-2"
                      >
                        <EnvironmentOutlined />
                      </Button>
                    </Popover>
                  </Col>
                  <Col span={4}>
                    <a
                      href={`${process.env.REACT_APP_DOMAIN_URL}/admin/amenity/${amenity?.subdivision}?amenityId=${amenity?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Edit
                    </a>
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            "No amenity locations specified by the client"
          )}
        </Modal>
      )}
      <Modal
        //style={{ marginTop: "150px" }}
        title={
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            Credit
          </Typography.Title>
        }
        visible={creditModal}
        onOk={() => (creditReason ? handleOk() : setShowReasonAlert(true))}
        onCancel={handleCreditCancel}
        style={{ top: 150 }}
        destroyOnClose={true}
      >
        <>
          <ExclamationCircleOutlined style={{ color: "#FFC300" }} />
          <Typography.Text
            style={{
              paddingLeft: "0.5em",
              marginBottom: 25,
            }}
          >
            Removing this service will create a client credit for the balance.
          </Typography.Text>
        </>
        {showReasonAlert && (
          <Alert
            style={{ marginTop: "30px" }}
            message="Please give a reason for the credit."
            type="error"
          />
        )}
        <div className="form-group mt-3">
          <label>Reason for the credit (required): </label>
          <Input
            style={{ marginTop: "0.5em" }}
            placeholder="Enter your reason"
            allowClear
            onChange={(event) => setCreditReason(event.target.value)}
          ></Input>
        </div>
      </Modal>
      <Collapse defaultActiveKey={["0"]}>
        {invoices && invoices.length
          ? invoices.map((inv, index) => (
              <Panel header={renderHeader(inv)} key={index}>
                <div style={{ padding: "0 25px 0 0" }}>
                  <List
                    size="small"
                    dataSource={inv.products}
                    renderItem={(item) => (
                      <>
                        {item?.code !== "FREE" &&
                          item?.code !== "RSC" &&
                          item?.code !== "UC" &&
                          item?.code !== "RFC" &&
                          item?.code !== "TC" && (
                            <List.Item>
                              <CheckOutlined
                                style={{
                                  color: "green",
                                }}
                              />{" "}
                              {item.description}
                              {(auth?.user?.access.includes("ADMIN") ||
                                auth?.user?.access.includes("COMMS")) &&
                                (item.code === "SP" ||
                                  item.code === "CSP" ||
                                  item.code === "MSP") && (
                                  <Button
                                    type="link"
                                    onClick={() => viewAmenityDetails(item)}
                                  >
                                    View Details
                                  </Button>
                                )}
                            </List.Item>
                          )}
                        {item?.code === "TC" && item?.price !== 0 && (
                          <List.Item>
                            <CheckOutlined
                              style={{
                                color: "green",
                              }}
                            />{" "}
                            {item.description}
                          </List.Item>
                        )}
                      </>
                    )}
                  />
                </div>
              </Panel>
            ))
          : selectedShoot.invoices?.invoice.map((inv, index) => (
              <Panel header={renderHeader(inv)} key={index}>
                <div style={{ padding: "0 25px 0 0" }}>
                  <List
                    size="small"
                    dataSource={inv.products}
                    renderItem={(item) => (
                      <List.Item>
                        <CheckOutlined style={{ color: "green" }} />{" "}
                        {item.description}
                      </List.Item>
                    )}
                  />
                </div>
              </Panel>
            ))}
      </Collapse>
    </>
  );
};

export default InvoiceList;
