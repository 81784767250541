import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, message, Select, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { fetchOfferings } from "../../../redux/action/offeringAction";
import { createInvoice } from "../../../redux/action/invoiceAction";
import { updateShoot } from "../../../redux/action/shootAction";
import { calculateTax } from "../../../shared/utils/utils";
import {
  photoCodes,
  photographersPayCalc,
} from "../../../shared/payBreakdown/photographersPayCalc";

const { Option } = Select;

const CreateInvoiceButton = ({
  shoot,
  fetchOfferings,
  offering: { offerings },
  createInvoice,
  updateShoot,
}) => {
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);

  useEffect(() => {
    fetchOfferings();
  }, [shoot, fetchOfferings]);

  const [state, setState] = useState({
    newOfferingsIds: [],
    shootId: shoot?._id,
    clientId: shoot?.client_id,
    shoot_type: shoot?.booking_form?.shoot_type,
    brokerageId: shoot?.brokerage_id,
    updatedOfferings: null,
    subtotal: 0,
  });
  const [selectedOfferings, setSelectedOfferings] = useState([]);

  const { newOfferingsIds, shoot_type } = state;

  useEffect(() => {
    let categorySelected = shoot_type;

    if (categorySelected === "Multi-family") {
      categorySelected = "Multifamily";
    }

    let dollHousePrice = shoot?.dollhouse?.price;
    let socialMediaPrice = shoot?.socialMediaPrice?.price;
    let sqft = shoot?.booking_form?.sqft;
    let newOfferings = [];
    offerings.map((off) => {
      if (off.category.includes(categorySelected)) {
        newOfferings.push({ ...off });
      }
    });
    const Residentialabove4kPhts = offerings?.filter(
      (off) => off.code === "4Kp"
    )[0];
    const Residentialabove4kVids = offerings?.filter(
      (off) => off.code === "4Kv"
    )[0];
    const Commercialabove4kPhts = offerings?.filter(
      (off) => off.code === "C4Kp"
    )[0];
    const Commercialabove4kVids = offerings?.filter(
      (off) => off.code === "C4Kpv"
    )[0];
    const Multifamilyabove4kPhts = offerings?.filter(
      (off) => off.code === "M4Kp"
    )[0];
    const Multifamilyabove4kVids = offerings?.filter(
      (off) => off.code === "M4Kv"
    )[0];

    newOfferings.map((off) => {
      if (
        off.category === "Residential - Interior/Exterior Photos" &&
        [
          "ie5",
          "ie8",
          "ie10",
          "ie15",
          "ie20",
          "ie25",
          "ie42",
          "ie50",
          "ie75",
          "ie100",
        ].includes(off.code) &&
        off.active &&
        categorySelected === "Residential"
      ) {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kPhts.price;
        }
      } else if (
        off.category === "Commercial - Interior/Exterior Photos" &&
        off.active &&
        categorySelected === "Commercial"
      ) {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kPhts.price;
        }
      } else if (
        off.category === "Multifamily - Interior/Exterior Photos" &&
        off.active &&
        categorySelected === "Multifamily"
      ) {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kPhts.price;
        }
      } else if (
        off.category === "Multifamily - Drone Photos" &&
        categorySelected === "Multifamily" &&
        off.active
      ) {
      } else if (off.code === "vA" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvA" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvA" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vWA" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvWA" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvWA" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vCA" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvCA" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvCA" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vAp" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvAp" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvAp" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vWAp" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvWAp" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvWAp" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vCAp" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvCAp" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvCAp" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vW" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvW" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvW" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "vC" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "CvC" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "MvC" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (off.code === "ae" && categorySelected === "Residential") {
        if (sqft && sqft >= 4000) {
          off.price += Residentialabove4kVids.price;
        }
      } else if (off.code === "Cae" && categorySelected === "Commercial") {
        if (sqft && sqft >= 4000) {
          off.price += Commercialabove4kVids.price;
        }
      } else if (off.code === "Mae" && categorySelected === "Multifamily") {
        if (sqft && sqft >= 4000) {
          off.price += Multifamilyabove4kVids.price;
        }
      } else if (
        off.code === "VR" &&
        categorySelected === "Residential" &&
        dollHousePrice
      ) {
        off.price = dollHousePrice;
      } else if (
        off.code === "CVR" &&
        categorySelected === "Commercial" &&
        dollHousePrice
      ) {
        off.price = dollHousePrice;
      } else if (
        off.code === "MVR" &&
        categorySelected === "Multifamily" &&
        dollHousePrice
      ) {
        off.price = dollHousePrice;
      } else if (off.code === "SMV" && categorySelected === "Residential") {
        off.price = socialMediaPrice ? socialMediaPrice : off.price;
      } else if (off.code === "CSMV" && categorySelected === "Commercial") {
        off.price = socialMediaPrice ? socialMediaPrice : off.price;
      } else if (off.code === "MSMV" && categorySelected === "Multi-family") {
        off.price = socialMediaPrice ? socialMediaPrice : off.price;
      } else if (off.code === "ASMV" && categorySelected === "Additional") {
        off.price = socialMediaPrice ? socialMediaPrice : off.price;
      }
    });
    setState({ ...state, updatedOfferings: newOfferings });
  }, [shoot_type, shoot, offerings]);

  const handleDeselect = (value) => {
    const offering = state.updatedOfferings.filter(
      (off) => off._id === value
    )[0];
    const arr = [...newOfferingsIds];
    arr.splice(arr.indexOf(value), 1);
    const subtotal = state?.subtotal - offering?.price;
    selectedOfferings.splice(selectedOfferings.indexOf(offering), 1);
    setState({
      ...state,
      newOfferingsIds: arr,
      subtotal: subtotal,
    });
  };

  const handleChange = (value, key) => {
    const offering = offerings.filter((off) => off._id === value)[0];

    if (offering.code === "VR") {
      if (state.shootId) {
        offering.price = shoot.dollhouse.price;
      } else {
        message.error(
          "Dollhouse cannot be selected unless shoot is configured to it."
        );
        return;
      }
    }
    // Add offe
    const subtotal = state.subtotal + offering.price;
    setSelectedOfferings((prev) => [...prev, offering]);
    setState({
      ...state,
      [key]: [...state[key], value],
      subtotal: subtotal,
    });
  };

  const handleSave = async () => {
    if (selectedOfferings.length === 0) {
      message.error("Please select at least one offering.");
      return;
    }

    let total_pay = 0.0;
    // Calculate pay for photographer
    selectedOfferings.forEach((prod) => {
      if (photoCodes?.includes(prod.code) && shoot?.events?.photographer) {
        const pay = photographersPayCalc(
          prod?.code,
          shoot?.events?.photographer?.pay_multiplier,
          shoot?.booking_form?.sqft,
          shoot?.trip_charge,
          shoot?.events.start,
          shoot?.events.end,
          shoot?.photographer_drive_time,
          prod.amenities
        );
        total_pay += pay;
      }
    });

    let updatedInvoice;
    const tax = calculateTax(state.subtotal);
    const total = state.subtotal + tax;
    const balance = state.subtotal + tax;
    updatedInvoice = {
      sent: false,
      products: selectedOfferings,
      subtotal: state.subtotal,
      tax: tax,
      total: total,
      balance: balance,
      discount: 0,
      photographer_total_pay: total_pay,
      tax_exempt: false,
      shoot: state.shootId,
      client: state.clientId,
      brokerage: state.brokerageId,
      hsn: shoot?.hsn,
      client_name: `${shoot?.client_name_first} ${shoot?.client_name_last}`,
      address: shoot?.address?.full,
    };
    console.log(updatedInvoice);

    const res = await createInvoice(updatedInvoice);
    if (!res.error) {
      // Update shoot.invoice array with the new invoice _id
      const shootRes = await updateShoot(shoot._id, {
        ...shoot,
        invoice: [...shoot.invoice, res.data._id],
      });

      if (!shootRes.error) {
        message.success("Invoice created successfully.");
        setShowCreateInvoiceModal(false);
        setState({
          ...state,
          newOfferingsIds: [],
          shoot,
          shootId: shoot?._id,
          clientId: shoot?.client_id,
          shoot_type: shoot?.booking_form?.shoot_type,
          brokerageId: shoot?.brokerage?._id,
          updatedOfferings: null,
          subtotal: 0,
        });
      }
    } else {
      message.error("Error creating invoice.");
    }
  };
  return (
    <>
      <Button onClick={() => setShowCreateInvoiceModal(true)}>
        <PlusOutlined /> Add an Invoice
      </Button>
      <Modal
        title={
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            Create Invoice
          </Typography.Title>
        }
        visible={showCreateInvoiceModal}
        onOk={() => handleSave()}
        onCancel={() => {
          setShowCreateInvoiceModal(false);
          setState({
            ...state,
            newOfferingsIds: [],
            shoot,
            shootId: shoot?._id,
            clientId: shoot?.client_id,
            shoot_type: shoot?.booking_form?.shoot_type,
            brokerageId: shoot?.brokerage?._id,
            updatedOfferings: null,
            subtotal: 0,
          });
        }}
        style={{ top: 20 }}
        destroyOnClose={true}
      >
        <Row>
          {/* <Col span={16} offset={4}>
            <Select
              allowClear
              style={{ width: "100%", padding: "5px" }}
              placeholder="Select Shoot Type"
              onChange={(val) => handleChange(val, "shoot_type")}
            >
              <Option value="Residential">Residential</Option>
              <Option value="Commercial">Commercial</Option>
              <Option value="Multi-family">Multi-Family</Option>
            </Select>
          </Col> */}
          <Col
            span={24}
            style={{ paddingTop: "20px", fontWeight: "bold" }}
            align="middle"
          >
            New Offerings
          </Col>
          <Col span={20} offset={2}>
            <Select
              mode="multiple"
              showSearch
              value={newOfferingsIds}
              style={{ width: "100%", padding: "5px" }}
              placeholder="Add new offering"
              onSelect={(val) => handleChange(val, "newOfferingsIds")}
              onDeselect={(val) => handleDeselect(val)}
              filterOption={(input, option) => {
                let offerings =
                  option.children.props.children[0].props.children;
                if (offerings) {
                  return (
                    offerings.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
            >
              {state.updatedOfferings?.map((off) => (
                <Option value={off._id} key={off._id}>
                  <Row key={off._id}>
                    <Col span={20}>{off.description}</Col>
                    <Col span={4} align="end">
                      ${off.price}
                    </Col>
                  </Row>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundColor: "#eee",
          }}
        >
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={4}>Sub Total</Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              {state.subtotal
                ? `$ ${parseFloat(state.subtotal).toFixed(2)}`
                : `$ 0.0`}
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  offering: state.offering,
});

export default connect(mapStateToProps, {
  fetchOfferings,
  createInvoice,
  updateShoot,
})(CreateInvoiceButton);
