import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducer/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import websocketMiddleware from "./middleware/websocketMiddleware"; // Import your custom middleware

// Apply middleware conditionally based on environment
const middleware = [thunk, websocketMiddleware]; // Include your custom middleware here

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools({ trace: true })(applyMiddleware(...middleware));

// Create the Redux store with rootReducer and middleware
const store = createStore(rootReducer, devTools);

export default store;
