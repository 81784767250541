import {
  FETCH_ALL_FLYERS,
  UPDATE_FLYER,
  CREATE_FLYER,
  REMOVE_FLYER,
  FETCH_FLYER_BY_ID
} from "../action/types";

const initialState = { allFlyers: [], currentFlyer: null };

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_FLYERS:
      return {
        ...state,
        allFlyers: action.payload
      };

    case FETCH_FLYER_BY_ID:
      return {
        ...state,
        currentFlyer: action.payload
      };

    case CREATE_FLYER:
      return {
        ...state,
        currentFlyer: action.payload
      };
    case REMOVE_FLYER:
      return {
        ...state,
        currentFlyer: action.payload
      };
    case UPDATE_FLYER:
      return Object.assign(
        {},
        {
          ...state,
          currentFlyer: action.payload
        }
      );
    default:
      return state;
  }
}
