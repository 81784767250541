import React, { useEffect } from "react";
import { connect } from "react-redux";
import NewOrders from "./Order/NewOrders.js";
import ClientDashboard from "./Dashboard/Client";
import PhotoEditorDashboard from "./Dashboard/EditorDashboard";
import VideoEditorDashboard from "./Dashboard/EditorDashboard";
import PhotogDashboard from "./Dashboard/Photographer/index";

const Dashboard = ({ handleCurrentPath, auth: { user } }) => {
  useEffect(() => {
    handleCurrentPath("dashboard", "1");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {(user?.access?.includes("ADMIN") || user?.access?.includes("COMMS")) && (
        <NewOrders />
      )}
      {user?.access?.includes("CLIENT") && <ClientDashboard />}
      {user?.access?.includes("PHOTO") && <PhotoEditorDashboard />}
      {user?.access?.includes("DELUXE") && <PhotoEditorDashboard />}
      {user?.access?.includes("VIDEO") && <VideoEditorDashboard />}
      {user?.access?.includes("PHOTOGRAPHER") && <PhotogDashboard />}
      {user?.access?.includes("SOCIAL") && <ClientDashboard />}
      {user?.access?.includes("MARKETING") && <div>Marketing Dashboard</div>}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state?.auth,
});

export default connect(mapStateToProps, {})(Dashboard);
