import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { createActivity } from "../../../redux/action/activityAction";
import { updateInvoice } from "../../../redux/action/invoiceAction";
import { updateShoot } from "../../../redux/action/shootAction";

const PayPalPayment = ({
  data,
  auth: { user },
  createActivity,
  updateInvoice,
  updateShoot,
  emailPaymentSuccess,
}) => {
  const onSuccess = async (payment) => {
    const paymentData = {
      client: data.client._id,
      invoice: data._id,
      hsn: data.shoot.hsn,
      amount: data.balance,
      fee: "",
      gateway: "paypal",
      payment_method: "PayPal",
      payment_id: payment.paymentID,
      gateway_payer_id: payment.payerID,
      gateway_email: payment.email,
      gateway_token: payment.paymentToken,
      paid: payment.paid,
      date_paid: new Date(),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/payments`,
        { ...paymentData }
      );

      if (res.status === 200) {
        updateInvoice(data._id, {
          ...data,
          date_paid: new Date(),
          balance: 0,
          paid: true,
        });
        createActivity(
          { ...data.shoot, user: user._id },
          `Invoice #${data.shoot.hsn} marked as paid.`
        );
        updateShoot(data.shoot._id, {
          checklist: { invoice_paid: true },
        });
        emailPaymentSuccess(false);
        console.log("Payment successful!", paymentData);
      } else {
        console.log("Payment failed!", paymentData);
      }
    } catch (error) {
      console.log(error);
    }
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    console.log("Payment cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  let env = process.env.REACT_APP_PAYPAL_ENV; // you can set this string to 'production'
  let currency = "USD"; // you can set this string from your props or state
  //let total = data.total; // this is the total amount (based on currency) to charge
  // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

  const client = {
    sandbox: process.env.REACT_APP_PAYPAL_SANDBOX_ID,
    production: process.env.REACT_APP_PAYPAL_PROD_ID,
  };
  // In order to get production's app-ID, you will have to send your app to Paypal for approval first
  // For your sandbox Client-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App" unless you have already done so):
  //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
  // Note: IGNORE the Sandbox test AppID - this is ONLY for Adaptive APIs, NOT REST APIs)
  // For production app-ID:
  //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

  // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
  return (
    <PaypalExpressBtn
      env={env}
      client={client}
      currency={currency}
      total={data.balance}
      onError={onError}
      onSuccess={onSuccess}
      onCancel={onCancel}
      style={{
        size: "responsive",
        color: "blue",
        shape: "rect",
        tagline: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  createActivity,
  updateInvoice,
  updateShoot,
})(PayPalPayment);
