import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Loading.scss";

const Loading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
  return (
    <div className="loading-container">
      <Spin indicator={antIcon} size="large" />
    </div>
  );
};

export default Loading;
