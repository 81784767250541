import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { updateShoot, fetchShootById } from "../../redux/action/shootAction";
import { createNote } from "../../redux/action/note";
import {
  Card,
  Col,
  Row,
  Result,
  Form,
  message,
  Input,
  Button,
  DatePicker,
} from "antd";
import {
  ArrowRightOutlined,
  CalendarOutlined,
  DashboardOutlined,
  QuestionCircleFilled,
  StopOutlined,
} from "@ant-design/icons";
import { createActivity } from "../../redux/action/activityAction";

const Invitation = ({
  match,
  updateShoot,
  fetchShootById,
  createNote,
  createActivity,
}) => {
  const [decline, setDecline] = useState();
  const [visible, setVisible] = useState(true);
  const [declineReason, setDeclineReason] = useState(null);
  const [bannerMessage, setBannerMessage] = useState("");
  const [user, setUser] = useState();
  const [final, setFinal] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [shoot, setShoot] = useState();
  const [altDate, setAltDate] = useState(null);
  const [cancelShoot, setCancelShoot] = useState(false);

  useEffect(() => {
    setDecline(match.params.status);

    const findUser = async () => {
      const shoot = await fetchShootById(match.params.shoot);
      shoot && setUser(shoot.data.user);
    };
    if (match.params.status === "accepted") {
      updateShootDetails();
    }
    findUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateShootDetails = async () => {
    const shootStatus = await updateShoot(match.params.shoot, {
      client_confirmed: "Accepted",
      client_confirmed_date: new Date(),
      checklist: {
        appointment_confirmed: true,
      },
    });
    if (shootStatus?.data) {
      setShoot(shootStatus.data);
      const acceptedMessage = `${shootStatus.data.client_name_first} ${shootStatus.data.client_name_last} has confirmed this shoot.`;
      sendChangeRequestEmail(shootStatus.data, "Accepted", acceptedMessage);
      createActivity(shootStatus.data, acceptedMessage);
      message.success("Thank you for confirming your shoot.");
      setBannerMessage("Thank you for confirming your shoot.");
    } else {
      message.error("Oops!.. Something went wrong");
    }
  };

  const handleRequestCancellation = async () => {
    // const reasonGiven = `${user?.name} has requested a cancellation the shoot. Reason: ${declineReason}`;
    const res = await updateShoot(match.params.shoot, {
      client_confirmed: "Cancelled",
      client_confirmed_date: new Date(),
    });

    if (res.status === 200) {
      const cancelMessage = `${res.data.client_name_first} has requested  a cancellation of this shoot. Reason: ${declineReason}`;

      createActivity(res.data, cancelMessage);

      // Send an email to HS
      sendChangeRequestEmail(res.data, "Cancel", cancelMessage);

      message.success("You have requested a cancellation.");
      setBannerMessage("You have requested a cancellation.");
      setDeclineReason(null);
    }
  };

  const updateShootAndCreateNote = async () => {
    if (declineReason !== "" && cancelShoot === false) {
      // const [shootStatus, noteStatus] = await Promise.all([
      //   updateShoot(match.params.shoot, {
      //     client_confirmed: "Reschedule",
      //   }),
      // createNote({
      //   text: declineReason,
      //   access: user.access,
      //   user: user._id,
      //   entity_id: match.params.shoot,
      //   entity_type: "Shoot",
      // }),
      // ]);
      const res = await updateShoot(match.params.shoot, {
        client_confirmed: "Declined",
        client_confirmed_date: new Date(),
      });
      if (res.status === 200) {
        const rescheduleMessage = `${
          res.data.client_name_first
        } has requested to reschedule this shoot. Reason: ${declineReason}.${
          altDate
            ? ` The requested date is ${moment(altDate).format("MM/DD/YYYY")}`
            : ""
        }`;

        setFinal(false);
        setVisible(false);
        setDecline("accepted");
        setShoot(res.data);
        setConfirmed(true);
        createActivity(res.data, rescheduleMessage);
        message.success("You have requested a reschedule.");
        setBannerMessage("You have requested a reschedule.");
        // Send an email to HS
        sendChangeRequestEmail(res.data, "Reschedule", rescheduleMessage);
      } else {
        message.error("Oops! Something went wrong.");
      }
    }
  };

  const handleOk = () => {
    setFinal(false);
    setVisible(false);
    setDecline("accepted");
    updateShootAndCreateNote();
  };

  const handleDateChange = (value) => {
    if (value) {
      setAltDate(moment(value).format());
      console.log(moment(value).format());
    } else {
      setAltDate(null);
    }
  };

  const sendChangeRequestEmail = async (currentShoot, updatedStatus, msg) => {
    const emailData = {
      requester_name: user?.name,
      request_reason: declineReason,
      street_address: currentShoot?.address?.street,
      new_status: updatedStatus,
      shoot_id: currentShoot?._id,
      hsn: currentShoot?.hsn,
      message: msg,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/notifications/admin-change-request`,
      emailData
    );
    // if (res.status === 200) {
    //   message.success(res.data);
    // }
  };

  const disableDate = (current) => {
    return (
      moment(current).day() === 0 ||
      moment(current).day() === 6 ||
      (current && current < moment().startOf("day"))
    );
  };

  return (
    <>
      <div className="invitation-container">
        <Row gutter={16} justify="center" align="middle">
          <Col>
            <Card className="login-card">
              <Row>
                <Col span={24}>
                  {(decline === "accepted" && !visible && !final) ||
                  match.params.status === "accepted" ? (
                    <>
                      <Result title={bannerMessage} />
                      <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <a href="/lets-book-a-shoot">
                            <Button type="primary" size="large" block>
                              Let's Book a Shoot
                            </Button>
                          </a>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <a href={`/admin/shoots/${shoot?._id}`}>
                            <Button size="large" block>
                              View Shoot Details
                            </Button>
                          </a>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Result
                      title={
                        confirmed
                          ? bannerMessage
                          : "Would you like an alternate appointment?"
                      }
                      icon={<QuestionCircleFilled />}
                    />
                  )}
                  <Row gutter={[8, 8]}>
                    {decline !== "accepted" && visible && (
                      <>
                        <Col span={24}>
                          <Form
                            name="reason"
                            initialValues={{ remember: true }}
                            onFinish={handleOk}
                          >
                            <div className="form-group mt-2">
                              <label>Select an preferred date (optional)</label>
                              <DatePicker
                                onChange={handleDateChange}
                                className="mt-2"
                                disabledDate={disableDate}
                              />
                            </div>
                            <Form.Item
                              name="reason"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your reason.",
                                },
                              ]}
                            >
                              <div className="form-group">
                                <label>
                                  Reason for rescheduling or cancelling
                                  (required)
                                </label>
                                <Input
                                  style={{ marginTop: "0.5em", width: "100%" }}
                                  placeholder="Enter your reason"
                                  allowClear
                                  onChange={(event) =>
                                    setDeclineReason(event.target.value)
                                  }
                                  value={declineReason}
                                />
                              </div>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="mr-2"
                                disabled={!declineReason}
                              >
                                <CalendarOutlined /> Request a Reschedule
                              </Button>
                              <Button
                                disabled={!declineReason || altDate}
                                danger
                                onClick={() => handleRequestCancellation()}
                              >
                                <StopOutlined /> Cancel the Shoot
                              </Button>
                            </Form.Item>
                          </Form>
                        </Col>
                        <Col span={24} align="center">
                          <a href="/admin/dashboard">
                            Go to my Dashboard <ArrowRightOutlined />
                          </a>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect(null, {
  updateShoot,
  fetchShootById,
  createNote,
  createActivity,
})(Invitation);
