import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tabs, Row, Col } from "antd";

import { fetchShootbyHsn } from "../../redux/action/shootAction";
import Gallery from "./Gallery/Gallery";
import Video from "./Video/Video";
import Dollhouse from "./Dollhouse/Dollhouse";
import { READY } from "../../redux/action/shoot/shootConstants";

const { TabPane } = Tabs;

const MediaTabs = ({ shoot: { selectedShoot }, fetchShootbyHsn, changeBg }) => {
  const [state, setState] = useState({
    photos: null,
    video: null,
    dollHouse: null,
    slideShow: null,
    tabView: true,
    selected_view: null,
  });

  const { photos, video, slideShow, dollHouse, tabView, selected_view } = state;

  useEffect(() => {
    const hsn = window.location.pathname.split("/")[3];
    fetchShootbyHsn(hsn);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedShoot && selectedShoot.media) {
      const ready = Object.values(selectedShoot.media).filter(
        (media) => media.status === READY
      );
      const params = new URLSearchParams(window.location.search);
      const view = params.get("tab");
      if (view && view !== "") {
        setState({
          ...state,
          ...selectedShoot.media,
          selected_view: view,
          tabView: false,
        });
      } else {
        setState({
          ...selectedShoot.media,
          tabView: true,
        });
      }
    }
  }, [selectedShoot]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {tabView ? (
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="large"
              tabBarStyle={{
                display: "flex",
                justifyContent: "center",
                zIndex: "1",
                margin: 3,
              }}
              tabBarGutter={0}
              animated={true}
              className="media"
            >
              {photos && (
                <TabPane tab="Gallery" key="1">
                  <Gallery photos={photos} changeBg={changeBg} />
                </TabPane>
              )}
              {video && video.status === READY && (
                <TabPane tab="Video" key="2">
                  <Video video={video} />
                </TabPane>
              )}
              {slideShow && slideShow.status === READY && (
                <TabPane tab="Slideshow" key="3">
                  <Video video={slideShow} />
                </TabPane>
              )}
              {dollHouse && dollHouse.status === READY && (
                <TabPane tab="Dollhouse" key="4">
                  <Dollhouse dollHouse={dollHouse} />
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      ) : selected_view ? (
        <div>
          {selected_view === "gallery" && photos && photos.status === READY && (
            <Gallery photos={photos} changeBg={changeBg} />
          )}
          {selected_view === "video" && video && video.status === READY && (
            <Video video={video} />
          )}
          {(selected_view === "youtube" || selected_view === "slideShow") &&
            slideShow &&
            slideShow.status === READY && <Video video={slideShow} />}
          {selected_view === "dollhouse" &&
            dollHouse &&
            dollHouse.status === READY && <Dollhouse dollHouse={dollHouse} />}
        </div>
      ) : (
        <div>
          {photos && photos.status === READY && (
            <Gallery photos={photos} changeBg={changeBg} />
          )}
          {video && video.status === READY && <Video video={video} />}
          {dollHouse && dollHouse.status === READY && (
            <Dollhouse dollHouse={dollHouse} />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  shoot: state.shoots,
});
export default connect(mapStateToProps, { fetchShootbyHsn })(MediaTabs);
