import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Button, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ListView from "../../../shared/Components/ListView";
import DeviceForm from "./DeviceForm";
import ability from "../../../user/Component/Auth/ability";
import {
  fetchDevicesTableView,
  removeDevice,
  updateDevice,
  createDevice,
} from "../../../redux/action/deviceAction";
import {
  fetchPhotographers,
  fetchPhotographerById,
} from "../../../redux/action/photographerAction";
import queryString from "query-string";
import "./styles.scss";

const Devices = ({
  handleCurrentPath,
  fetchDevicesTableView,
  devices: { devices, totalCount, isLoading },
  removeDevice,
  updateDevice,
  createDevice,
  fetchPhotographers,
  fetchPhotographerById,
  photographer: { photographers },
  auth: { user },
}) => {
  const { confirm } = Modal;
  const [state, setState] = useState({
    title: "Add Device",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,
  });

  const { mode, modalVisiblity, initVal, title } = state;

  const formInitValue = {
    description: "",
    serial_number: "",
    purchase_date: null,
    bought_from: "",
    price: 0,
    warranty_info: "",
    thumbnail: "",
    current_assignee: {
      name: "",
      email: "",
      phone: "",
      address: "",
      date_assigned: "",
      date_returned: "",
      condition: "",
      quantity: 1,
      repair_cost: 0,
      images: [],
    },
    assignee_history: [],
  };

  useEffect(() => {
    handleCurrentPath("Devices", "13");
    fetchDevicesTableView({ search: "", skip, limit: 10 });
    fetchPhotographers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      title: "",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail, record) => (
        <Link to={`/admin/equipment/${record._id}`}>
          <img
            src={thumbnail}
            alt={record.description}
            style={{ width: 80, borderRadius: 5 }}
          />
        </Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description, record) => (
        <Link to={`/admin/equipment/${record._id}`}>{description}</Link>
      ),
    },
    {
      title: "Serial No.",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Assigned To",
      dataIndex: "current_assignee",
      render: (current_assignee, record) =>
        current_assignee ? current_assignee.name : "Unassigned",
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      render: (purchase_date, record) => (
        <span>{moment(purchase_date).format("M/D/YYYY")}</span>
      ),
    },
    {
      title: "Bought From",
      dataIndex: "bought_from",
      key: "bought_from",
    },
    // {
    //   title: "Purchase Price",
    //   dataIndex: "price",
    //   key: "price",
    // },
    {
      title: "",
      key: "_id",
      render: (record) => (
        <span>
          {ability.can("edit", "Device") && (
            <Button
              onClick={() => clickHandler("Edit", record)}
              style={{ margin: "0 5px 5px 0" }}
              icon={<EditOutlined />}
            />
          )}
          {ability.can("delete", "Device") && (
            <Button
              onClick={() => onConfirmRemove(record)}
              danger
              icon={<DeleteOutlined />}
              disabled={record.redeemed}
            />
          )}
        </span>
      ),
    },
  ];

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const history = useHistory();

  const { search, skip, limit } = tableOpt;

  useEffect(() => {
    window.onpopstate = (e) => {
      let skip = 1;
      const value = queryString.parse(history.location.search);
      if (value.page) {
        skip = parseInt(value.page);
        setOperation({ ...tableOpt, skip });
      } else {
        setOperation({ ...tableOpt, skip });
      }
      fetchDevicesTableView();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHandler = ({ target }) => {
    const params = new URLSearchParams();
    params.append("page", skip);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: 1,
      search: target.value,
    });
    fetchDevicesTableView({
      search: target.value,
      skip: 1,
      limit,
    });
  };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchDevicesTableView({
      search,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const onConfirmRemove = (device) => {
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete ${device.description}?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeDevice(device._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type, body) => {
    if (type === "Add") {
      const res = await createDevice(body);
      return res;
    } else {
      const res = await updateDevice(body._id, body);
      return res;
    }
  };

  const clickHandler = (mode, initVal) => {
    setState({
      mode,
      initVal: initVal,
      title: mode + " Device",
      modalVisiblity: true,
    });
  };

  return (
    <div className="equipment-log">
      <ListView
        title="Equipment Log"
        onClick={() => clickHandler("Add", formInitValue)}
        handleSearch={searchHandler}
        buttonText="Add a Device"
        data={devices}
        columns={columns}
        handleTableChange={handleTableChange}
        pagination={{
          current: skip,
          pageSize: limit,
          total: totalCount,
        }}
      />
      <Modal
        title={<span style={{ color: "white" }}>{title}</span>}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={false}
        mask={true}
        maskClosable={false}
        destroyOnClose={true}
        style={{ top: 20 }}
        width={1000}
      >
        <DeviceForm
          initVal={initVal}
          close={handleCancel}
          mode={mode}
          submitHandler={(values) => submitHandler(mode, values)}
          user={user}
          photographers={photographers}
          fetchPhotographerById={fetchPhotographerById}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  devices: state.devices,
  photographer: state.photographer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchDevicesTableView,
  removeDevice,
  updateDevice,
  createDevice,
  fetchPhotographers,
  fetchPhotographerById,
})(Devices);
