import axios from "axios";
import {
  FETCH_DEVICES,
  FETCH_DEVICES_TABLEVIEW,
  FETCH_DEVICE,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  REMOVE_DEVICE,
} from "./types";

export const fetchDevices = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/devices`);
    dispatch({
      type: FETCH_DEVICES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchDevicesTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/devices/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );
    dispatch({
      type: FETCH_DEVICES_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/devices/${id}`
    );
    dispatch({
      type: FETCH_DEVICE,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createDevice = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/devices`,
      formData,
      config
    );
    dispatch({
      type: CREATE_DEVICE,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err);
  }
};

export const updateDevice = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/devices/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_DEVICE,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const removeDevice = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/devices/${id}`);
    dispatch({
      type: REMOVE_DEVICE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
