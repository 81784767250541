import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, DatePicker, Form, Button, message, Spin } from "antd";
import {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
} from "../../../redux/action/photographerAction";
import moment from "moment";

const DistanceTravelled = ({
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
  photographerShoots,
  photographer,
  TotalDistance,
}) => {
  const [newloader, setNewLoader] = useState(false);
  const [shootno, setShootno] = useState();
  const [thisPage, setThisPage] = useState(false);
  const [array, setArray] = useState([]);
  const [totalShootDistance, setTotalShootDistance] = useState();
  useEffect(() => {
    setTotalShootDistance(TotalDistance);
    setNewLoader(false);
  }, [TotalDistance]);
  useEffect(() => {
    if (thisPage) {
      let addPass;

      setShootno(photographerShoots?.length);
      let oldArray = [];
      if (array) {
        oldArray = array;
      }
      let homeAddress = `${photographer.currentPhotographer.geometry.coordinates[1]},${photographer.currentPhotographer.geometry.coordinates[0]}`;
      photographerShoots.map((photographerShoot) => {
        if (addPass === undefined) {
          oldArray.push(`${homeAddress}`);
          addPass = photographerShoot.events.start;
        }
        if (addPass === photographerShoot.events.start) {
          oldArray.push(
            `${photographerShoot.address.geometry.coordinates[1]},${photographerShoot.address.geometry.coordinates[0]}`
          );
          if (oldArray.length === photographerShoots?.length + 1) {
            oldArray.push(`${homeAddress}`);
          }
        }
        if (addPass !== photographerShoot.events.start) {
          oldArray.push(
            `${photographerShoot.address.geometry.coordinates[1]},${photographerShoot.address.geometry.coordinates[0]}`
          );
          oldArray.push(`${homeAddress}`);
          addPass = undefined;
        }
      });
      setArray(oldArray);
      if (array) {
        fetchDistanceArrayAndTotal(
          photographer.currentPhotographer._id,
          oldArray
        );
      } else {
        fetchDistanceArrayAndTotal(
          photographer.currentPhotographer._id,
          oldArray
        );
      }
      setArray([]);
    }
  }, [photographerShoots]);
  const onFinish = async (values) => {
    setThisPage(true);
    setNewLoader(true);
    try {
      const id = photographer.currentPhotographer._id;
      var startdatee = moment(values.startDate._d).format("YYYY-MM-DD");
      var enddatee = moment(values.endDate._d).format("YYYY-MM-DD");
      await fetchPhotographerShoots(
        id,
        `status=Completed&startDate=${startdatee}&endDate=${enddatee}`
      );
    } catch (e) {
      console.log(e);
      message.info("Something went wrong");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {" "}
      <Row>
        <Col span={8}>
          <Form.Item label={<strong>From</strong>} name="startDate">
            <DatePicker
              name="start_date"
              // defaultValue={activationDate ? activationDate : moment()}
              // onChange={(value) =>
              // setActivationDate(moment(value).format())
              // }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<strong>To</strong>} name="endDate">
            <DatePicker
              name="end_date"
              //  defaultValue={}
              // onChange={(value) =>
              // setExpirationDate(moment(value).format())
              // }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Spin spinning={newloader}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Spin>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <p>No. of shoots (Completed) - {shootno ? shootno : "-"}</p>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <p>
            Total Distance Travelled -{" "}
            {totalShootDistance ? `${totalShootDistance} - miles` : "-"}
          </p>
        </Col>
        <Col span={8}></Col>
      </Row>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    photographer: state.photographer,
    photographerShoots: state?.photographer?.photographerShoots,
    TotalDistance: state?.photographer?.TotalDistance,
  };
};
export default connect(mapStateToProps, {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
})(DistanceTravelled);
