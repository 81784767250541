import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Card, Table, Tag, Dropdown, Button, message } from "antd";
import { MoreOutlined, LinkOutlined, FilePdfOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Loading from "../../../shared/loading/Loading.js";
import queryString from "query-string";
import DownloadCsvInvoices from "../../../shared/Components/DownloadCsvInvoices.jsx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfInvoice from "../Invoices/PdfInvoice";

const ClientInvoices = ({ clientInvoices, selectedClient, isLoading }) => {
  const [tableOpt, setOperation] = useState({
    pageSize: 10,
    skip: 1,
    pageSizeOptions: [10, 20, 50, 100],
  });
  const history = useHistory();

  const columns = [
    {
      title: "Invoice#",
      dataIndex: "hsn",
      key: "hsn",
      width: 100,
      render: (hsn, record) => (
        <Link to={`/admin/invoices/${record._id}`}>{hsn}</Link>
      ),
      sorter: (a, b) => a.hsn - b.hsn,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      width: 350,
    },
    {
      title: "Shoot Date",
      dataIndex: "start",
      width: 120,
      key: "start",
      render: (start, record) => (
        <span>
          {record.shoot?.events?.start
            ? moment(record.shoot?.events?.start).format("MM/DD/YY")
            : "---"}
        </span>
      ),
      sorter: (a, b) => {
        const dateA = moment(a.shoot?.events?.start);
        const dateB = moment(b.shoot?.events?.start);
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: 170,
      render: (balance, record) => (
        <span>
          <NumberFormat
            value={record.balance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </span>
      ),
      sorter: (a, b) => a.balance - b.balance,
      responsive: ["lg", "md"],
    },
    {
      title: "Status",
      dataIndex: "paid",
      key: "paid",
      width: 100,
      render: (status, record) => (
        <div>
          <>
            {record.canceled && <Tag>Cancelled</Tag>}
            {record.sent === true && <Tag color="gold">Sent</Tag>}
            {record.paid === false && <Tag color="red">Unpaid</Tag>}
            {record.paid === true && <Tag color="green">Paid</Tag>}
            {record.reschedule === true && <Tag color="pink">Rescheduled</Tag>}
            {record.refunded && <Tag color="purple">Refunded</Tag>}
          </>
        </div>
      ),
      responsive: ["lg", "md"],
    },
    {
      title: "Date Paid",
      dataIndex: "date_paid",
      key: "date_paid",
      width: 120,
      responsive: ["md"],
      render: (date_paid, record) => (
        <span>
          {record.date_paid
            ? moment(record.date_paid).format("MM/DD/YY")
            : "---"}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 100,
      render: (actions, record) => (
        <Dropdown
          menu={{
            onClick: (e) => {
              if (e.key === "2") {
                navigator.clipboard.writeText(
                  `${window.location.origin}/admin/invoices/${record._id}`
                );
                message.success("Link copied to clipboard");
              }
            },
            items: [
              {
                key: "1",
                label: (
                  <PDFDownloadLink
                    fileName={`invoice-${record.shoot?.hsn}.pdf`}
                    document={<PdfInvoice invoice={record} />}
                  >
                    <Button
                      className="ml-1 mt-2 float-right"
                      size="small"
                      type="link"
                      danger
                    >
                      <FilePdfOutlined /> Download PDF
                    </Button>
                  </PDFDownloadLink>
                ),
              },
              {
                key: "2",
                label: (
                  <>
                    <LinkOutlined style={{ marginRight: 8 }} />
                    Copy Link
                  </>
                ),
              },
            ],
          }}
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const setPageBasedOnParam = () => {
    let skip = 1;
    const value = queryString.parse(history.location.search);
    if (value.page) {
      skip = parseInt(value.page);
      setOperation({ ...tableOpt, skip });
    } else {
      setOperation({ ...tableOpt, skip });
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", setPageBasedOnParam);
    return () => {
      window.removeEventListener("popstate", setPageBasedOnParam);
    };
  }, []);
  useEffect(() => {
    setPageBasedOnParam();
  }, []);

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("tab", "Invoices");
    params.append("page", pagination.current);
    history.push({ search: params.toString() });
    setOperation({
      ...tableOpt,
      skip: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const items = [
    {
      key: "1",
      label: "Download Invoice",
    },
    {
      key: "2",
      label: "Copy Link",
    },
  ];

  const onMenuClick = (e) => {
    console.log("click", e);
  };

  return (
    <div>
      <Card>
        {isLoading && (
          <div className="loading-container">
            <Loading />
          </div>
        )}
        {!isLoading && clientInvoices && (
          <>
            <div className="float-right mb-3">
              <DownloadCsvInvoices clientId={selectedClient?._id} />
            </div>
            <Table
              dataSource={clientInvoices}
              columns={columns}
              onChange={handleTableChange}
              pagination={{
                ...tableOpt,
                total: clientInvoices?.length,
                current: tableOpt.skip,
                defaultCurrent: tableOpt.skip,
              }}
              rowKey={(record) => record._id}
              scroll={{ x: 500 }}
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default ClientInvoices;
