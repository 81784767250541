import axios from "axios";
import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_TABLEVIEW,
  FETCH_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
} from "./types";

export const fetchTemplates = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/templates`);
    dispatch({
      type: FETCH_TEMPLATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchTemplatesTableView = (params) => async (dispatch) => {
  try {
    const { search, skip, limit } = params;
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/templates/tableview?search=${search}&skip=${skip}&limit=${limit}`
    );

    dispatch({
      type: FETCH_TEMPLATES_TABLEVIEW,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const fetchTemplateById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/templates/${id}`
    );
    dispatch({
      type: FETCH_TEMPLATE,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createTemplate = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/templates`,
      formData,
      config
    );
    dispatch({
      type: CREATE_TEMPLATE,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.log("err", err);
  }
};

export const updateTemplate = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/templates/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_TEMPLATE,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const removeTemplate = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_ROOT_URL}/templates/${id}`);
    dispatch({
      type: REMOVE_TEMPLATE,
      payload: id,
    });
  } catch (err) {
    console.log(err);
  }
};
