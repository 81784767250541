import {
  FETCH_DEVICES,
  FETCH_DEVICES_TABLEVIEW,
  FETCH_DEVICE,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  REMOVE_DEVICE,
} from "../action/types";

const initialState = { devices: [], selectedDevice: {}, isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DEVICES:
      return {
        ...state,
        isLoading: false,
        devices: payload,
      };
    case FETCH_DEVICES_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        devices: payload.devices,
        totalCount: payload.count,
      };
    case FETCH_DEVICE:
      return {
        ...state,
        isLoading: false,
        selectedDevice: payload,
      };
    case CREATE_DEVICE:
      return {
        ...state,
        isLoading: false,
        devices: [...state.devices, payload],
        selectedDevice: payload,
      };
    case UPDATE_DEVICE:
      const updatedDevices = state.devices.concat();
      updatedDevices.map((post, index) => {
        if (post._id === payload._id) {
          updatedDevices[index] = payload;
        }
        return true;
      });
      return {
        ...state,
        isLoading: false,
        devices: updatedDevices,
        selectedDevice: payload,
      };
    case REMOVE_DEVICE:
      return {
        ...state,
        isLoading: false,
        devices: state.devices.filter((post) => post._id !== payload),
      };
    default:
      return state;
  }
}
