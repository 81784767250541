import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Input, Button, message, Row, Col } from "antd";
import {
  createSubdivision,
  updateSubdivision,
} from "../../../redux/action/subdivisionAction";

const SubDivisionForm = ({
  subDivision,
  mode,
  createSubdivision,
  updateSubdivision,
  onOk,
  handleCancel,
  subdivision,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    const filteredData = subdivision?.find(
      (sub) => sub._id === subDivision?._id
    );
    if (filteredData) {
      setFormData({
        ...formData,
        name: filteredData.name,
        city: filteredData.city,
        state: filteredData.state,
        zip: filteredData.zip,
      });
    }
  }, [subDivision]);

  const { name, state, city, zip } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCancel = () => {
    setFormData({});
    handleCancel();
  };

  const onSubmit = async (e) => {
    if (
      formData.name &&
      formData.city &&
      formData.state &&
      formData.zip &&
      mode === "create"
    ) {
      e.preventDefault();
      const res = await createSubdivision(formData);
      if (res) {
        message.success("Subdivision created Successfullt!");
      }
      setFormData({});
      onOk();
    } else if (
      formData.name &&
      formData.city &&
      formData.state &&
      formData.zip &&
      mode === "edit"
    ) {
      const res = await updateSubdivision(subDivision._id, formData);
      if (res) {
        message.success("SubDivision updated Successfully!");
      }
      setFormData({});
      handleCancel();
    } else {
      if (!formData.name) {
        message.error("Please enter subdivision name to proceed");
      }
      if (!formData.city) {
        message.error("Please enter city to proceed");
      }
      if (!formData.state) {
        message.error("Please enter state to proceed");
      }
      if (!formData.zip) {
        message.error("Please enter zipcode to proceed");
      }
    }
  };

  return (
    <Row gutter={12}>
      <Col span={24}>
        <div className="form-group">
          <label>Subdivision Name</label>
          <Input
            value={formData.name}
            name="name"
            onChange={(e) => onChange(e)}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <div className="form-group">
          <label>City</label>
          <Input
            value={formData.city}
            name="city"
            onChange={(e) => onChange(e)}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <div className="form-group">
          <label>State </label>
          <Input
            value={formData.state}
            name="state"
            onChange={(e) => onChange(e)}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <div className="form-group">
          <label>Zip code </label>
          <Input
            value={formData.zip}
            name="zip"
            onChange={(e) => onChange(e)}
          />
        </div>
      </Col>
      <div className="form-group">
        <Button
          type="primary"
          onClick={(e) => onSubmit(e)}
          style={{ marginTop: "20px", marginRight: 10 }}
        >
          Save
        </Button>
        <Button onClick={onCancel} style={{ marginTop: "20px" }}>
          Cancel
        </Button>
      </div>
    </Row>
  );
};
const mapStateToProps = (state) => ({
  subdivision: state.subdivision.divisions,
});
export default connect(mapStateToProps, {
  createSubdivision,
  updateSubdivision,
})(SubDivisionForm);
