import {
  FETCH_OFFERINGS,
  CREATE_OFFERING,
  REMOVE_OFFERING,
  UPDATE_OFFERING,
  FETCH_OFFERINGS_TABLEVIEW,
} from "../action/types";

const initialState = { offerings: [], isLoading: true };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_OFFERINGS:
      return {
        ...state,
        isLoading: false,
        offerings: payload,
      };
    case CREATE_OFFERING:
      return {
        ...state,
        offerings: [...state.offerings, payload],
        isLoading: false,
      };
    case REMOVE_OFFERING:
      return {
        ...state,
        offerings: state.offerings.filter(
          (offering) => offering._id !== payload
        ),

        isLoading: false,
      };
    case FETCH_OFFERINGS_TABLEVIEW:
      return {
        ...state,
        isLoading: false,
        offerings: payload.newOfferings,
        totalCount: payload.count,
      };

    case UPDATE_OFFERING:
      return Object.assign(
        {},
        {
          ...state,
          offerings: state.offerings.map((offering) =>
            offering._id === payload._id ? payload : offering
          ),
          isLoading: false,
        }
      );
    default:
      return state;
  }
}
