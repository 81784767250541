import React, { useState, useRef } from "react";
import { Button } from "antd";

import Mute from "../../../content/icons/mute.svg";
import Unmute from "../../../content/icons/unmute.svg";

const AudioButton = ({ soundSrc }) => {
  const [muted, setMuted] = useState(true);

  const audioRef = useRef();

  const handlePlay = () => {
    audioRef.current.play();
    audioRef.current.muted = false;
    setMuted(false);
  };
  const handleMute = () => {
    audioRef.current.muted = true;
    setMuted(true);
  };
  return (
    <div>
      <audio ref={audioRef} id="music">
        <source src={soundSrc} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {!muted ? (
        <Button type="link" shape="circle" size="large" onClick={handleMute}>
          <img src={Mute} width="30" alt="Sound Off" />
        </Button>
      ) : (
        <Button type="link" shape="circle" size="large" onClick={handlePlay}>
          <img src={Unmute} width="30" alt="Sound On" />
        </Button>
      )}
    </div>
  );
};

export default AudioButton;
