import React from "react";
import { Card, Row, Col } from "antd";

import CardList from "./CardList";
import CardForm from "./CardForm";

import "./style.scss";

const PaymentMethods = ({
  selectedClient,
  cards,
  savePaymentMethod,
  removePaymentMethod,
}) => {
  return (
    <div>
      <Card>
        {/* <h4>Payment Methods</h4> */}
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {" "}
            <CardList
              selectedClient={selectedClient}
              cards={cards}
              removePaymentMethod={removePaymentMethod}
              allowPay={false}
              allowDelete={true && cards?.length > 1}
              removePaymentMethodForShoot={() => null}
              allowRemovePaymentMethodForShoot={false}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CardForm
              selectedClient={selectedClient}
              savePaymentMethod={savePaymentMethod}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PaymentMethods;
